<template lang="pug">
  div.creative-device-split-rows
    div(v-for="(value, device) of values" :key="device")
      div.inline-flex(v-tooltip="{value: `${labels[device]} ${type_labels[type]}`}")
        i(:class="`nexd-icon-16-${device}`")
        div.label.ml-8 {{value}}
</template>
<script>
export default {
  name: 'CreativeDetailedDeviceAnalyticsRow',
  props: {
    analytics: Object,
    type: String,
    percentage: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      values: {},
      labels: {
        mobile: 'Mobile',
        desktop: 'Desktop',
        tv: 'TV'
      },
      type_labels: {
        impressions: 'Impressions',
        engagement: 'Engagement',
        ctr: 'CTR',
        viewable: 'Viewable Impressions',
        engagements: 'Engaged Impressions',
        clicks: 'Clicks'
      }
    };
  },

  created() {
    for (const device in this.analytics) {
      if (device !== 'total') {
        if (this.percentage) {
          this.$set(this.values, device, this.$options.filters.PercentageFilter(this.analytics[device][this.type]));
        } else {
          this.$set(this.values, device, this.$options.filters.NumberFilter(this.analytics[device][this.type]));
        }
      }
    }
  }
};
</script>
