<template lang="pug">
SearchSelectCustom(
  variant="creative_import"
  :search="false"
  :handler="handleOptions"
  @select="importCreative"
)
  template(v-slot:placeholder="{opened}")
    Button(type="dropdown" label="Import" :disabled="disabled" :active="opened" :flip="true")
      template(v-slot:suffix)
        IconDropdown
</template>

<script>
import { IMPORT } from '@master/constants';

import CreativesMixins from '@cm/Views/Creatives/Components/CreativesMixins.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import Button from '@master/UI/Buttons/Button.vue';
import IconDropdown from '@master/UI/Buttons/Icons/IconDropdown.vue';
import SearchSelectCustom from '@master/UI/SearchSelect/SearchSelectCustom.vue';

export default {
  name: 'CreativesImport',

  mixins: [CreativesMixins, SDKMixin],

  components: {
    Button,
    IconDropdown,
    SearchSelectCustom,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    show_HTML5_option() {
      if (this.admin_toggle) {
        return true;
      }
      return !this.instream_expert;
    },

    show_social_option() {
      if (this.admin_toggle) {
        return true;
      }

      return this.is_social_approved && !this.is_sdk_user;
    },

    import_dropdown_options() {
      let options = [
        {
          value: IMPORT.VAST,
          label: 'VAST Video',
          icon: 'nexd-icon-16-video',
        },
      ];

      if (this.show_responsive_type_option) {
        options.push({
          value: IMPORT.QUANTUM,
          label: 'Responsive',
          icon: 'nexd-icon-16-responsive',
        });
      }

      if (this.show_HTML5_option) {
        options.push({
          value: IMPORT.HTML5,
          label: 'HTML5 Zip/Tag',
          icon: 'nexd-icon-16-html',
        });
      }

      options.push({
        value: IMPORT.EXCEL,
        label: 'Data from XLS / CSV',
        icon: 'nexd-icon-16-xls-csv-file',
      });

      if (this.show_social_option) {
        options.push({
          value: IMPORT.SOCIAL,
          label: 'Import from Social',
          icon: 'nexd-icon-16-facebook',
        });
      }

      return options;
    },
  },

  data() {
    return {
      admin_toggle: false,
      is_social_approved: false,
    };
  },

  created() {
    this.$user.subscribe(_ => {
      this.admin_toggle = this.$user.adminMode();
      this.is_social_approved = this.$user.isSocialApproved();
    }, this);
  },

  methods: {
    handleOptions() {
      return Promise.resolve(this.import_dropdown_options);
    },

    importCreative(item, close) {
      this.$emit('createPlacement', item.value);
      close();
    },
  },
};
</script>
