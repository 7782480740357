<template lang="pug">
div
  Breadcrumb(:items="breadcrumbs")
  center.mt-32
    h1.fs-22 Welcome to DCO Tools
    p.mt-18.mb-24.color-gray-800.fs-14.lh-18(style="max-width: 500px")
      | Our DCO tools automatically optimize your ad delivery based on the metrics you choose.
      | Select the option below that best fits your needs to proceed.

    div.inline-flex.flex-align-start.column-gap-16
      DCOActionCard(
        title="Creative optimization"
        description="Boost top-performing creatives by optimizing for CTR or Engagement"
        icon="nexd-icon-32-dco"
        :loading="loading_dco"
        :disabled="loading_flight"
        @click="selectDco"
      )
      DCOActionCard(
        title="Flights"
        description="Display ads based on custom rules to target your audience"
        icon="nexd-icon-32-flight-timeline"
        :loading="loading_flight"
        :disabled="loading_dco"
        @click="selectFlight"
      )
</template>

<script>
import { VIEW } from '@master/constants';

import Breadcrumb from '@cm/UI/Global/Breadcrumb';
import DCOActionCard from '@cm/Views/Flights/Components/DCOActionCard/DCOActionCard.vue';

export default {
  name: 'DCOSelection',

  components: {
    Breadcrumb,
    DCOActionCard,
  },

  data() {
    return {
      breadcrumbs: [],

      loading_dco: false,
      loading_flight: false,
    };
  },

  created() {
    this.getBreadcrumbs();
  },

  methods: {
    getBreadcrumbs() {
      this.$http.get(`v2/campaigns/${this.$route.params.campaign_id}/flights/breadcrumbs`).then(breadcrumbs => {
        this.breadcrumbs = breadcrumbs;
      });
    },

    selectFlight() {
      if (this.loading_flight) {
        return;
      }

      this.loading_flight = true;

      this.$http
        .post(`v2/campaigns/${this.$route.params.campaign_id}/flights`)
        .then(flight => {
          this.redirect(flight.id);
        })
        .catch(() => {
          /** suppress errors, handled by HttpService */
        })
        .finally(() => {
          this.loading_flight = false;
        });
    },

    selectDco() {
      if (this.loading_dco) {
        return;
      }

      this.loading_dco = true;

      this.$http
        .post(`v2/campaigns/${this.$route.params.campaign_id}/flights/dco`)
        .then(flight => {
          this.redirect(flight.id);
        })
        .catch(() => {
          /** suppress errors, handled by HttpService */
        })
        .finally(() => {
          this.loading_dco = false;
        });
    },

    redirect(flight_id) {
      this.$router.push({
        name: VIEW.FLIGHT,
        params: {
          flight_id,
        },
      });
    },
  },
};
</script>
