<template lang="pug">
div.charts-wrapper
  div.single-chart(ref="chart" style="height: 280px")
</template>

<script>
import Filters from '@master/Filters';

import Chartist from '@libs/Chartist';
import PointTooltip from '@analytics/Classes/PointTooltip';

function TooltipContent(total, impressions) {
  let numberFilter = Filters.NumberFilter;
  let percentageFilter = Filters.PercentageFilter;
  return `<div class="ct-engagement-row">
    <h1 class="value">${percentageFilter(impressions / total)}<small class="text-nowrap">(${numberFilter(impressions)})</small></h1>
  </div>`;
}

export default {
  name: 'VideoEngagementChart',

  props: {
    chart: Object,
    total: Number,
  },

  mounted() {
    this.loadChart();
  },

  methods: {
    loadChart() {
      // initial filter change happens before mounting, use timeout to wait
      this.$nextTick(() => {
        // allow chart to load only after input data exists
        if (this.chart == null) return;

        const labels = this.chart.labels;
        const series = this.chart.series;
        const options = {
          chartPadding: {
            left: 16,
            bottom: 0,
            right: 16,
          },
          axisY: {
            type: Chartist.FixedScaleAxis,
            divisor: 5,
            low: 0,
          },
          axisX: {
            limitLabels: true,
          },
        };

        let chartData = [];
        for (const value of series) {
          chartData.push({ meta: TooltipContent(this.total, value), value });
        }

        const chart = new Chartist.Line(
          this.$refs['chart'],
          {
            labels,
            series: [chartData],
          },
          options,
        );

        // save horiozontal grid lines, to bind them to the point (visibility on hover)
        let lines = {};
        const drawHandler = context => {
          if (context.seriesIndex === 1) {
            // series index 1 can only be on average lines
            if (context.type === 'point') {
              const parent = context.element.parent();
              parent.addClass('ct-dashed');
              parent.addClass('yellow-primary');
            }
          } else if (context.type === 'point' || context.type === 'line') {
            const colour = 'blue-primary';
            const parent = context.element.parent();
            parent.addClass(colour);
            parent.addClass('animate');
            if (context.type === 'point') {
              const tooltip = new PointTooltip(context, lines[context.index]);
              const newSVG = tooltip.init();
              context.element.replace(newSVG);
            } else {
              try {
                const path = context.element.getNode();
                const length = Math.ceil(path.getTotalLength());
                parent.getNode().setAttribute('style', '--dataLength: ' + length);
              } catch (err) {
                // element not yet rendered
              }
            }
          } else if (context.type === 'grid') {
            if (context.element.classes().indexOf('ct-horizontal') !== -1) {
              lines[context.index] = context.element.getNode();
            }
          }
        };
        chart.on('draw', context => {
          drawHandler(context);
        });
      });
    },
  },
};
</script>
