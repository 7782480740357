<template lang="pug">
TestLayout(title="UI Group")

  TestSection(subtitle="default" )
    UIGroup(title="UI Group")
      span Opened

  TestSection(subtitle="subtitle")
    UIGroup(title="UI Group" :subtitle="true")
      span Opened

  TestSection(subtitle="uppercase")
    UIGroup(title="UI Group" :uppercase="true")
      span Opened

  TestSection(subtitle="closed by default")
    UIGroup(title="UI Group" :collapsed="true")
      span Opened
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import UIGroup from '@master/UI/UIGroup.vue';

export default {
  name: 'UIGroupTest',

  components: {
    TestLayout,
    TestSection,

    UIGroup,
  },

  data() {
    return {};
  },
};
</script>
