<template lang="pug">
div(:class="styles.details")
  slot
</template>

<script>
import styles from './Detail.module.scss';

export default {
  name: 'Details',

  data() {
    return {
      styles
    };
  }
};
</script>
