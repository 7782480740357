<template lang="pug">
TestLayout(title="Creative Size")
  div(style="grid-column: 1 / -1;")
    CreativeDetails.p-0(v-if="creative" :creative="creative")
    pre.mt-24 {{ state }}
</template>

<script>
import CreativeSizeService from '@master/Services/CreativeSizeService';
import { getNewCreativeOptions, newCreative } from '@helpers/Creative';

import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';

import CreativeDetails from '@cm/Views/Creatives/Creative/CreativeDetails.vue';

export default {
  name: 'CreativeSizesTest',

  components: {
    TestLayout,

    CreativeDetails,
  },

  data() {
    return {
      creative: null,
      state: null,
    };
  },

  async created() {
    this.creative = await newCreative(getNewCreativeOptions('infeed'));

    CreativeSizeService.subscribe(state => {
      this.state = state;
    }, this);
  },
};
</script>
