import moment from 'moment';

export function generateCalendar(start, month) {
  const dates = [];

  while (dates.length < 42) {
    dates.push(getDateObject(getMoment(dates.length, start), month));
  }

  return dates;
}

function getMoment(index, unix) {
  return moment.unix(unix).utc().add(index, 'days');
}

function getDateObject(m, month) {
  return {
    date: m.format('D'),
    unix: m.startOf('day').unix(),
    odd: m.month() !== month,
  };
}

export function getWeekdaysAbbreviation(isoWeek = true) {
  const weekday_abbreviations = ['M', 'T', 'W', 'T', 'F', 'S'];

  if (isoWeek) {
    weekday_abbreviations.push('S');
  } else {
    weekday_abbreviations.unshift('S');
  }

  return weekday_abbreviations;
}
