<template lang="pug">
  div
    small(v-tooltip="{template: 'analytics.categorydivison'}") Device division
    div.row(v-if="Object.keys(analytics).length > 0")
      template(v-for="(item, device_key) of devices")
        div.col-8(v-if="analytics[device_key] != null && analytics[device_key] > 0" :key="item.label")
          div.icon
            i(:class="item.icon" aria-hidden="true")
          h3 {{analytics[device_key] | PercentageFilter}}
          small {{item.label}}
    div.mt-8(v-else) Not enough impressions
</template>

<script>

export default {
  name: 'SummaryCardFooter',

  props: {
    analytics: Object
  },

  data() {
    return {
      devices: {
        mobile: {
          icon: 'nexd-icon-32-mobile-portrait',
          label: 'Mobile'
        },
        desktop: {
          icon: 'nexd-icon-32-desktop',
          label: 'Desktop'
        },
        tv: {
          icon: 'nexd-icon-32-tv',
          label: 'TV'
        }
      }
    };
  }
};
</script>
