import Subscriber from '@libs/Subscriber';

class CreativeService extends Subscriber {
  subscribe(cb, vnode = null) {
    super.subscribe(cb, vnode);
  }

  get() {
    return this.data;
  }

  set(creative) {
    this.data = creative;
  }
}

export default new CreativeService();
