<template lang="pug">
a(
  v-if="href != null"
  :class="[styles.info_row, styles.download]"
  :href="href"
  download
)
  em(v-if="title != null") {{ title }}:&nbsp;
  span(v-if="text != null") {{ text }}
span(v-else :class="styles.info_row")
  em(v-if="title != null") {{ title }}:&nbsp;
  span(v-if="text != null") {{ text }}
</template>

<script>
import styles from './Slot.module.scss';

export default {
  name: 'SlotTextRow',

  props: {
    title: {
      type: String,
      default: null
    },

    text: {
      type: String,
      default: null
    },

    href: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      styles
    };
  }
};
</script>
