<template>
  <div
    class="pie-chart"
    :class="{'over-fifty': _percentage > 50, 'has-warning': _percentage > 80 && _percentage < 100, 'has-danger': _percentage >= 100}"
    :style="'--rotate: ' + _percentage / 100 + 'turn'">
  </div>
</template>

<script>
export default {
  name: 'PieChart',
  props: {
    percentage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      /* eslint-disable vue/no-reserved-keys */
      _percentage: 0
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.percentage > 1) {
        this._percentage = 1;
      } else if (this.percentage < 0) {
        this._percentage = 0;
      } else {
        this._percentage = this.percentage;
      }
      this._percentage *= 100;
      this.$forceUpdate();
    }
  },
  watch: {
    percentage: {
      handler() {
        this.init();
      }
    }
  }
};
</script>
