<template lang="pug">
  div.card-body.transparent
    div.row
      div.col.flex-grow
        div.inline-block(v-tooltip="{template: 'analytics.trackedAds'}")
          h3 {{analytics.total.trackable | NumberFilter}}
          small Tracked ads
      div.col-10(v-if="admin_toggle")
        div.inline-block
          h3 {{analytics.total.billable | NumberFilter}}
          small Billable
    div.row(v-if="analytics.total.loaded != null")
      div.col.flex-grow
        div.inline-block(v-tooltip="{template: 'analytics.loadedImp'}")
          h3 {{analytics.total.loaded | NumberFilter}}
          small Loaded Impr.
      div.col-10(v-if="analytics.total.load_rate != null")
        div.inline-block(v-tooltip="{template: 'analytics.loaded'}")
          h3 {{analytics.total.load_rate | PercentageFilter}}
          small Load rate
    div.row(v-if="Controller.isViewabilityEnabled() === true && analytics.total.viewable != null")
      div.col.flex-grow
        div.inline-block(v-tooltip="{template: 'analytics.viewable'}")
          h3 {{analytics.total.viewable | NumberFilter}}
          small Viewable Impr.
      div.col-10(v-if="analytics.total.viewability != null")
        div.inline-block(v-tooltip="{template: 'analytics.viewability'}")
          h3 {{(analytics.total.viewability) | PercentageFilter}}
          small Viewability
</template>

<script>
import { Controller } from '@master/Services/AnalyticsAPPService';

export default {
  name: 'ImpressionsAddon',
  props: {
    analytics: Object
  },

  data() {
    return {
      admin_toggle: false,

      Controller
    };
  },

  created() {
    this.$user.subscribe(() => {
      this.admin_toggle = this.$user.adminMode();
    }, this);
  }
};
</script>
