<template lang="pug">
div#creative-row(:class="{'disabled': disabled}" v-tooltip="disabled && disabled_tooltip ? disabled_tooltip : null")
  label.creative-row-title(:class="{'disabled': disabled, 'flex flex-align-center': has_checkbox}")
    slot(name="checkbox")
    span(v-if="label != null") {{ label }}
    i.nexd-icon-32-help(
      v-if="tooltip != null"
      aria-hidden="true"
      v-tooltip="tooltip"
    )
    slot(name="switch-type")
  div.creative-row-content
    slot(name="content")
</template>

<script>
import CreativeTraits from '@master/Traits/CreativeTraits';

export default {
  name: 'CreativeRow',

  mixins: [CreativeTraits],

  props: {
    label: {
      type: String,
      default: null,
    },

    tooltip: {
      type: Object,
      default: () => null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    disabled_tooltip: {
      type: Object,
      default: () => null,
    },

    has_checkbox: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
