<template lang="pug">
  div.effect-body
    div.form-group
      div.row
        div.col-9
          p(style="line-height: 3rem")
            span Object
            i.nexd-icon-16-help.ml-8(v-tooltip="{value: object_tooltip}")
        div.col
          CustomAsset( :creative="creative" :asset_id="'custom_effect_' + effect.id" :cropper_enabled="false" @change="customAssetChangeHandler")
          em.block.mt-4.fs-12.color-gray-800 Accepted files:&nbsp;
            span.color-gray-500 ({{ file_extensions }})
          em.block.mt-4.fs-12.color-gray-800 Size:&nbsp;
            span.color-gray-500 Max: placement size {{creative.width * 2}}x{{creative.height * 2}}
    div.row.flex-align-center
      div.col(style="line-height: 3rem" @click="toggle")
        label.color-primary Settings
        i.nexd-icon-32-arrow-down-small.color-primary.ml-8(aria-hidden="true" :class="{'active': !collapsed}")
    template(v-for="(effect, key) of effect_settings" v-if="!collapsed")
      div.row(style="padding: 0.7rem 0;")
        div.col-9
          p
            span {{effect.title}}
            i.nexd-icon-16-help.ml-8(v-tooltip="{value: effect.tooltip}")
        div.col-13
          Slider(
            v-if="effect.type === 'slider'"
            v-model="settings[key]"
            :options="effect.options"
            @change="change"
          )
          Toggle( v-if="effect.type === 'toggle'" v-model="settings[key]" @input="change")
          AnglePicker(v-if="effect.type === 'chart'" :angle="settings[key]" @change="angleChangeHandler" :reference="key")
</template>

<script>
import AssetHelper from '@helpers/Asset';

import CustomAsset from './CustomAsset';
import Slider from '@master/UI/Slider';
import Toggle from '@master/UI/Toggle';
import AnglePicker from '@master/UI/AnglePicker';

import { clone } from '@helpers/Global';

const predefined_settings =  {
  item_size_multiplier: {
    title: 'Object size',
    tooltip: 'Choose the base size of the objects.',
    type: 'slider',
    options: { min: 0.1, max: 5, step: 0.1, min_text: 'Small', max_text: 'Big'}
  },
  randomize_item_size: {
    title: 'Randomize size',
    tooltip: 'If you would like the sizes of the objects to vary (up to 50% smaller or bigger than chosen object size).',
    type: 'toggle'
  },
  speed_multiplier: {
    title: 'Speed',
    tooltip: 'Specify how slow or fast the objects should move on the ad.',
    type: 'slider',
    options: { min: 0.1, max: 5, step: 0.1, min_text: 'Slow', max_text: 'Fast'}
  },
  alpha_multiplier: {
    title: 'Opacity',
    tooltip: 'Select how transparent the objects are. (The lower the value, the more transparent the object is).',
    type: 'slider',
    options: { min: 0.1, max: 1, step: 0.05, min_text: '10%', max_text: '100%'}
  },
  randomize_alpha: {
    title: 'Randomize opacity',
    tooltip: 'If you would like the opacity of the objects to vary.',
    type: 'toggle'
  },
  item_count: {
    title: 'Density',
    tooltip: 'Choose the amount of objects that are animated on the ad.',
    type: 'slider',
    options: { min: 100, max: 10000, step: 100, min_text: 'Low', max_text: 'High'}
  },
  direction: {
    title: 'Direction',
    tooltip: 'Specify the angle where the objects move onto the ad.',
    type: 'chart'
  }
};

export default {
  name: 'CustomParticlesEffect',

  components: {
    CustomAsset,
    Slider,
    Toggle,
    AnglePicker
  },

  props: {
    creative: Object,
    effect: Object,
    settings: Object
  },

  computed: {
    object_tooltip() {
      return `<div>
        <div>Select the object icon from dropdown. You can also drag a custom icon from Asset library or reset to default.</div>
        <br/>
        <div>Particle files should consist of 1 element eg. if the intention is to show clouds, it should contain a cloud, not a whole sky.</div>
      <div>`;
    },
  },

  data() {
    return {
      effect_settings: clone(predefined_settings),
      collapsed: false,
      file_extensions: AssetHelper.mimeToExtension(AssetHelper.getImageMimes().join(', '))
    };
  },

  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },

    change() {
      this.$emit('change');
    },

    customAssetChangeHandler(reload_assets_library = false) {
      this.$emit('change', reload_assets_library);
    },

    angleChangeHandler(value) {
      this.$set(this.settings, 'direction', value);
      this.change();
    }
  }
};

</script>
