<template>
  <div class="stickybar">
    <div class="text">
      Calculations are based on
      <strong :class="rightStyle">{{ calculationBasedOn }}</strong>
      from
      <strong :class="rightStyle">{{ filters.start | DateRangeFilter(filters.end, { utc: true }) }}.</strong>
      Week starts on
      <strong :class="rightStyle">{{ filters.start_week }}.</strong>
    </div>
  </div>
</template>

<script>
import { filters } from '@master/Services/AnalyticsAPPService.js';

export default {
  name: 'Stickybar',

  data() {
    return {
      filters,
    };
  },

  computed: {
    calculationBasedOn() {
      return filters.base === 'viewable' ? filters.base + ' impressions' : filters.base;
    },

    rightStyle() {
      return this.calculationBasedOn === 'viewable impressions' ? 'color-primary' : '';
    },
  },
};
</script>
