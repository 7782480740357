import { LISTITEM } from '@master/constants';

export default class Selection {
  #data = null;

  selected_items = [];
  selected_folders = [];
  selected = [];

  has_items_selection = false;
  has_folders_selection = false;

  is_some_selected = false;
  is_all_selected = false;

  /**
   * @param {{ item_id: string }[]} data
   */
  set data(data) {
    this.#data = data;

    this.#validateSelection();
    this.#updateMeta();
  }

  select(item) {
    if (item.item_type === LISTITEM.FOLDER) {
      this.#selectFolder(item.item_id);
    } else {
      this.#selectItem(item.item_id);
    }

    this.#updateMeta();
  }

  unselect(item) {
    if (item.item_type === LISTITEM.FOLDER) {
      this.#unselectFolder(item.item_id);
    } else {
      this.#unselectItem(item.item_id);
    }

    this.#updateMeta();
  }

  toggle(item) {
    if (this.selected_items.includes(item.item_id) || this.selected_folders.includes(item.item_id)) {
      this.unselect(item);
    } else {
      this.select(item);
    }
  }

  selectAll() {
    if (this.#data == null) {
      return;
    }

    for (const item of this.#data) {
      this.select(item);
    }

    this.#updateMeta();
  }

  unselectAll() {
    this.selected_items = [];
    this.selected_folders = [];

    this.#updateMeta();
  }

  toggleSelectAll() {
    if (this.is_all_selected) {
      this.unselectAll();
    } else {
      this.selectAll();
    }
  }

  isSelected(item) {
    return this.selected.includes(item.item_id);
  }

  #selectItem(item_id) {
    if (!this.selected_items.includes(item_id)) {
      this.selected_items.push(item_id);
    }
  }

  #selectFolder(item_id) {
    if (!this.selected_folders.includes(item_id)) {
      this.selected_folders.push(item_id);
    }
  }

  #unselectItem(item_id) {
    if (this.selected_items.includes(item_id)) {
      this.selected_items = this.selected_items.filter(id => id !== item_id);
    }
  }

  #unselectFolder(item_id) {
    if (this.selected_folders.includes(item_id)) {
      this.selected_folders = this.selected_folders.filter(id => id !== item_id);
    }
  }

  #validateSelection() {
    if (this.#data == null) {
      return;
    }

    const items = [];
    const folders = [];

    for (const item of this.#data) {
      if (item.item_type === LISTITEM.FOLDER) {
        folders.push(item.item_id);
      } else {
        items.push(item.item_id);
      }
    }

    this.selected_items = this.selected_items.filter(id => items.includes(id));
    this.selected_folders = this.selected_folders.filter(id => folders.includes(id));
    this.selected = [...this.selected_items, ...this.selected_folders];
  }

  #updateMeta() {
    const data_length = this.#data?.length ?? 0;
    const selected_items_length = this.selected_items.length;
    const selected_folders_length = this.selected_folders.length;
    const selection_length = selected_items_length + selected_folders_length;

    this.has_items_selection = selected_items_length > 0;
    this.has_folders_selection = selected_folders_length > 0;
    this.is_some_selected = selection_length > 0;
    this.is_all_selected = selection_length === data_length;

    this.selected = [...this.selected_items, ...this.selected_folders];
  }
}
