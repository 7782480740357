<template lang="pug">
div(:class="styles.features")
  section
    h3.color-gray-800.mb-8.fw-400 Additional Features:
    slot(name="features")
  section
    h3.color-gray-800.mb-8.fw-400 Support:
    slot(name="support")
</template>

<script>
import styles from './Feature.module.scss';

export default {
  name: 'Features',

  data() {
    return {
      styles
    };
  }
};
</script>
