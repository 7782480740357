<template lang="pug">
section(:class="styles.section")
  span(:class="styles.subtitle") {{ subtitle }}:
    slot(name="tooltip")
  slot
</template>

<script>
import styles from '@root/src/apps/components/Global/Components.module.scss';

export default {
  name: 'TestSection',

  props: {
    subtitle: {
      type: String,
      default: 'Default'
    }
  },

  data() {
    return {
      styles
    };
  }
};
</script>
