<template lang="pug">
div(:class="styles.detail")
  section(:class="styles.label")
    h2(v-if="large") {{ label }}
    h3(v-else) {{ label }}
    i.nexd-icon-32-help(v-if="tooltip != null" aria-hidden="true" v-tooltip="tooltip")
  div(v-if="value != null" :class="{ [styles.value]: true, [styles.large]: large, [styles[highlight]]: highlight != null }") {{ formatted_value }}
</template>

<script>
import styles from './Detail.module.scss';

import { formatNumber, formatDate, formatPrice } from '@cm/Views/Billing/helpers';

export default {
  name: 'Detail',

  props: {
    large: {
      type: Boolean,
      default: false
    },

    highlight: {
      type: String,
      default: null,
      validator: value => (['', 'danger', 'warning', 'success'].includes(value))
    },

    label: {
      type: String,
      default: null
    },

    value: {
      type: [String, Number, Object],
      default: () => (null),
      validator: value => {
        if (typeof value !== 'object') {
          return false;
        }

        return value?.value != null && ['date', 'cost'].includes(value?.format);
      }
    },

    tooltip: {
      type: Object,
      default: () => (null),
      validator: tooltip => tooltip?.value != null || tooltip?.template != null,
    }
  },

  computed: {
    formatted_value() {
      if (this.value?.format === 'date') {
        return formatDate(this.value.value);
      }

      if (this.value?.format === 'cost') {
        return formatPrice(this.value.value, {
          currency: this.value.currency,
          suffix: ' / month'
        });
      }

      return formatNumber(this.value);
    }
  },

  data() {
    return {
      styles
    };
  }
};
</script>
