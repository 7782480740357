<template lang="pug">
div.custom-thead
  div.custom-tr
    template(v-if="service.columns != null" v-for="(column, index) of service.columns")
      div.flex.flex-align-center.flex-justify-end(v-if="index === 0" :key="index")
        CustomCheckbox(
          :value="selection?.is_all_selected"
          :has_selection="(selection?.is_some_selected && !selection?.is_all_selected)"
          :disabled="select_all_disabled"
          @change="selection.toggleSelectAll()"
        )
      div.custom-th.text-uppercase.color-cyan-blue.fw-500.fs-14(
        v-else
        :key="index"
        scope="col"
        :data-key="column?.key"
        :class="{[order]: order != null && sort === column?.key, 'cursor-pointer': column?.key != null}"
        @click="updateSorting(column)"
      )
        span.overflow-ellipsis(:data-name="column?.name" :data-slug="column?.slug ?? column?.name")
        i(v-if="column?.key != null" aria-hidden="true" :class="getIcon(column.key)")
</template>

<script>
import ContentService from '@master/Services/ContentService';
import Selection from '@libs/Selection';

import CustomCheckbox from '@master/UI/CustomCheckbox.vue';

export default {
  name: 'ListHeader',

  components: {
    CustomCheckbox,
  },

  props: {
    service: {
      type: ContentService,
      required: true,
    },

    selection: {
      type: Selection,
      required: true,
    },

    select_all_disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sort: null,
      order: null,
    };
  },

  created() {
    this.service.options.subscribe(({ sort, order }) => {
      this.sort = sort;
      this.order = order;
    }, this);
  },

  methods: {
    updateSorting(column) {
      if (column?.key == null) {
        return;
      }

      this.service.updateSorting(column.key, column.key === 'name');
      this.selection.unselectAll();
    },

    getIcon(key) {
      return {
        'nexd-icon-16-sort': this.order == null || this.sort !== key,
        'nexd-icon-16-arrow-down1': this.order === 'asc' && this.sort === key,
        'nexd-icon-16-arrow-up1': this.order === 'desc' && this.sort === key,
      };
    },
  },
};
</script>
