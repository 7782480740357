import Clock from './Clock';
import MonitorWindow from './MonitorWindow';

class Monitor {
  constructor(svg) {
    this.svg = svg;
    let buttonNode = this.svg.querySelector('#monitor-power-btn');
    this.button = buttonNode.querySelector('[id^=HITBOX]');
    this.screen = this.svg.querySelector('#monitor');
    this.screenItems = [];
    let screenNodes = this.screen.querySelectorAll('[id^=monitor-window-]');
    for (let node of screenNodes) {
      this.screenItems.push(new MonitorWindow(node, true, null, this));
    }
    this.passcodeWindow = this.screenItems[0];

    let successNode = this.screen.querySelector('#monitor-success');
    this.successScreen = new MonitorWindow(successNode, false, null, this);

    let errorNode = this.screen.querySelector('#monitor-error');
    this.errorScreen = new MonitorWindow(errorNode, false, () => {
      this.unCrash();
    }, this);
    this.clock = new Clock(this.screen);
    this.on = true;
    this.crashInterval = null;
    this.toggleEvents = [];
    this.error = false;
    this.init();
  }
  init() {
    this.screen.classList.add('transition');
    this.screen.classList.add('fast');
    this.initSwitch();
    this.initSpamEventClearer();
  }
  initSwitch() {
    this.button.classList.add('clickable');
    this.button.addEventListener('click', () => this.toggleScreen());
  }
  initSpamEventClearer() {
    this.toggleEvents = [];
    this.crashInterval = setInterval(() => {
      this.toggleEvents.shift();
    }, 400);
  }
  toggleScreen() {
    if (this.error === false) {
      this.toggleEvents.push(true);
      this.on = !this.on;
      if (this.on === true) {
        this.turnOn();
      } else {
        this.turnOff();
      }
      // spam counter until crash
      if (this.toggleEvents.length >= 10) {
        this.crash();
      }
    }
  }
  turnOn() {
    this.screen.classList.remove('hidden');
  }
  turnOff() {
    this.screen.classList.add('hidden');
  }
  crash() {
    // timeout on crash, to wait for everything finish
    setTimeout(() => {
      this.error = true;
      window.clearInterval(this.crashInterval);
      this.clock.crash();
      this.turnOn();
      for (let node of this.screenItems) {
        node.show();
      }
      // force error show on crash
      this.errorScreen.show(true);
    });
  }
  unCrash() {
    this.initSpamEventClearer();
    this.error = false;
    this.clock.error = false;
  }
  initFlicker() {
    this.flicker();
  }
  flicker() {
    let time = Math.round(Math.random() * 2500 + 500);
    setTimeout(() => {
      // turn off only when screen is actually on
      if (this.on === true || this.error === true) {
        this.turnOff();
      }
      setTimeout(() => {
        // turn on only when screen is actually on
        if (this.on === true || this.error === true) {
          this.turnOn();
        }
        this.flicker();
      }, 100);
    }, time);
  }
}

export default Monitor;
