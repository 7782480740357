<template lang="pug">
Container(
  :title="title"
  :subtitle="is_trial ? 'This is Trial subscription package' : subtitle"
)
  template(v-slot:pill)
    Pill(v-if="subscription_status != null" :variant="`subscription-${subscription_status}`" :title="subscription_status")

  template(v-slot:buttons)
    Button(
      v-if="!is_pending && !is_cancelled"
      type="primary"
      :animate="true"
      label="Change plan"
      :loading="loading"
      @click="$router.push({ name: `${VIEW.BILLING}.package` })"
    )

  Details
    Detail(v-if="subscription.featured_impressions" :large="true" :label="featured_impressions_title" :value="subscription.featured_impressions")
    Detail(v-if="subscription.valid_from" :label="valid_from_title" :value="{ value: subscription.valid_from, format: 'date' }")
    Detail(v-if="subscription.expires_on" :label="expires_on_title" :value="{ value: subscription.expires_on, format: 'date' }")
    Detail(v-if="subscription.price" :label="price_title" :value="{ value: subscription.price, format: 'cost', currency: subscription.currency }")

  hr.my-32(style="border-color:#DDE3EB")

  div(v-if="loading")
    Loading
  Features(v-else-if="features != null")
    template(v-slot:features v-if="features?.features?.length > 0")
      Feature(
        v-for="feature of features.features"
        :key="feature.key"
        :label="feature.name"
        :price="feature.price"
        :currency="subscription?.currency"
      )
    template(v-slot:support v-if="features?.support != null")
      Feature(
        :label="features?.support?.name"
        :price="features?.support?.price"
        :currency="subscription?.currency"
      )

  hr.mt-32(style="border-bottom-color:#DDE3EB")

  Button(
    v-if="is_cancelled"
    type="primary"
    label="Re-activate supscription"
    :loading="loading"
    @click="reActivatePlan"
  )
  Button(
    v-else
    type="link-danger"
    :label="cancel_button_label"
    :loading="loading"
    @click="cancel"
  )
</template>

<script>
import BillingService from '@cm/Views/Billing/helpers/BillingService';
import { getSubscriptionFeatures } from '@cm/Views/Billing/helpers';
import { VIEW } from '@master/constants';

import Button from '@master/UI/Buttons/Button';
import Loading from '@master/UI/Loading';
import Pill from '@master/UI/Pill/Pill.vue';

import Container from '@cm/Views/Billing/Components/Container/Container';
import Detail from '@cm/Views/Billing/Components/Details/Detail';
import Details from '@cm/Views/Billing/Components/Details/Details';
import Feature from '@cm/Views/Billing/Components/Features/Feature';
import Features from '@cm/Views/Billing/Components/Features/Features';

export default {
  name: 'Subscription',

  components: {
    Button,
    Container,
    Detail,
    Details,
    Loading,
    Feature,
    Features,
    Pill
  },

  props: {
    subscription: Object,

    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    }
  },

  computed: {
    is_pending() {
      return this.subscription?.pending ?? false;
    },

    is_cancelled () {
      return this.subscription?.cancelled ?? false;
    },

    is_trial () {
      return this.subscription?.trial ?? false;
    },

    is_upcoming() {
      return this.is_cancelled || this.is_pending;
    },

    subscription_status() {
      if (this.subscription == null) return null;

      if (this.is_pending)    return 'pending';
      if (this.is_cancelled)  return 'cancelled';
      if (this.is_trial)      return 'trial';

      if (this.subscription?.pricing_period === 'annually') {
        return 'annual';
      }

      return 'monthly';
    },

    cancel_button_label() {
      if (this.is_upcoming) return 'Cancel new plan';
      return 'Cancel subscription';
    },

    featured_impressions_title() {
      if (this.is_trial) return 'Package included impressions after trial';
      return 'Featured impressions';
    },

    valid_from_title () {
      if (this.is_pending) return 'Activation date';
      if (this.is_trial) return 'Trial activated at';
      return 'Purchased at';
    },

    expires_on_title () {
      if (this.is_cancelled) return 'Ending at';
      if (this.is_trial) return 'Trial ends at';
      return 'Renews at';
    },

    price_title() {
      if (this.is_pending) return 'Next payment amount';
      if (this.is_trial) return 'Payment amount at trial end';
      return 'Purchase price';
    }
  },

  data() {
    return {
      VIEW,

      loading: true,
      features: null
    };
  },

  created() {
    this.loadFeatures();
  },

  methods: {
    async loadFeatures() {
      if (this.subscription == null) return;

      this.loading = true;

      const pricing_details = await BillingService.getPricingDetails();

      this.features = getSubscriptionFeatures(
        pricing_details.additional_features,
        this.subscription.features,
        this.subscription.currency
      );

      this.loading = false;
    },

    cancel() {
      if (this.is_upcoming) {
        return this.deletePlan();
      }

      return this.cancelPlan();
    },

    reActivatePlan() {
      if (this.subscription == null) return;

      this.loading = true;

      this.$http.put(`v2/subscription/${this.subscription.id}/reactivate`)
        .then(response => {
          if (response === true) {
            window.location.reload();
          }
        })
        .catch(() => {
          this.$alert('Plan activation failed for some reason', 'Oops!');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async cancelPlan() {
      if (this.subscription == null) return;

      if (!await this.confirmPlanCancellation(
        'Are you sure?',
        'You will be able to use all purchased features/impressions up to until period end. But after period end we will not update your subscription',
        'Yes, cancel everything'
      )) {
        return;
      }

      this.loading = true;

      this.$http.put(`v2/subscription/${this.subscription.id}/cancel`)
        .then(response => {
          if (response === true) {
            window.location.reload();
          }
        })
        .catch(() => {
          this.$alert('Plan canceling failed for some reason', 'Oops!');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async deletePlan() {
      if (this.subscription == null) return;

      if (!await this.confirmPlanCancellation(
        'Are you sure about cancelling modified subscription?',
        'After you cancel your downgraded package. We will charge your card for currently full package price as presented in Active subscription section',
        'Delete'
      )) {
        return;
      }

      this.loading = true;

      this.$http.delete(`v2/subscription/${this.subscription.id}`)
        .then(response => {
          if (response === true) {
            window.location.reload();
          }
        })
        .catch(() => {
          this.$alert('Plan deletion failed for some reason', 'Oops!');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async confirmPlanCancellation(title, message, confirm) {
      if ([title, message, confirm].some(v => v == null)) {
        return false;
      }

      return await this.$confirm(
        title,
        message,
        {
          buttons: [
            { type: 'link-primary', label: 'Cancel', action: false },
            { type: 'primary', label: confirm, action: true }
          ],
          image: `${this.$cdn}dist/nexd/imgs/attention.svg`
        }
      );
    }
  }
};
</script>
