<template lang="pug">
div(:class="styles.feature")
  section
    div.extra-publish-info
      i.nexd-icon-16-check-mini(aria-hidden="true")
    div(v-if="label != null") {{ label }}
    i.nexd-icon-32-help(v-if="tooltip != null" aria-hidden="true" v-tooltip="tooltip")
  div(v-if="price != null") {{ formatted_price }}
</template>

<script>
import styles from './Feature.module.scss';

import { formatPrice } from '@cm/Views/Billing/helpers';

export default {
  name: 'Feature',

  props: {
    label: {
      type: String,
      default: null
    },

    tooltip: {
      type: Object,
      default: () => (null),
      validator: tooltip => tooltip?.value != null && tooltip?.template != null
    },

    price: {
      type: [String, Number],
      default: null
    },

    currency: {
      type: String,
      default: null
    }
  },

  computed: {
    formatted_price() {
      return formatPrice(this.price, {
        currency: this.currency,
        free: true
      });
    }
  },

  data() {
    return {
      styles
    };
  }
};
</script>
