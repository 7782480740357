import $http from '@master/Services/HttpService';
import { poll } from '@helpers/Global';

class Upload {
  constructor(file = null) {
    if (file == null) {
      console.error('File error');
      return;
    }
    this.upload_id = null;
    this.info = {};
    this.file = file;
    this.reader = new FileReader();
    this.percentage = 0;
    this.chunk_size = 5000 * 1024;
    this.fake_percentage_interval = null;
  }

  upload(callback) {
    this.onupdate = callback;
    this.start();
  }

  start() {
    this.next(0);
  }

  next(index = 0) {
    const next_chunk = index + this.chunk_size + 1;
    const blob = this.file.slice(index, next_chunk);

    this.reader.onloadend = e => {
      if (e.target.readyState !== FileReader.DONE) {
        return;
      }

      const obj = {
        chunk: e.target.result,
        filename: this.file.name,
        type: this.file.type,
        upload_id: this.upload_id,
        next: next_chunk < this.file.size,
      };

      // small visual hax if a big one color image will be added
      // since the size will be small it will be just 1 chunk
      // but since it takes time to resize it and move, we "fake" some percentages
      if (index === 0 && !obj.next) {
        this.fake_percentage_interval = window.setInterval(() => {
          this.percentage += 0.1;
          if (this.percentage > 1) {
            this.percentage = 0.99;
          }
          this.notify(false);
        }, 500);
      }

      const path = 'upload/async';
      $http
        .post(path, obj, {
          notification: false,
          withCredentials: false,
          auth: false,
        })
        .then(async response => {
          if (!obj.next && response?.process_id != null) {
            this.notify(false, null, 'Finalizing. Please wait...');
            response = await poll({
              path: 'upload/async/poll',
              data: { process_id: response.process_id },
            });
          }

          this.upload_id = response.upload_id;
          this.info = response.info;
          const size_done = index + this.chunk_size;
          this.percentage = Math.min(1, Math.floor((size_done / this.file.size) * 100) / 100);

          this.notify(!obj.next);
          if (obj.next) {
            this.next(next_chunk);
          }
        })
        .catch(error => {
          this.notify(false, error);
        })
        .finally(_ => {
          window.clearInterval(this.fake_percentage_interval);
        });
    };

    this.reader.readAsDataURL(blob);
  }

  notify(done = false, error = null, msg = null) {
    if (this.onupdate != null && typeof this.onupdate === 'function') {
      this.onupdate({
        upload_id: this.upload_id,
        percentage: this.percentage,
        info: this.info,
        done,
        error,
        msg,
      });
    }
  }
}

export default Upload;
