<template lang="pug">
div(:class="styles.creative")
  img(:src="creative.thumbnail_url" alt="thumbnail")
  div.fw-500.overflow-ellipsis {{ creative.name }}
  div(v-if="!is_dco" :class="styles.priority")
    slot(name="priority")
  div(v-else)
  div(:class="styles.icons")
    i.nexd-icon-32-delete(v-tooltip="{value: 'Delete'}" @click="removeCreative")
</template>

<script>
import styles from './FlightCreative.module.scss';

import FlightService from '@master/Services/FlightService';

export default {
  name: 'FlightCreative',

  props: {
    statement: Object,
    creative: Object,
    index: {
      type: Number,
      default: 0,
    },
    is_dco: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      styles,
    };
  },

  methods: {
    async removeCreative() {
      if (await this.$confirm('Are you sure you would like to delete creative from condition group?')) {
        this.statement?.creatives?.splice(this.index, 1);
        this.$emit('delete');

        if (FlightService.hasValidId(this.statement?.id) && FlightService.hasValidId(this.creative?.id)) {
          this.$http.delete(`v2/flights/${this.statement.flight_id}/statements/${this.statement.id}/creatives/${this.creative.id}`);
        }
      }
    },
  },
};
</script>
