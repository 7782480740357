
import Filters from '@master/Filters';
import { filters, Controller, colorOptions } from '@master/Services/AnalyticsAPPService';

function TooltipContent (input) {
  const {data, index, main_series, active_series, label = null} = input;
  const numberFilter = Filters.NumberFilter;

  const classes = {
    impressions: '',
    viewable: '',
    ctr: '',
    engagement: '',
    dwell: '',
    viewability: '',
    clicks: '',
    vtr: ''
  };

  for (const key of active_series) {
    classes[key] = 'active';
  }
  classes[main_series] += ' main';

  // base content
  let html = '<div class="row no-wrap">';
  html += '<div class="col flex-grow">';
  html += '<div class="ct-row title">Total Impressions</div>';

  if (label != null) {
    html += `<div class="ct-row date-range">${label}</div>`;
  }

  html += '<div class="ct-tooltip-content">';

  if (filters.base !== 'viewable') {
    html += `<div class="ct-row ${classes.impressions}">
      <div class="value"><span>${numberFilter(data.impressions[index])}</span><span class="circle ${colorOptions.impressions.class}"></span></div>
      <div class="label">Impressions</div>
    </div>`;
  }

  if (data.viewable != null && filters.base === 'viewable' && Controller.isViewabilityEnabled()) {
    html += `<div class="ct-row ${classes.viewable}">
      <div class="value"><span>${numberFilter(data.viewable[index])}</span><span class="circle ${colorOptions.viewable.class}"></span></div>
      <div class="label">Viewable Impr.</div>
    </div>`;
  }

  html += `<div class="ct-row ${classes.engagement}">
    <div class="value"><span>${data.engagement[index]}%</span><span class="circle ${colorOptions.engagement.class}"></span></div>
    <div class="label">Engagement</div>
  </div>`;

  html += `<div class="ct-row ${classes.ctr}">
    <div class="value"><span>${data.ctr[index]}%</span><span class="circle ${colorOptions.ctr.class}"></span></div>
    <div class="label">CTR</div>
  </div>`;

  html += `<div class="ct-row ${classes.clicks}">
    <div class="value"><span>${numberFilter(data.clicks[index])}</span><span class="circle ${colorOptions.clicks.class}"></span></div>
    <div class="label">Clicks</div>
  </div>`;

  if (filters.base !== 'viewable' && Controller.isViewabilityEnabled()) {
    html += `<div class="ct-row ${classes.viewability}">
      <div class="value"><span>${data.viewability[index]}%</span><span class="circle ${colorOptions.viewability.class}"></span></div>
      <div class="label">Viewability</div>
    </div>`;
  }

  html += '</div>';
  html += '</div>';
  html += '</div>';
  return html;
}

export {
  TooltipContent
};
