<template lang="pug">
div(ref="modal")
  div.modal.modal-unset-overflow
    div.backdrop
    div.modal-content.map
      div.modal-head
        div.modal-title Import map data

      div.modal-body
        p.fs-14.color-gray-800 To show the user which the closest locations are to them on the map, please upload the relevant location data in Excel or CSV format.&nbsp;
          | Each location should be on a separate row in the file.
          | Read more about&nbsp;
          a(href="https://support.nexd.com/en/articles/5366363-map-layouts#h_0d7cf25951" target="_blank") map data requirements and our download template.

        p.mt-16.fs-14.color-gray-800 The minimum required columns are:
        ul.mt-0.fs-14
          li Location Title (this text will be displayed on the map)
          li Latitude
          li Longitude

        p.mb-16.fs-14.color-gray-800 Additionally, depending on the layout, there can be up to two additional columns. This data will then be displayed under the location title on the map.

        Dropzone(v-if="!input" mime="text/csv" :multiple="false" @change="dropzoneFileHandler")
        template(v-else)

          div.row.flex-align-center
            template(v-if="!hide_delimitter_column")
              label.col Delimiter:
              div.col
                SearchSelect(
                  :options="{',': 'Comma', ':': 'Colon', '=': 'Equals Sign', ';': 'Semicolon', '&nbsp;': 'Space', '&#9;': 'Tab'}"
                  :search="false"
                  v-model="options.delimiter"
                  @input="delimiterChangeHandler"
                )
            label.col Start @ row:
            div.col-3
              input(type="number" class="form-control" min="1" :max="total_rows" v-model="options.start_row_index" @change="startRowChangeHandler")

          hr
          div.row.mt-16(v-if="column_index_options != null")

            div.col-5ths
              div.mb-4.fs-14(:class="{'lh-32': total_columns > 3}") Title
                span.color-red-primary *
              small.color-gray-800 Choose your location name column here.
              SearchSelect.mt-4(
                :options="column_index_options"
                :search="false"
                v-model="options.column_selections[0]"
                @input="columnIndexChangeHandler"
              )

            div.col-5ths
              div.mb-4.fs-14(:class="{'lh-32': total_columns > 3}") Latitude
                span.color-red-primary *
              small.color-gray-800 Choose your location latitude column here.
              SearchSelect.mt-4(
                :options="column_index_options"
                :search="false"
                v-model="options.column_selections[1]"
                @input="columnIndexChangeHandler"
              )

            div.col-5ths
              div.mb-4.fs-14(:class="{'lh-32': total_columns > 3}") Longitude
                span.color-red-primary *
              small.color-gray-800 Choose your location longitude column here.
              SearchSelect.mt-4(
                :options="column_index_options"
                :search="false"
                v-model="options.column_selections[2]"
                @input="columnIndexChangeHandler"
              )

            div.col-5ths(v-if="total_columns > 3")
              SearchSelect.mb-4(
                :options="additional_column_options_a"
                :search="false"
                v-model="options.additional_column_selections[0]"
                @input="additionalColumnChangeHandler"
              )
              small.color-gray-800 Please choose your custom column here.
              SearchSelect.mt-4(
                v-if="options.additional_column_selections[0] !== 'none'"
                :options="column_index_options"
                :search="false"
                v-model="options.column_selections[3]"
                @input="columnIndexChangeHandler"
              )

            div.col-5ths(v-if="total_columns > 4")
              SearchSelect.mb-4(
                :options="additional_column_options_b"
                :search="false"
                v-model="options.additional_column_selections[1]"
                @input="additionalColumnChangeHandler"
              )
              small.color-gray-800 Please choose your custom column here.
              SearchSelect.mt-4(
                v-if="options.additional_column_selections[1] !== 'none'"
                :options="column_index_options"
                :search="false"
                v-model="options.column_selections[4]"
                @input="columnIndexChangeHandler"
              )

          Loading(v-if="loading || formatted_data == null" :fill="true")
          div.mt-16(v-else)
            MapRow(
              v-for="(row, index) in formatted_data"
              :key="index"
              :row="row"
              :index="index"
            )

      Buttons.modal-footer
        Button(type="link-primary" label="Cancel" @click="close")
        Button(type="primary" label="Done" :animate="true" :loading="is_saving" @click="save")
</template>

<script>

import AssetHelper from '@helpers/Asset';
import Dropzone from '@cm/Views/Creatives/Cards/Components/Dropzone';
import MapRow from './Components/MapRow';

import SearchSelect from '@master/UI/SearchSelect/SearchSelect';
import Loading from '@master/UI/Loading';
import Modal from '@master/UI/Modal';
import Button from '@master/UI/Buttons/Button';
import Buttons from '@master/UI/Buttons/Buttons';

import { getBase64 } from '@helpers/Global';

export default {
  name: 'MapCSVModal',
  extends: Modal,
  components: {
    Button,
    Buttons,
    Dropzone,
    MapRow,
    SearchSelect,
    Loading
  },
  props: {
    creative: Object,
    asset: Object,
    input: Object
  },

  computed: {
    asset_has_settings() {
      return this.creative?.assets?.[this.asset?.asset_id]?.settings != null;
    },

    settings() {
      if (this.asset_has_settings) {
        return this.creative.assets[this.asset.asset_id].settings;
      }
      return null;
    },

    asset_has_options() {
      if (this.asset_has_settings) {
        return this.settings.options != null;
      }
      return false;
    },

    options() {
      let settings = { ...this.default_options };
      if (this.asset_has_options) {

        // overwrite default options
        const options = this.settings.options;
        for (const key in settings) {
          if (options[key] != null) {
            settings[key] = options[key];
          }
        }
      }
      return settings;
    },

    column_index_options() {
      if (!this.original_csv_array || this.original_csv_array.length === 0) return null;

      // take columns from first rows
      const columns =  Object.keys(this.original_csv_array[0]);

      // default output on errors or so, if data is shorter or any
      let indexes = [
        {
          'value': 0,
          'label': 'error'
        },
        {
          'value': 1,
          'label': 'error'
        },
        {
          'value': 2,
          'label': 'error'
        }
      ];

      // create correct options
      for (const i of columns) {
        const n = parseInt(i, 10);
        indexes[n] = {
          value: n,
          label: (n + 1).toString()
        };
      }

      return indexes;
    },

    additional_column_options_a() {
      let options = { ...this.additional_column_options};
      if (this.options.additional_column_selections[1] !== 'none') {
        delete options[this.options.additional_column_selections[1]];
      }
      return options;
    },
    additional_column_options_b() {
      let options = { ...this.additional_column_options};
      if (this.options.additional_column_selections[0] !== 'none') {
        delete options[this.options.additional_column_selections[0]];
      }
      return options;
    }
  },

  data() {
    return {
      loading: false,
      is_saving: false,
      csv_string: null,
      formatted_data: null,
      original_csv_array: null,

      total_rows: 0,
      total_columns: 0,

      hide_delimitter_column: false,

      additional_column_options: {
        none: 'Select',
        address: 'Address',
        url: 'URL',
        phone: 'Phone nr.',
        custom: 'Custom'
      },

      default_options: {
        delimiter: ';',
        start_row_index: 1,
        column_selections: [0, 1, 2, 3, 4],
        additional_column_selections: ['none', 'none']
      }
    };
  },

  created() {
    if (this.input?.new) {
      const file = this.input.new;

      // new file dropped from the assets library
      if (file.original_asset_id != null) {
        this.openMapCSVFromOriginal(file);
        return;
      }

      if (AssetHelper.isCSVMime(file.type, file.name)) {
        this.readFile(file);
        return;
      } else if (AssetHelper.isXLSMime(file.type, file.name)) {
        // hide delimitter for xls files
        this.hide_delimitter_column = true;
        // reset csv selected options when dropping new xls file to assign defaults
        if (this.asset_has_settings) {
          this.$set(this.creative.assets[this.asset.asset_id].settings, 'options', {});
        }
        // convert dropped xls to csv
        this.convertXLS(file);
      }
    } else if (this.input?.edit) {
      this.openMapCSVFromOriginal(this.input.edit);
    }
  },

  methods: {
    convertXLS(file) {
      this.loading = true;
      getBase64(file)
        .then(data => {
          const obj = { data };
          const path = 'assets/convert/xls/csv';
          this.$http.post(path, obj)
            .then(({csv, separator}) => {
              this.csv_string = csv;
              this.options.separator = separator;
              this.formatCSV();
            })
            .finally(() => {
              this.loading = false;
            });
        });
    },

    openMapCSVFromOriginal(file) {
      // hide delimitter for xls files
      if (AssetHelper.isXLSMime(file.mime)) {
        this.hide_delimitter_column = true;
      }

      this.loading = true;
      const path = `assets/library/${file.library_id}/${file.original_asset_id}/mapcsv`;
      this.$http.get(path)
        .then(({csv, separator}) => {
          this.csv_string = csv;
          this.options.separator = separator;
          this.formatCSV();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      this.is_saving = true;
      const path = `creatives/${this.creative.creative_id}/assets/${this.asset.asset_id}/mapcsv`;

      if (this.input?.new) {
        const file = this.input.new;

        // set user defined options
        let obj = {
          options: this.options
        };

        // keep user defined setting on new file upload
        if (this.asset_has_settings) {

          if (this.settings.map_show_four_markers != null) {
            obj.map_show_four_markers = this.settings.map_show_four_markers;
          }
          if (this.settings.map_style != null) {
            obj.map_style = this.settings.map_style;
          }
          if (this.settings.map_direction_btn != null) {
            obj.map_direction_btn = this.settings.map_direction_btn;
          }
          if (this.settings.map_unit != null) {
            obj.map_unit = this.settings.map_unit;
          }
        }

        if (file.original_asset_id != null) {
          // asset library drop upload
          obj.original_asset_id = file.original_asset_id;
          this.$http.post(path, obj).then(asset => {
            this.$set(this.creative.assets, asset.asset_id, asset);
            this.$emit('change');
            this.close();
          }).finally(() => {
            this.is_saving = false;
          });
        } else {
          // slot drop upload
          getBase64(file)
            .then(data => {
              this.$http.post(path, {
                ...obj,
                data,
                filename: file.name,
              })
                .then(asset => {
                  this.$set(this.creative.assets, asset.asset_id, asset);
                  this.$emit('change');
                  this.close();
                })
                .finally(() => {
                  this.is_saving = false;
                });
            });
        }
      } else if (this.input?.edit) {
        const obj = {
          options: this.options
        };
        this.$http.put(path, obj).then(() => {
          this.$emit('change');
          this.close();
        }).finally(() => {
          this.is_saving = false;
        });
      } else {
        this.is_saving = false;
      }
    },

    async formatData() {
      this.formatted_data = null;
      await this.$nextTick();

      this.formatted_data = [];

      this.total_columns = Object.keys(this.column_index_options).length;

      for (const row of this.original_csv_array) {
        const obj = {
          title: row[this.options.column_selections[0]] || null,
          lat: row[this.options.column_selections[1]] || null,
          lon: row[this.options.column_selections[2]] || null
        };
        if (this.total_columns > 3 && this.options.additional_column_selections[0] !== 'none') {
          // map 4th item to the selected key
          obj[this.options.additional_column_selections[0]] = row[this.options.column_selections[3]] || null;
        }
        if (this.total_columns > 4 && this.options.additional_column_selections[1] !== 'none') {
          // map 5th item to the selected key
          obj[this.options.additional_column_selections[1]] = row[this.options.column_selections[4]] || null;
        }
        this.formatted_data.push(obj);
      }
    },

    formatCSV() {
      this.original_csv_array = [];
      const lines = this.csv_string.split('\n');
      this.total_rows = lines.length;

      for (let i = this.options.start_row_index - 1; i < this.total_rows; i++) {
        const columns = lines[i].split(this.options.delimiter);
        this.original_csv_array.push(columns);
      }

      this.formatData();
    },

    dropzoneFileHandler(event) {
      if (event?.files != null) {
        this.$emit('drop', event.files[0]);
      }
    },

    readFile (file) {
      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (ev) => {
        if (ev.target.result != null) {
          this.csv_string = ev.target.result;
          this.formatCSV();
        }
      };
    },

    columnIndexChangeHandler() {
      this.formatData();
    },

    additionalColumnChangeHandler() {
      this.formatData();
    },

    startRowChangeHandler() {
      if (this.options.start_row_index < 1) {
        this.options.start_row_index = 1;
      }
      this.formatCSV();
    },

    delimiterChangeHandler() {
      this.formatCSV();
    },

    close() {
      this.$emit('close');
    },
  }
};
</script>
