<template lang="pug">
div(:class="styles.container")
  section(:class="styles.header")
    h1(v-if="title != null") {{ title }}
    slot(name="pill")
    Buttons.flex-grow(align="right")
      slot(name="buttons")

  section(v-if="subtitle != null" :class="styles.subtitle")
    span {{ subtitle }}

  section.mt-32
    slot
</template>

<script>
import styles from './Container.module.scss';

import Buttons from '@master/UI/Buttons/Buttons';

export default {
  name: 'Container',

  components: {
    Buttons
  },

  props: {
    title: {
      type: String,
      default: null
    },

    subtitle: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      styles
    };
  }
};
</script>
