<template lang="pug">
  div.card.campaign-analytics-card.detailed
    div.row
      div.col.left-block.no-gutter-right
        div.card-body
          Pill.status-pill(:status="creative.live_status" :google_ads="creative.live_dsp === DSP.GOOGLEADS")
          div.splash
            div.img(:style="{'background-image': 'url(' + creative.thumbnail_url || '' + ')'}")
          div.bottom-content
            Button(
              v-if="this.$whitelabel?.analytics?.preview_links !== false"
              type="primary"
              :block="true"
              :outline="true"
              label="View Creative in Preview site"
              :disabled="creative.live_preview_url == null"
              @click="openPreview"
            )
      div.col-16.no-gutter-left.border-left
        button.btn.btn-link.detailed-analytics(@click="$parent.openAnalytics('creative/' + creative.live_id)")
          span View detailed analytics
          div.collapse-toggle.toggle-position
            i.nexd-icon-32-arrow-down-small.turn-arrow(aria-hidden="true")
        div.card-body
          div.form-group.header
            h2.detail-header
              Pill.mr-8(v-if="isHTML5()" variant="html5" title="html5" style="vertical-align: middle;")
              span {{creative.alt_name || creative.name}}
            small Exported on {{creative.publish_date | dateFilter}}
          div.row
            div.col-6
              h3 {{creative.type_string}}
              small Placement type
            div.col-6
              h3(v-if="creative.device === 3") Multiple
              h3(v-else) {{creative.width}} x {{creative.height}}
              small Size
            div.col-6
              h3 {{template_or_layout_name}}
              small Layout
            div.col-6
              template(v-if="creative.live_dsp != 'None' && creative.live_dsp_title != null")
                h3 {{creative.live_dsp_title != null ? creative.live_dsp_title : 'None'}}
                small Platform
        div.card-body.border-top
          div.row.mb-16
            div.col-8
              div.mb-8.total-popup
                AnalyticsTotalInfoPopup(:trackable="analytics_popup_data"
                  :analytics="info_popup_content")
                h2(:class="[filters.base === 'impressions' ? '' : 'impressions category']") {{creative.analytics.total.impressions | NumberFilter}}
                small Impressions
              CreativeDetailedDeviceAnalyticsRow(:analytics="creative.analytics" type="impressions")
            div.col-8
              div.mb-8
                div.inline-block(v-tooltip="{template: 'analytics.engagement'}")
                  h2 {{creative.analytics.total.engagement | PercentageFilter}}
                  small Engagement
              CreativeDetailedDeviceAnalyticsRow(:analytics="creative.analytics" type="engagement" :percentage="true")
            div.col-8
              div.mb-8
                div.inline-block(v-tooltip="{template: 'analytics.ctr'}")
                  h2 {{creative.analytics.total.ctr | PercentageFilter}}
                  small CTR
              CreativeDetailedDeviceAnalyticsRow(:analytics="creative.analytics" type="ctr" :percentage="true")
          div.row
            div.col-8
              template(v-if="creative.analytics.total.viewable != null && filters.base !== 'viewable' && Controller.isViewabilityEnabled()")
                div.mb-8
                  div.inline-block(v-tooltip="{template: 'analytics.viewable'}")
                    h3(:class="[filters.base === 'viewable' ? 'viewable category' : '']") {{creative.analytics.total.viewable | NumberFilter}}
                    small Viewable Impressions
                CreativeDetailedDeviceAnalyticsRow(:analytics="creative.analytics" type="viewable")
            div.col-8
              div.mb-8
                div.inline-block(v-tooltip="{template: 'analytics.engagementImpressions'}")
                  h3 {{creative.analytics.total.engagements | NumberFilter}}
                  small Engaged Impressions
              CreativeDetailedDeviceAnalyticsRow(:analytics="creative.analytics" type="engagements")
            div.col-8
              div.mb-8
                div.inline-block(v-tooltip="{template: 'analytics.clicks'}")
                  h3 {{creative.analytics.total.clicks | NumberFilter}}
                  small Clicks
              CreativeDetailedDeviceAnalyticsRow(:analytics="creative.analytics" type="clicks")
</template>

<script>
import CreativeDetailedDeviceAnalyticsRow from './CreativeDetailedDeviceAnalyticsRow';
import Pill from '@master/UI/Pill/Pill.vue';
import { filters, Controller } from '@master/Services/AnalyticsAPPService';
import AnalyticsTotalInfoPopup from '@analytics/Views/Campaign/Components/AnalyticsTotalInfoPopup';
import CreativeTraits from '@master/Traits/CreativeTraits';
import Button from '@master/UI/Buttons/Button';
import { DSP } from '@master/constants';

export default {
  name: 'AnalyticsCreativeDetailed',
  mixins: [CreativeTraits],

  components: {
    CreativeDetailedDeviceAnalyticsRow,
    Pill,
    AnalyticsTotalInfoPopup,
    Button,
  },

  props: {
    creative: Object,
  },

  computed: {
    info_popup_content() {
      return [
        [
          {
            data: this.creative.analytics.total.loaded,
            filter: 'number',
            label: 'Loaded Impr.',
          },
          {
            data: this.creative.analytics.total.load_rate,
            filter: 'percentage',
            label: 'Load rate',
          },
        ],
        [
          {
            data: !Controller.isViewabilityEnabled()
              ? null
              : this.creative.analytics.total.viewable,
            filter: 'number',
            label: 'Viewable Impr.',
          },
          {
            data: !Controller.isViewabilityEnabled()
              ? null
              : this.creative.analytics.total.viewability,
            filter: 'percentage',
            label: 'Viewability',
          },
        ],
      ];
    },

    template_or_layout_name() {
      if (this.creative?.template?.name) return this.creative.template.name;
      if (this.creative?.layout?.name) return this.creative.layout.name;
      return 'Custom';
    },

    analytics_popup_data() {
      const out = [
        {
          data: this.creative.analytics.total.trackable,
          filter: 'number',
          label: 'Tracked ads',
        },
      ];
      if (this.admin_toggle) {
        out.push({
          data: this.creative.analytics.total.billable,
          filter: 'number',
          label: 'Billable',
        });
      }
      return out;
    },
  },

  data() {
    return {
      admin_toggle: false,

      DSP,
      preview_url: null,
      filters,
      Controller,
    };
  },

  created() {
    this.$user.subscribe(_ => {
      this.admin_toggle = this.$user.adminMode();
    }, this);
  },

  methods: {
    openPreview() {
      window.open(this.creative.live_preview_url, '_blank');
    },
  },
};
</script>
