<template lang="pug">
div.row.color-gray-800.fs-14.lh-24(:class="[index % 2 == 0 ? 'bg-gray-300' : 'bg-gray-100']")
  div.col-5ths.flex
    span(style="min-width: 2rem;") {{ index + 1 }}
    span.overflow-ellipsis {{row.title}}
  div.col-5ths.overflow-ellipsis(
    v-for="(item, index) in content"
    v-if="item != null"
    :key="index"
  ) {{item}}
</template>

<script>

export default {
  name: 'MapRow',

  props: {
    row: Object,
    index: Number
  },

  computed: {
    content() {
      return [
        this.row.lat,
        this.row.lon,
        this.additional_1,
        this.additional_2
      ];
    }
  },

  data() {
    return {
      additional_1: null,
      additional_2: null
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const keys = Object.keys(this.row);
      this.additional_1 = this.row[keys[3]];
      this.additional_2 = this.row[keys[4]];
    }
  }
};
</script>
