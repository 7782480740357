import { addFiltersToPath } from '@helpers/Global';

import QueryOptions from '@libs/QueryOptions';
import Queue from '@libs/Queue';
import Subscriber from '@libs/Subscriber';

class CopyMoveService extends Subscriber {
  options = new QueryOptions();
  queue = new Queue();

  load(path) {
    if (path == null) {
      return;
    }

    this.data = undefined;
    this.options.reset();
    return this.#load(path);
  }

  loadMore(path) {
    if (path == null || this.data?.can_load_more === false) {
      return;
    }

    this.options.update();
    return this.#load(path);
  }

  updateSearch(search, path) {
    this.options.updateSearch(search);
    this.data = undefined;

    return this.queue
      .get(addFiltersToPath(path, this.options.get()), { notification: false })
      .then(response => {
        const folders = response?.result?.folders ?? [];
        const campaigns = response?.result?.campaigns ?? [];

        this.data = {
          items: folders.concat(campaigns),
          search: true,
        };
      })
      .catch(_ => {
        this.data = {
          not_found: true,
        };
      })
      .finally(() => {
        return;
      });
  }

  #load(path) {
    return this.queue
      .get(addFiltersToPath(path, this.options.get()), { notification: false })
      .then(response => {
        if (!this.data?.items?.length) {
          this.data = response;
        } else {
          this.data = {
            ...response,
            items: this.data.items.concat(response.items),
          };
        }
      })
      .catch(_ => {
        this.data = {
          not_found: true,
        };
      })
      .finally(() => {
        return;
      });
  }
}

export default CopyMoveService;
