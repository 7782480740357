import Subscriber from '@libs/Subscriber';

class LayoutsSettingsService extends Subscriber {
  async subscribe(layout_id, cb, vnode = null) {
    this.data = null;
    await super.load(`v2/layouts/${layout_id}`, {}, true);
    super.subscribe(cb, vnode);
  }
}

export default new LayoutsSettingsService();
