<template>
  <div class="progress-bar-container">
    <div class="progress-bar" :style="{'height': percentage * 100 + '%'}"></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    percentage: Number
  }
};
</script>
