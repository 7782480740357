<template lang="pug">
  div.form-control.textarea-input.with-remove-icon(@click="toggleTextarea")
    textarea.tracking-input(
      :value="value"
      ref="textarea"
      :placeholder="placeholder"
      :maxlength="maxlength"
      rows="1"
      :disabled="disabled"
      @blur="change"
      @keyup="adjustTextarea"
      @input="update"
    )
    slot
</template>

<script>
export default {
  name: 'TextareaInput',
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: String,
    maxlength: {
      type: [String, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    url: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show_textarea: false
    };
  },
  methods: {
    async toggleTextarea() {
      this.show_textarea = true;
      await this.$nextTick();
      this.adjustTextarea();
      this.$refs.textarea.focus();
    },

    adjustTextarea() {
      if (this.show_textarea) {
        this.$refs.textarea.style.height = 'auto';
        this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
      } else {
        this.$refs.textarea.style.height = '32px';
      }
    },

    update(event) {
      this.$emit('update', event.target.value);
    },

    change() {
      if (this.show_textarea) {
        this.show_textarea = false;
        this.adjustTextarea();
      }

      if (this.url && this.value !== '') {
        this.$emit('update', this.value);
      }

      this.$emit('change');
    },

    focus() {
      if (this.$refs?.textarea) {
        this.$refs?.textarea.focus();
        this.$refs?.textarea.select();
      }
    }
  }
};
</script>
