<template lang="pug">
TestLayout(title="Status Pill")

  TestSection(subtitle="pill | status")
    div.pills-container(v-for="status of Object.values(STATUS).slice(1)" :key="status")
      Pill(:status="status")
    div.pills-container
      Pill(:status="STATUS.LIVE" :google_ads="true")

  TestSection(subtitle="pill | variants")
    div.pills-container(v-for="variant of variants" :key="variant")
      Pill(:variant="variant" :title="variant")

  TestSection(subtitle="pill | templates")
    div.pills-container(v-for="template of templates" :key="template")
      Pill(:variant="template" :title="template")

  TestSection(subtitle="pill | subscriptions")
    div.pills-container(v-for="subscription of subscriptions" :key="subscription")
      Pill(:variant="subscription" :title="subscription")
</template>

<script>
import { STATUS } from '@master/constants';
import { subscriptions, templates, variants } from '@master/UI/Pill/pills';

import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Pill from '@master/UI/Pill/Pill.vue';

export default {
  name: 'StatusPillTest',

  components: {
    TestLayout,
    TestSection,

    Pill
  },

  data() {
    return {
      STATUS,

      variants,
      templates,
      subscriptions
    };
  }
};
</script>

<style lang="scss" scoped>
.pills-container {
  width: max-content;
}
</style>
