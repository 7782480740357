<template lang="pug">
div.modal.modal-sm.modal-unset-overflow
  div.backdrop
  div.modal-content
    div.modal-head
      div.modal-title Edit profile

    div.modal-body
      form.flex.flex-direction-column.row-gap-16
        div.row
          div.col-12
            label.mb-4 First name&nbsp;
              sup.color-danger *
            Input(v-model="input.first_name")
          div.col-12
            label.mb-4 Last name&nbsp;
              sup.color-danger *
            Input(v-model="input.last_name")

        div
          label.mb-4 Job position
          Input(v-model="input.job_title" placeholder="Enter Job position")

        div
          label.mb-4 Country
          SearchSelect(:options="countries" v-model="input.country" :first="false" placeholder="Select Country")

    Buttons.modal-footer
      Button(type="link-primary" label="Cancel" @click="close")
      Button(type="primary" :animate="true" label="Save" :disabled="!is_valid" :tooltip="{value: tooltip}" @click="saveUser")
</template>

<script>
import Modal from '@master/UI/Modal';

import { getCountries, trimQuery } from '@helpers/Global';

import Button from '@master/UI/Buttons/Button';
import Buttons from '@master/UI/Buttons/Buttons';
import Input from '@master/UI/Input/Input';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect';

export default {
  name: 'ChangeBasicInfoModal',
  extends: Modal,

  components: {
    Button,
    Buttons,
    Input,
    SearchSelect
  },

  props: {
    user: Object
  },

  computed: {
    is_valid() {
      return trimQuery(this.input?.first_name ?? '').length > 0 && trimQuery(this.input?.last_name ?? '').length > 0;
    },

    tooltip() {
      return this.is_valid ? null : 'First name and Last name are required.';
    }
  },

  data () {
    return {
      input: {
        first_name: '',
        last_name: '',
        job_title: '',
        country: '',
      },

      countries: null,
    };
  },

  created() {
    for (const key in this.input) {
      this.input[key] = this.user[key];
    }

    getCountries().then(countries => {
      this.countries = countries;
    });
  },

  methods: {
    payload() {
      return Object.entries(this.input)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          if (value?.trim) {
            acc[key] = value?.trim();
          }
          return acc;
        }, {});
    },

    saveUser() {
      if (this.is_valid) {
        this.$user.update(this.payload());
        this.close();
      }
    },

    close() {
      this.$emit('close');
    }
  }
};
</script>
