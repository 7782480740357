<template lang="pug">
TestLayout(title="Calendar")

  TestSection(subtitle="Select start day of the week")
    SearchSelect(v-model="start_day" :search="false" :options="[{ value: 'isoWeek', label: 'Monday' }, { value: 'week', label: 'Sunday' }]")
  div(style="grid-column: span 3")

  div(style="grid-column: span 2")
    TestSection(subtitle="Default")
      Calendar(:start="modela_start" :end="modela_end" :start_day="start_day" @change="changeA")
      pre {{ modela_start }}
      pre {{ modela_end }}
      pre {{ modela_start | DateRangeFilter(modela_end, { utc: true }) }}

  TestSection(subtitle="Single")
    Calendar(:start="modelb_start" :end="modelb_end" :single="true" :start_day="start_day" @change="changeB")
    pre {{ modelb_start }}
    pre {{ modelb_end }}
    pre {{ modelb_start | DateRangeFilter(modelb_end, { utc: true }) }}

  TestSection(subtitle="Single - w/o range")
    Calendar(:start="modelc_start" :single="true" :range="false" :start_day="start_day" @change="changeC")
    pre {{ modelc_start }}
    pre {{ modelc_start | DateFilter({ utc: true }) }}
</template>

<script>
import moment from 'moment';

import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Calendar from '@master/UI/Calendar/Calendar.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';

export default {
  name: 'CalendarTest',

  components: {
    TestLayout,
    TestSection,

    Calendar,
    SearchSelect,
  },

  data() {
    return {
      start_day: 'isoWeek',

      modela_start: null,
      modela_end: null,

      modelb_start: null,
      modelb_end: null,

      modelc_start: null,
    };
  },

  created() {
    this.modela_start = moment().unix();
    this.modela_end = moment().add(4, 'day').unix();

    this.modelb_start = moment().unix();
    this.modelb_end = moment().add(4, 'day').unix();

    this.modelc_start = moment().unix();
  },

  methods: {
    changeA({ start, end }) {
      this.modela_start = start;
      this.modela_end = end;
    },

    changeB({ start, end }) {
      this.modelb_start = start;
      this.modelb_end = end;
    },

    changeC({ start }) {
      this.modelc_start = start;
    },
  },
};
</script>
