<template lang="pug">
  div.style-input.col-3
    input(
      type="text"
      v-model="tmp_value"
      @blur="validate"
      v-number-input
    )
    div.input-label(v-if="label != null") {{ label }}
</template>

<script>

export default {
  name: 'StyleInput',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    default: {
      type: Number,
      default: 16
    },
    // how much the input/output will be scaled
    scale: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 100
    },
    min: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      // multiply initial value with scale
      tmp_value: (this.value ?? this.default) * this.scale
    };
  },

  methods: {
    validate() {
      const output = this.getValidInput();
      this.tmp_value = output * this.scale;
      this.$emit('input', output);
    },

    getValidInput() {
      const value = Math.round(parseInt(this.tmp_value, 10) / this.scale * 10000) / 10000;
      if (isNaN(value)) return this.default;
      return Math.min(this.max, Math.max(this.min, value));
    }
  }
};
</script>
