<template lang="pug">
div.card.card-rounded(:class="{'overdue' : invoice.overdue}")
  div.card-body.medium-body
    div.row
      div.col-4.invoice-number \#{{invoice.scoro_no}}
      div.col-4.invoice-date {{invoice.date | DateFilter}}
      div.col.flex-grow.invoice-payment-type {{invoice.payment_type}}
      div.col-4.text-right.invoice-total {{currency}} {{invoice.total}}
      div.col-4.text-right
        a(v-if="invoice.url" :href="invoice.url" target="_blank") See invoice
</template>

<script>
export default {
  name: 'InvoiceRow',
  props: {
    invoice: Object,
  },
  computed: {
    currency() {
      if (this.invoice.currency != null) {
        if (this.invoice.currency === 'USD' || this.invoice.currency === '$') {
          return '$';
        } else if (this.invoice.currency === 'GBP' || this.invoice.currency === '£') {
          return '£';
        } else if (this.invoice.currency !== 'EUR' && this.invoice.currency !== '€') {
          return this.invoice.currency;
        }
      }
      return '€';
    },
  },
};
</script>
