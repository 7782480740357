<template lang="pug">
  div.col.no-gutter
    div.row.flex-align-center
      template(v-if="object.background.color != null")
        small.col-5 Fill:
        ColorOption.full-width.col-6(v-model="object.background.color" @input="$emit('update')")
      div.col-2
      template(v-if="object.background.alpha != null")
        small.col-6 Opacity:
        StyleInput.col-5(v-model="object.background.alpha" :label="'%'" :default="1" :min="0" :max="1" :scale="100" @input="update($event, 'alpha')")
    div.row.flex-align-center.mt-8
      template(v-if="object.background.border.color != null")
        small.col-5 Stroke:
        ColorOption.full-width.col-6(v-model="object.background.border.color" @input="$emit('update')")
      div.col-2
      template(v-if="object.background.border.radius != null")
        small.col-6 Corner radius:
        StyleInput.col-5(:key="object.background.border.radius" v-model="object.background.border.radius" :label="'px'" :default="4" @input="update($event, 'radius')")
</template>

<script>
import StyleInput from '@cm/UI/Inputs/StyleInput';
import ColorOption from '@master/UI/ColorOption';

export default {
  name: 'ButtonSettings',
  components: {
    ColorOption,
    StyleInput
  },

  props: {
    object: Object
  },

  methods: {
    update(value, key) {
      this.$set(this.object, key, value);
      this.$emit('update');
    }
  }
};
</script>
