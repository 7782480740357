<template lang="pug">
tr
  td(colspan="4")
    div.flex.flex-align-center.gap-8
      UserImage(:name="user.name" :img="user.img")
      div.flex-grow {{user.name}}
  td(colspan="4")
    div.flex.flex-align-center
      div.flex-grow.overflow-ellipsis
        span.color-gray-800(v-tooltip="{value: email}") {{ email }}
      Reveal(v-model="revealed")
  td(colspan="4")
    div.flex.flex-align-center.gap-8
      SearchSelect.flex-grow(
        v-if="editable"
        :options="roles"
        v-model="user.role_id"
        :disabled="disabled"
        :search="false"
        @change="roleChangeHandler(user)")
      div.flex-grow(v-else) {{ roles[user.role_id] }}
      slot
</template>

<script>
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import UserImage from '@master/UI/UserImage/User.vue';

import Reveal from '@cm/Views/Profile/Blocks/Components/Reveal.vue';

import Filters from '@master/Filters';
import { getRoles } from '@helpers/Global';

export default {
  name: 'MemberRow',

  components: {
    Reveal,
    SearchSelect,
    UserImage,
  },

  props: {
    user: {
      type: Object,
      validator: obj => {
        return obj.name && obj.email && obj.role_id;
      },
    },
    organization: Object,
    editable: Boolean,
    disabled: Boolean,
  },

  computed: {
    email() {
      if (this.revealed) return this.user.email;
      return Filters.SensitivityFilter(this.user.email, 'email');
    },
  },

  data() {
    return {
      roles: getRoles(),
      revealed: false,
    };
  },

  methods: {
    roleChangeHandler() {
      // minimal required obj
      const user = {
        user_id: this.user.user_id,
        role_id: this.user.role_id,
      };
      const path = `groups/${this.organization.group_id}/users/${this.user.user_id}`;
      this.$http.put(path, { user });
    },
  },
};
</script>
