<template lang="pug">
Container(
  title="Your Subscription Plan"
)
  section(:class="styles.billing_time")
    section(v-for="period of ['monthly', 'annually']")
      CustomCheckbox(
        :key="period"
        :radio="true"
        :value="pricing_period === period"
        @change="handlePricingPeriodChange(period)"
      )
        span.ml-8 Billed {{ period }}
    Pill(variant="success" :title="`save ${formatted_annual_discount}%`")
    div.flex-grow
    section
      CustomCheckbox(
        v-for="_currency in currencies"
        :key="_currency"
        :radio="true"
        :value="_currency === currency"
        @change="handleCurrencyChange(_currency)"
      )
        span.ml-8 {{ _currency }}&nbsp;
          CurrencySign(:currency="_currency")

  div.fs-14.mb-16 Your monthly ad impressions:
  ImpressionSlider(
    :impressions="purchased_impressions"
    :currency="currency"
    :cpm_price="topup_cpm_price"
    @input="updateImpressionCost"
  )

  div(v-if="loading")
    Loading
  section(v-else :class="styles.packages_container")
    Packages(title="Additional features:")
      Package(
        v-if="features != null && Object.keys(features).length > 0"
        v-for="service in features"
        :key="service.key"
        :title="service.name"
        :description="service.description"
        :price="service.price?.[currency ?? 'EUR']"
        :currency="currency"
        :checked="selected_features.find(s => s?.key === service.key) != null"
        @change="handleServiceSelection($event, service.key)"
      )

    Packages(title="Support:")
      Package(
        v-if="support != null && Object.keys(support).length > 0"
        v-for="support in supports"
        :key="support.key"
        :title="support.name"
        :description="support.description"
        :price="support.price?.[currency ?? 'EUR']"
        :currency="currency"
        :checked="selected_support?.key === support.key"
        :support="true"
        @change="handleSupportSelection(support)"
      )

  div(:class="styles.footer")
    UpsellingFooter(type="billing" style="margin-block: 4rem 0;")
</template>

<script>
import styles from './PackageCalculator.module.scss';

import BillingService from '@cm/Views/Billing/helpers/BillingService';
import { aggregateFeatures, formatNumber } from '@cm/Views/Billing/helpers';

import CustomCheckbox from '@master/UI//CustomCheckbox';
import Loading from  '@master/UI/Loading';
import Pill from '@master/UI/Pill/Pill.vue';
import UpsellingFooter from '@master/UI/UpsellingFooter';

import Container from '@cm/Views/Billing/Components/Container/Container';
import CurrencySign from '@cm/Views/Billing/Components/CurrencySign';
import ImpressionSlider from '@cm/Views/Billing/Components/ImpressionSlider/ImpressionSlider';
import Package from '@cm/Views/Billing/Components/PackageCalculator/Package';
import Packages from '@cm/Views/Billing/Components/PackageCalculator/Packages';

export default {
  name: 'PackageCalculator',

  components: {
    Container,
    CurrencySign,
    CustomCheckbox,
    ImpressionSlider,
    Loading,
    Package,
    Packages,
    Pill,
    UpsellingFooter
  },

  computed: {
    formatted_annual_discount() {
      return formatNumber(this.annual_discount * 100);
    },

    topup_cpm_price() {
      if (this.products == null) return .4;

      return Object.values(this.products?.impression_products)
        ?.find(product => this.impressions <= product.max_impressions)
        ?.price?.[this.currency ?? 'EUR'] ?? 0;
    }
  },

  data() {
    return {
      styles,

      organization: null,
      subscription: null,
      products: null,
      loading: true,

      features: {},
      supports: {},

      currencies: [],
      currency: 'EUR',
      pricing_period: 'monthly',
      annual_discount: 0,

      selected_support: null,
      selected_features: [],

      purchased_impressions: 1000000,
      impressions: 0
    };
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      this.features = {};
      this.supports = {};
      this.loading = true;

      this.organization = await BillingService.getOrganization();
      this.subscription = await BillingService.getSubscription();
      const pricing_details = await BillingService.getPricingDetails();

      if (pricing_details?.additional_features != null) {
        this.products = pricing_details;

        const aggregated_features = aggregateFeatures(this.products.additional_features);

        this.features = aggregated_features.features;
        this.supports = aggregated_features.supports;

        this.createInitialCart();
      }

      if (pricing_details?.annual_discount != null) {
        this.annual_discount = pricing_details.annual_discount;
      }

      if (pricing_details?.currencies != null) {
        this.currencies = pricing_details.currencies;
      }

      this.loading = false;
    },

    updateImpressionCost(value) {
      this.impressions = value;
      this.impression_cost = this.impressions * 400;

      this.updateCart();
    },

    handlePricingPeriodChange(pricing_period) {
      this.pricing_period = pricing_period;
      this.updateCart();
    },

    handleCurrencyChange(currency) {
      this.currency = currency;
      this.updateCart();
    },

    handleServiceSelection(value, key) {
      if (value) {
        this.selected_features.push(this.features[key]);
      } else {
        this.selected_features = this.selected_features.filter(service => service.key !== key);
      }

      this.updateCart();
    },

    handleSupportSelection(support) {
      this.selected_support = support;
      this.updateCart();
    },

    createInitialCart() {
      if (this.subscription?.features?.length > 0) {
        for (const feature_id of this.subscription.features) {
          if (feature_id.includes('sla-')) {
            this.selected_support = this.supports[feature_id];
          } else {
            this.selected_features.push(this.features[feature_id]);
          }
        }
      }

      if (this.subscription?.pricing_period != null) {
        this.pricing_period = this.subscription.pricing_period;
      }

      if (this.selected_support == null) {
        this.selected_support = this.supports['sla-1'];
      }

      if (this.subscription?.featured_impressions != null) {
        this.purchased_impressions = this.subscription.featured_impressions;
      }

      if (this.subscription?.currency != null) {
        this.currency = this.subscription.currency;
      } else if (this.organization?.currency != null) {
        this.currency = this.organization.currency;
      }

      this.updateCart();
    },

    updateCart() {
      this.$emit('update', this.createCartPayload());
    },

    createCartPayload() {
      const features = this.selected_features?.map(feature => feature?.key) ?? [];

      if (this.selected_support != null) {
        features.unshift(this.selected_support.key);
      }

      return {
        currency: this.currency,
        featured_impressions: this.impressions,
        features,
        payment_period: this.pricing_period === 'annually' ? 'annual' : 'monthly'
      };
    }
  }
};
</script>
