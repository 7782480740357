<template lang="pug">
div
  h3.mb-16 {{ title }}

  slot(name="header")

  div(:class="[flex ? styles.flex : styles.grid]")
    slot
</template>

<script>
import styles from '@root/src/apps/components/Global/Components.module.scss';

export default {
  name: 'TestLayout',

  props: {
    title: {
      type: String,
      default: 'Test'
    },

    flex: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      styles
    };
  }
};
</script>
