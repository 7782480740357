<template lang="pug">
div(:class="[styles.card, styles.card_medium]")
  span(:class="styles.label") Your CO2 saving
  Loading(v-if="loading")
  template(v-else)

    div(:class="[styles.title, { 'color-gray-600': component_value === 0 }]") {{ component_value | NumberFilter }} {{ component_unit }}

    div.flex.flex-align-center.column-gap-16(v-if="component_value > 0")
      img(:src="`${$cdn}dist/assets/cm/analytics/co2/${equivalent.key}.svg`" :alt="equivalent.key")
      span.fs-14.color-cyan-blue.fw-400 {{ equivalent.label }}
</template>

<script>
import styles from '../Dashboard.module.scss';

import Loading from '@master/UI/Loading';

export default {
  name: 'TotalCarbonSaving',

  components: {
    Loading,
  },

  props: {
    loading: {
      type: Boolean,
      default: true,
    },

    data: Object,
  },

  computed: {
    savetheplanet() {
      return this.data?.scope3?.saved ?? this.data?.nexd;
    },

    component_value() {
      return this.savetheplanet?.value ?? 0;
    },

    component_unit() {
      return this.savetheplanet?.unit ?? '';
    },

    equivalent() {
      if (this.savetheplanet == null) {
        return null;
      }

      return this.getEquivalent(this.savetheplanet.equivalents);
    },
  },

  data() {
    return {
      styles,
    };
  },

  methods: {
    randomElFromArray(array) {
      return array[Math.floor(Math.random() * array.length)];
    },

    getEquivalent(data) {
      const equivalent = this.randomElFromArray(Object.keys(data));

      return {
        key: equivalent,
        label: this.randomElFromArray(data[equivalent]),
      };
    },
  },
};
</script>
