<template lang="pug">
  div
    hr
    label Admin details:
    div.row
      div.col-4
        h4.mb-2 Group imp. type
        div.small-text {{Controller.getImpressionType() | ImpressionTypeFilter}}
      div.col-4
        h4.mb-2 Creative imp. type
        div.small-text {{creative.channel_type | ImpressionTypeFilter}}
    hr
</template>
<script>

import { Controller } from '@master/Services/AnalyticsAPPService';

export default {
  name: 'SummaryCardAdminHeader',

  props: {
    creative: Object
  },

  data() {
    return {
      Controller
    };
  }
};
</script>
