<template lang="pug">
div(:class="styles.group_wrapper")
  div(:class="styles.title") Export report on given date range:

  Group(v-if="!is_sdk_user" :label="`${name} Report:`" :tooltip="{template: 'analytics.campaignExportInfo'}")
    Buttons(align="left")
      Button(type="link" label="Report in XLSX format" @click="exportCampaign('overview_xlsx')")
        template(v-slot:prefix)
          i.nexd-icon-32-download(aria-hidden="true")
    Buttons(align="left")
      Button(type="link" label="Report in XLSX format, detailed" :disabled="disable_detail_export" @click="exportCampaign('overview_details')")
        template(v-slot:prefix)
          i.nexd-icon-32-download(aria-hidden="true")
    Buttons(align="left")
      Button(type="link" label="Report in XLSX format, pivot" :disabled="disable_pivot_export" @click="exportCampaign('overview_pivot')")
        template(v-slot:prefix)
          i.nexd-icon-32-download(aria-hidden="true")

  Group(v-if="can_export_creative" label="Creative Report:" :tooltip="{template: 'analytics.creativeExportInfo'}")
    Buttons(align="left")
      Button(type="link" label="Report in XLSX format" @click="exportCreative('creative_xlsx')")
        template(v-slot:prefix)
          i.nexd-icon-32-download(aria-hidden="true")

  Group(v-if="can_see_schedules")
    Button(type="primary" :label="`Scheduled Reports${schedules?.length > 0 ? ` ${schedules?.length}` : ''}`" :disabled="schedules == null" @click="openSchedulerModal")

  Group(v-if="can_share_analytics" label="Share Analytics" :tooltip="{value: 'Anyone who has this link can view this campaign analytics without needing access to Nexd'}")
    Buttons(align="left")
      Button(type="link" label="Get Public Link" @click="getShareLink")
        template(v-slot:prefix)
          i.nexd-icon-32-share(aria-hidden="true")

    input(v-if="public_url != null" ref="public_url" type="text" class="form-control" v-model="public_url" readonly)

  LoadingModal(
    v-if="exporting_report"
    :head="exporting_report_head"
    :body="exporting_report_body"
    :closable="exporting_report_closable"
    @close="closeExportLoadingModal(true)"
  )
  SchedulerModal(v-if="scheduler_modal_opened" :schedules="schedules" @close="closeSchedulerModal")
</template>

<script>
import styles from '../Sidebar.module.scss';

import CreativeService from '@master/Services/CreativeService';
import AnalyticsService from '@master/Services/AnalyticsService';
import { filters, report } from '@master/Services/AnalyticsAPPService';
import UserNotificationService from '@master/Services/Cache/UserNotificationService';

import { setClipboard, getURLForEnv } from '@helpers/Global';

import Group from '@analytics/UI/Sidebar/Components/Group';
import SchedulerModal from '@analytics/Modals/SchedulerModal';

import Button from '@master/UI/Buttons/Button';
import Buttons from '@master/UI/Buttons/Buttons';
import LoadingModal from '@master/UI/LoadingModal';

import PermissionMixin from '@master/Services/mixins/PermissionMixin';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import { ANALYTICS_EXPORT } from '@master/constants';

export default {
  name: 'ExportCampaign',
  mixins: [PermissionMixin, SDKMixin],

  components: {
    Button,
    Buttons,
    Group,
    LoadingModal,
    SchedulerModal,
  },

  data() {
    return {
      styles,

      admin_toggle: false,
      public_url: null,
      data: null,
      active_creative: null,
      filters,
      report,
      collapse: false,
      scheduler_modal_opened: false,
      user: null,
      schedules: null,
      async_dismissed: false,
      name: 'Campaign',

      exporting_report: false,
      exporting_report_head: null,
      exporting_report_body: null,
      exporting_report_closable: false,
    };
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);

    AnalyticsService.subscribe(data => {
      this.data = data;
    }, this);

    this.name = AnalyticsService.getActiveParentName();

    CreativeService.subscribe(creative => {
      this.active_creative = creative;
    }, this);

    this.$user.subscribe(user => {
      this.user = user;
      this.admin_toggle = this.$user.adminMode();
    }, this);

    if (this.schedule_block_enabled) {
      this.fetchScheduledReports();
    }
  },

  computed: {
    can_see_schedules() {
      if (this.$route?.params?.campaign_id == null || this.is_sdk_user) {
        return false;
      }

      /**
       * show schedule reports button if:
       * - user is nexd admin
       */
      if (this.admin_toggle) {
        return true;
      }

      /**
       * show schedule reports button if:
       * - user is owner of the campaign
       * - user has admin role
       */
      if (this.user?.user_id === this.data?.owner?.user_id || this.can_manage_group_content) {
        return true;
      }

      /**
       * show schedule reports button if:
       * - user is collaborator of the campaign
       */
      if (this.data?.collaborators?.length > 0) {
        for (let collaborator of this.data.collaborators) {
          if (collaborator?.user_id === this.user?.user_id) {
            return true;
          }
        }
      }

      return false;
    },

    can_export_creative() {
      return this.active_creative != null && this.$route?.params?.live_id != null && this.$route?.params?.campaign_id != null;
    },

    can_share_analytics() {
      const params = this.$route?.params;
      return params?.campaign_id != null || (params?.live_id == null && params?.flight_id != null);
    },

    disable_pivot_export() {
      if (!this.data) return true;
      return this.data.published_creatives > ANALYTICS_EXPORT.MAX_PIVOT_CREATIVES;
    },

    disable_detail_export() {
      if (!this.data) return true;
      return this.data.published_creatives > ANALYTICS_EXPORT.MAX_DETAILED_CREATIVES;
    },

    exporting_data() {
      return {
        base: filters.base,
        startDate: filters.start,
        endDate: filters.end,
        incvtr: filters.incvtr,
      };
    },

    is_private_view() {
      return this.$route.meta.requires_auth;
    },

    schedule_block_enabled() {
      return this.is_private_view;
    },
  },

  methods: {
    openSchedulerModal() {
      this.scheduler_modal_opened = true;
    },
    closeSchedulerModal() {
      this.scheduler_modal_opened = false;
    },

    fetchScheduledReports() {
      const path = `analytics/campaigns/${this.data.campaign_id}/schedule`;
      this.$http.get(path).then(response => {
        this.schedules = response;
      });
    },

    exportCampaign(type) {
      if (this.exporting_report) return;

      // disable downloading details if the big reports are disabled
      if (type === 'overview_details' && this.disable_detail_export) return;
      if (type === 'overview_pivot' && this.disable_pivot_export) return;

      this.exporting_report = true;

      this.exporting_report_head = 'Please wait while we create the campaign analytics spreadsheet';
      this.exporting_report_body = 'You may dismiss this window and a download link to the report will be sent to you via a notification or you can wait for the spreadsheet to be compiled.';
      this.exporting_report_closable = true;

      if (this.$route?.params?.public_id) {
        this.exporting_report_body = 'Please wait while we create a campaign analytics spreadsheet with a daily summary. This may take a while...';
        this.exporting_report_closable = false;
      }

      const path = AnalyticsService.getActiveParentExportPath(type);

      if (path == null) return;

      this.$http
        .post(path, this.exporting_data)
        .then(async response => {
          if (this.$route?.params?.public_id) {
            return;
          }

          if (this.async_dismissed) {
            UserNotificationService.poll();
            this.async_dismissed = false;
            return;
          }

          if (response.notification_id) {
            // mark this download notification as seen
            UserNotificationService.seen({ notification_id: response.notification_id }, false);
          }

          if (response.file) {
            // remove loading before await
            this.closeExportLoadingModal();

            const download = await this.$confirm('All good!', 'Your spreadsheet is compiled and ready to be downloaded.', {
              buttons: [
                { type: 'link-primary', label: 'Close', action: null },
                { type: 'primary', label: 'Download', action: true },
              ],
              image: 'success',
            });

            if (download) {
              window.open(response.file, '_blank');
            }
          } else if (response.notification) {
            // 1st started process will send notification
            this.$alert("Seems like this report generates longer than expected, we will send you a notification when it's ready.", 'Uh oh!', { image });
          } else {
            // if this export was triggered while the process was active
            // backend only handles notification for the 1st process
            this.$alert('Seems like this report generates longer than expected, please try again later.', 'Uh oh!', { image });
          }
        })
        .catch(_ => {
          this.$alert('Generating analytics report failed, please try again');
        })
        .finally(() => {
          this.closeExportLoadingModal();
        });
    },

    exportCreative(type) {
      if (this.exporting_report) return;
      this.exporting_report = true;

      this.exporting_report_head = 'Please wait while we create the creative analytics spreadsheet';
      this.exporting_report_body = 'Please wait while we create a creative analytics spreadsheet with a daily summary. This may take a while...';
      this.exporting_report_closable = false;

      let path = `analytics/creatives/${this.active_creative.live_id}/export/${type}`;
      if (this.$route?.params?.public_id) {
        path = `shared/campaigns/${this.$route.params.public_id}/creatives/live/${this.active_creative.live_id}/export/${type}`;
      } else if (this.$route?.params?.public_live_id) {
        path = `shared/creatives/${this.$route.params.public_live_id}/export/${type}`;
      }

      const notificationHandler = (t, msg) => {
        let heading, image;
        if (t === 'primary' || t === 'success') {
          heading = 'Please wait';
          image = 'success';
        }
        this.$alert(msg ?? 'Generating analytics report failed, please try again', heading, { image });
      };

      this.$http
        .post(path, this.exporting_data, { notification: notificationHandler })
        .catch(_ => {
          /** supress errors */
        })
        .finally(() => {
          this.closeExportLoadingModal();
        });
    },

    closeExportLoadingModal(async_dismissed = false) {
      this.exporting_report = false;
      this.async_dismissed = async_dismissed;
    },

    toggleCollapse() {
      this.collapse = !this.collapse;
    },

    getShareLink() {
      if (this.active_creative?.live_id) {
        return this.shareCreative();
      } else if (this.data?.flight_id) {
        return this.shareFlight();
      } else if (this.data?.campaign_id) {
        return this.shareCampaign();
      }
    },

    shareCreative() {
      if (this.$router.public_id == null && this.$router.public_live_id == null) {
        const path = `analytics/creatives/${this.active_creative.live_id}/share`;
        this.$http.post(path, {}).then(response => {
          this.setPublicUrl(response.public_url);
        });
      } else {
        this.setPublicUrl(window.location.href);
      }
    },

    shareCampaign() {
      if (this.$router.public_id == null) {
        const path = `analytics/campaigns/${this.data.campaign_id}/share`;
        this.$http.post(path, {}).then(response => {
          const params = window.location.href.split('?');
          params[0] = response.public_url;
          if (response.public_url.indexOf('?') === -1) {
            this.setPublicUrl(params.join('?'));
          } else {
            this.setPublicUrl(params.join('&'));
          }
        });
      } else {
        // clean campaign url from opened creative
        const parts = window.location.href.split('?');
        let url = parts[0];
        let params = null;
        if (parts[1] != null) {
          params = parts[1];
        }

        let end = url.indexOf('/creative/');
        if (end === -1) {
          end = url.length;
        }
        url = url.substring(0, end);
        if (params == null) {
          this.setPublicUrl(url);
        } else {
          this.setPublicUrl(url + '?' + params);
        }
      }
    },

    shareFlight() {
      if (this.$router.public_id == null) {
        const path = `v2/flights/${this.data.flight_id}/analytics/share`;
        this.$http.post(path, {}).then(response => {
          const params = window.location.href.split('?');
          params[0] = response.public_url;
          if (response.public_url.indexOf('?') === -1) {
            this.setPublicUrl(params.join('?'));
          } else {
            this.setPublicUrl(params.join('&'));
          }
        });
      }
    },

    async setPublicUrl(public_url) {
      this.public_url = getURLForEnv(public_url);

      // let the input be rendered before focusing and selecting all of its content
      await this.$nextTick();

      if (this.$refs.public_url != null) {
        this.$refs.public_url.focus();
        this.$refs.public_url.setSelectionRange(0, this.$refs.public_url.value.length);

        setClipboard(this.public_url)
          .then(_ => {
            this.$notifications.add('primary', 'Sharable link copied to clipboard');
          })
          .catch(error => {
            this.$notifications.add('warning', error);
          });
      }
    },
  },
};
</script>
