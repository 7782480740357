<template lang="pug">
span(v-if="currency === 'USD'") &dollar;
span(v-else-if="currency === 'EUR'") &euro;
span(v-else-if="currency === 'GBP'") &pound;
</template>

<script>
export default {
  name: 'CurrencySign',

  props: {
    currency: {
      type: String,
      default: null
    }
  }
};
</script>
