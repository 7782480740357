<template lang="pug">
div
  slot(name="logo")
  div#content-wrapper
    div#title
      h1#magic-title
        | This is where
        br
        | the magic happens
      h5 Let's get creative. Log in and start building.

    form#form(@submit.prevent="submit")
      div.flex.flex-direction-column.row-gap-4
        label E-mail
        Input(v-model="email" placeholder="someone@domain.com" autocomplete="username" :medium="true")

      Buttons(align="center")
        Button(
          :type="form_success ? 'success' : 'login-primary'"
          :label="form_success ? 'Check your e-mail' : 'Request reset'"
          :loading="loading"
          :block="true"
          :medium="true"
          :disabled="empty_input"
          :submit="!form_success"
        )
        Button(type="link-light" label="Back to login" @click="openLogin")
</template>

<script>
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Button from '@master/UI/Buttons/Button.vue';
import Input from '@master/UI/Input/Input.vue';

export default {
  name: 'Reset',

  components: {
    Buttons,
    Button,
    Input
  },

  computed: {
    empty_input() {
      return this.email.trim() === '';
    }
  },

  data() {
    return {
      email: '',
      loading: false,
      form_success: false
    };
  },

  methods: {
    openLogin() {
      this.$parent.open('login');
    },

    submit() {
      if (this.loading || this.form_success || this.empty_input) return;

      this.loading = true;

      this.$http.post('user/forgot-password', { email: this.email })
        .then(() => {
          this.form_success = true;
        })
        .catch(() => {/** suppress errors */})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
