import { VIEW } from '@master/constants';

import GroupAssetsLibrary from '@cm/Views/GroupAssets/GroupAssetsLibrary.vue';

const meta = {
  app_key: 'campaign-manager',
  app_name: 'Team Assets',
  requires_auth: true,
  show_navbar: true,
  validate_adblock: true,
  show_sidebar: true,
  intercom: true,
  view: VIEW.GROUP_ASSETS,
  sdk_client: false,
};

export default [
  {
    path: '/g/:group_id/assets',
    name: `${VIEW.GROUP_ASSETS}`,
    component: GroupAssetsLibrary,
    meta,
    props: true,
  },
  {
    path: '/g/:group_id/assets/f/:folder_id',
    name: `${VIEW.GROUP_ASSETS_FOLDER}`,
    component: GroupAssetsLibrary,
    meta,
    props: true,
  },
];
