<template>
  <div id="bg"></div>
</template>

<script>
import InteractiveBG from '@login/Components/Background/InteractiveBG';

export default {
  name: 'SVGBackground',
  mounted() {
    if (window._christmas && window._christmas_8bit) {
      window._christmas.addImages(this.$el);
    } else {
      const path = this.$cdn + 'dist/assets/login/Illu.svg';
      this.$http.get(path, {withCredentials: false, raw: true}).then(response => {
        this.$el.innerHTML = response;
        const ibg = new InteractiveBG(this.$el);
        ibg.init();
      });
    }
  }
};
</script>
