<template lang="pug">
div.quantum-previews-container(:style="container_style")
  div.row(v-if="creative.type !== -1 && creative.template_id != null && creative.template != null" :class="{'single-preview': sizeset !== null}")
    template(v-for="(size, ss) of screens")
      div.col(:key="size.title" :class="{'hidden': sizeset !== null && sizeset !== ss, 'flex-grow': sizeset !== null}")
        div.mb-16
          div.quantum-preview(:style="{width: size.el.width + 'px'}")
            LivePreview(
              ref="preview"
              :sizeset="ss"
              :creative="creative"
              :height="size.el.height"
              :options="{screen_width: size.width, screen_height: size.height, loading: true}"
              :poll="size.poll"
              @versionchange="handleVersionChange"
            )
            div.footer
              div.title {{size.title}}
              Pill.fs-10(v-if="modifications[ss]" v-tooltip="{value: 'This placement size has different asset or tracking from global.'}" variant="modified" title="modified")
</template>

<script>
import LivePreview from '@master/UI/LivePreview/LivePreview.vue';
import Pill from '@master/UI/Pill/Pill.vue';

export default {
  name: 'PreviewQuantumTemplate',

  components: {
    LivePreview,
    Pill,
  },

  props: {
    creative: Object,
    sizeset: String,
  },

  computed: {
    modifications() {
      let modifications = {};
      for (const sizeset of this.creative.settings.quantum.unique) {
        modifications[sizeset] = false;

        if (this.creative?.settings?.module?.[sizeset] != null) {
          // check if module has been positioned
          modifications[sizeset] = true;
        } else if (this.creative?.settings?.cta?.quantum?.[sizeset]?.uri !== '') {
          // check global cta change
          modifications[sizeset] = true;
        } else {
          // check asset based values
          for (let asset_id in this.creative.assets) {
            if (asset_id.indexOf('_' + sizeset) !== -1) {
              modifications[sizeset] = true;
              break;
            }
          }
          if (!modifications[sizeset]) {
            // check if we have overwritten any asset cta
            for (let asset_id in this.creative.settings.cta.assets) {
              if (asset_id.indexOf('_' + sizeset) !== -1 && this.creative.settings.cta.assets[asset_id].uri !== '') {
                modifications[sizeset] = true;
                break;
              }
            }
          }
        }
      }
      return modifications;
    },

    screens() {
      // for debugging single size
      // return {'320x320': {width: 320, height: 320, title: '320x320'}};
      let screens = {};
      if (this.container_width > 0) {
        const max_width = this.container_width;

        // have only the first element to actually poll for changes
        let poll = true;

        for (const size of this.creative.settings.quantum.unique) {
          let [width, height] = size.split('x');
          width = parseInt(width, 10);
          height = parseInt(height, 10);

          // element sizes where component is being shown
          let el_width = width;
          let el_height = height;

          // make sure the element fits the container
          if (el_width > max_width) {
            const ar = height / width;
            el_width = max_width;
            el_height = Math.round(el_width * ar);
          }

          screens[size] = {
            poll,
            width,
            height,
            el: {
              width: el_width,
              height: el_height,
            },
            title: size,
          };

          // set false after first cycle
          poll = false;
        }
      }

      return screens;
    },

    container_style() {
      if (this.container_height === 0) {
        return null;
      }

      let style = {};
      style.minHeight = this.container_height + 'px';
      return style;
    },
  },

  data() {
    return {
      container_width: 0,
      container_height: 0,
    };
  },

  created() {
    // force debug to false so preview component would not spam the console in lcl :D
    window.debug = false;
  },

  async mounted() {
    // container width - padding of the col
    // this will also trigger tha computed prop `screen` change
    this.container_width = this.$el.clientWidth - 14;

    // set container height var after the preview components are rendered with their sizes
    await this.$nextTick();
    this.container_height = this.$el.clientHeight;
  },

  methods: {
    handleVersionChange(version) {
      this.$emit('versionchange', version);

      if (this.$refs.preview != null) {
        for (const vnode of this.$refs.preview) {
          if (!vnode.poll) {
            // call same fn that poll does in the first component
            vnode.load();
          }
        }
      }
    },
  },
  watch: {
    sizeset: {
      async handler() {
        // if sizeset is active, container will be col-12 (50%), so we need to overwrite the width value for component size generations
        this.container_width = this.$el.clientWidth - 14;

        // on sizeset change, reset height values
        this.container_height = 0;

        // after the tick, get new height from the content
        await this.$nextTick();
        this.container_height = this.$el.clientHeight;
      },
    },
  },
};
</script>
