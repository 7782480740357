<script>
import { getURLForEnv } from '@helpers/Global';

export default {
  computed: {
    analytics_url() {
      if (!this.campaign.analytics_url) return null;
      return getURLForEnv(this.campaign.analytics_url);
    },

    has_creatives() {
      return this.campaign?.meta?.creatives?.total > 0;
    },

    free_or_basic_plan_user() {
      return this.$user.isFreeOrBasicPlanUser();
    },

    is_new() {
      return this.campaign.campaign_id.indexOf('new-') !== -1;
    },

    is_owner() {
      return this.campaign?.owner?.user_id != null && this.$user?.user?.user_id != null && this.campaign.owner.user_id === this.$user.user.user_id;
    },

    can_change_owner() {
      return this.is_owner || this.$user.isNexdAdmin();
    },

    can_delete() {
      return !!this.campaign?.actions?.can_remove;
    },

    can_update() {
      return this.is_new || !!this.campaign?.actions?.can_update;
    },

    can_download_tag() {
      return !!this.campaign?.actions?.can_download_tag;
    }
  },

  data() {
    return {
      user: null
    };
  },

  created() {
    this.$user.subscribe(user => {
      this.user = user;
    }, this);
  }
};
</script>
