<template lang="pug">
div#app-wrapper.full-width
  CreativesNavbar(
    :creatives="[]"
    :folders="[]"
    :selection="selection"
    :disable_import="disable_new"
    :disbale_new_creative="disable_new"
    :breadcrumbs="[]"
    :show_unarchive_btn="false"
    :show_duplicate_btn="false"
    @createPlacement="createPlacement"
  )

  div#creatives-list(v-content)
    NoListItem(v-if="creative == null && import_type == null" type="creatives")

    Component(v-if="import_type != null" :is="imports[import_type]" @onCreate="imported" @onClose="hideImport" @onRemove="hideImport")
    CreativeBase(v-if="creative != null" :creative="creative" @cancel="hideNewCreative" @create="create")

  LoadingModal(
    v-if="loading_head != null"
    :head="loading_head"
    body="Please wait until creative is created. It can take a while."
  )
</template>

<script>
import { IMPORT, VIEW } from '@master/constants';
import { getNewCreativeOptions, newCreative } from '@helpers/Creative';
import Selection from '@libs/Selection';
import SDKPlugin from '@libs/SDKPlugin';

import CreativeTraits from '@master/Traits/CreativeTraits';

import CreativeBase from '@cm/Views/Creatives/Creative/CreativeBase';
import CreativesNavbar from '@cm/Views/Creatives/Components/CreativesNavbar.vue';
import ExcelImport from '@cm/Views/Creatives/Cards/ExcelImport';
import HTML5Import from '@cm/Views/Creatives/Cards/HTML5Import';
import NoListItem from '@cm/UI/Global/NoListItem.vue';
import QuantumImport from '@cm/Views/Creatives/Cards/QuantumImport';
import VASTImport from '@cm/Views/Creatives/Cards/VASTImport';

import LoadingModal from '@master/UI/LoadingModal';

export default {
  name: 'SDKNewCreative',

  mixins: [CreativeTraits],

  components: {
    CreativeBase,
    CreativesNavbar,
    ExcelImport,
    HTML5Import,
    LoadingModal,
    NoListItem,
    QuantumImport,
    VASTImport,
  },

  computed: {
    disable_new() {
      return this.creative != null || this.import_type != null;
    },
  },

  data() {
    return {
      IMPORT,

      selection: new Selection(),

      imports: {
        [IMPORT.VAST]: 'VASTImport',
        [IMPORT.QUANTUM]: 'QuantumImport',
        [IMPORT.HTML5]: 'HTML5Import',
        [IMPORT.EXCEL]: 'ExcelImport',
      },

      import_type: null,
      creative: null,
      loading_head: null,
    };
  },

  methods: {
    createPlacement(type = 'infeed') {
      if (Object.values(IMPORT).includes(type)) {
        this.import_type = type;
        return;
      }

      this.newDummyCreative(type);
    },

    newDummyCreative(type) {
      newCreative(getNewCreativeOptions(type)).then(creative => {
        this.creative = creative;

        if (this.isVast()) {
          this.create();
        }
      });
    },

    async hideNewCreative() {
      if (!(await this.$confirm('Are you sure?', 'All changes will be discarded'))) {
        return;
      }

      this.creative = null;
    },

    create(creative) {
      creative = creative ?? this.creative;

      this.loading_head = 'Creating new creative';

      const version = creative.layout_id != null && creative.template_id == null ? 'v2/' : '';

      this.$http
        .post(`${version}campaigns/${this.$route.params.campaign_id}/creatives`, creative, { notification: false })
        .then(new_creative => {
          SDKPlugin.sendMessage({
            action: 'creative.created',
            data: new_creative,
          });
          this.goToCreative(new_creative.creative_id);
        })
        .catch(_ => {
          this.$notifications.add('error', 'Campaign not found');
        })
        .finally(() => {
          this.loading_head = null;
          this.creative = null;
        });
    },

    goToCreative(creative_id) {
      if (creative_id == null) {
        return;
      }

      this.$router
        .push({
          name: VIEW.CREATIVE,
          params: { creative_id },
        })
        .catch(_ => {
          /** supress duplicate route error */
        });
    },

    imported(creatives) {
      this.hideImport();
      this.goToCreative(creatives?.[0]?.creative_id);
    },

    hideImport() {
      this.import_type = null;
    },
  },
};
</script>
