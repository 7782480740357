<script>
export default {
  computed: {
    disable_save() {
      return this.disable_create;
    },

    is_new() {
      return this.creative?.creative_id?.indexOf('new-') !== -1;
    },
  },
};
</script>
