class Lamp {
  constructor(svg) {
    this.svg = svg;
    this.light = this.svg.querySelector('#lamplight');
    this.switch = this.svg.querySelector('#switches');
    this.onSwitch = this.switch.querySelector('#switch-on');
    this.offSwitch = this.switch.querySelector('#switch-off');
    this.on = true;
    this.init();
  }
  init() {
    this.light.classList.add('transition');
    this.light.classList.add('fast');
    this.toggleLight();
    setTimeout(() => {
      setTimeout(() => {
        this.toggleLight();
        setTimeout(() => {
          this.toggleLight();
          setTimeout(() => {
            this.toggleLight();
            setTimeout(() => {
              this.toggleLight();
              setTimeout(() => {
                this.toggleLight();
                this.initSwitch();
              }, 400);
            }, 300);
          }, 200);
        }, 100);
      }, 100);
    }, 1000);
  }
  initSwitch() {
    this.switch.classList.add('clickable');
    this.switch.addEventListener('click', () => this.switchHandler());
  }
  switchHandler() {
    // this.on will be changed at toggle light, so the check is reversed
    if (this.on === false) {
      this.onSwitch.style.opacity = 1;
      this.offSwitch.style.opacity = 0;
    } else {
      this.onSwitch.style.opacity = 0;
      this.offSwitch.style.opacity = 1;
    }
    this.toggleLight();
  }
  toggleLight() {
    this.on = !this.on;
    this.light.classList.toggle('hidden');
  }
}

export default Lamp;
