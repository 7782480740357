import Chartist from '@libs/Chartist';

class PointTooltip {
  constructor (context, bindHover = null) {
    this.context = context;
    this.tooltipContent = this.context.meta;
    // horizontal line, that will be visible on hover
    this.bindHover = bindHover;
    this._tooltip = null;
  }
  init () {
    let hitboxSizeX = this.context.axisX.stepLength;
    let hitboxSizeY = this.context.axisY.stepLength;
    this.group = new Chartist.Svg('g', {}, 'ct-point-with-hitbox');
    this.hitbox = new Chartist.Svg('rect', {
      x: this.context.x - hitboxSizeX * 0.5,
      y: this.context.y - hitboxSizeY * 0.5,
      width: hitboxSizeX,
      height: hitboxSizeY,
      fill: 'transparent'
    }, 'ct-hitbox', this.group);
    this.circle = new Chartist.Svg('circle', {
      cx: this.context.x,
      cy: this.context.y,
      // outcommented, because this seem to give no value to the circle obj
      // 'ct:value': this.context.value,
      fill: 'white',
      r: [8]
    }, 'ct-point', this.group);

    this.group.getNode().addEventListener('mouseover', () => {
      this.show();
    });
    this.group.getNode().addEventListener('mouseout', () => {
      this.hide();
    });
    return this.group;
  }
  create () {
    this._tooltip = document.createElement('div');
    this._tooltip.className = 'ct-tooltip point-tooltip';
    this._tooltip.innerHTML = this.tooltipContent;
  }
  getPosition () {
    const pointPosition = this.circle.getNode().getBoundingClientRect();
    const top = pointPosition.top + window.scrollY;
    const left = pointPosition.left + window.scrollX;
    const offsetY = pointPosition.height;
    const offsetX = pointPosition.width * 0.5;
    this._tooltip.style.top = top - offsetY + 'px';
    this._tooltip.style.left = left + offsetX + 'px';
  }
  show () {
    this.create();
    this.getPosition();
    if (this._tooltip != null) {
      document.body.appendChild(this._tooltip);
    }
    if (this.bindHover !== null) {
      this.bindHover.classList.add('hover');
    }
  }
  hide () {
    if (this._tooltip?.parentNode != null) {
      this._tooltip.parentNode.removeChild(this._tooltip);
    }
    if (this.bindHover !== null) {
      this.bindHover.classList.remove('hover');
    }
  }
}
export default PointTooltip;
