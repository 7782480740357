<template lang="pug">
div(:class="styles.container")
  div.fs-16.fw-500 {{ is_dco ? 'Creatives' : 'Fallback' }}
  div.color-gray-800 {{ description }}
  div(v-if="!is_dco" :class="styles.subtitle") Show following creatives:

  FlightStatementCreatives(:statement="statement" @change="$emit('change')")
</template>

<script>
import styles from './FlightFallback.module.scss';

import FlightStatementCreatives from '@cm/Views/Flights/Components/FlightStatements/FlightStatementCreatives/FlightStatementCreatives';

export default {
  name: 'FlightFallback',

  components: {
    FlightStatementCreatives,
  },

  props: {
    statement: Object,

    is_dco: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    description() {
      if (this.is_dco) {
        return 'Choose the creatives that you will run with these settings.';
      }

      return 'These are the creatives shown if none of the chosen conditions above don’t match inventory availability.';
    },
  },

  data() {
    return {
      styles,
    };
  },
};
</script>
