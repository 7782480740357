<template lang="pug">
div.flex.pt-32.pb-32.pl-24.pr-24(
  :class="active_tab !== 'tracking' && active_tab !== 'cta' ? 'flex-justify-between' : 'flex-justify-end'")
  CreativeRow.shrink(
    v-if="active_tab !== 'tracking' && active_tab !== 'cta'"
    label="Creative preview:"
  )
  div(data-intercom-target="creative-settings-navigation-tab")
    div.inline-tabs.full-width
      template(v-for="(label, tab, index) of enabled_tabs")
        i.nexd-icon-16-arrow-right-small.color-primary.content-arrow(v-if="index > 0")
        div.tab(
          :class="{'active': active_tab === tab, 'disabled': !can_change_tab}"
          @click="changeTab(tab)"
        )
          div.tab-content {{ label }}
</template>

<script>
import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import CreativeRow from '@cm/Views/Creatives/Creative/CreativeRow.vue';

export default {
  name: 'SettingsTab',

  mixins: [CreativeTraits, SDKMixin],

  components: {
    CreativeRow,
  },

  props: {
    creative: Object,

    // used when isExpanded is true
    parent: Object,

    isExpanded: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    responsive_settings_closed: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    active_tab() {
      return this.$store.get('active_tab');
    },

    can_change_tab() {
      return !this.isFeed() && !this.loading;
    },

    enabled_tabs() {
      let tabs = {
        assets: 'Assets',
        extras: 'Extras',
        options: 'Options',
        cta: 'Click Actions',
        tracking: 'Tracking',
      };

      if (this.is_sdk_user) {
        delete tabs.cta;
        delete tabs.tracking;
      }

      if (process.env.VUE_APP_enabled_tabs) {
        let dsp_app_tabs = process.env.VUE_APP_enabled_tabs;

        // if the configuration has them as csv, convert em to an array
        if (typeof dsp_app_tabs === 'string') {
          dsp_app_tabs = process.env.VUE_APP_enabled_tabs.split(',');
        }

        for (const key in tabs) {
          if (!dsp_app_tabs.includes(key)) {
            delete tabs[key];
          }
        }
      }

      // responsive has no extras assets tab
      if (this.isQuantum()) {
        delete tabs.extras;
        return tabs;
      }

      // HTML5 has preview, click actions and tracking tab
      if (this.isHTML5()) {
        // show asset tabs as preview tab
        tabs.assets = 'Preview';
        delete tabs.extras;
        delete tabs.options;

        // lightbox second state should not have cta
        if (this.isExpanded) {
          delete tabs.cta;
        }

        return tabs;
      }

      // expandable and dynamic endcard 1st has fixed CTA from the backend
      if (this.isBinded() && !this.isExpanded) {
        delete tabs.cta;
        return tabs;
      }

      // any other case, all possible tabs
      return tabs;
    },
  },

  methods: {
    changeTab(tab) {
      const current_tab = this.active_tab;
      this.$store.set('active_tab', tab);

      if (this.isQuantum() && this.responsive_settings_closed) {
        this.$emit('openResponsiveSettings');
      } else if (this.isQuantum() && !this.responsive_settings_closed && tab === current_tab) {
        this.$emit('closeResponsiveSettings');
      }
    },
  },
};
</script>
