<template lang="pug">
div(:class="styles.container")
  h3 {{ title }}:

  div(:class="styles.content")
    section.mt-4
      slot(name="services")

    section.mt-32
      template(v-if="saved > 0")
        span.color-success.fw-500 Annual win
        span.color-success -{{ formatValue(saved) }}

      span Subtotal
      span {{ formatValue(subtotal) }}

      slot(name="vat")

    div(:class="styles.total")
      div.fw-500 {{ total_label }}:
      div.fs-22.color-primary.mt-4 {{ formatValue(total) }}

      slot(name="estimated")
</template>

<script>
import styles from './Cart.module.scss';

import { formatPrice } from '@cm/Views/Billing/helpers';

export default {
  name: 'Cart',

  props: {
    title: {
      type: String,
      default: 'Plan overview',
    },

    subtotal: {
      type: Number,
      default: 0,
    },

    total: {
      type: Number,
      default: 0,
    },

    total_label: {
      type: String,
      default: 'Total',
    },

    saved: {
      type: Number,
      default: 0,
    },

    currency: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      styles,
    };
  },

  methods: {
    formatValue(value) {
      return formatPrice(value, {
        currency: this.currency,
      });
    },
  },
};
</script>
