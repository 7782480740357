class MonitorWindow {
  constructor(node, active = true, onClose = null, screen = null) {
    this.node = node;
    this.active = active;
    this.onClose = onClose;
    this.screen = screen;
    this.init();
  }
  init() {
    if (this.active === false) {
      this.hide();
    }
    let closeButton = this.node.querySelector('[id^=close]');
    // not all windowses have close button
    if (closeButton != null) {
      let closeButtonHitbox = closeButton.querySelector('[id^=HITBOX]');

      closeButtonHitbox.classList.add('clickable');
      closeButtonHitbox.addEventListener('click', () => {
        if (this.onClose !== null) {
          this.onClose();
        }
        this.hide(true);
      });
    }
  }
  show(force = false) {
    if (this.canToggle() || force === true) {
      this.active = true;
      this.node.style.display = null;
      this.node.style.opacity = 1;
    }
  }
  hide(force = false) {
    if (this.canToggle() || force === true) {
      this.active = false;
      this.node.style.display = 'none';
      this.node.style.opacity = 0;
    }
  }
  toggle() {
    if (this.canToggle()) {
      if (this.active === false) {
        this.show();
      } else {
        this.hide();
      }
    }
  }
  canToggle() {
    return this.screen.on === true && this.screen.error === false;
  }
}

export default MonitorWindow;
