<template lang="pug">
div.tracking-row-component(:class="{'global': global}")

  //- Asset column
  div.tracking-row-column(v-if="asset_id != null")
    Thumbnail(:asset="asset" :small="true")
    div.name {{ name }}
  div.tracking-row-column.column(v-else-if="object != null" :class="{'indent': isOption}")
    div.name {{ name }}
    div.name.fs-12 "{{ user_value }}"

  //- Event/Tracker inline grid
  template(v-if="trackers != null")
    div.tracking-row-inline-grid
      template(v-for="(tracker, index) in trackers")
        //- Event column
        div.tracking-row-column.mb-0(:key="`${index}_1_column`")
          SearchSelect.input-prefix(
            v-if="Object.keys(pixel_url_options).length > 1"
            :options="pixel_url_options"
            :search="false"
            v-model="tracker.action"
            width="100%"
            @input="change"
          )
          div.fs-14(v-else) {{ Object.values(pixel_url_options)[0] }}
          div.flex-grow.text-right(v-if="tooltip != null")
            i.nexd-icon-32-help(v-tooltip="{template: tooltip}")
            i.nexd-icon-32-warning.color-warning(v-if="tooltip != null && name.includes('Choice') && pii && tracker.uri === ''" v-tooltip="{template: 'cm.personallyIdentifiableInformationTracking'}")

        //- Tracker column
        div.tracking-row-column.form-group.mb-0(:key="`${index}_2_column`")
          TextareaInput(
            ref="tracking-input"
            placeholder="Insert tracking pixel URL (incl. macro)"
            :url="true"
            v-model="tracker.uri"
            @change="parseTrackersExcelAndChange(index)"
          )
            RemoveIcon(
              type="input"
              :tooltip="{value: 'Delete'}"
              @click="removeImpressionTracker(index);"
            )
          i.nexd-icon-16-plus.add(@click="addPixelInput(index)" v-tooltip="{value: 'Add'}")
</template>

<script>
import SearchSelect from '@master/UI/SearchSelect/SearchSelect';
import RemoveIcon from '@master/UI/RemoveIcon';

import Thumbnail from '@cm/UI/Global/Thumbnail/Thumbnail';
import TextareaInput from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TextareaInput';

import CreativeTraits from '@master/Traits/CreativeTraits';
import { parseTrackerInput, clone } from '@helpers/Global';
import AssetHelper from '@helpers/Asset';

export default {
  name: 'TrackingRowComponent',
  components: {
    SearchSelect,
    RemoveIcon,
    Thumbnail,
    TextareaInput,
  },
  mixins: [CreativeTraits],
  props: {
    creative: Object,
    name: String,
    user_value: {
      type: String,
      default: '',
    },
    global: {
      type: Boolean,
      default: false,
    },
    sizeset: {
      type: String,
      default: null,
    },
    asset_id: {
      type: String,
      default: null,
    },
    object: {
      type: Object,
      default: null,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    isOption: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    pii: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      pixel_urls: null,
      show_textarea: false,
      trackers_root: null,
      tracker_parent: null,
      trackers: null,
    };
  },

  created() {
    // validate impression tracker options if theres only one option in total
    if (Object.keys(this.pixel_url_options).length === 1) {
      const valid_value = Object.keys(this.pixel_url_options)[0];
      let trigger_change = false;
      for (const tracker of this.trackers) {
        if (tracker.action !== valid_value) {
          tracker.action = valid_value;
          trigger_change = true;
        }
      }

      // trigger auto update
      if (trigger_change) {
        this.change();
      }
    }
  },

  computed: {
    is_sizeset_existing() {
      return (
        this.sizeset != null &&
        this.creative?.settings?.cta?.quantum?.[this.sizeset]
          ?.impressiontracker != null
      );
    },

    asset() {
      if (this.asset_id == null) {
        return null;
      }
      if (this.creative.assets[this.asset_id] != null) {
        return this.creative.assets[this.asset_id];
      } else if (this.creative.additional_assets[this.asset_id] != null) {
        return this.creative.additional_assets[this.asset_id];
      } else {
        // can be responsive, check for the id without the sizeset suffix
        const clean_asset_id = this.asset_id.replace(/_(\d+x\d+)/i, '');
        if (this.creative.assets[clean_asset_id] != null) {
          return this.creative.assets[clean_asset_id];
        }
      }
      return null;
    },

    is_global_tracker_for_video() {
      if (this.creative == null || this.creative?.template?.assets == null)
        return;

      let required_asset_ids = [];

      for (const asset in this.creative.template.assets) {
        if (this.creative.template.assets[asset].required === 1) {
          required_asset_ids.push(asset);
        }
      }

      // if only one required asset, then check if it is video
      if (required_asset_ids.length === 1) {
        const asset_id = required_asset_ids[0];
        if (this.creative?.assets?.[asset_id] == null) return false;
        return AssetHelper.isVideo(this.creative.assets[asset_id].uri);
      }

      return false;
    },

    pixel_url_options() {
      if (this.actions == null) {
        return { impression: 'Impression' };
      }

      let pixel_url_options = {};

      if (this.actions.indexOf('click') !== -1) {
        pixel_url_options['ctr'] = 'Click';
      }

      if (this.actions.indexOf('impression') !== -1) {
        pixel_url_options['impression'] = 'Impression';
      }

      if (this.actions.indexOf('all') !== -1) {
        if (this.isVast() || this.isVpaid()) {
          return {
            ctr: 'Click',
            impression: 'Impression',
            creativeView: 'In view',
            start: 'Start',
            firstQuartile: 'First Quartile',
            midpoint: 'Midpoint',
            thirdQuartile: 'Third Quartile',
            complete: 'Complete',
            mute: 'Mute',
            unmute: 'Unmute',
            pause: 'Pause',
            rewind: 'Rewind',
            resume: 'Resume',
            closeLinear: 'Close Linear',
            fullscreen: 'Fullscreen',
            close: 'Close',
            skip: 'Skip',
          };
        }

        if (!this.global || this.is_global_tracker_for_video) {
          return {
            impression: 'Impression',
            ctr: 'Click',
            play: 'Play',
            pause: 'Pause',
            mute: 'Mute',
            unmute: 'Unmute',
            'vtr-0': 'VTR-0',
            'vtr-25': 'VTR-25',
            'vtr-50': 'VTR-50',
            'vtr-75': 'VTR-75',
            'vtr-100': 'VTR-100',
            'ad-is-in-view': 'Ad in view',
            close: 'Close',
          };
        }
        pixel_url_options.impression = 'Impression';
        pixel_url_options.creativeView = 'In view';
        pixel_url_options.ctr = 'Click';
      }
      return pixel_url_options;
    },
  },

  methods: {
    init() {
      // root object is what being sent on changes to the parent, to be saved
      // this will be cloned from the original data
      this.trackers_root = this.getTrackersRootObject();
      // parent object can be either the root, global cta or asset cta
      this.trackers_parent = this.getTrackersParentObject(this.trackers_root);
      // object for the UI
      this.trackers = this.getTrackersObject(this.trackers_parent);
    },

    getTrackersRootObject() {
      // layout system
      if (this.object != null) {
        return clone(this.object);
      }
      return clone(this.creative.settings.cta);
    },

    getTrackersParentObject(root) {
      // layout system, root is the parent
      if (this.object != null) {
        return root;
      }

      // template system
      if (root == null) {
        this.$set(root, 'cta', {
          action: 'url',
          uri: '',
          impressiontracker: [],
          assets: {},
        });
      }

      // asset specific
      if (this.asset != null && root.assets != null) {
        // if asset specific is missing, add it
        if (root.assets[this.asset_id] == null) {
          this.$set(root.assets, this.asset_id, {
            action: 'url',
            uri: '',
            impressiontracker: [],
          });
        }
        return root.assets[this.asset_id];
      }

      // global objects
      if (this.global) {
        // responsive
        if (
          this.is_sizeset_existing &&
          root?.quantum?.[this.sizeset] != null &&
          root.quantum[this.sizeset]
        ) {
          return root.quantum[this.sizeset];
        }

        // normal, global tracjers
        return root;
      }
      return null;
    },

    getTrackersObject(parent = null) {
      if (!parent) return null;

      if (
        parent.impressiontracker == null ||
        !Array.isArray(parent.impressiontracker)
      ) {
        this.$set(parent, 'impressiontracker', []);
      }

      if (parent.impressiontracker.length < 1) {
        parent.impressiontracker.push({
          action: this.getCorrectActionTypeForNewObjects(this.actions[0]),
          uri: '',
        });
      }

      return parent.impressiontracker;
    },

    change() {
      // send the trakcers root
      // for layouts its the object
      // for templates its the creative.settings.cta
      this.$emit('change', this.trackers_root);
      this.show_textarea = false;
    },

    addPixelInput(index) {
      this.trackers.splice(index + 1, 0, {
        action: this.global ? 'impression' : this.actions[0],
        uri: '',
      });
      this.change();
    },

    removeImpressionTracker(index) {
      if (this.trackers.length > 1) {
        this.trackers.splice(index, 1);
      } else {
        this.$set(this.trackers[index], 'uri', '');
      }
      this.change();
    },

    parseTrackersExcelAndChange(index) {
      const input = this.trackers?.[index]?.uri;
      if (!input) return;

      const uris = parseTrackerInput(input);
      if (uris.length === 0) return;

      // skip slice and push if we only parsed single url, aka nothing was parsed basically (but could been fixed)
      if (uris.length === 1) {
        this.trackers[index].uri = uris[0];
        this.change();
        return;
      }

      // remove current value
      this.trackers.splice(index, 1);

      // push new items from parser
      for (const uri of uris) {
        this.trackers.push({
          action: 'impression',
          uri,
        });
      }

      this.change();
    },

    getCorrectActionTypeForNewObjects(action) {
      const pixe_url_keys = Object.keys(this.pixel_url_options);
      if (pixe_url_keys.length > 0) {
        // Returning first key for defaults
        return pixe_url_keys[0];
      }
      return action;
    },

    async showTextarea() {
      this.show_textarea = true;
      await this.$nextTick();
      this.$refs.sms.focus();
    },
  },

  watch: {
    'creative.settings.cta': {
      handler() {
        this.init();
      },
      deep: true,
      immediate: true,
    },

    object: {
      handler() {
        this.init();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
