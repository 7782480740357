<template lang="pug">
div.modal.schedule-editor
  div.modal-content
    div.modal-head
      div.modal-title.block
        span(v-if="!new_schedule") Edit report
        span(v-else) Schedule new report
        div.row.flex-align-center
          div.col.flex-grow
            p.description Report is sent to your selected email addresses as frequently as you choose it below. It contains a summary sheet and a separate sheet for each creative.
    div.modal-body
      div.mb-16
        label.flex.flex-align-center
          span Send Reports to:
          i.nexd-icon-16-help.ml-8(aria-hidden="true" v-tooltip="{template: 'analytics.sendReport', position: 'right'}")
        input.form-control(type="text" placeholder="Insert emails" v-model="email_input" @keydown="emailKeydownHandler" @blur="addNewEmailHandler")
        div.scheduled-emails(v-if="selected_emails.length > 0")
          div.recipient.with-remove-icon(v-for="recipient of selected_emails" :key="recipient" @click="removeRecipient(recipient)") {{recipient}}
            RemoveIcon
      div.mb-16
        label Report type:
        div.flex.column-gap-16
          CustomCheckbox(
            v-for="option of type_options"
            :key="option.value"
            :value="selected_type.includes(option.value)"
            @change="selected_type.includes(option.value) ? selected_type.splice(selected_type.indexOf(option.value), 1) : selected_type.push(option.value)"
          )
            span.ml-4 {{ option.label  }}
      div.mb-16
        label Range (inc. today):
        SearchSelect(:options="range_options" :search="false" v-model="selected_range")
      div.mb-16
        label Frequency:
        SearchSelect(:options="interval_options" :search="false" v-model="selected_interval")
      div.row.mb-16
        div.col.flex-grow
          label Start date of the reports:
          SelectDateRange(
            :start="selected_start_date"
            :options="{timezone: false, start_day: false, single: true, range: false, apply: false}"
            @change="calendarChangeHandler"
          )

        div.col-8
          label Time:
          SearchSelect(:options="time_options" :search="false" v-model="selected_hour")
      div
        label Timezone:
        SearchSelect(:first="false" :options="timezones" v-model="component_timezone")
    div.modal-footer
      Buttons
        Button(type="link-primary" label="Cancel" @click="close")
        Button(v-if="new_schedule" type="primary" label="Create" :disabled="has_errors" :loading="loading" @click="create")
        Button(v-else type="primary" label="Save" :disabled="has_errors" :loading="loading" @click="save")
</template>

<script>
import AnalyticsService from '@master/Services/AnalyticsService';

import { ANALYTICS_EXPORT, ANALYTICS_EXPORT_INTERVAL, ANALYTICS_EXPORT_RANGE } from '@master/constants';
import { validateEmail, getTimezones } from '@helpers/Global';
import TimezoneMixin from '@master/Mixins/TimezoneMixin';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import CustomCheckbox from '@master/UI/CustomCheckbox.vue';
import RemoveIcon from '@master/UI/RemoveIcon.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import SelectDateRange from '@master/UI/SearchSelect/SelectDateRange.vue';

export default {
  name: 'ScheduleEditor',
  mixins: [TimezoneMixin],

  components: {
    Button,
    Buttons,
    CustomCheckbox,
    RemoveIcon,
    SearchSelect,
    SelectDateRange,
  },

  props: {
    schedule: {
      type: Object,
      default: () => {
        return {
          report_id: null,
          email: [],
          type: [],
          start_date: Math.round(Date.now() / 1000),
          interval: 'daily',
          ui_hour: 12,
          ui_timezone: null,

          // enable for debugging
          // ui_timezone: 'Asia/Singapore',
          // ui_hour: 8,
          // email: ['maanusa@nexd.com', 'kevin@nexd.com', "longlonglonglonglong.longlonglonglong@emailisthissuperlong.com", 'maanusa@nexd.com', 'kevin@nexd.com', "longlonglonglonglong.longlonglonglong@emailisthissuperlong.com", 'maanusa@nexd.com', 'kevin@nexd.com', "longlonglonglonglong.longlonglonglong@emailisthissuperlong.com", 'maanusa@nexd.com', 'kevin@nexd.com', "longlonglonglonglong.longlonglonglong@emailisthissuperlong.com", 'maanusa@nexd.com', 'kevin@nexd.com', "longlonglonglonglong.longlonglonglong@emailisthissuperlong.com", 'maanusa@nexd.com', 'kevin@nexd.com', "longlonglonglonglong.longlonglonglong@emailisthissuperlong.com"],
          // type: ['overview_xlsx']
        };
      }
    }
  },

  computed: {
    new_schedule() {
      return this.schedule.report_id == null;
    },

    type_options() {
      return [
        {
          value: ANALYTICS_EXPORT.CAMPAIGN_XLSX,
          label: 'xlsx'
        },
        {
          value: ANALYTICS_EXPORT.CAMPAIGN_PIVOT,
          label: 'xlsx, pivot'
        },
        {
          value: ANALYTICS_EXPORT.CAMPAIGN_CSV,
          label: 'csv'
        }
      ];
    },

    time_options() {
      let options = [];
      for (let value = 0; value < 24; value++) {
        const label = value.toString().padStart(2, '0') + ':00';
        options.push({
          value,
          label
        });
      }
      return options;
    },

    range_options() {
      return [
        {
          value: ANALYTICS_EXPORT_RANGE.THREE_DAY,
          label: '3 Days'
        },
        {
          value: ANALYTICS_EXPORT_RANGE.WEEK,
          label: '7 Days'
        },
        {
          value: ANALYTICS_EXPORT_RANGE.TWO_WEEK,
          label: '14 Days'
        },
        {
          value: ANALYTICS_EXPORT_RANGE.MONTH,
          label: '30 Days'
        }
      ];
    },

    interval_options() {
      return [
        {
          value: ANALYTICS_EXPORT_INTERVAL.DAILY,
          label: 'Daily'
        },
        {
          value: ANALYTICS_EXPORT_INTERVAL.WEEKLY,
          label: 'Weekly'
        },
        {
          value: ANALYTICS_EXPORT_INTERVAL.MONTHLY,
          label: 'Monthly'
        },
        {
          value: ANALYTICS_EXPORT_INTERVAL.ONEND,
          label: 'When campaign is finished'
        }
      ];
    },

    has_errors() {
      return this.loading || this.selected_emails.length === 0 || this.selected_type.length === 0 || this.selected_interval === 'choose';
    }
  },

  data() {
    return {
      data: null,
      timezones: {},
      email_input: '',
      loading: false,
      selected_start_date: this.schedule.start_date,
      selected_emails: [...this.schedule.email],
      selected_type: [...this.schedule.type],
      selected_range: this.schedule.range ?? ANALYTICS_EXPORT_RANGE.DEFAULT,
      selected_interval: this.schedule.interval ?? ANALYTICS_EXPORT_INTERVAL.DEFAULT,
      selected_hour: this.schedule.ui_hour
    };
  },

  async created() {
    AnalyticsService.subscribe(data => {
      this.data = data;
    }, this);

    this.timezones = await getTimezones();
  },

  methods: {
    calendarChangeHandler({ start }) {
      this.selected_start_date = start;
    },

    getRequestObject() {
      return {
        email: this.selected_emails,
        type: this.selected_type,
        range: this.selected_range,
        interval: this.selected_interval,
        ui_timezone: this.component_timezone,
        ui_hour: this.selected_hour,
        start_date: this.selected_start_date,
      };
    },

    save() {
      if (this.loading) return;

      this.loading = true;
      const obj = this.getRequestObject();

      const path = `analytics/campaigns/${this.data.campaign_id}/schedule/${this.schedule.report_id}`;
      this.$http.put(path, obj)
        .then(response => {
          this.$emit('save', response);
        })
        .finally(_ => {
          this.loading = false;
        });
    },

    create() {
      if (this.create_disabled) return;

      this.loading = true;
      const obj = this.getRequestObject();

      const path = `analytics/campaigns/${this.data.campaign_id}/schedule`;
      this.$http.post(path, obj)
        .then(response => {
          this.$emit('create', response);
        })
        .finally(_ => {
          this.loading = false;
        });
    },

    close() {
      this.$emit('close');
    },

    removeRecipient(email) {
      const index = this.selected_emails.indexOf(email);
      this.selected_emails.splice(index, 1);
    },

    emailKeydownHandler(event) {
      const keyCode = event.keyCode || event.which;

      const submit_keys = [
        9,  // tab
        13, // enter
        32, // space
        188 // comma
      ];

      if (keyCode === 27) {
        // esc cancels and clears input
        this.email_input = '';
      } else if (submit_keys.includes(keyCode)) {
        event.preventDefault();
        this.addNewEmailHandler();
      }
    },

    addNewEmailHandler() {
      if (validateEmail(this.email_input)) {
        if (!this.selected_emails.includes(this.email_input)) {
          this.selected_emails.push(this.email_input);
        }
        this.email_input = '';
      }
    },
  },

  watch: {
    'schedule.ui_timezone': {
      handler() {
        this.initTimezone();
      },
      immediate: true
    }
  }
};
</script>
