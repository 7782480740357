class Sun {
  constructor(svg) {
    this.svg = svg;
    this.sun = this.svg.querySelector('#SUN');
    this.init();
  }
  init() {
    // make the sun visible
    this.sun.removeAttribute('opacity');
    this.sun.classList.add('transition');
    this.sun.classList.add('slow');
    this.sun.classList.add('hidden');
    this.sun.style.display = 'none';
  }
  show() {
    this.sun.style.display = null;
    setTimeout(() => {
      this.sun.classList.remove('hidden');
      // 3 sec is the fade in animation for slow transition, wait before adding opacity change animation
      setTimeout(() => {
        this.sun.classList.add('animation-opacity');
      }, 3000);
    }, 500);
  }
}

export default Sun;
