<template lang="pug">
div#creative-list-item(v-if="creative != null")
  CreativeName(
    :creative="creative"
    :is_new="is_new"
    @save="save"
    @delete="$emit('delete')"
  )
  CardDivider

  //- creatives's type and size selection
  CreativeDetails(
    ref="creative-details"
    :creative="creative"
    :is_new="is_new"
    :can_reimport_social="can_reimport_social"
    @reimport="reimportSocial"
    @createNewCreative="createNewCreative"
    @loadTemplate="$emit('loadTemplate', $event)"
    @typeChange="showExpandedLayoutChangeHandler(false)"
  )
  CardDivider

  template(v-if="!is_sdk_user")
    //- creative's export settings
    CreativeExport(

      :creative="creative"
      :is_new="is_new"
      @save="save"
    )
    CardDivider

  //- creative's state selection when expandable or dynamic endcard
  div.expanded-state-buttons.flex(v-if="creative.expanded != null && show_states")
    Button(
      type="quantum"
      label="First state"
      :active="!show_expanded_layout"
      :block="true"
      :large="true"
      @click="showExpandedLayoutChangeHandler(false)"
    )
    Button(
      type="quantum"
      label="Second state"
      :active="show_expanded_layout"
      :block="true"
      :large="true"
      :disabled="creative.template == null"
      :tooltip="{value: creative.template == null ? 'Please add first state layout before editing second state.' : null}"
      @click="showExpandedLayoutChangeHandler(true)"
    )

  //- social widget if not new creative and selected from layout libra
  SocialImport(
    v-if="social_widget"
    :is_import="false"
    @onCreate="changeLayoutToSocial"
    @onRemove="removeSocial"
    @onImport="socialImportDone"
  )

  //- show loading if dynamic endcard type change (infeed <--> interstitial)
  Loading.my-16(v-else-if="loading_dyn_endcard")

  //- creatives's layout info
  CreativeLayoutBlock(
    v-else-if="!isDevtoolCreative()"
    ref="layout_block"
    :key="`${active_creative.creative_id}_${active_creative.import_size}`"
    :creative="active_creative"
    :html5="show_expanded_layout && isLightbox()"
    :is_new="is_new"
    :active_import="active_import"
    @openLayoutGallery="openLayoutGallery"
    @showVASTImport="$emit('showImport', IMPORT.VAST)"
    @showHTML5Import="$emit('showImport', IMPORT.HTML5)"
    @change="$emit('rerender')"
  )
    template
      CreativeFooter(
        v-if="!hasLORT() && !is_new_with_layout_selected && !isHTML5()"
        :creative="creative"
        :disabled="disable_create"
        :tooltip="disable_create ? { value: 'In order to create new creative, size must be selected.' } : {}"
        @cancel="$emit('cancel')"
        @save="createNewCreative"
      )

  div.p-24(v-if="is_new_with_layout_selected")
    CreativeFooter(
      :creative="creative"
      :disabled="disable_create"
      :tooltip="disable_create ? { value: 'In order to create new creative, size must be selected.' } : {}"
      @cancel="$emit('cancel')"
      @save="createNewCreative"
    )

  //- if not new then rest of creatives (preview, settings, slots etc.)
  slot

  //- choose creatives's layout
  LayoutLibrary(
    v-if="library_modal_opened"
    ref="creative_library"
    :creative="library_modal_creative"
    :options="layout_gallery_options"
    @close="closeLayoutGallery"
  )
</template>

<script>
import RequestLimiter from '@libs/RequestLimiter';
import CreativesService from '@master/Services/CreativesService';
import CreativeSizeService from '@master/Services/CreativeSizeService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import CreativeMixins from '@cm/Views/Creatives/Creative/CreativeMixins.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import Button from '@master/UI/Buttons/Button.vue';
import CardDivider from '@master/UI/Divider/CardDivider.vue';
import Loading from '@master/UI/Loading.vue';

import CreativeName from '@cm/Views/Creatives/Creative/CreativeName.vue';
import CreativeDetails from '@cm/Views/Creatives/Creative/CreativeDetails.vue';
import CreativeExport from '@cm/Views/Creatives/Creative/CreativeExport.vue';
import CreativeFooter from '@cm/Views/Creatives/Creative/CreativeFooter.vue';
import CreativeLayoutBlock from '@cm/Views/Creatives/Creative/CreativeLayoutBlock.vue';
import LayoutLibrary from '@cm/Views/Creatives/Modals/LayoutLibrary/LayoutLibrary.vue';
import SocialImport from '@cm/Views/Creatives/Cards/Social/SocialImportV2.vue';

import { TEMPLATE, IMPORT } from '@master/constants';

export default {
  name: 'CreativeBase',
  mixins: [CreativeTraits, CreativeMixins, SDKMixin],

  components: {
    Button,
    CardDivider,
    CreativeName,
    CreativeDetails,
    CreativeExport,
    CreativeFooter,
    LayoutLibrary,
    Loading,
    CreativeLayoutBlock,
    SocialImport,
  },

  props: {
    creative: {
      type: Object,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    active_import: String,
  },

  computed: {
    is_new_with_layout_selected() {
      return this.is_new && this.hasLORT();
    },

    layout_gallery_options() {
      return {
        placement: this.creative.device !== 2 && !this.isDynamicEndcard() && !this.isDesktop(),
        type: this.creative.device !== 2,
      };
    },

    show_states() {
      return this.isBinded();
    },

    loading_dyn_endcard() {
      return this.isDynamicEndcard() && !this.is_new && this.creative?.template?.template_id == null;
    },

    active_creative() {
      return this.show_expanded_layout && this.creative?.expanded != null ? this.creative.expanded : this.creative;
    },

    disable_create() {
      return this.creative.width === 0 || this.creative.height === 0 || this.size_warning;
    },
  },

  data() {
    return {
      IMPORT,

      temporary_template: null,
      library_modal_opened: false,
      library_modal_creative: null,
      show_expanded_layout: false,
      can_reimport_social: false,
      social_widget: false,
      size_warning: false,
    };
  },

  created() {
    CreativeSizeService.subscribe(state => {
      this.size_warning = state?.has_warning ?? false;
    }, this);
  },

  mounted() {
    this.$watch('$refs.layout_block.can_reimport_social', can_reimport_social => {
      this.can_reimport_social = can_reimport_social;
    });
  },

  methods: {
    createNewCreative() {
      if (!CreativeSizeService.validate(this.creative)) return;

      if (this.disable_create && !this.isQuantum() && !this.isSomeSortOfFullscreen()) {
        return this.$refs['creative-details']?.$refs['combined-width-height-input']?.open(true);
      }

      this.$emit('create', this.creative);
    },

    clearPlacement(creative = null) {
      if (creative == null) {
        creative = this.creative;
      }

      if (creative.parent_id != null) {
        const path = `/creatives/${creative.parent_id}/clear/${creative.creative_id}`;
        this.$http.post(path).then(response => {
          creative.parent_id = response.parent_id;
          delete creative.ad_creative_id;
        });
      }
    },

    async openLayoutGallery() {
      const creative = this.active_creative;

      // hide any active import
      this.$emit('hideImport');

      if (this.isVast() || this.isDynamicEndcard(this.active_creative)) return;

      if (!this.is_new) {
        if (this.hasLORT(creative)) {
          const result = await this.$confirm('Are you sure you want to change the template?', 'It will discard all settings and assets for the current creative.');
          if (!result) return;
        } else if (this.isHTML5(creative.type)) {
          const result = await this.$confirm('Are you sure you want to remove HTML5?', 'It will convert the creative to be layout based.');
          if (!result) return;
        }

        this.clearPlacement(creative);
      }

      this.library_modal_opened = true;
      this.library_modal_creative = creative;
    },

    socialImportDone(creative) {
      for (const key in creative) {
        this.$set(this.creative, key, creative[key]);
      }

      this.$emit('loadTemplate', false);
      this.removeSocial();
    },

    changeLayoutToSocial(layout) {
      const creative = this.show_expanded_layout ? this.creative.expanded : this.creative;

      if (layout?.layout_id) {
        this.$set(creative, 'template_id', null);
        this.$set(creative, 'template', null);
        this.$set(creative, 'layout_id', layout.layout_id);
        this.$set(creative, 'layout', layout);
      }

      if (this.is_new) {
        this.createNewCreative();
        return;
      }

      this.changeLORT(creative, true);
    },

    closeLayoutGallery(selected_layout) {
      this.library_modal_creative = null;
      this.library_modal_opened = false;
      this.temporary_template = selected_layout;

      const creative = this.show_expanded_layout && this.creative.expanded != null ? this.creative.expanded : this.creative;

      if (selected_layout == null) return;
      if (selected_layout.layout_id === creative.layout_id || selected_layout.template_id === creative.template_id) return;

      if (selected_layout != null && !this.is_new) {
        this.$emit('loadTemplate', true);
      }

      if (selected_layout.layout_id != null) {
        if (selected_layout.layout_id === TEMPLATE.SOCIAL_DEFAULT) {
          this.social_widget = true;
          this.$emit('loadTemplate', false);
          this.$emit('showSocial', true);
          return;
        }

        this.$set(creative, 'template_id', null);
        this.$set(creative, 'template', null);
        this.$set(creative, 'layout_id', selected_layout.layout_id);
        this.$set(creative, 'layout', selected_layout);
      }

      if (selected_layout.template_id != null) {
        this.$set(creative, 'layout_id', null);
        this.$set(creative, 'layout', null);
        this.$set(creative, 'template_id', selected_layout.template_id);
        this.$set(creative, 'template', selected_layout);
      }

      if (this.social_widget) {
        this.social_widget = false;
      }

      if (this.isQuantum()) {
        this.$emit('closeQuantumSettings');
      }

      if (this.is_new) {
        return this.createNewCreative();
      }

      if (!this.isCMCreative()) return;

      this.changeLORT(creative);
    },

    changeLORTRequest(creative, social = false) {
      let data = {};

      if (creative.template_id != null) {
        data['template_id'] = creative.template_id;
      } else if (creative.layout_id != null) {
        data['layout_id'] = creative.layout_id;
      }

      this.$http
        .put(`v2/creative/${creative.creative_id}/layout`, data, {
          notification: true,
        })
        .then(_creative => {
          for (const key in _creative) {
            this.$set(creative, key, _creative[key]);

            if (key === 'objects' && _creative[key] == null) {
              this.$delete(creative, key);
            }
          }

          if (_creative.preview_url != null && this.$sidebar) {
            this.$sidebar.setPreview(_creative.preview_url);
          }
        })
        .finally(() => {
          this.temporary_template = null;
          this.$emit('loadTemplate', false);

          this.$set(creative, 'assets', creative?.assets?.splash != null ? { splash: creative.assets.splash } : {});
          this.$set(creative, 'additional_assets', {});

          if (this.$store.get('active_assets_library')?.has_used_assets) {
            this.$store.get('active_assets_library').markAllAssetsUnused();
          }

          CreativesService.export.requestTagUpdate(this.creative.status);

          if (social) {
            this.removeSocial();
          }
        });
    },

    changeLORT(creative, social = false) {
      RequestLimiter.hook(`change-lort-${creative.creative_id}`, this.changeLORTRequest(creative, social), 0);
    },

    async showExpandedLayoutChangeHandler(value) {
      this.$store.reset('active_tab');

      // dont allow state switch on new creative
      if (value && this.is_new) return;

      // if no template or layout, always force to 1st state
      if (!this.hasLORT() && value) {
        value = false;
      }

      this.show_expanded_layout = value;
      this.$emit('changeActiveCreative', value);
    },

    save(timeout = 1500) {
      // timeout must be same as the parent comp. save method
      if (this.is_new) return;
      this.$emit('save', timeout);
    },

    reimportSocial() {
      if (this.can_reimport_social) {
        this.$refs['layout_block'].reimportSocial();
      }
    },

    removeSocial() {
      this.social_widget = false;
      this.$emit('showSocial', false);
    },
  },
};
</script>
