<script>
export default {
  name: 'TimezoneMixin',

  data() {
    return {
      component_timezone: null,
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user) {
        this.initTimezone();
      }
    }, this);
  },

  methods: {
    initTimezone() {
      const timezone = this.timezone ?? this.schedule?.ui_timezone;
      if (timezone != null) {
        this.component_timezone = timezone;
        return;
      }

      const group_tz = this.$user.getGroupTimezone();
      if (group_tz && group_tz !== '') {
        this.component_timezone = group_tz;
        return;
      }

      this.component_timezone = 'UTC';
    },
  },
};
</script>
