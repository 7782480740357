<template>
  <div>
    <div class="view-creative-header">
      <div class="row flex-align-center">
        <h1 class="col no-gutter-right">{{item.title}}</h1>
        <div class="col flex-grow relative">
          <div class="collapse-card-toggle">
            <i class="nexd-icon-32-arrow-down-small" aria-hidden="true" :class="{'active': !block_collapsed}" @click="toggleBlockCollapse"></i>
          </div>
          <hr class="line">
        </div>
      </div>
    </div>
    <!-- summaries per creative -->
    <template v-if="!block_collapsed">
      <div class="card campaign-analytics-card">
        <div class="row">
          <div class="col left-block no-gutter-right">
            <div class="card-body flex flex-align-center flex-justify-center">
              <div class="content text-center">
                <img class="icon" :src="item.icon" alt="category icon" />
                <div>
                  <h3>{{item.count}} {{item.type}}</h3>
                  <small>{{item.subtitle}}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-16 no-gutter-left border-left">
            <button v-if="details" class="btn btn-link detailed-analytics" @click="openAnalytics(details)">
              <span>View detailed analytics</span>
              <div class="collapse-toggle toggle-position">
                <i class="nexd-icon-32-arrow-down-small turn-arrow" aria-hidden="true"></i>
              </div>
            </button>
            <div class="card-body">
              <div class="mb-16">
                <div class="row heading-icons">
                  <div class="col-8">
                    <i class="nexd-icon-32-seen" aria-hidden="true"></i>
                  </div>
                  <div class="col-8">
                    <i class="nexd-icon-32-engagement" aria-hidden="true"></i>
                  </div>
                  <div class="col-8">
                    <i class="nexd-icon-32-ctr-mobile" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <div class="mb-16">
                <div class="row">
                  <div class="col-8">
                    <div class="flex flex-align-center">
                      <ProgressBar :percentage="item.analytics.total.impressions" />
                      <div class="total-popup">
                        <AnalyticsTotalInfoPopup
                          :trackable="analytics_popup_data"
                          :analytics="info_popup_content"/>
                        <div class="flex-grow">
                          <h2 :class="[filters.base === 'impressions' ? '' : 'impressions category']">{{item.analytics.impressions | NumberFilter}}</h2>
                          <small>Total Impressions</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="inline-flex flex-align-center" v-tooltip="{template: 'analytics.engagement'}">
                      <ProgressBar :percentage="item.analytics.total.engagements" />
                      <div class="flex-grow">
                        <h2>{{item.analytics.engagement | PercentageFilter}}</h2>
                        <small>Total Engagement</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="inline-flex flex-align-center" v-tooltip="{template: 'analytics.ctr'}">
                      <ProgressBar :percentage="item.analytics.total.clicks" />
                      <div class="flex-grow">
                        <h2>{{item.analytics.ctr | PercentageFilter}}</h2>
                        <small>Total CTR</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <template v-if="item.analytics.viewable != null && filters.base !== 'viewable' && Controller.isViewabilityEnabled() === true">
                    <div class="inline-flex flex-align-center" v-tooltip="{template: 'analytics.viewable'}">
                      <ProgressBar :percentage="item.analytics.total.viewable" />
                      <div class="flex-grow">
                        <h3 :class="[filters.base === 'viewable' ? 'viewable category' : '']">{{item.analytics.viewable | NumberFilter}}</h3>
                        <small>Viewable Impressions</small>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-8">
                  <div class="inline-flex flex-align-center" v-tooltip="{template: 'analytics.engagementImpressions'}">
                    <ProgressBar :percentage="item.analytics.total.engagements" />
                    <div class="flex-grow">
                      <h3>{{item.analytics.engagements | NumberFilter}}</h3>
                      <small>Engaged Impressions</small>
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <div class="inline-flex flex-align-center" v-tooltip="{template: 'analytics.clicks'}">
                    <ProgressBar :percentage="item.analytics.total.clicks" />
                    <div class="flex-grow">
                      <h3>{{item.analytics.clicks | NumberFilter}}</h3>
                      <small>Clicks</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          v-if="creatives_collapsed && item.creatives.length > 0"
          class="all-creatives-btn"
          type="primary"
          :label="`View all ${item.type}`"
          @click="toggleCreativesCollapse"
        />
      </div>
      <template v-if="!creatives_collapsed">
        <div v-for="(creative, index) of item.creatives" :key="index">
          <div class="connection">
            <div class="connecting-cards"></div>
            <div v-if="index === 0" class="collapse-toggle toggle-position">
              <i class="nexd-icon-32-arrow-down-small active close" aria-hidden="true" @click="toggleCreativesCollapse"></i>
            </div>
          </div>
          <AnalyticsCreativeDetailed :creative="creative"/>
        </div>
      </template>
    </template>
  </div>
</template>


<script>

import ProgressBar from '@analytics/Views/Campaign/Components/ProgressBar';
import AnalyticsCreativeDetailed from '@analytics/Views/Campaign/Components/AnalyticsCreativeDetailed';
import AnalyticsTotalInfoPopup from '@analytics/Views/Campaign/Components/AnalyticsTotalInfoPopup';
import { filters, Controller } from '@master/Services/AnalyticsAPPService';
import Button from '@master/UI/Buttons/Button';

export default {
  name: 'AnalyticsCardCreative',

  components: {
    ProgressBar,
    AnalyticsCreativeDetailed,
    AnalyticsTotalInfoPopup,
    Button
  },

  props: {
    item: Object,

    // detailed analytics path
    details: {
      type: String,
      default: null
    }
  },

  computed: {
    info_popup_content() {
      return [
        [
          {data: this.item.analytics.loaded, filter: 'number', label: 'Loaded Impr.'},
          {data: this.item.analytics.load_rate, filter: 'percentage', label: 'Load rate'}
        ],
        [
          {data: !Controller.isViewabilityEnabled() ? null : this.item.analytics.viewable, filter: 'number', label: 'Viewable Impr.'},
          {data: !Controller.isViewabilityEnabled() ? null : this.item.analytics.viewability, filter: 'percentage', label: 'Viewability'}
        ]
      ];
    },

    analytics_popup_data() {
      const out = [{data: this.item.analytics.trackable, filter: 'number', label: 'Tracked ads'}];
      if (this.admin_toggle) {
        out.push({data: this.item.analytics.billable, filter: 'number', label: 'Billable'});
      }
      return out;
    }
  },

  data() {
    return {
      admin_toggle: false,

      block_collapsed: false,
      creatives_collapsed: true,
      filters,
      Controller
    };
  },

  created() {
    this.$user.subscribe(_ => {
      this.admin_toggle = this.$user.adminMode();
    }, this);
  },

  methods: {
    toggleBlockCollapse() {
      this.block_collapsed = !this.block_collapsed;
    },

    toggleCreativesCollapse() {
      this.creatives_collapsed = !this.creatives_collapsed;
    },

    openAnalytics(url) {
      let path = this.$route.path;

      // sometimes theres / in the end, but not always
      if (!path.endsWith('/')) {
        path += '/';
      }
      path += url;
      this.$router.push({ path, query: filters});
    }
  }
};
</script>
