<template lang="pug">
div.row(:class="[align === 'top' ? 'flex-align-top' : 'flex-align-center']")
  div.col-10.pl-0
    label.flex.flex-align-center.mb-0(:style="{height: asset ? '40px' : 'auto'}")
      span(v-if="label != null" :class="{ 'disabled': disabled }") {{ label }}
      i.nexd-icon-16-help.ml-8(v-if="tooltip != null" aria-hidden="true" v-tooltip="{'value': tooltip}")
  div(:class="{'col-14': !wide}")
    slot DEFAULT SLOT FOR COMPONENT
</template>

<script>
export default {
  name: 'OptionsBlockRow',
  props: {
    label: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    },
    align: {
      type: String,
      default: null
    },
    asset: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    }
  }
};
</script>
