<script>
export default {
  name: 'PercentageTimerMixin',

  data() {
    return {
      timer_percentage: 0,
      timer_interval: null
    };
  },

  methods: {
    timerReset() {
      this.timer_percentage = 0;
    },

    timerStart(items = 0, expected_seconds_per_item = 1) {
      let cycle = 0;
      this.timerReset();
      this.timer_interval = setInterval(_ => {
        cycle++;
        // fake to 99%
        this.timer_percentage = Math.min(0.99, Math.round(cycle / items / expected_seconds_per_item * 100) / 100);
      }, 1000);
    },

    timerStop() {
      this.timer_percentage = 1;
      window.clearInterval(this.timer_interval);
    }
  }
};
</script>
