<template lang="pug">
div.tab-content
  SlotMaker(:creative="creative" v-if="Object.keys(slots).length > 0" :slots="slots" :key="'assetstab_slots'" @update="$emit('update')")
  ModuleMaker(:creative="creative" v-if="modules.length > 0" :modules="modules" :key="'assetstab_modules'" @update="$emit('update')")
</template>

<script>

import SlotMaker from '../Modules/SlotMaker';
import ModuleMaker from '../Modules/ModuleMaker';

export default {
  name: 'TabAssets',
  components: {
    SlotMaker,
    ModuleMaker
  },
  props: {
    creative: Object,
    group: Object
  },

  data () {
    return {
      slots: {},
      modules: [],
    };
  },

  created () {
    if (this.group?.slots != null) {
      for (let slot of this.group.slots) {
        this.slots[slot.slot_id] = slot;
      }
    }
    if (this.group?.modules != null) {
      for (let module of this.group.modules) {
        this.modules.push(module);
      }
    }
  }
};
</script>
