<template>
  <div v-if="data != null && data.length > 0">
    <div v-if="data.length > 1" class="tabs outlined">
      <div v-for="(item, index) of data" :key="index" class="tab video-tab" @click="changeTab(index)" :class="{'active': index === active_tab}">
        {{item.title}}
      </div>
    </div>
    <div class="split-cards">
      <div class="analytics-card">
        <CardHeader title="View through rate" tooltip="analytics.videoThroughRateCard" :no_border="true" />
        <div class="card-body no-top-border">
          <Loading v-if="data == null" />
          <div v-else-if="active_tab !== null" class="vtr">
            <template v-for="(item, key) of circle_chart_values[active_tab]">
              <SummaryStats
                :key="key"
                :title="ana.get(item.key)"
                :value="item.value"
                :total="item.total"
                :tooltip="key === 0 ? 'Percentage of impressions where user started watching the video' :
                  'Percentage of impressions where user ' + (key === 4 ? 'watched video until the end' : 'watched at least ' + (key === 2 ? 'half of the video' : 25*key + '% of the video'))"
                :tooltipTotal="key === 0 ? 'Amount of impressions where user started watching the video' :
                  'Amount of impressions where user ' + (key === 4 ? 'watched the video until the end' : 'watched at least ' + (key === 2 ? 'half of the video' : 25*key + '% of the video'))"
                :change="item.key === 'w100'" />
            </template>
          </div>
        </div>
      </div>
      <div class="analytics-card">
        <CardHeader title="Video engagement" tooltip="analytics.videoEngagementCard" :no_border="true" />
        <div class="card-body no-top-border">
          <Loading v-if="data == null" />
          <div v-if="data != null && data.length > 0">
            <div v-if="active_tab !== null" class="row chart-header">
              <div class="col">
                <h1>{{data[active_tab].started | NumberFilter}}</h1>
              </div>
              <div class="col" v-tooltip="{value: 'Amount of impressions where user started watching the video'}">
                <span class="color-cyan-blue lh-32 fs-14">Impressions<br>Started watching</span>
              </div>
              <div class="col-1"><!-- single col spacing --></div>
              <div class="col">
                <h1>{{data[active_tab].completed | NumberFilter}}</h1>
              </div>
              <div class="col" v-tooltip="{value: 'Amount of impressions where user watched the video until the end'}">
                <span class="color-cyan-blue lh-32 fs-14">Impressions<br>Completed watching</span>
              </div>
            </div>
            <template v-if="active_tab != null">
              <VideoEngagementChart :key="active_tab" :total="data[active_tab].started" :chart="data[active_tab].chart"/>
              <div v-if="data[active_tab].chart != null" class="engagement-stats" style="padding-left: 2.5rem;">
                <div class="stats-row inline">
                  <span class="circle blue-dark"/>Impressions
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardHeader from '@analytics/Views/Creative/Cards/Components/CardHeader';
import VideoEngagementChart from '@analytics/UI/Charts/Creative/VideoEngagementChart';
import Loading from '@master/UI/Loading';
import { videoEngagementColorMap } from '@master/Services/AnalyticsAPPService';
import ana from '@master/Services/AnalyticsStringMapping';
import SummaryStats from '@analytics/Views/Creative/Cards/Components/SummaryStats';

export default {
  name: 'VideoEngagement',
  components: {
    CardHeader,
    VideoEngagementChart,
    Loading,
    SummaryStats
  },
  props: {
    data: Array,
    analytics: Object
  },
  data () {
    return {
      active_tab: null,
      circle_chart_values: null,
      ana,
      total_values: []
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      if (this.data != null && this.data.length > 0) {
        this.circle_chart_values = [];
        for (const index in this.data) {
          this.circle_chart_values[index] = [];
          for (const key in this.data[index].vtr) {
            this.circle_chart_values[index].push({
              class: videoEngagementColorMap[key],
              key,
              value: this.data[index].vtr[key],
              total: 0
            });
          }
        }
        this.active_tab = 0;
        this.calculateVtrTotal();
      }
    },

    changeTab (index) {
      this.active_tab = index;
    },

    calculateVtrTotal() {
      this.data.forEach((video, index )=> {
        // video loop
        video.info.forEach(day => {
          // all days chosen by user
          this.circle_chart_values[index][0].total += day.started;
          this.circle_chart_values[index][1].total += day.w25;
          this.circle_chart_values[index][2].total += day.w50;
          this.circle_chart_values[index][3].total += day.w75;
          this.circle_chart_values[index][4].total += day.w100;
        });
      });
    }
  },
  watch: {
    data: {
      handler () {
        this.init();
      }
    }
  }
};
</script>
