<template lang="pug">
div
  template(v-for="(title, type, index) in footers")
    hr.gray-200(v-if="index > 0")
    TestSection(:subtitle="title")
    UpsellingFooter.text-center(:type="type")
</template>

<script>
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import UpsellingFooter from '@master/UI/UpsellingFooter.vue';

export default {
  name: 'UpsellingFooterTest',

  components: {
    TestSection,

    UpsellingFooter,
  },

  data() {
    return {
      footers: {
        billing: 'Billing',
        campaigns: 'Campaigns',
        options: 'Creative Options',
        templates: 'Templates',
        creatives: 'Creatives',
        group_assets: 'Group Assets',
        search: 'Search',
      },
    };
  },
};
</script>
