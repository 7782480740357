<template lang="pug">
i.fs-32.lh-16.hover-primary(
  :class="`nexd-icon-32-${value ? 'seen' : 'unseen'}`"
  aria-hidden="true"
  @click="$emit('input', !value)"
)
</template>

<script>
export default {
  name: 'Reveal',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
