export const subscriptions = [
  'subscription-cancelled',
  'subscription-pending',
  'subscription-trial',
  'subscription-annual',
  'subscription-monthly',
];

export const templates = ['template-exclusive', 'template-secondary'];

export const variants = ['html5', 'success', 'modified', 'keyword'];

export const pills = [...subscriptions, ...templates, ...variants];

export function parseRegion(region) {
  switch (region) {
    case 'apac':
    case 'apse1':
      return 'apac';
    case 'eu':
    case 'euw1':
      return 'eu';
    case 'usw2':
    case 'ore':
      return 'us';
    default:
      return 'unknown';
  }
}
