import { getLanguages } from '@helpers/Global';
import Queue from '@libs/Queue';
import router from '@root/src/router/Router';

import { getSentence } from '@cm/Views/Flights/helpers/preview';

class FlightAnalyticsService {
  #queue = new Queue();
  #rulesets = {};

  async getOptions() {
    return this.#makeRequest()
      .then(response => {
        return response?.options;
      })
      .catch(() => {
        return null;
      });
  }

  async getActiveRulesets(ruleset_id) {
    this.#rulesets = {};

    return this.#makeRequest()
      .then(async response => {
        await this.#parseRulesets(response?.rulesets);

        if (!(ruleset_id in this.#rulesets)) {
          return this.#rulesets;
        }

        return {
          [ruleset_id]: this.#rulesets[ruleset_id],
        };
      })
      .catch(() => {
        return this.#rulesets;
      });
  }

  async #makeRequest() {
    const path = this.#getPath();

    if (path != null) {
      return await this.#queue.get(path);
    }
  }

  #getPath() {
    const params = this.#getQueryParams();

    if (params != null) {
      return `/v2/flights/${params.flight_id}/analytics/${params.live_id}/rulesets`;
    }
  }

  #getQueryParams() {
    const params = router?.history?.current?.params ?? {};

    const flight_id = params.flight_id;
    const live_id = params.live_id ?? params.public_live_id;

    if (flight_id != null && live_id != null) {
      return { flight_id, live_id };
    }
  }

  async #parseRulesets(rulesets = {}) {
    const languages = await getLanguages();

    for (const ruleset_id in rulesets) {
      const ruleset = rulesets[ruleset_id];
      const sentences = [];

      for (const condition of ruleset.conditions) {
        sentences.push(
          getSentence(
            {
              key: condition[0],
              value: condition[2],
            },
            languages,
          ),
        );
      }

      this.#rulesets[ruleset_id] = { ...ruleset, sentences };
    }
  }
}

export default new FlightAnalyticsService();
