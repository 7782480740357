<template lang="pug">
div.html-5-preview.mt-32
  div.mb-16
    LivePreview(
      ref="preview"
      :key="device"
      :creative="creative"
      :options="preview_options"
      :height="component_height"
      :width="component_width"
      :poll="true"
      @onload="handleOnLoad"
      @versionchange="handleVersionChange")
  PreviewDevice.mt-16(v-if="!is_sdk_user" v-bind="$props")
  CreativeSizes(:creative="creative" :optimizable="true" @optimize="openOptimizerModal" @reload="reloadDevice")
</template>

<script>
import CreativeSizes from '@master/UI/CreativeSizes.vue';
import LivePreview from '@master/UI/LivePreview/LivePreview.vue';
import PreviewDevice from '@master/UI/LivePreview/PreviewDevice.vue';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import { DEVICE, DSP } from '@master/constants';
import { sleep } from '@helpers/Global';

export default {
  name: 'PreviewHTML5',

  mixins: [CreativeTraits, SDKMixin],

  components: {
    CreativeSizes,
    LivePreview,
    PreviewDevice,
  },

  props: {
    creative: Object,
    device: {
      type: Number,
      default: DEVICE.MOBILE,
    },
  },

  computed: {
    is_google_ads_over_size() {
      return this.creative.dsp === DSP.GOOGLEADS && this.size.original > 600000;
    },

    preview_options() {
      return {
        screen_height: null,
        browser_bars: true,
        force_placement_device: this.device,
      };
    },

    component_height() {
      return '500px';
    },

    component_width() {
      return 'auto';
    },
  },

  methods: {
    reloadDevice() {
      if (this.$refs.preview != null) {
        this.$refs.preview.reloadDevice();
      }
    },

    openOptimizerModal() {
      this.$emit('openOptimizerModal');
    },

    async handleOnLoad() {
      await sleep(5000);

      if (!this.$refs?.preview?.teele?.getAfterloadAssets) return;

      const should_be_afterloaded = this.$refs.preview.teele.getAfterloadAssets();

      let afterloaded_assets = [];

      // check if anything should be afterloaded
      for (const name in should_be_afterloaded) {
        // find matching assets from creative assets list
        for (const [asset_id, asset] of Object.entries(this.creative.assets)) {
          // only call update on assets that are not afterloaded
          if (asset.uri.includes(name) && !asset.afterload) {
            afterloaded_assets.push({
              asset_id: asset_id,
              afterload: true,
            });
            break;
          }
        }
      }

      if (afterloaded_assets.length > 0) {
        this.$http
          .put(`creatives/${this.creative.creative_id}/assets/bulk`, {
            assets: afterloaded_assets,
          })
          .then(({ assets }) => {
            // keep UI info up to date, update all values received from the backend
            for (const [asset_id, asset] of Object.entries(assets)) {
              if (this.creative?.assets?.[asset_id]) {
                for (const [key, value] of Object.entries(asset)) {
                  this.$set(this.creative.assets[asset_id], key, value);
                }
              }
            }
          });
      }
    },

    handleVersionChange(version) {
      this.$emit('versionchange', version);
    },
  },
};
</script>
