<template lang="pug">
  div.list-search-not-matched
    img(:src="`${$cdn}dist/assets/cm/search.svg`" alt="search")
    span No results found based on your filters or search.
    div
      span Try changing your search terms or&nbsp;
      span.cursor-pointer.color-red-primary(@click="$emit('clear')") clear all filters.
</template>

<script>
export default {
  name: 'NoMatchedItemsList'
};
</script>
