<template lang="pug">
div(:class="[styles.card, styles.card_long]")
  div.fw-500.fs-18.mb-16 What's new?
  Loading(v-if="loading")
  div.flex.flex-direction-column.row-gap-16(v-else)
    template(v-for="(post, index) of posts")
      hr.m-0(v-if="index > 0")
      section.fs-14.flex.flex-direction-column.row-gap-4.flex-align-start(:key="post.id")
        img(v-if="post.image != null" :src="`${path}/${post.image}`" :height="70")
        div.mt-4.fw-500.fs-16(v-if="post.title != null") {{ post.title }}
        div.lh-20(v-if="post.description != null") {{ post.description }}
        a(
          v-if="post.link != null"
          :href="post.link"
          target="_blank"
          v-google-analytics="{ key: 'click', element_name: slugify(post.title) }"
        ) {{ post.cta ?? 'Read more' }}
</template>

<script>
import styles from '../Dashboard.module.scss';

import Queue from '@libs/Queue';
import { slugify } from '@helpers/Global';

import Loading from '@master/UI/Loading.vue';

const queue = new Queue();

export default {
  name: 'TopPosts',

  components: {
    Loading,
  },

  data() {
    return {
      styles,
      slugify,

      loading: false,
      posts: null,

      path: `${this.$cdn}dist/static/dashboard`,
    };
  },

  created() {
    this.loadPosts();
  },

  methods: {
    loadPosts() {
      this.loading = true;

      queue
        .get(`${this.path}/posts.json`, { withCredentials: false })
        .then(posts => {
          this.posts = posts;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
