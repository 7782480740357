<template lang="pug">
TestLayout(title="Inputs")

  TestSection(subtitle="Default")
    Input(v-model="modela" placeholder="Default text")
    pre {{ modela }}

  TestSection(subtitle="Not Empty")
    Input(v-model="modelb" variant="empty")
    pre {{ modelb }}

  TestSection(subtitle="Phone Number")
    Input(v-model="modelc" variant="phone")
    pre {{ modelc }}

  TestSection(subtitle="Disabled")
    Input(v-model="modeld" variant="phone" :disabled="true")
    pre {{ modeld }}

  TestSection(subtitle="Textarea")
    Input(v-model="modele" variant="empty" :textarea="true")
    pre {{ modele }}

  TestSection(subtitle="Search")
    SearchInput(v-model="modelf")
    pre {{ modelf }}

  TestSection(subtitle="Search Delay 0")
    SearchInput(v-model="modelg" :delay="0")
    pre {{ modelg }}

  TestSection(subtitle="Search Disabled")
    SearchInput(v-model="modelh" :disabled="true")
    pre {{ modelh }}

  TestSection(subtitle="Password")
    Input(variant="password" v-model="modeli")

  TestSection(subtitle="Email")
    Input(variant="email" v-model="modelj")

  TestSection(subtitle="Default (medium)")
    Input(:medium="true" v-model="modelk")

  TestSection(subtitle="Error as prop (medium)")
    Input(variant="password" :medium="true" :error_msg="errors.modell" v-model="modell" @error="message => errors.modell = message")

  TestSection(subtitle="Fixed textarea")
    Input(variant="empty" placeholder="Add comment" :textarea="true" :autosize="false" :rows="3" v-model="modelm")
    pre {{ modelm }}

  TestSection(subtitle="URL")
    Input(variant="url" v-model="modeln")
    pre {{ modeln }}

  div.wide
    hr

    TestLayout(title="Checkbox")
      TestSection.self-justify-start(subtitle="Default")
        label.cbx_container
          input(type="checkbox" v-model="model_cb_default")
        pre {{ model_cb_default }}

      TestSection.self-justify-start(subtitle="Disabled")
        label.cbx_container
          input(type="checkbox" v-model="model_cb_disabled" :disabled="true")
        pre {{ model_cb_disabled }}

      TestSection.self-justify-start(subtitle="Multiple")
        label.cbx_container
          input(
            type="checkbox"
            :checked="all_checkbox_values.length === model_cb_array.length"
            :data-partial="model_cb_array.length > 0 && model_cb_array.length < all_checkbox_values.length"
            @input="toggleBulkSelection"
          )
          span All
        br
        label.cbx_container(v-for="value of all_checkbox_values" :key="value")
          input(type="checkbox" v-model="model_cb_array" :value="value")
          span Select {{ value }}
        pre {{ model_cb_array }}

      TestSection.self-justify-start(subtitle="With values")
        label.cbx_container(v-for="value of all_checkbox_values" :key="value")
          input(type="checkbox" v-model="model_ra_default" :value="value")
          span Select {{ value }}
        pre {{ model_ra_default }}

  div.wide
    hr

    TestLayout(title="Radio")
      TestSection.self-justify-start(subtitle="Multiple")
        label.cbx_container(v-for="value of all_checkbox_values" :key="value")
          input(type="radio" v-model="model_rb_default" :value="value")
          span Select {{ value }}
        pre {{ model_rb_default }}

  div.wide
    hr

    TestLayout(title="Color")
      TestSection.self-justify-start(subtitle="Default")
        ColorOption(v-model="model_color_default")
        pre {{ model_color_default }}

  div.wide
    hr

    TestLayout(title="Textarea w counter")
      TestSection.self-justify-start(subtitle="Default")
        TextareaWithCounter(v-model="model_tac_default")
        pre {{ model_tac_default }}
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import ColorOption from '@master/UI/ColorOption.vue';
import Input from '@master/UI/Input/Input.vue';
import SearchInput from '@master/UI/Input/SearchInput.vue';
import TextareaWithCounter from '@master/UI/TextareaWithCounter.vue';

export default {
  name: 'InputTest',

  components: {
    TestLayout,
    TestSection,

    ColorOption,
    Input,
    SearchInput,
    TextareaWithCounter,
  },

  data() {
    return {
      errors: { modell: 'test' },
      modela: '',
      modelb: '',
      modelc: '',
      modeld: '',
      modele: '',
      modelf: '',
      modelg: '',
      modelh: '',
      modeli: '',
      modelj: '',
      modelk: '',
      modell: '',
      modelm: '',
      modeln: '',

      all_checkbox_values: ['a', 'b', 'c'],

      model_cb_default: false,
      model_cb_disabled: false,
      model_cb_array: ['a'],
      model_ra_default: ['a'],
      model_rb_default: 'a',

      model_color_default: '#ff0000',

      model_tac_default: '',
    };
  },

  methods: {
    toggleBulkSelection() {
      if (this.model_cb_array.length === this.all_checkbox_values.length) {
        this.model_cb_array = [];
      } else {
        this.model_cb_array = [...this.all_checkbox_values];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wide {
  grid-column: 1 / -1;
}

.self-justify-start {
  justify-items: flex-start;
}

.cbx_container {
  display: flex;
  align-items: center;
}
</style>
