<template lang="pug">
div.breadcrumbs(v-if="breadcrumbs")
  template(v-for="(item, index) of breadcrumbs")
    div.breadcrumb(
      v-if="!item.group"
      v-tooltip="{value: item.tooltip}"
      :class="{'url': item.url, 'active': item.active}"
      @click="open(item)"
    )
      i(v-if="item.icon" :class="item.icon" aria-hidden="true")
      span.label(:class="{'no-icon': !item.icon}") {{ item.label }}
    div.breadcrumb.group(v-else)
      SearchSelectCustom(
        variant="breadcrumb"
        :search="false"
        :handler="optionsHandler(item)"
        @select="e => e.onclick()"
      )
        template(v-slot:placeholder="scope")
          span.label(:class="{'active': scope.opened}")
            i.nexd-icon-16-more-actions(aria-hidden="true")
</template>

<script>
import { BREADCRUMB } from '@master/constants';
import { truncateString } from '@helpers/Global';

import SearchSelectCustom from '@master/UI/SearchSelect/SearchSelectCustom.vue';

export default {
  name: 'Breadcrumb',

  components: {
    SearchSelectCustom,
  },

  props: {
    items: {
      type: Array,
    },
  },

  computed: {
    breadcrumbs() {
      if (!this.items) return null;

      let breadcrumbs = [];

      for (const item of this.items) {
        breadcrumbs.push({
          tooltip: item.label.length > this.max_string_length ? item.label : null,
          url: item.url,
          active: item.active,
          icon: this.hasIcon(item) ? this.getIcon(item) : null,
          label: this.getLabel(item),
          group: this.isGroup(item),
          options: this.getGroupDropdownOptions(item),
        });
      }

      return breadcrumbs;
    },
  },

  data() {
    return {
      max_string_length: 20,
    };
  },

  methods: {
    optionsHandler(item) {
      return () => Promise.resolve(item.options);
    },

    getLabel(item) {
      return truncateString(item.label, this.max_string_length);
    },

    open(item) {
      if (!item.url) return;
      this.$router.push(item.url).catch(_ => {
        /** supress duplicate route error */
      });
    },

    isGroup(item) {
      return item.type === BREADCRUMB.BETWEEN;
    },

    getGroupDropdownOptions(item) {
      if (!item.breadcrumbs) return null;

      let options = [];
      for (const breadcrumb of item.breadcrumbs) {
        options.push({
          icon: this.getIcon(breadcrumb),
          label: this.getLabel(breadcrumb),
          onclick: () => this.open(breadcrumb),
        });
      }
      return options;
    },

    hasIcon(item) {
      return ![BREADCRUMB.ROOT, BREADCRUMB.BETWEEN].includes(item.type);
    },

    getIcon(item) {
      switch (item.type) {
        case BREADCRUMB.CAMPAIGN:
          return 'nexd-icon-32-campaign';
        case BREADCRUMB.FOLDER:
          return 'nexd-icon-32-folder';
        case BREADCRUMB.FLIGHT:
          return 'nexd-icon-32-flight-timeline fs-24 mx-4';
        case BREADCRUMB.DCO:
          return 'nexd-icon-32-dco fs-24 mx-4';
        case BREADCRUMB.DCO_TOOLS:
          return 'nexd-icon-32-flights';
        case BREADCRUMB.TEAM_LIBRARY:
          return 'nexd-icon-32-library';
        default:
          return 'nexd-icon-32-creative';
      }
    },
  },
};
</script>
