<template lang="pug">
div.slot.survey-slot
  Question(
    v-for="(question, index) in object.data.questions"
    :key="question._key"
    :index="index"
    :question="question"
    :object="object"
    @remove="removeQuestion"
    @update="componentChangeHandler"
  )
  Button.mt-16(
    type="primary"
    :outline="true"
    :block="true"
    :large="true"
    label="Add Question"
    :disabled="(max_questions - object.data.questions.length) === 0"
    @click="addQuestion"
  )
    template(v-slot:prefix)
      IconAdd
  div.text-center.mt-8
    small.color-gray-600
      | You can add&nbsp;
      span.color-primary {{max_questions - object.data.questions.length}}
      | &nbsp;more {{ questions_string }}
  CollapsableCard
    template(v-slot:header_title)
      Thumbnail.self-align-center(variant="styles" bg="unset")
      h5.self-align-center.flex-grow.ml-8
        span Question styles
        i.nexd-icon-32-help(v-tooltip="{value: 'Here you can choose the text color and background color for choices.'}")
    template(v-slot:collapsable_body)
      QuestionsSettings(:settings="object.settings" @update="componentChangeHandler")

  div.module-slots
    SlotMaker(v-if="module.slots != null" :creative="creative" :slots="module.slots" :key="module.module_id" @update="componentChangeHandler")
</template>

<script>
import Button from '@master/UI/Buttons/Button';
import IconAdd from '@master/UI/Buttons/Icons/IconAdd';
import CollapsableCard from '@cm_modules/CollapsableCard';
import SurveyHelper from './Helper.js';
import Question from './Question';
import QuestionsSettings from './QuestionsSettings';
import Thumbnail from '@cm/UI/Global/Thumbnail/Thumbnail';

export default {
  name: 'QuestionModule',
  beforeCreate () {
    this.$options.components.SlotMaker = require('@cm_modules/SlotMaker').default;
  },
  components: {
    CollapsableCard,
    Question,
    Thumbnail,
    QuestionsSettings,
    Button,
    IconAdd
  },
  props: {
    creative: Object,
    module: Object,
    module_id: String
  },

  data() {
    return {
      object: null,
      max_questions: 6,
      changeTimeout: null,
      collapsed_list: []
    };
  },

  created() {
    this.object = {};

    // Won't use modularitySetup - since Survey module has a bit different behaviour
    if (this.creative?.objects?.[this.$vnode.key] != null) {
      this.$set(this, 'object', this.creative.objects[this.$vnode.key]);
    } else {
      // Setting up default object
      this.object =  {
        settings: {
          global: {
            ...SurveyHelper.getDefaultGlobalSettings()
          }
        },
        data: {}
      };
      this.addQuestion(true);
    }

    if (this.module.settings != null) {
      // Fill module default settings - if creative doesn't have anything - currently only shallow
      for (const key in this.module.settings) {
        if (this.object.settings[key] == null) {
          this.$set(this.object.settings, key, this.module.settings[key]);
        }
      }
    }
  },
  computed: {
    questions_string () {
      return this.max_questions - this.object.data.questions.length === 1 ? 'question' : 'questions';
    }
  },

  methods: {
    addQuestion(initalization = false) {
      if (!this.object.data.questions) {
        this.$set(this.object.data, 'questions', []);
      }

      if (this.max_questions - this.object.data.questions.length <= 0) return;

      this.object.data.questions.push(SurveyHelper.getQuestionBase());
      if (initalization === false) {
        this.componentChangeHandler();
      }
    },

    removeQuestion (key) {
      // Better way of keeping existing quesitons open if another is removed
      let index = null;
      for(const k in this.object.data.questions) {
        if (this.object.data.questions[k]._key === key) {
          index = k;
          break;
        }
      }
      if (index != null) {
        this.object.data.questions.splice(index, 1);
        this.componentChangeHandler();
      }
    },
    // async removeQuestion(index) {
    //   // MIght be hacky way to rerender full array with new  keys etc - bad, since opened states are gooooone
    //   const old_questions = this.object.data.questions;
    //   this.$set(this.object.data, 'questions', []);
    //   old_questions.splice(index, 1);
    //   await this.$nextTick();
    //   this.$set(this.object.data, 'questions', old_questions);
    //   this.componentChangeHandler();
    // },

    componentChangeHandler() {
      this.$emit('update', 'object', this.module._fullpath, this.object);
    },
    toggleCollapse (index) {
      this.collapsed_list[index] = this.collapsed_list[index] || false;
    }
  }
};
</script>
