
<template>
  <div v-observe-visibility="visibilityChanged" class="circle-chart small">
    <div class="inner-circle">
      <div class="counter"/>
      <div class="progress"/>
      <div class="end"/>
    </div>
    <div class="right-txt">
      <h1>{{value | percentageFilter}}</h1>
      <small class="color-cyan-blue lh-32 fs-14">{{label}}</small>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  name: 'SmallCircleChart',
  props: {
    value: Number,
    label: String
  },
  data () {
    return {
      currentPercentage: 0,
      percentage: null,
      isVisible: false
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.currentPercentage = 0;
      // nan check
      if (this.value <= 1) {
        this.percentage = this.value * 100;
      } else {
        this.percentage = this.value;
      }
      let circle = this.$el.querySelector('.inner-circle');
      let degrees = this.percentage * 3.6;
      let targets = [
        this.$el.querySelector('.end'),
        this.$el.querySelector('.progress')
      ];
      let animeObj = {
        targets,
        rotate: degrees,
        easing: 'easeInOutExpo',
        delay: 100,
        update: (anim) => {
          degrees = parseInt(anim.animations[0].currentValue, 10);
          if (degrees >= 180 && !circle.classList.contains('fifty')) {
            circle.classList.add('fifty');
          } else if (degrees < 180 && circle.classList.contains('fifty')) {
            circle.classList.remove('fifty');
          }
        }
      };
      if (degrees === 0) {
        animeObj.duration = 0;
      } else {
        animeObj.duration = 1500;
      }
      if (this.isVisible === true) {
        anime(animeObj);
      }

    },
    visibilityChanged (status) {
      if (status === true) {
        this.isVisible = status;
        this.init();
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.init();
      }
    }
  }
};
</script>
