<template>
  <div
    v-if="asset != null && (!additional || creative.additional_assets[asset_id] != null)"
    class="card card-rounded asset-upload-card"
    :class="{ 'card-additional': additional, dashed: creative.assets[asset_id] == null, 'responsive-view': sizeset == null && has_size_specific_assets }"
  >
    <div v-if="sizeset == null && has_size_specific_assets" class="asset-upload-wrapper">
      <Thumbnail bg="unset" :asset="{ image_uri: $cdn + 'dist/nexd/imgs/multiple-assets.svg' }" />
      <div class="asset-settings">
        <div v-if="!additional" class="name">{{ asset.name }}</div>
        <div class="type-information-row">&nbsp;</div>
        <div class="type-information-row">&nbsp;</div>
        <div class="information-row">
          <span>Accepted files:&nbsp;</span>
          <span>
            (
            <span v-if="accepted_image_extensions !== ''">{{ accepted_image_extensions }}</span>
            <span v-if="accepted_video_extensions !== ''"> {{ accepted_video_extensions }}</span>
            )
          </span>
        </div>
      </div>
      <div class="flex-grow margin-left">
        <div class="collapse-card-toggle responsive-card-upload">
          <i class="nexd-icon-32-arrow-down-small" aria-hidden="true" :class="{ active: collapsed === false }" @click="toggleCollapse"></i>
        </div>
      </div>
      <hr v-if="!collapsed" class="line" />
    </div>
    <div v-if="!collapsed" class="card-body asset-upload-wrapper">
      <template>
        <Thumbnail :asset="creative_asset" />
        <div class="asset-settings">
          <div v-if="!additional" class="name" :class="{ 'size-specific': has_size_specific_assets }">
            <span v-if="sizeset == null && has_size_specific_assets">
              <span v-if="counter === 0">Global</span>
              <span v-else>{{ active_collection_responsive_asset_id.split('_')[1] }}</span>
            </span>
            <template v-if="slot_object != null">
              <span>{{ slot_object.name }}</span>
              <span v-if="slot_object.required" class="color-required" v-tooltip="{ value: 'Required asset' }">&nbsp;*</span>
              <i v-if="slot_object.description != null" class="nexd-icon-32-help" aria-hidden="true" v-tooltip="{ value: slot_object.description }" />
            </template>
          </div>
          <div v-else class="name">
            <input
              type="text"
              class="form-control"
              ref="nameInput"
              v-tooltip="{ value: 'Overlay name', position: 'bottom' }"
              :value="asset.name || 'Overlay element'"
              @focusout="endAssetRenaming"
              @keydown.enter="endAssetRenaming"
              @keydown.esc="resetAssetRenaming"
            />
            <div class="asset-title-border"></div>
          </div>
          <template v-if="geo_data">
            <div class="information-row">
              <span>Format: </span>
              <p class="description-csv">CSV</p>
            </div>
          </template>
          <template v-else>
            <div class="type-information-row"><span v-if="false">Asset type: Media|DCO</span>&nbsp;</div>
            <template v-if="has_asset_uri">
              <div class="type-information-row">&nbsp;</div>
              <div v-if="creative_asset.original_name != null" class="information-row">
                <span class="original-asset-info" v-tooltip="{ value: creative_asset.original_name, class: 'advanced' }">
                  <span class="original-asset-name">{{ creative_asset.original_name }}&nbsp;</span><span v-if="uploaded_asset_filesize != null">({{ uploaded_asset_filesize | FileSize }})</span>
                </span>
              </div>
            </template>
            <template v-else>
              <div class="information-row">
                <span>Accepted files:&nbsp;</span>
                <span>
                  (
                  <span v-if="accepted_image_extensions !== ''">{{ accepted_image_extensions }}</span>
                  <span v-if="accepted_video_extensions !== ''"> {{ accepted_video_extensions }}</span>
                  )
                </span>
              </div>
              <div class="information-row">
                <span>Size:&nbsp;</span>
                <span>{{ asset_recommended_size.text }}</span>
              </div>
            </template>
            <div v-if="sizeset == null && has_size_specific_assets" class="asset-specific-assets">
              <i class="nexd-icon-16-arrow-up color-primary left-arrow" aria-hidden="true" @click="previousFromCollection"></i>
              <span class="counter">{{ counter + 1 }}</span
              ><span class="color-gray-800 fs-12"> / {{ responsive_collection.length + 1 }}</span>
              <i class="nexd-icon-16-arrow-up color-primary right-arrow" aria-hidden="true" @click="nextFromCollection"></i>
            </div>
          </template>
        </div>
        <div v-if="!has_asset_uri" class="upload-button" @click="selectFile">
          <i class="nexd-icon-16-upload" aria-hidden="true"></i>
        </div>
        <div v-else class="asset-controls">
          <i
            v-if="(has_size_specific_assets && counter > 1) || (sizeset != null && !use_alternative_asset && this.creative.assets[this.asset_id] != null)"
            class="nexd-icon-16-counter-clockwise"
            aria-hidden="true"
            @click="removeAsset(active_collection_responsive_asset_id != null ? active_collection_responsive_asset_id : null, 'clear_asset')"
            v-tooltip="{ value: 'Reset to Global' }"
          >
          </i>
          <i
            v-if="dsp_asset_slot_options == null || dsp_asset_slot_options.indexOf('upload_new') !== -1"
            class="nexd-icon-16-upload"
            aria-hidden="true"
            @click="selectFile"
            v-tooltip="{ value: `Upload new${tooltip_suffix}` }"
          ></i>
          <i v-if="geo_data" class="nexd-icon-16-edit" aria-hidden="true" @click="mapSlotEditHandler" v-tooltip="{ value: `Edit${tooltip_suffix}` }"></i>
          <template v-else>
            <i
              v-if="dsp_asset_slot_options == null || dsp_asset_slot_options.indexOf('edit_optimization') !== -1"
              class="nexd-icon-16-cog"
              aria-hidden="true"
              @click="editAssetOptimization"
              v-tooltip="{ value: `Change${tooltip_suffix} optimization` }"
            >
            </i>
            <i v-if="dsp_asset_slot_options == null || dsp_asset_slot_options.indexOf('edit') !== -1" class="nexd-icon-16-edit" aria-hidden="true" @click="editAsset" v-tooltip="{ value: `Edit${tooltip_suffix}` }"></i>
          </template>
          <i
            v-if="dsp_asset_slot_options == null || dsp_asset_slot_options.indexOf('delete') !== -1"
            class="nexd-icon-16-delete"
            aria-hidden="true"
            @click="
              additional || sizeset != null
                ? clearAsset(active_collection_responsive_asset_id != null ? active_collection_responsive_asset_id : null)
                : removeAsset(active_collection_responsive_asset_id != null ? active_collection_responsive_asset_id : null)
            "
            v-tooltip="{ value: additional ? `Clear${tooltip_suffix}` : `Remove${tooltip_suffix}` }"
          >
          </i>
        </div>
        <i v-if="has_asset_uri && !geo_data" class="nexd-icon-32-help-big size-help" aria-hidden="true" v-tooltip="{ value: accepted_info_tooltip, class: 'advanced', width: 270 }"> </i>
        <input ref="asset-input" type="file" :accept="file_input_accept_extensions" @change="preUploadCheck" />
        <div v-if="additional" class="asset-delete" v-tooltip="{ value: `Delete${tooltip_suffix}` }" @click="removeAsset()">
          <i class="nexd-icon-16-close-small" aria-hidden="true"></i>
        </div>
      </template>
    </div>

    <Loading v-if="loading !== null" :fill="true" :text="loading" :percentage="loading_percentage" />

    <AssetDebug v-if="admin_toggle" :asset="asset" :asset_id="asset_id" :recommended="asset_recommended_size" :options="cropper_options" :uploaded="creative_asset" />
    <AssetSettings v-if="has_settings" :creative="creative" :asset_id="asset_id" :crop_settings="cropper_options" />
    <div v-if="dropbox_visible === true" ref="dropbox" class="dropbox">
      <div class="dropbox-content">
        <div>Drop your asset here<span v-if="creative.assets[asset_id] != null"> to replace</span></div>
      </div>
    </div>

    <template v-if="geo_data">
      <div v-if="creative.assets[asset_id] != null && creative.assets[asset_id].settings != null" class="map-options-wrap">
        <div class="flex flex-align-center mt-8">
          <i class="nexd-icon-32-warning color-warning mr-8" aria-hidden="true"></i>
          <span class="fs-14 color-cyan-blue">Location accuracy depends on the permissions the user has given to the app and the ad itself.</span>
        </div>
        <div class="row map-options-row">
          <div class="col-8 map-options-label">
            Map style:
            <i class="nexd-icon-32-help-big" aria-hidden="true" v-tooltip="{ value: 'Choose how the map should look like: greyscale, classic or night mode' }"> </i>
          </div>
          <div class="col-16">
            <div class="row map-asset-mode-wrap">
              <div
                class="map-asset-mode"
                :class="{ selected: creative.assets[asset_id].settings.map_style === 'grayscale' }"
                :style="{ 'background-image': 'url(' + $cdn + 'dist/assets/map-mode-black-and-white.png)' }"
                v-tooltip="{ value: 'Greyscale' }"
                @click="selectMapStyle('grayscale')"
              >
                <div class="map-asset-overlay"></div>
              </div>
              <div
                class="map-asset-mode"
                :class="{ selected: creative.assets[asset_id].settings.map_style === 'default' }"
                :style="{ 'background-image': 'url(' + $cdn + 'dist/assets/map-mode-normal.png)' }"
                v-tooltip="{ value: 'Classic' }"
                @click="selectMapStyle('default')"
              >
                <div class="map-asset-overlay"></div>
              </div>
              <div
                class="map-asset-mode"
                :class="{ selected: creative.assets[asset_id].settings.map_style === 'night' }"
                :style="{ 'background-image': 'url(' + $cdn + 'dist/assets/map-mode-night.png)' }"
                v-tooltip="{ value: 'Night' }"
                @click="selectMapStyle('night')"
              >
                <div class="map-asset-overlay"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row map-options-row">
          <div class="col-8 map-options-label">
            Legend:
            <i class="nexd-icon-32-help-big" aria-hidden="true" v-tooltip="{ value: 'Choose whether to show none, one or three nearest locations on the map' }"> </i>
          </div>
          <div class="col-16">
            <div>
              <input id="disableLegend" type="radio" :value="0" v-model="creative.assets[asset_id].settings.map_show_four_markers" @change="mapUpdateSettings" />
              <label for="disableLegend" class="map-radio-label">Do not show</label>
            </div>
            <div>
              <input id="markerOne" type="radio" :value="1" v-model="creative.assets[asset_id].settings.map_show_four_markers" @change="mapUpdateSettings" />
              <label for="markerOne" class="map-radio-label">Only closest location</label>
            </div>
            <div>
              <input id="markerTwo" type="radio" :value="2" v-model="creative.assets[asset_id].settings.map_show_four_markers" @change="mapUpdateSettings" />
              <label for="markerTwo" class="map-radio-label">Closest + 2 nearest locations</label>
            </div>
          </div>
        </div>
        <div class="row map-options-row">
          <div class="col-8 map-options-label">
            Directions icon:
            <i class="nexd-icon-32-help-big" aria-hidden="true" v-tooltip="{ value: 'Choose whether to show an icon on the map that opens a map app with directions' }"> </i>
          </div>
          <div class="col-16">
            <div class="row map-asset-mode-wrap">
              <Toggle v-model="creative.assets[asset_id].settings.map_direction_btn" @input="mapUpdateSettings" />
            </div>
          </div>
        </div>
        <div class="row map-options-row">
          <div class="col-8 map-options-label">
            Distance units:
            <i class="nexd-icon-32-help-big" aria-hidden="true" v-tooltip="{ value: 'Choose whether to show the locations distance in kilometers or miles' }"> </i>
          </div>
          <div class="col-4">
            <div class="row map-asset-mode-wrap">
              <div>
                <input id="map_unit_metric" type="radio" value="metric" v-model="creative.assets[asset_id].settings.map_unit" @change="mapUpdateSettings" />
                <label for="map_unit_metric" class="map-radio-label">km</label>
              </div>
              <div>
                <input id="map_unit_imperial" type="radio" value="imperial" v-model="creative.assets[asset_id].settings.map_unit" @change="mapUpdateSettings" />
                <label for="map_unit_imperial" class="map-radio-label">miles</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <MapCSVModal v-if="show_map_csv_modal" :creative="creative" :asset="asset" :input="map_slot_data" @drop="mapSlotFileChangeHandler" @change="componentChangeHandler(false)" @close="closeMapCSVModal" />
    <AssetOptimizeModal v-if="optimize_target != null" :creative="creative" :target="optimize_target" @save="saveAssetOptimization" @cancel="closeAssetOptimization" />
  </div>
</template>

<script>
import Loading from '@master/UI/Loading';

import AssetHelper from '@helpers/Asset';
import AssetHelperLayouts from '@helpers/AssetV2';
import { clone, dataURLtoFile } from '@helpers/Global';

import AssetsLibraryService from '@master/Services/AssetsLibraryService';

import AssetDebug from '@cm/Views/Creatives/Cards/Components/Asset/Debug';
import AssetSettings from '@cm/Views/Creatives/Cards/Components/Asset/Settings';

import CreativeTraits from '@master/Traits/CreativeTraits';
import AssetTraits from '@cm/Views/Creatives/Traits/AssetTraits';

import AssetOptimizeModal from '@master/UI/AssetOptimizeModal';
import MapCSVModal from '@cm/Views/Creatives/Modals/MapCSVModal';

import Thumbnail from '@cm/UI/Global/Thumbnail/Thumbnail';

import Upload from '@helpers/Upload';
import Toggle from '@master/UI/Toggle';

import IconLoader from '@helpers/IconLoader';

import { ASSET, DSP } from '@master/constants';

export default {
  name: 'AssetUpload',
  mixins: [CreativeTraits, AssetTraits],
  components: {
    Loading,
    AssetDebug,
    AssetSettings,
    AssetOptimizeModal,
    MapCSVModal,
    Thumbnail,
    Toggle,
  },
  props: {
    creative: Object,

    // template asset, not creative asset
    asset: Object,
    additional: {
      type: Boolean,
      default: false,
    },
    sizeset: {
      type: String,
      default: null,
    },
    parent: {
      type: Object,
      default: null,
    },
    parent_slot: {
      type: Object,
      default: null,
    },
  },

  computed: {
    slot_object() {
      return this.parent_slot != null ? this.parent_slot : this.asset;
    },

    assets_library() {
      return this.$store.get('active_assets_library');
    },

    asset_id() {
      if (!Object.keys(this.creative.assets).length || this.asset?._video?._fullpath == null) return this.$vnode.key;

      if (this.creative.assets[this.asset._video._fullpath] != null) return this.asset._video._fullpath;

      return this.$vnode.key;
    },

    use_alternative_asset() {
      return this.sizeset != null && this.asset.alternative != null && this.creative.assets[this.asset_id] == null && this.creative.assets[this.asset.alternative.asset_id] != null;
    },

    creative_asset() {
      if (this.creative?.assets?.[this.asset_id] != null) {
        return this.creative.assets[this.asset_id];
      }
      return null;
    },

    has_asset_uri() {
      return this.creative_asset?.uri != null && this.creative_asset?.uri !== '';
    },

    uploaded_asset_filesize() {
      if (this.creative_asset?.filesize != null) {
        return this.creative_asset.filesize;
      }
      return null;
    },

    tooltip_suffix() {
      if (this.sizeset != null) {
        return ` for ${this.sizeset}`;
      }
      return '';
    },

    force_responsive_overlay() {
      // forcing overlays are only for quantum size specific views
      if (this.sizeset != null || this.active_collection_responsive_asset_id != null) {
        // only cta type of assets or single panorama overlay main asset will be forced into overlay position
        return (this.isOverlay() || this.isResponsivePanoramaOverlay()) && this.asset.position === 'relative';
      }
      return false;
    },

    has_size_specific_assets() {
      return this.responsive_collection.length > 0;
    },

    active_collection_responsive_asset_id() {
      if (this.sizeset == null && this.responsive_collection[this.counter - 1] != null) {
        return this.responsive_collection[this.counter - 1].asset_id;
      }
      return null;
    },

    accepted_info_tooltip() {
      return `<div>
        <div>Accepted files: <span class="color-gray-500"">(${this.accepted_image_extensions}, ${this.accepted_video_extensions})</span></div>
        <div>Size: <span class="color-gray-500">${this.asset_recommended_size.text}<span></div>
      <div>`;
    },

    responsive_collection() {
      let collection = [];
      for (const asset_id in this.creative.assets) {
        if (this.asset.asset_id !== asset_id && asset_id.includes(this.asset.asset_id) && this.creative.assets[asset_id].image_uri != null) {
          collection.push(this.creative.assets[asset_id]);
        }
      }
      return collection;
    },

    cropper_options() {
      if (!this.creative.width || !this.creative.height) return {};

      let options = {
        grid: 9,
        clip: {
          horizontal: false,
          vertical: false,
        },
        cropSize: {
          width: 0,
          height: 0,
        },
        anchor: 'center',
        position: this.asset.position || 'relative',
        asset_name: this.asset.name,
      };

      if (this.force_responsive_overlay || AssetHelper.isOverlay(this.asset)) {
        options.clip.horizontal = true;
        options.clip.vertical = true;
        options.grid = 16;

        // overlays are always absolute, will overwrite the custom logo and cta relative position for quantums as well
        options.position = 'absolute';

        if (this.asset.default_position != null) {
          options.reset_behaviour = this.asset.default_position;
        }

        if (this.sizeset != null) {
          const responsive_type = AssetHelper.getResponsiveType(this.cropper_options_additions.placement.size.width, this.cropper_options_additions.placement.size.height);

          const is_logo = this.asset.filename.includes('custom_logo');
          const is_cta = this.asset.filename.includes('custom_cta');
          const is_panorama_overlay_content = this.isResponsivePanoramaOverlay();

          // cta / logo size for 2 & 0 = full width, logo center-top cta center-bottom
          // cta / logo size for 3 = 1/3 width, logo left-center, cta right-center
          // cta / logo size for 1 = 1/3 width, logo left-top cta left-bottom
          if (responsive_type === 1) {
            if (is_logo) {
              options.reset_behaviour = ['third', 'top', 'left'];
            } else if (is_cta) {
              options.reset_behaviour = ['third', 'bottom', 'left'];
            } else if (is_panorama_overlay_content) {
              options.reset_behaviour = ['fit', 'left'];
            }
          } else if (responsive_type === 3) {
            if (is_logo) {
              options.reset_behaviour = ['third', 'center', 'left'];
            } else if (is_cta) {
              options.reset_behaviour = ['third', 'center', 'right'];
            } else if (is_panorama_overlay_content) {
              options.reset_behaviour = ['fit'];
            }
          } else if (is_logo) {
            options.reset_behaviour = ['contain', 'top'];
          } else if (is_cta) {
            options.reset_behaviour = ['contain', 'bottom'];
          } else if (is_panorama_overlay_content) {
            options.reset_behaviour = ['fit'];
          }
        }
      } else if (this.creative.template != null) {
        if (this.creative.template.template_base === 'scrollToZoom') {
          // special scroll to zoom
          options.clip.horizontal = true;
          options.clip.vertical = true;
        }
      }

      if (this.sizeset != null && this.isBackground()) {
        options.scaling_behaviour = 'cover';
      }

      options.background_options_disabled = false;
      if (this.asset?.settings?.crop_tactic != null) {
        options.background_options_disabled = true;
        options.crop_tactic = this.asset.settings.crop_tactic;
      }

      return { ...options, ...this.cropper_options_additions };
    },

    is_google_ads() {
      return this.creative.dsp === DSP.GOOGLEADS;
    },

    is_custom_splash() {
      return AssetHelper.isCustomSplash(this.asset_id);
    },

    has_settings() {
      if (this.is_vast || this.is_vpaid) return false;
      return this.is_video || this.is_custom_splash;
    },
  },

  data() {
    return {
      Cropper: this.$cropper(),
      is_new: false,
      show_debug_info: false,
      dropbox_visible: false,
      loading: null,
      loading_percentage: null,
      is_video: false,
      asset_recommended_size: {
        text: 'error finding suggested size',
        size: {
          width: 0,
          height: 0,
        },
      },
      accepted_mimes: '',
      file_input_accept_extensions: '',
      accepted_video_extensions: '',
      accepted_image_extensions: '',
      cropper_settings: {},
      cropper_options_additions: {},
      geo_data: AssetHelper.isMap(this.asset.filename),

      is_vast: this.isVast(this.creative.type),
      is_vpaid: this.isVpaid(this.creative.type),

      // when file being converted, the response might have something that should go with the upload later, those settings are saved here
      initial_settings: null,
      collapsed: false,
      counter: 0,
      hold_drop_event: null,
      upload_from_library: false,
      library_upload_data: null,
      convert_upload_data: null,
      optimize_target: null,
      show_map_csv_modal: false,
      map_slot_data: null,
      global_responsive_change_all: false,
      dsp_asset_slot_options: process.env.VUE_APP_asset_slot_options || null,
      map_asset_icon: IconLoader.url('map-asset.svg'),
      map_asset_uploaded_icon: IconLoader.url('map-asset-uploaded.svg'),
      overlays_inside_placement: this.isTemplateInterscroller(),
      admin_toggle: false,
    };
  },

  created() {
    this.generateMimes();
    this.getCurrentType();

    // before show gives 2 arguments, done is the function to be called if its ready to show the croptool, cropper is the lib object
    this.Cropper.beforeShow = (done, cropper) => {
      this.initializeBackgroundSettingsForCropper(cropper);

      this.cropperBackgroundHandler(done, cropper, this.asset_id);

      // hide opening croppingtool text bit later, after the fade
      setTimeout(() => {
        this.loading = null;
      }, 500);
    };

    this.Cropper.afterCropperReady = cropper => {
      this.initializeBackgroundSettingsForCropper(cropper);
    };

    this.$user.subscribe(user => {
      if (user != null) {
        this.admin_toggle = this.$user.adminMode();
      }
    }, this);
  },

  mounted() {
    this.$el.addEventListener('dragover', e => {
      if (this.show_map_csv_modal) return;
      e.preventDefault();
      e.stopPropagation();
      this.dropbox_visible = true;
    });

    this.$el.addEventListener('drop', async e => {
      if (this.show_map_csv_modal) return;
      e.preventDefault();
      e.stopPropagation();

      const original_data = e.dataTransfer.getData('application/original-asset');
      if (original_data != null && original_data !== '') {
        this.library_upload_data = JSON.parse(original_data);
        if (AssetHelper.isAcceptedMime(this.accepted_mimes, this.library_upload_data.mime)) {
          // if google ads is selected as dsp, dont allow dragging video asset
          if (this.is_google_ads && AssetHelper.isVideo(this.library_upload_data.uri)) {
            this.$alert('Google Ads platform does not allow video in ads from third parties. Please use only images or publish your creative via other platform', 'Google Ads does not allow video in ads');
            this.dropbox_visible = false;
            return;
          }

          // if responsive has size specific assets and global is being replaced/re-uploaded
          if (this.has_size_specific_assets && this.active_collection_responsive_asset_id == null) {
            this.upload_from_library = true;
            this.showModal();
          } else {
            this.fromAssetLibrary();
          }
        } else {
          this.notAcceptedError();
        }
      } else if (this.$refs['asset-input'] != null) {
        // asset dropped
        const file = e.dataTransfer.files[0];
        const file_list = new DataTransfer();
        for (let file_item of e.dataTransfer.files) {
          file_list.items.add(file_item);
        }

        if (file != null && AssetHelper.isAcceptedMime(this.accepted_mimes, file.type, file.name)) {
          if (this.geo_data) {
            this.mapSlotFileChangeHandler(file);
          } else {
            this.is_new = true;
            this.initCropper();
            this.$refs['asset-input'].files = file_list.files;
            this.$refs['asset-input'].dispatchEvent(new Event('change'));
          }
        } else {
          this.notAcceptedError();
        }
      }
      this.dropbox_visible = false;
    });

    this.$el.addEventListener('dragleave', e => {
      if (this.show_map_csv_modal) return;
      this.dropbox_visible = false;
      e.preventDefault();
      e.stopPropagation();
    });
  },

  methods: {
    initCropper() {
      this.Cropper.onsave = (settings, auto_crop = false) => {
        this.cropper_settings = settings;
        this.cropperSave(auto_crop);
      };

      this.Cropper.init(this.cropper_options, this.creative);
    },

    generateMimes() {
      let accepted_mimes = AssetHelper.getAcceptedMimes(
        this.asset.extensions
          .map(i => {
            return `.${i}`;
          })
          .join(','),
        this.is_google_ads,
        this.asset.type === ASSET.SPLASH,
      );

      if (this.asset._video != null && !this.is_google_ads) {
        const accepted_video_mimes = AssetHelper.getAcceptedMimes(
          this.asset._video.extensions
            .map(i => {
              return `.${i}`;
            })
            .join(','),
        );
        accepted_mimes.video = accepted_video_mimes.video;
      }

      if (!this.geo_data) {
        let mimes = [];
        if (accepted_mimes.image !== '') {
          mimes.push(accepted_mimes.image);
        }
        if (accepted_mimes.video !== '') {
          mimes.push(accepted_mimes.video);
        }
        this.accepted_mimes = mimes.join(', ');

        this.accepted_image_extensions = AssetHelper.mimeToExtension(accepted_mimes.image);
        this.accepted_video_extensions = AssetHelper.mimeToExtension(accepted_mimes.video);

        // get accepted mimes for input accept, which wants extensions only
        this.file_input_accept_extensions = [this.accepted_image_extensions, this.accepted_video_extensions].join(',');

        this.getRecommendedDimensions();
      } else {
        this.accepted_mimes = accepted_mimes.other;
        this.file_input_accept_extensions = AssetHelper.mimeToExtension(this.accepted_mimes);
      }
    },

    async showModal() {
      const chooseAction = await this.$confirm(
        'Apply changes to all placements?',
        'You are currently replacing the global asset for ' + this.asset.name + '. Do you' + '\n' + 'want to replace this asset for all placements?',
        {
          buttons: [
            { type: 'link-primary', label: 'Cancel', action: 'false' },
            { type: 'primary', outline: true, label: 'Few', action: 'few' },
            { type: 'primary', label: 'All', action: 'all' },
          ],
        },
      );

      if (chooseAction === 'all') {
        this.global_responsive_change_all = true;
        this.globalResponsiveAssetChange(true);
      } else if (chooseAction === 'few') {
        this.globalResponsiveAssetChange(false);
      }
    },

    initializeBackgroundSettingsForCropper(cropper) {
      if (cropper == null) {
        return false;
      }
      if (this.is_new) {
        if (this.isMain()) {
          // check if we should set background option to be bg fill by default or not
          if ((this.asset?.settings?.defaults?.background_color || null) != null) {
            cropper.BackgroundController.setType('bg-color');
          }
        } else if (this.isBackground()) {
          // For backgrounds we do set bg-color by default
          cropper.BackgroundController.setType('bg-color');
        }
      }
    },

    cropperBackgroundHandler(done, cropper, active_asset_id, vnode = this) {
      if (this.isQuantum() || this.singleLayerAsset(active_asset_id)) {
        return done();
      }

      AssetHelperLayouts.createCropperBackground(vnode, layers => {
        AssetHelper.addCropperBackgroundLayers(cropper, layers, this.asset);
        done();
      });
    },

    checkForSizeSpecificAssets() {
      let collection = [];

      for (const asset_id in this.creative.assets) {
        if (this.asset.asset_id.includes(asset_id) && this.asset.asset_id !== asset_id && this.creative.assets[asset_id].image_uri != null) {
          collection.push(this.creative.assets[asset_id]);
        }
      }

      return collection;
    },

    nextFromCollection() {
      if (this.counter < this.responsive_collection.length) {
        this.counter++;
        this.getRecommendedDimensions();
      }
    },

    previousFromCollection() {
      if (this.counter > 0) {
        this.counter--;
        this.getRecommendedDimensions();
      }
    },

    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },

    preUploadCheck(e) {
      // check to see if its responsive and global asset
      if (this.has_size_specific_assets && this.active_collection_responsive_asset_id == null) {
        this.hold_drop_event = e;
        this.showModal();
      } else {
        this.inputChangeHandler(e);
      }
    },

    globalResponsiveAssetChange(value) {
      if (value === true) {
        for (const asset of this.responsive_collection) {
          this.removeAsset(asset.asset_id);
        }
      }
      if (!this.upload_from_library) {
        this.inputChangeHandler(this.hold_drop_event);
      } else {
        this.fromAssetLibrary();
      }
    },

    selectFile() {
      this.is_new = true;
      if (this.geo_data) {
        this.map_slot_data = null;
        this.openMapCSVModal();
      } else {
        this.initCropper();
        // test case for when Intercom tour is active, open cropper without choosing an image
        if (this.$user.isTourActive()) {
          const path = this.$cdn + 'dist/assets/cm/tutorial-image.json';
          this.$http.get(path, { withCredentials: false }).then(response => {
            const file = dataURLtoFile(response.image, 'temp.png');
            this.Cropper.inputChangeHandler({
              target: {
                files: [file],
              },
            });
          });
        } else {
          this.$refs['asset-input'].click();
        }
      }
    },

    async assetInUseConfirmation() {
      return this.$confirm('Are you sure you want to remove this asset from slot?');
    },

    async removeAsset() {
      if ((await this.assetInUseConfirmation()) !== true) {
        return;
      }

      const path = `v2/creative/${this.creative.creative_id}/assets/${this.asset_id}`;

      // remove the asset before the request, so we can disable the buttons to disable deleting 2ce in a row
      this.$delete(this.creative.assets, this.asset_id);

      this.is_video = false;

      // remove asset settigns (if video asset and we have them)
      if (this.creative?.settings?.assets?.[this.asset_id] != null) {
        this.$delete(this.creative.settings.assets[this.asset_id]);
      }

      // make the real request to remove the asset
      this.$http.delete(path).then(response => {
        if (this.counter > 1) {
          this.counter--;
        }

        if (this.creative.assets_library_id != null) {
          AssetsLibraryService.load();
        }

        if (response.has_video != null) {
          this.$set(this.creative, 'has_video', response.has_video);
        }
      });
    },

    async clearAsset(new_id = null) {
      const confirm = await this.assetInUseConfirmation();
      if (!confirm) {
        return;
      }

      let clear_asset_id = this.asset_id;
      if (new_id != null) {
        clear_asset_id = new_id;
      }
      const path = `creatives/${this.creative.creative_id}/assets/${clear_asset_id}/clear`;
      this.$http
        .put(path)
        .then(response => {
          if (this.additional) {
            this.$set(this.creative.additional_assets, clear_asset_id, response);
          } else {
            this.$set(this.creative.assets, clear_asset_id, response);
          }
          if (this.counter > 1) {
            this.counter--;
          }

          if (this.creative.assets_library_id != null) {
            AssetsLibraryService.load();
          }
        })
        .finally(_ => {
          // just because we cannot modify the clear endpoint (because of DSP app using it)
          // and theres no reason to make v2 clear (but might?)
          // just call simple creative get to get the has_video param update
          this.$http.get(`v2/creative/${this.creative.creative_id}`).then(response => {
            if (response.has_video != null) {
              this.$set(this.creative, 'has_video', response.has_video);
            }
          });
        });
    },

    async inputChangeHandler(e) {
      const file = e.target.files[0];
      if (file != null) {
        // if google ads is selected as dsp, dont allow dragging video asset
        if (this.is_google_ads && AssetHelper.isVideo(file.type)) {
          return this.$alert('Google Ads platform does not allow video in ads from third parties. Please use only images or publish your creative via other platform', 'Google Ads does not allow video in ads');
        }

        if (e.converted == null) {
          if (file.type === 'image/gif') {
            this.loading = 'Converting GIF';
            this.convertFile(file);
            return;
          } else if (file.type === 'video/quicktime') {
            this.loading = 'Converting MOV';
            this.convertFile(file);
            return;
          } else if (AssetHelper.isVideo(file.type)) {
            const valid = await AssetHelper.isVideoSupported(file);
            if (!valid) {
              this.loading = 'Converting unsupported video';
              this.convertFile(file);
              return;
            }
          }
        }

        const valid = await AssetHelper.isValidFile(file);
        if (!valid) {
          this.loading = null;
          return;
        }

        const opened = await this.Cropper.inputChangeHandler(e);
        if (!opened) {
          // if cropper.init returns false, it means there was video length error
          this.loading = null;
        }
      }
    },

    fromAssetLibrary() {
      // drop is made from asset library
      this.is_new = true;
      if (this.geo_data) {
        this.mapSlotFileChangeHandler(this.library_upload_data);
        return;
      }

      this.initCropper();
      this.Cropper.newFromURL(this.library_upload_data.uri);
    },

    convertFile(file) {
      this.loading_percentage = 0;
      const handler = new Upload(file);
      handler.upload(({ percentage, done, info, error, msg }) => {
        if (msg != null) {
          this.loading = msg;
        }
        this.loading_percentage = percentage;
        if (done) {
          this.loading = null;
          this.initCropper();
          this.convert_upload_data = info;

          this.Cropper.newFromURL(this.convert_upload_data.url);
        }

        if (error?.message != null) {
          this.loading = null;
          this.loading_percentage = null;
          this.$alert(error.message);
        }
      });
    },

    cropperSave(auto_crop = false) {
      if (this.is_new) {
        if (this.library_upload_data != null) {
          this.uploadFromLibrary();
        } else if (this.convert_upload_data != null) {
          this.uploadFromConvertedFile();
        } else {
          this.uploadAsset();
        }
      } else {
        this.saveAsset(auto_crop);
      }
    },

    uploadFromLibrary() {
      const obj = {
        original_asset_id: this.library_upload_data.original_asset_id,
      };
      this.loading = 'Uploading';
      this.upload(obj, { type: this.library_upload_data.mime, width: this.library_upload_data.width, height: this.library_upload_data.height });
    },

    uploadFromConvertedFile() {
      const obj = {
        upload_id: this.convert_upload_data.upload_id,
      };
      this.loading = 'Uploading';
      this.upload(obj, { type: this.convert_upload_data.mime, width: this.convert_upload_data.width, height: this.convert_upload_data.height });
    },

    uploadAsset() {
      const file = this.Cropper.getFile();
      if (!file) return;

      if (file) {
        file._callback = (asset, start = false) => {
          if (start) {
            // start upload, show the text in this component
            this.loading = 'Uploading';
          } else {
            this.library_upload_data = asset;
            this.uploadFromLibrary();
          }
        };
        this.assets_library.assetSlotUploadHandler(file);
      }
    },

    upload(obj, file) {
      // if done via new upload, use text finalizing while files are being moved and hide percentage
      // especially for larger vid files thay will stay at 100% for a long time
      if (obj.upload_id != null) {
        this.loading = 'Finalizing';
        this.loading_percentage = null;
      }

      let options = {
        notification: true,
      };

      // disable upload notification when uploading library asset
      if (this.library_upload_data != null) {
        options.notification = false;
      }

      let id = this.asset._fullpath;

      if (this.asset._video != null) {
        if (AssetHelper.isVideo(file.type)) {
          id = this.asset._video._fullpath;
        }
      }

      const path = `v2/creative/${this.creative.creative_id}/assets/${id}`;
      this.$http
        .post(path, obj, options)
        .then(response => {
          // if uploading different type, image/video, which has different fullpath id, remove the previous type, as this asset will be removed
          if (this.asset_id !== response.asset_id) {
            this.$delete(this.creative.assets, this.asset_id);
          }

          this.$set(this.creative.assets, response.asset_id, response);

          if (AssetHelper.isVideo(response.uri)) {
            this.$set(this.creative, 'has_video', true);
          }

          // after upload, also save asset to crop it
          this.saveAsset(true);

          if (this.creative.assets_library_id != null) {
            AssetsLibraryService.load();
          }
        })
        .catch(_ => {
          // set upload to null only on catch
          // because this value will be re-assigned on saveAsset function inside .then pipe
          this.loading = null;
        })
        .finally(_ => {
          this.loading_percentage = null;

          // reset
          this.library_upload_data = null;
          this.convert_upload_data = null;

          // reload asset settings
          this.$http.get(`creative/${this.creative.creative_id}/settings/assets`).then(settings_object => {
            this.$set(this.creative.settings, 'assets', settings_object.assets);
          });
        });
    },

    saveAsset(notification = true) {
      const settings = clone(this.cropper_settings);
      this.loading = 'Optimizing';

      const path = `v2/creative/${this.creative.creative_id}/assets/${this.asset_id}`;
      const options = { notification };

      this.$http
        .put(path, { crop: true, settings }, options)
        .then(response => {
          // map response values for asset
          this.$set(this.creative.assets, this.asset_id, response);

          // get type after upload, it might change
          this.getCurrentType();
        })
        .finally(() => {
          this.loading = null;
        });
    },

    renameAsset() {
      this.loading = 'Saving';

      const path = `v2/creative/${this.creative.creative_id}/assets/${this.asset_id}`;
      this.$http
        .put(path, { name: this.asset.name })
        .then(response => {
          this.loading = null;
          this.componentChangeHandler(true);
          this.$set(this.creative.assets, this.asset_id, response);
        })
        .finally(() => {
          this.loading = null;
        });
    },

    editAsset() {
      this.is_new = false;

      if (this.creative_asset != null) {
        this.initCropper();

        // set loading, loading is removed in "beforeShow" function
        this.loading = 'Opening cropper';

        let settings = this.creative_asset.settings;
        // if this responsive asset is not in the list, use no initial setitngs so cropepr would act like it would be the new asset
        if ((this.isOverlay() || this.isResponsivePanoramaOverlay() || this.isBackground()) && this.creative_asset == null) {
          settings = null;
        }

        this.Cropper.editAsset(this.creative_asset.original_url, settings);
      }
    },

    editAssetOptimization() {
      this.loading = 'Opening optimizer';
      // try to set target as optimized asset from assets or additionals, fallback final would be the original (global for responsives)

      let target = this.creative_asset;

      this.optimize_target = target;
    },

    saveAssetOptimization() {
      this.loading = null;
      this.closeAssetOptimization();
      this.componentChangeHandler(true);
    },

    closeAssetOptimization() {
      this.loading = null;
      this.optimize_target = null;
    },

    componentChangeHandler(reload = false) {
      if (reload) {
        this.$emit('reload');
      } else {
        this.$emit('change');
      }
    },

    getCurrentType() {
      this.is_video = false;
      if (this.creative_asset != null && AssetHelper.isVideo(this.creative_asset.uri)) {
        this.is_video = true;
      }
    },

    getRecommendedDimensions() {
      let placement_width = this.creative.width;
      let placement_height = this.creative.height;
      let force_width = null;
      let force_height = null;

      if (this.active_collection_responsive_asset_id != null || this.sizeset != null) {
        if (this.sizeset != null) {
          [placement_width, placement_height] = this.sizeset.split('x');
        } else if (this.active_collection_responsive_asset_id != null) {
          // abcde_300x250
          const [, sizeset] = this.active_collection_responsive_asset_id.split('_');
          [placement_width, placement_height] = sizeset.split('x');
        }
        placement_width = parseInt(placement_width, 10);
        placement_height = parseInt(placement_height, 10);

        // overwrite asset width and height for background assets (x2 size)
        if (this.isBackground()) {
          force_width = `=${placement_width * 2}`;
          force_height = `=${placement_height * 2}`;
        }
      }

      // real dimensions for the asset cropping
      const sizes = AssetHelperLayouts.getSize(this.creative, this.asset, {
        force_overlay: this.force_responsive_overlay,
        placement_width,
        placement_height,
        force_width,
        force_height,
      });

      const is_panorama = sizes.panorama;

      this.cropper_options_additions.cropSize = sizes.dimensions;
      this.cropper_options_additions.scale = sizes.scale;
      this.asset_recommended_size.size = sizes.recommended;

      if (!this.additional) {
        if (is_panorama) {
          this.asset_recommended_size.text = `Max: ${parseInt(this.asset_recommended_size.size.width, 10)} x ${parseInt(this.asset_recommended_size.size.height, 10)} px`;
        } else if (this.force_responsive_overlay || AssetHelper.isOverlay(this.asset)) {
          if (this.isFullscreen() && this.asset_recommended_size.size.height >= 854) {
            this.asset_recommended_size.text = `Max: ${this.asset_recommended_size.size.width} x ${this.asset_recommended_size.size.height} px`;
          } else {
            this.asset_recommended_size.text = 'Up to placement size';
          }
        } else {
          this.asset_recommended_size.text = `Min: ${this.asset_recommended_size.size.width}x${this.asset_recommended_size.size.height} px`;
        }
      } else {
        this.asset_recommended_size.text = 'Up to placement size';
      }

      // add placement layer to cropper depending on the creative size
      // let total_ad_width = placement_width;
      // let total_ad_height = placement_height;

      let placement = {
        width: placement_width,
        height: placement_height,
      };

      // handle cases where placement size is not actually the same what user chooses
      if (this.isMobile()) {
        if (this.isInfeed() && this.isInterscroller()) {
          const ar = placement.height / placement.width;
          placement.width = sizes.total.width;
          placement.height = placement.width * ar;
          placement = AssetHelper.validateDimensions(placement);
        }
      } else if (this.isQuantum()) {
        placement.width = sizes.total.width;
        placement.height = sizes.total.height;
      }

      // tell cropper about where to put the placement layer and how big is the ad itself
      // 90% of cases adsize = placement, but this will handle exceptions for interscroller

      let offset_x = this.asset.x || 0;
      let offset_y = this.asset.y || 0;

      const half_asset_width = this.cropper_options_additions.cropSize.width / 2;
      const half_asset_height = this.cropper_options_additions.cropSize.height / 2;

      // special case for asset x and y, if they are -1 or 1, they are positioned on top or bottom (like overlays)
      if (offset_x === 1) {
        offset_x = sizes.total.width / 2 - half_asset_width;
      } else if (offset_x === -1) {
        offset_x = -sizes.total.width / 2 + half_asset_width;
      }

      if (offset_y === 1) {
        offset_y = sizes.total.height / 2 - half_asset_height;
      } else if (offset_y === -1) {
        offset_y = -sizes.total.height / 2 + half_asset_height;
      }

      this.cropper_options_additions.placement = {
        size: placement,
        adsize: sizes.total,
        offset: {
          x: offset_x,
          y: offset_y,
        },
      };
    },

    openMapCSVModal() {
      this.show_map_csv_modal = true;
    },

    closeMapCSVModal() {
      this.show_map_csv_modal = false;
    },

    async mapSlotFileChangeHandler(file) {
      // make sure map modal is closed, drop can happen from opened modal, so on that case we recreate modal for the created lifecylce
      this.closeMapCSVModal();
      await this.$nextTick();

      // key new indicates it will be a new upload after user will press save
      this.map_slot_data = {
        new: file,
      };
      this.openMapCSVModal();
    },

    mapSlotEditHandler() {
      // csv file is being loaded by the original asset, from the library / original
      this.map_slot_data = {
        edit: {
          library_id: this.creative.assets_library_id,
          original_asset_id: this.creative.assets[this.asset_id].original_asset_id,
        },
      };
      this.openMapCSVModal();
    },

    selectMapStyle(style) {
      this.creative.assets[this.asset_id].settings.map_style = style;
      this.mapUpdateSettings();
    },

    mapUpdateSettings() {
      const path = `creatives/${this.creative.creative_id}/assets/mapSettings`;
      const data = {
        asset_id: this.asset_id,
        ...this.creative.assets[this.asset_id].settings,
      };
      this.$http.post(path, data).then(() => {
        this.componentChangeHandler(false);
      });
    },

    endAssetRenaming(e) {
      if (e.target.value.trim().length > 0) {
        if (this.asset.name !== this.$refs.nameInput.value) {
          this.asset.name = this.$refs.nameInput.value;
          this.renameAsset();
        }
      } else {
        this.$refs.nameInput.value = this.asset.name;
      }
    },

    resetAssetRenaming() {
      this.$refs.nameInput.value = this.asset.name;
    },

    notAcceptedError() {
      this.$alert('Please check the list of permitted file types and try again.', 'Sorry, that file type is not accepted');
    },
  },

  watch: {
    'creative.dsp': {
      handler() {
        this.generateMimes();
      },
    },
    'creative.settings.cta.enabled': {
      handler() {
        this.getRecommendedDimensions();
      },
    },
    'creative.height': {
      handler() {
        this.getRecommendedDimensions();
      },
    },
    'creative.width': {
      handler() {
        this.getRecommendedDimensions();
      },
    },
  },
};
</script>
