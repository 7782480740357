<template lang="pug">
Container(
  title="Impressions usage"
  subtitle="Your current period impressions balance"
)
  template(v-slot:buttons)
    Button(
      type="primary"
      :animate="true"
      label="Purchase extra impressions"
      :loading="loading"
      @click="$router.push({ name: `${VIEW.BILLING}.impressions` })"
    )

  div(v-if="loading")
    Loading
  Details(v-else)
    Detail(v-if="organization?.impression_balance != null" :large="true" :highlight="balance_status" label="Available Balance" :value="organization?.impression_balance ?? 0")
    Detail(v-if="details?.featured != null" label="Featured" :value="details.featured")
    Detail(v-if="details?.topup != null" label="Topup" :value="details.topup")
    Detail(v-if="details?.spend != null" highlight="danger" label="Spend" :value="details.spend")
</template>

<script>
import BillingService from '@cm/Views/Billing/helpers/BillingService';
import { VIEW } from '@master/constants';

import Button from '@master/UI/Buttons/Button';
import Loading from '@master/UI/Loading';

import Container from '@cm/Views/Billing/Components/Container/Container';
import Detail from '@cm/Views/Billing/Components/Details/Detail';
import Details from '@cm/Views/Billing/Components/Details/Details';

export default {
  name: 'ImpressionBalance',

  components: {
    Button,
    Container,
    Detail,
    Details,
    Loading
  },

  computed: {
    balance_status() {
      if (this.details?.spend == null || this.organization?.impression_balance == null) return '';

      const total = Math.abs(this.details.spend) + Math.abs(this.organization?.impression_balance);
      const spend_percentage = this.organization?.impression_balance / total * 100;

      if (spend_percentage < 3) {
        return 'danger';
      }

      if (spend_percentage < 12) {
        return 'warning';
      }

      return 'success';
    }
  },

  data() {
    return {
      VIEW,

      organization: null,
      subscription: null,

      loading: true,
      details: null
    };
  },

  created() {
    this.loadDetailedBalance();
  },

  methods: {
    async loadDetailedBalance () {
      try {
        this.loading = true;

        this.organization = await BillingService.getOrganization();
        this.subscription = await BillingService.getSubscription();

        if (this.subscription?.id != null) {
          this.details = await this.$http.get(`v2/subscription/${this.subscription?.id}/impressions/balance/detailed`);
        }
      } catch (error) {
        /** Failed to load subscription balance details */
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
