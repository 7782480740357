class Clock {
  constructor(svg) {
    this.svg = svg;
    this.parent = this.svg.querySelector('#time');
    this.hours = this.parent.querySelector('#time-hours tspan');
    this.minutes = this.parent.querySelector('#time-minutes tspan');
    this.colon = this.parent.querySelector('#time-colon tspan');
    this.amPm = this.parent.querySelector('#am-pm tspan');
    this.error = false;
    this.init();
  }
  init() {
    this.colon.classList.add('transition');
    this.setTime();
    setInterval(() => {
      this.setTime();
    }, 1000);
  }
  setTime() {
    if (this.error === false) {
      let date = new Date();
      let dateSuffix = 'AM';
      let minutes = date.getMinutes();
      let hours = date.getHours();
      if (hours > 12) {
        hours = hours - 12;
        dateSuffix = 'PM';
      }
      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      this.hours.textContent = hours;
      this.minutes.textContent = minutes;
      this.amPm.textContent = dateSuffix;
    }
    this.colon.classList.toggle('hidden');
  }
  show() {
    this.parent.style.opacity = 1;
  }
  hide() {
    this.parent.style.opacity = 0;
  }
  crash() {
    this.error = true;
    this.hours.textContent = '13';
    this.minutes.textContent = '37';
    this.amPm.textContent = 'PRO';
  }
}

export default Clock;
