<template lang="pug">
TestLayout(title="Alerts")

  TestSection(subtitle="New Alert")
    Button(type="primary" label="Alert" :animate="true" @click="$alert('Something went wrong, please try again.')")
    pre arguments in order: {{ { message: '', heading: '', options: {} } }}
    pre(style="text-wrap:wrap") By default it will have one button - close
    pre(style="text-wrap:wrap") By default has ice cream image

  TestSection(subtitle="New Confirm (error)")
    Button(type="primary" label="Confirm" :animate="true" @click="confirm('error')")
    pre overwrite: {{ confirm_response }}
    pre arguments in order: {{ { heading: '', message: '', options: {} } }}
    pre(style="text-wrap:wrap") By default it will have two buttons - close &amp; confirm
    pre(style="text-wrap:wrap") By default has roadblock image

  TestSection(subtitle="New Confirm (success)")
    Button(type="primary" label="Confirm" :animate="true" @click="confirm('success')")
    pre overwrite: {{ confirm_response }}
    pre arguments in order: {{ { heading: '', message: '', options: {} } }}
    pre(style="text-wrap:wrap") By default it will have two buttons - close &amp; confirm
    pre(style="text-wrap:wrap") By default has roadblock image


  pre(style="grid-column:1/-1") OPTIONS_EXAMPLE: {{ confirm_options }}
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Button from '@master/UI/Buttons/Button';

export default {
  name: 'AlertTest',

  components: {
    TestLayout,
    TestSection,
    Button,
  },

  data() {
    return {
      confirm_options: {
        buttons: [
          {
            type: 'link-primary',
            label: 'Close',
            action: null,
          },
          {
            type: 'primary',
            label: 'Keep',
            action: false,
          },
          {
            type: 'primary',
            label: 'Replace',
            action: true,
          },
        ],
        image: 'error',
      },
      confirm_response: null,
    };
  },

  methods: {
    async confirm(image) {
      this.confirm_response = await this.$confirm('Are you sure?', 'Please confirm changes before rerouting.', { ...this.confirm_options, image });
    },
  },
};
</script>
