import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

import LoginRouter from './login.js';
import CMRouter from './cm.js';
import AnalyticsRouter from './analytics.js';
import BillingRouter from './billing.js';
import GroupAssetsRouter from './assets.js';

import UserService from '@master/Services/UserService';
import IntercomService from '@master/Services/IntercomService';
import SidebarService from '@root/src/global/sidebar/global/Service';
import { clearCookie } from '@helpers/Global';
import NotificationService from '@master/Services/NotificationService.js';
import { VIEW } from '@master/constants.js';

let routes = LoginRouter.concat(CMRouter).concat(AnalyticsRouter).concat(BillingRouter).concat(GroupAssetsRouter);

if (['tst', 'lcl', 'stg'].includes(process.env.VUE_APP_ENV)) {
  const ComponentTestsRouter = require('./components.js').default;
  routes = routes.concat(ComponentTestsRouter);
}

const router = new Router({
  mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
  routes,
});

function handleChristmasEvent() {
  const has_login_xmas = ['login', 'reset'].includes(to.name);
  if (has_login_xmas && !window._christmas) {
    const Snowing = require('@libs/snow').default;
    window._christmas = new Snowing({ speed: 150 });
    window._christmas.start();
    window._christmas.music(true);
  } else if (!has_login_xmas && window._christmas) {
    window._christmas.stop();
    window._christmas = undefined;
  }
}

function handleIntercom(to) {
  // allow initing intercom if route allows it or query param wants to start a tour
  if (to.meta.intercom || to.query.product_tour_id) {
    if (to.meta.intercom) {
      // make sure to show intercom launcher
      IntercomService.showLauncher();
    } else {
      // if intercom is disabled but tourn needs to be started, hide the intercom launcher
      IntercomService.hideLauncher();
    }

    if (!to.meta.requires_auth) {
      // make sure all non auth routes would have intercom via non auth boot
      IntercomService.init(null);
      // only call route change on non auth routes instantly
      IntercomService.routeChange(to);
    }
  } else if (to.meta.intercom === false) {
    IntercomService.disable();
  }
}

router.beforeEach(async (to, from, next) => {
  const is_analytics_page = to.meta?.app_key === 'analytics';
  const is_allowed_host = process.env.VUE_APP_CM.includes(window.location.hostname);

  if (!is_analytics_page && !is_allowed_host) {
    return next('/');
  }

  if (!to.name.includes(VIEW.ANALYTICS)) {
    SidebarService.reset();
  }

  if (to.query.error) {
    let query = { ...to.query };
    NotificationService.add('error', decodeURIComponent(query.error));
    delete query.error;
    router.replace({
      path: to.path,
      query: query,
    });
    return next();
  }

  document.title = `${Vue.prototype.$whitelabel.title} | ${to.meta.app_name}`;

  // christmas shenanigans for login screen
  if (window._christmas_8bit) {
    handleChristmasEvent();
  }

  if (to.path.includes('CLICKURL') && to.name !== 'CLICKURL') {
    return next({ path: '/CLICKURL' });
  }

  handleIntercom(to);

  // if route requires auth, check auth status from userservice
  if (to.meta.requires_auth) {
    const status = await UserService.isAuthenticated();
    const is_sdk_user = UserService.isSDKUser();

    if (status) {
      if (is_sdk_user && !to.meta.sdk_client) {
        return next({ name: VIEW.SDK_CREATE });
      }
      // only call route change when user is authenticated on routes that require auth
      IntercomService.routeChange(to);
      return next();
    }

    // @todo, where should we redirect unauthenticated sdk users?

    // clear lb cookie
    clearCookie('__cflb');

    // go to login if it was root path
    if (to.path === '/') {
      return next({ name: VIEW.LOGIN });
    }

    // goto login with current url as reference to redirect after login
    return next({ name: VIEW.LOGIN, query: { ref: window.location.href } });
  }
  // going to non auth math, remove onbefore unload that was set in UserService for demo user
  window.onbeforeunload = null;
  return next();
});

export default router;
