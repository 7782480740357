<template lang="pug">
  div.row
    div.col-5
      small Color:
    div.col-6
      ColorOption(v-model="settings.color" @input="$emit('change')")
</template>
<script>

import ColorOption from '@master/UI/ColorOption';

export default {
  name: 'BackgroundSettings',
  components: {
    ColorOption
  },
  props: {
    settings: Object
  }
};
</script>
