<template lang="pug">
UIGroup(v-if="group" :class="classes" :title="title" :subtitle="true" :collapsed="collapsed")
  div(:class="layout")
    slot
div(v-else :class="[classes, layout]")
  slot
</template>

<script>
import UIGroup from '@master/UI/UIGroup.vue';

import styles from './Settings.module.scss';

export default {
  name: 'SettingsContainer',

  components: {
    UIGroup
  },

  props: {
    columns: {
      type: Number,
      default: 1,
      max: 3
    },
    background: {
      type: Boolean,
      default: false
    },
    group: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    },
    collapsed: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    classes() {
      return {
        [styles.container]: true,
        [styles.background]: this.background
      };
    },

    layout() {
      return {
        [styles.layout]: true,
        [styles[`columns-${this.columns}`]]: true
      };
    }
  },
};
</script>
