<template lang="pug">
  div.slot
    CollapsableCard(
      :tooltip="(module != null && module.settings != null ? module.settings.tooltip : null)"
      :reset_btn_visible="hasUserData()"
      :reset_btn_tooltip="{value: `Reset ${parent_slot.name || module.name} to default`}"
      @reset_btn_click="__remove"
    )
      template(v-slot:header_title)
        h5.self-align-center {{module.name}}
      template(v-slot:additional_header_content)
        AssetSlot.mt-16(:creative="creative" :parent="parent" :parent_slot="parent_slot" :item="slot" :key="slot._fullpath")
      template(v-slot:collapsable_body)
        BackgroundSettings(:settings="object.settings" @change="__update")
</template>

<script>
import BackgroundSettings from './BackgroundSettings';
import CollapsableCard from '@cm_modules/CollapsableCard';
import AssetSlot from '@cm_modules/Asset/Module';

import ModuleTraits from '@master/Traits/ModuleTraits';

export default {
  name: 'BackgroundModule',
  mixins: [ModuleTraits],
  components: {
    BackgroundSettings,
    CollapsableCard,
    AssetSlot
  },

  props: {
    creative: Object,
    module: Object,
    parent: {
      type: Object,
      default: null
    },
    parent_slot: {
      type: Object,
      default: null
    }
  },

  computed: {
    slot() {
      for (const slot in this.module_slots) {
        for (const module of this.module_slots[slot].modules) {
          for (const _slot in module.slots) {
            return module.slots[_slot];
          }
        }
      }
      return null;
    }
  },

  data() {
    return {
      object: null,
      module_slots: {}
    };
  },

  created() {
    this.modularitySetup();
  }
};
</script>
