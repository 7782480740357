<template lang="pug">
div.text-right.relative(v-if="creative != null")
  Buttons(v-if="is_new && !isProcessing()")
    Button(
      label="Cancel"
      type="link-primary"
      v-google-analytics="{ key: 'click', element_name: 'creating creative cancelled' }"
      @click="$emit('cancel')"
    )

    Button(
      label="Save"
      type="primary"
      :outline="!is_new"
      :loading="loading"
      :disabled="disabled || loading || size_warning"
      :tootlip="tooltip"
      :animate="!is_new"
      v-google-analytics="{ key: 'click', element_name: 'creating creative saved' }"
      @click="save"
    )
</template>

<script>
import Button from '@master/UI/Buttons/Button';
import Buttons from '@master/UI/Buttons/Buttons';

import CreativeSizeService from '@master/Services/CreativeSizeService';

import CreativeTraits from '@master/Traits/CreativeTraits';
import CreativeMixins from '@cm/Views/Creatives/Creative/CreativeMixins';

export default {
  name: 'CreativeFooter',
  mixins: [CreativeTraits, CreativeMixins],

  components: {
    Button,
    Buttons,
  },

  props: {
    creative: {
      type: Object,
      default: () => null,
    },

    // tooltip on top of save button
    tooltip: {
      type: Object,
      default: () => ({}),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      size_warning: false,
    };
  },

  created() {
    CreativeSizeService.subscribe(state => {
      this.size_warning = state?.has_warning ?? false;
    }, this);
  },

  methods: {
    save() {
      if (this.is_new && CreativeSizeService.validate(this.creative)) {
        this.$emit('save', 0);
      }
    },
  },
};
</script>
