<template lang="pug">
  div.module-content
    div.module(v-for="(module, module_id) in modules")

      //- Modules container section
      EffectModule(
        v-if="['custom_effect'].includes(module.module_id)"
        :creative="creative"
        :parent_slot="parent_slot"
        :module="module"
        :parent="parent"
        :key="module._fullpath"
        @update="update")
      SocialModule(
        v-else-if="['social_widget'].includes(module.module_id)"
        :creative="creative"
        :parent_slot="parent_slot"
        :module="module"
        :parent="parent"
        :key="module._fullpath"
        @update="update")
      CountdownModule(v-else-if="module.module_id === 'countdown_widget'" :creative="creative" :parent_slot="parent_slot" :module="module" :parent="parent" :key="module._fullpath" @update="update")

      //- ${parent_id || slot.slot_id}.
      //- Modules with their own custom behaviours ETC
      QuestionModule(v-else-if="module.module_id === 'questions'"       :creative="creative" :parent_slot="parent_slot" :module="module" :parent="parent" :key="module._fullpath" @update="update")
      ButtonModule(v-else-if="module.module_id === 'button'"            :creative="creative" :parent_slot="parent_slot" :module="module" :parent="parent" :key="module._fullpath" @update="update")
      BackgroundModule(v-else-if="module.module_id === 'background'"    :creative="creative" :parent_slot="parent_slot" :module="module" :parent="parent" :key="module._fullpath" @update="update")
      LogoModule(v-else-if="module.module_id === 'logo'"                :creative="creative" :parent_slot="parent_slot" :module="module" :parent="parent" :key="module._fullpath" @update="update")
      CTAModule(v-else-if="module.module_id === 'cta'"                  :creative="creative" :parent_slot="parent_slot" :module="module" :parent="parent" :key="module._fullpath" @update="update")
      TextModule(v-else-if="module.module_id === 'text'"                :creative="creative" :parent_slot="parent_slot" :module="module" :parent="parent" :key="module._fullpath" @update="update")
      //- If module doesn't have it's custom Component, we will use Default one
      DefaultModule(v-else    :creative="creative" :parent_slot="parent_slot" :module="module" :parent="parent" :key="module._fullpath" @update="update")
      //- Modules container section END -- each module itself should import their own slots - and position them wherever they are needed
</template>

<script>
import CountdownModule from '@cm_modules/Widgets/Countdown/Module';
import EffectModule from '@cm_modules/Effects/Module';
import SocialModule from '@cm_modules/Widgets/Social/Module';
import QuestionModule from '@cm_modules/Questions/Module';
import ButtonModule from '@cm_modules/Button/Module';
import BackgroundModule from '@cm_modules/Background/Module';
import LogoModule from '@cm_modules/Logo/Module';
import CTAModule from '@cm_modules/CTA/Module';
import TextModule from '@cm_modules/Text/Module';
import DefaultModule from '@cm_modules/Default/Module';

import RequestLimiter from '@libs/RequestLimiter';

export default {
  name: 'ModuleMaker',
  components: {
    CountdownModule,
    EffectModule,
    SocialModule,
    QuestionModule,
    ButtonModule,
    BackgroundModule,
    LogoModule,
    CTAModule,
    TextModule,
    DefaultModule,
  },
  props: {
    creative: Object,
    modules: Array,
    parent: {
      type: Object,
      default: null,
    },
    parent_slot: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      slots: [],
    };
  },
  methods: {
    update(type, id, data) {
      if (data != null) {
        RequestLimiter.hook(`${type}.${id}`, () => {
          let datatype = type;
          if (type === 'object') {
            datatype = 'objects';
          } else if (type === 'asset') {
            datatype = 'assets';
          }
          let method = 'post';
          if (this.creative[datatype][id] != null) {
            method = 'put';
          }

          this.$http[method](`v2/creative/${this.creative.creative_id}/${datatype}/${id}`, data).then(response => {
            delete response['creative_id'];
            if (response._inserted != null) {
              // This is a bit $#17, that _inserted is included with object self data/settings, but currently no point of rewriting all those things in result obj.
              const _inserted = response._inserted;
              delete response._inserted;
              for (const indx in _inserted) {
                delete _inserted[indx].creative_id;
                this.$set(this.creative[datatype], _inserted[indx].object_id, _inserted[indx]);
              }
            }
            this.$set(this.creative[datatype], id, response);
          });
        });
      } else {
        // Delete request has to be initial - but it should overwrite pending update request
        // - so we won't get state where user updated - and deleted right after and the pending update request will put data into DB
        RequestLimiter.hook(
          `${type}.${id}`,
          () => {
            // return;
            let datatype = type;
            if (type === 'object') {
              datatype = 'objects';
            } else if (type === 'asset') {
              datatype = 'assets';
            }
            let method = 'delete';
            this.$http[method](`v2/creative/${this.creative.creative_id}/${datatype}/${id}`).then(response => {
              if (datatype === 'objects' && response.deleted != null) {
                // SHould have it here - cause whenever compoent is closed children are not reachable and we dan't remove this data from creative, but if API tells what to remove - we good
                for (const object_id of response.deleted) {
                  this.$delete(this.creative[datatype], object_id);
                }
              } else {
                this.$delete(this.creative[datatype], id);
              }
            });
          },
          1,
        );
      }
    },
  },
};
</script>
