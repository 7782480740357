import ContentService from '@master/Services/ContentService';

class CampaignService extends ContentService {
  load() {
    super.load(this.#getPath());
  }

  loadMore() {
    super.loadMore(this.#getPath());
  }

  updateSorting(sort, reverse) {
    super.updateSorting(sort, this.#getPath(), reverse);
  }

  #getPath() {
    const active_user_id = this.getId('active_item_id');

    if (active_user_id != null) {
      return `v2/user/${active_user_id}/campaigns/view`;
    }

    const folder_id = this.getId('folder_id');

    if (folder_id != null) {
      return `v2/folder/${folder_id}/campaigns/view`;
    }

    return 'v2/campaigns/view';
  }

  columns = [
    null,
    { name: 'Name', key: 'name' },
    null,
    { name: 'Last edit', key: 'updated_on' },
    { name: 'Creatives', key: 'meta_counts.creatives' },
    { name: 'Impressions', slug: 'Imp.', key: 'meta_analytics.impressions' },
    { name: 'Engagement', slug: 'Eng.', key: 'meta_analytics.engagement' },
    { name: 'CTR', key: 'meta_analytics.ctr' },
    null,
  ];
}

export default new CampaignService();
