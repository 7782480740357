<template lang="pug">
div(v-if="status != null")
  h1#magic-title.mb-16
    template(v-if="success") Email confirmed
    template(v-else) Email confirmation error
  p.mb-32
    template(v-if="success") Redirecting to Campaign Manager...
    template(v-else) Redirecting to Login...

  Button(
    v-if="success"
    label="Click here if you aren't redirected automatically"
    type="link-light"
    :text="true"
    @click="handleClose"
  )

div(v-else)
  h1#magic-title.mb-16 Please wait...
  h5.mb-32 Checking confimation link
  Loading(color="#ffffff")
</template>

<script>

import Button from '@master/UI/Buttons/Button.vue';
import Loading from '@master/UI/Loading.vue';
import { VIEW } from '@master/constants';

const REDIRECT_TIMEOUT = 3000;
const SUCCESS = 'success';
const ERROR = 'error';

export default {
  name: 'ConfirmEmail',

  components: {
    Button,
    Loading
  },

  computed: {
    success() {
      return this.status === SUCCESS;
    }
  },

  data() {
    return {
      status: null
    };
  },

  created() {
    this.handleConfirmation();
  },

  methods: {
    handleConfirmation() {
      const hash = this.$route.query.confirm;

      const path = `user/confirm/${hash}`;
      this.$http.post(path, {}, { notification: false })
        .then(_ => {
          this.status = SUCCESS;
        })
        .catch(_ => {
          this.status = ERROR;
        })
        .finally(_ => {
          setTimeout(() => {
            this.handleClose();
          }, REDIRECT_TIMEOUT);
        });
    },

    openDashboard() {
      this.$router.push(VIEW.DASHBOARD);
    },

    handleClose() {
      if (this.success) {
        this.openDashboard();
      } else {
        this.$emit('close');
      }
    }
  }
};
</script>
