<template lang="pug">
div.custom-responsive-add-popup
  p.fs-14 Placement size (px):

  div.flex.column-gap-16
    div.input-with-label
      PlacementWarningPopup(v-if="warning != null && warning.width != null" :warning="warning.width")
      input.form-control(ref="width" type="text" v-model="width" placeholder="Width" @focus="clearWarnings" @blur="blur('width')")
      div.input-label W
    div.input-with-label
      PlacementWarningPopup(v-if="warning != null && warning.height != null" :warning="warning.height")
      input.form-control(ref="height" type="text" v-model="height" placeholder="Height" @focus="clearWarnings" @blur="blur('height')" @keyup="inputHandler")
      div.input-label H

  Buttons
    Button(type="link-primary" label="Cancel" @click="close")
    Button(type="primary" label="OK" :animate="true" :disabled="add_disabled" @click="add")
</template>

<script>
import Button from '@master/UI/Buttons/Button';
import Buttons from '@master/UI/Buttons/Buttons';

import PlacementWarningPopup from '@master/UI/CreativeSize/PlacementWarningPopup.vue';

export default {
  name: 'CustomResponsiveAddPopup',

  components: {
    Button,
    Buttons,
    PlacementWarningPopup
  },

  computed: {
    add_disabled() {
      return this.width === '' || this.height === '' || this.width < 1 || this.width > 1280 || this.height < 1 || this.height > 1280;
    }
  },

  data() {
    return {
      width: 0,
      height: 0,
      warning: {
        width: null,
        height: null
      }
    };
  },

  mounted() {
    if (this.$refs?.width != null) {
      this.$refs.width?.focus();
    }
  },

  methods: {
    add() {
      this.$emit('add', `${this.width}x${this.height}`);
    },

    blur(key) {
      if (this[key] !== '') {
        this[key] = parseInt(this[key], 10);
        if (this[key] < 1 || this[key] > 1280) {
          this.warning[key] = 'Valid range is between 1 and 1280 px';
        } else {
          this.warning[key] = null;
        }
      }
    },

    clearWarnings(event) {
      for (let key in this.warning) {
        this.warning[key] = null;
      }

      // similar to cr width, select the content if value < 50 (default is 0)
      const value = parseInt(event.target.value);
      if (value < 50) {
        event.target.select();
      }
    },

    inputHandler (event) {
      let keycode = event.keyCode || event.which;
      if (keycode === 13) {
        this.add();
      }
    },

    close() {
      this.$emit('close');
    }
  }
};
</script>
