<script>
export default {
  name: 'SDKMixin',

  data() {
    return {
      is_sdk_user: false,
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user) {
        this.is_sdk_user = this.$user.isSDKUser();
      }
    }, this);
  },
};
</script>
