<template lang="pug">
  div.card.creative.publish
    div.card-head-without-checkbox
      div.card-head.publish-confirmation(:class="{'has-tag': can_download_tag}" @click="toggleCollapse")
        div.row.flex-align-center
          div.col-10.overflow-ellipsis
            div.flex(:class="{'clamp': !creative.location}")
              div {{creative.live?.name || creative.name}}
            div.color-gray-800(v-if="creative.location") in {{ creative.location }}
          div.col.flex-grow.color-gray-800 {{info}}
          div.col.color-gray-800(v-if="!published")
            span(v-if="creative.live != null") Exported on {{creative.live.created_on | DateFilter}}
            span(v-else) Not Exported
          template(v-if="published")
            div.col
              CollapsedHeaderPublishing(:creative="creative")
            div.col(v-if="can_download_tag")
              div.collapse-btn.with-text View Tag
                i.nexd-icon-32-arrow-down-small(aria-hidden="true" :class="{'active': !collapsed}")

      template(v-if="collapsed === false")
        div.card-body
          div.form-group
            pre(ref="tag" tabindex="0" @keydown="selectTagHandler") {{tag == null ? 'Loading...' : tag}}
          div.text-right(v-if="tag != null")
            Button(type="link" label="Copy tag to clipboard" @click="copyToClipboard(tag)")
              template(v-slot:prefix)
                IconLink
</template>

<script>
import CreativeTraits from '@master/Traits/CreativeTraits';

import DSPService from '@master/Services/Cache/DSPService';
import CollapsedHeaderPublishing from '@cm/Views/Creatives/Cards/Components/Header/CollapsedHeaderPublishing';

import { getFileContents, setClipboard } from '@helpers/Global';

import Button from '@master/UI/Buttons/Button';
import IconLink from '@master/UI/Buttons/Icons/IconLink';

export default {
  name: 'PublishCreative',
  components: {
    CollapsedHeaderPublishing,
    Button,
    IconLink
  },
  extends: CreativeTraits,
  props: {
    creative: Object,
    categorize: Boolean,
    published: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      collapsed: true,
      tag: null
    };
  },

  computed: {
    info() {
      const info = [
        this.creative.type_string,
        this.isSomeSortOfFullscreen() ? 'Fullscreen' : `${this.creative.width}x${this.creative.height}`
      ];

      if (this.creative?.template?.name) {
        info.push(this.creative.template.name);
      } else if (this.creative.custom !== 0) {
        info.push('Custom');
      }

      if (this.creative.live != null) {
        if (this.creative.live.dsp !== 'None') {
          info.push(this.creative.live.dsp_title);
        }
      } else if (this.creative.dsp !== 'None') {
        info.push(this.creative.dsp_title);
      }

      return info.join(', ');
    },
    can_download_tag() {
      if (this.creative.live == null) return false;
      return !this.isVast() && !this.isVpaid() && DSPService.hasTag(this.creative.live.dsp || this.creative.dsp);
    },
    can_copy_xml_link() {
      if (this.creative.live == null) return false;
      return this.isVast() || this.isVpaid();
    },
    can_download_zip() {
      if (this.creative.live == null) return false;
      return DSPService.hasZip(this.creative.live.dsp || this.creative.dsp);
    }
  },

  methods: {
    selectTagHandler(e) {
      if (e.ctrKey || e.metaKey && e.keyCode === 65) {
        e.preventDefault();
        let range = document.createRange();
        range.selectNodeContents(e.target);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      }
    },

    toggleCollapse() {
      if (!this.can_download_tag) return;
      this.collapsed = !this.collapsed;

      if (!this.collapsed) {
        this.getTag();
        this.$nextTick(() => {
          if (this.$refs.tag != null) {
            this.$refs.tag.focus();
          }
        });
      }
    },

    copyToClipboard(value) {
      setClipboard(value)
        .then(_ => {
          this.$notifications.add('primary', 'Tag copied to clipboard');
        })
        .catch(error => {
          this.$notifications.add('warning', error);
        });
    },

    getTag() {
      if (this.tag == null) {
        const path = `creative/${this.creative.creative_id}/tags`;
        this.$http.get(path).then((response) => {
          if (response.length > 0) {
            this.tag = getFileContents(response[0]);
          }
        }).catch(() => {
          this.tag = null;
        });
      }
    }
  }
};
</script>
