<template lang="pug">
div(ref="modal")
  div.modal.modal-unset-overflow.creatives-bulk-action
    div.backdrop
    div.modal-content
      div.modal-head
        div.modal-title Bulk Edit Creatives
      div.modal-body(:class="{'modal-body-tracking': parameter === 'tracking'}")
        div.mb-16.fs-14.color-gray-800 Choose the parameter you wish to edit for the selected creatives.
        SearchSelect(
          v-model="parameter"
          :options="parameters"
          :search="false"
          :first="false"
          width="270px"
        )
        template(v-if="parameter !== 'null'")
          hr

          //- EDIT NAME
          section.creatives-bulk-action-name(v-if="parameter === 'name'")
            SearchSelect(
              v-model="name_option"
              :options="name_options"
              :search="false"
            )
            template(v-if="name_option === 'add'")
              div.form-group.mb-0
                input.form-control(
                  placeholder="Insert text"
                  v-model="add_text"
                )
              SearchSelect(
                v-model="add_text_option"
                :options="add_text_options"
                :search="false"
              )
            div.creatives-bulk-action-name-replace.full-width-row(v-else-if="name_option === 'replace'")
              section
                span Find text:
                div.form-group.mb-0
                  input.form-control(
                    placeholder="Insert text you want to find"
                    v-model="find_text"
                  )
              section
                span Replace with:
                div.form-group.mb-0
                  input.form-control(
                    placeholder="Insert text you want to replace text with"
                    v-model="replace_text"
                  )
            div.full-width-row(v-if="example_creative")
              span.color-gray-800 Example:&nbsp;
              | {{ example_text }}

          //- EDIT CTA
          section.creatives-bulk-action-cta(v-if="parameter === 'cta'")
            span.full-width-row.mb-8 Default click action&nbsp;
              i.nexd-icon-16-help(aria-hidden="true" v-tooltip="{template: 'cm.ctaGlobal'}")
            span Type
            span Output
            SearchSelect.input-prefix(
              v-model="cta.action"
              :options="global_cta_options"
              :search="false"
              width="100%"
              @input="CTAactionChangeHandler"
            )
            div.form-group.mb-0.creatives-bulk-action-cta-inputs
              TextareaInput(
                v-if="cta.action === 'url'"
                :placeholder="cta_output_placeholder"
                v-model="cta.uri"
                :url="true"
                @change="change"
              )
              Input(
                v-if="['call', 'sms'].includes(cta.action)"
                :key="cta.action"
                v-model="cta.uri"
                :placeholder="cta_output_placeholder"
                variant="phone"
                @change="change"
              )
              TextareaWithCounter(
                v-if="cta.action === 'sms'"
                v-model="cta.sms_body"
                :max_char="140"
                :isInput="true"
                placeholder="Insert predefined SMS body here. Max 140 characters"
              )
              template(v-if="cta.action === 'calendar'")
                TextareaInput(
                  :placeholder="cta_output_placeholder"
                  v-model="cta.title"
                  :url="false"
                  @change="change"
                )
                div
                  span Description
                  TextareaInput.mt-4(
                    placeholder="Enter description"
                    v-model="cta.description"
                    :url="false"
                    @change="change"
                  )
                div
                  span Location
                  TextareaInput.mt-4(
                    placeholder="Enter address or link"
                    v-model="cta.location"
                    :url="false"
                    @change="change"
                  )
                div
                  span Date and time
                  SelectDateRange.mt-4(
                    :start="cta.start"
                    :end="cta.end"
                    :timezone="cta.timezone"
                    :placeholder="cta.start | DateRangeFilter(cta.end, {utc: true})"
                    :options="{ time: true }"
                    style="width: max-content;"
                    @change="calendarChangeHandler"
                  )
              template(v-if="cta.action === 'map'")
                TagInputLocations(ref="tag_input_locations" @add="addLocationHandler")
                div.creatives-bulk-action-cta-locations(v-if="cta.uri.length > 0")
                  span Map locations
                  div.locations-list.mt-4
                    div.flex.flex-align-center(v-for="(location, index) in cta.uri" :key="index")
                      div.flex-grow.overflow-ellipsis {{location.address}}
                      i.nexd-icon-32-close(aria-hidden="true" @click="removeLocation(index)")
                div.flex.flex-align-center.column-gap-16
                  span Focus on the closest
                  Toggle(v-model="cta.focus_closest" @input="")

          //- EDIT TRACKING
          section.creatives-bulk-action-cta(v-if="parameter === 'tracking'")
            span.full-width-row.mb-8  Creative tracking&nbsp;
              i.nexd-icon-16-help(aria-hidden="true" v-tooltip="{template: 'cm.trackingGlobal'}")
            span Event:
            span Tracker:
            template(v-for="(tracker, index) of impressiontrackers")
              SearchSelect.input-prefix(
                v-model="tracker.action"
                :options="global_tracking_options"
                :search="false"
                width="100%"
              )
              div.flex.flex-align-center.column-gap-16
                div.form-group.mb-0.flex-grow
                  TextareaInput(
                    placeholder="Insert tracking pixel URL (incl. macro)"
                    v-model="tracker.uri"
                    :url="true"
                    @change="parseTrackersExcelAndChange(index)"
                  )
                  i.nexd-icon-16-close-small(aria-hidden="true" @click="removeImpressionTracker(index)")
                i.nexd-icon-16-plus.add(aria-hidden="true" @click="addImpressionTracker")

          //- EDIT DSP
          section.creatives-bulk-action-dsp(v-if="parameter === 'dsp'")
            span Export as tag for:
            SearchSelect(
              v-model="dsp"
              :options="dsp_options"
            )


        template(v-if="parameter === 'tracking'")
          hr
          div.mb-16.fs-14.color-gray-800 Choose if you want to append or replace the impression trackers.
          div.fs-14 Choose action:
          CustomCheckbox.mt-4(:radio="true" :value="!append_trackers" @change="append_trackers = false")
            span.ml-4 Replace already existing trackers
          CustomCheckbox.mt-4(:radio="true" :value="append_trackers" @change="append_trackers = true")
            span.ml-4 Add trackers to already existing trackers
          template(v-if="has_expandable_creatives")
            hr
            div.mb-16.fs-14.color-gray-800 Selection includes some expandable creative(s). Choose for which state the tracker will be added.
            div.fs-14 Choose state:
            CustomCheckbox.mt-4(:radio="true" :value="state === 1" @change="state = 1")
              span.ml-4 First State
            CustomCheckbox.mt-4(:radio="true" :value="state === 2" @change="state = 2")
              span.ml-4 Second State

      Buttons.modal-footer
        Button(type="link-primary" label="Cancel" :disabled="loading" @click="close")
        Button(type="primary" label="Apply" :animate="true" :disabled="loading || parameter === 'null'" @click="save")
      div.modal-loading(v-if="loading")
        Loading(text="Updating creatives" subtext="This will take a moment")
</template>

<script>
import moment from 'moment';

import { parseTrackerInput, validateURL, validatePhone } from '@helpers/Global';

import DSPService from '@master/Services/Cache/DSPService';
import CreativesService from '@master/Services/CreativesService';
import CreativeTraits from '@master/Traits/CreativeTraits';

import Buttons from '@master/UI/Buttons/Buttons';
import Button from '@master/UI/Buttons/Button';
import Modal from '@master/UI/Modal';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect';
import Toggle from '@master/UI/Toggle';
import TagInputLocations from '@master/UI/TagInputLocations';
import Loading from '@master/UI/Loading';
import Input from '@master/UI/Input/Input';
import TextareaWithCounter from '@master/UI/TextareaWithCounter';
import CustomCheckbox from '@master/UI/CustomCheckbox';
import SelectDateRange from '@master/UI/SearchSelect/SelectDateRange.vue';

import TextareaInput from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TextareaInput';
import { CTA_OPTIONS } from '@master/constants';

export default {
  name: 'CreativesBulkActionModal',
  mixins: [Modal, CreativeTraits],

  components: {
    SearchSelect,
    Toggle,
    TagInputLocations,
    Loading,
    TextareaInput,
    Input,
    TextareaWithCounter,
    Button,
    Buttons,
    CustomCheckbox,
    SelectDateRange,
  },

  props: {
    creative_ids: {
      type: Array,
      default: () => null,
    },

    creatives: Array,
  },

  computed: {
    parameters() {
      return {
        [null]: 'Choose parameter',
        name: 'Creative name',
        cta: 'Click Action',
        tracking: 'Tracking',
        dsp: 'Platform (DSP)',
      };
    },

    name_options() {
      return {
        add: 'Add Text',
        replace: 'Replace text',
      };
    },

    add_text_options() {
      return {
        before: 'Before',
        after: 'After',
      };
    },

    global_cta_options() {
      /**
       * @description Since this is bulk edit, expandable options are not available (to x state)
       */
      return {
        ...CTA_OPTIONS,
      };
    },

    global_tracking_options() {
      /**
       * @description Since we allow editing global trackers, then impression and in view are the only options
       */
      return {
        impression: 'Impression',
        creativeView: 'In view',
      };
    },

    cta_output_placeholder() {
      switch (this.cta.action) {
        case 'url':
          return 'Insert click-out URL (landing page URL, click-tracker or click tag)';
        case 'call':
        case 'sms':
          return '+155512345';
        case 'calendar':
          return 'Insert title';
        default:
          return '';
      }
    },

    cta_output_value() {
      if (this.cta.action === 'calendar') {
        return this.cta.title;
      }

      return this.cta.uri;
    },

    example_creative() {
      return this.creatives?.[0] ?? null;
    },

    example_text() {
      const creative = this.example_creative;
      if (!creative) return '';

      const name = creative.name;

      // add text before or after
      if (this.name_option === 'add') {
        if (this.add_text_option === 'before') {
          return `${this.add_text}${name}`;
        }
        return `${name}${this.add_text}`;
      }

      // replace
      if (
        this.name_option === 'replace' &&
        this.find_text !== '' &&
        this.replace_text !== ''
      ) {
        return name.replace(this.find_text, this.replace_text);
      }

      // fallback
      return name;
    },

    has_expandable_creatives() {
      return this.creatives?.some(creative => this.isBinded(creative.type));
    },
  },

  data() {
    return {
      parameter: 'name',
      state: 1,
      append_trackers: false,

      name_option: 'add',
      add_text: '',
      add_text_option: 'before',
      find_text: '',
      replace_text: '',

      dsp: '',
      dsp_options: null,

      cta: { action: 'url', uri: '' },
      impressiontrackers: [{ action: 'impression', uri: '' }],

      loading: false,
    };
  },

  created() {
    DSPService.subscribe(dsps => {
      if (dsps == null) return;

      this.dsp_options = dsps.reduce((options, dsp) => {
        options.push({ value: dsp.slug, label: dsp.title });
        return options;
      }, []);
    }, this);
  },

  mounted() {},

  methods: {
    CTAactionChangeHandler() {
      const keys_to_delete = [
        'uri',
        'sms_body',
        'title',
        'location',
        'description',
        'start',
        'end',
        'dateStart',
        'dateEnd',
        'timezone',
        'focus_closest',
      ];
      // remove all keys when changin action
      for (const key of keys_to_delete) {
        if (this.cta[key] != null) {
          this.$delete(this.cta, key);
        }
      }

      if (this.cta.action === 'url') {
        this.$set(this.cta, 'uri', '');
      } else if (this.cta.action === 'call') {
        this.$set(this.cta, 'uri', '');
      } else if (this.cta.action === 'sms') {
        this.$set(this.cta, 'uri', '');
        this.$set(this.cta, 'sms_body', '');
      } else if (this.cta.action === 'calendar') {
        this.$set(this.cta, 'title', '');
        this.$set(this.cta, 'location', '');
        this.$set(this.cta, 'description', '');
        this.$set(
          this.cta,
          'start',
          moment.utc().add(14, 'days').set('hour', 12).set('minutes', 0).unix(),
        );
        this.$set(
          this.cta,
          'end',
          moment.utc().add(14, 'days').set('hour', 13).set('minutes', 0).unix(),
        );
        this.$set(this.cta, 'timezone', 'UTC');
      } else if (this.cta.action === 'map') {
        this.$set(this.cta, 'uri', []);
        this.$set(this.cta, 'focus_closest', true);
      }
    },

    calendarChangeHandler({ start, end, timezone }) {
      this.$set(this.cta, 'start', start);
      this.$set(this.cta, 'end', end);
      this.$set(this.cta, 'timezone', timezone);
    },

    addImpressionTracker() {
      this.impressiontrackers.push({ action: 'impression', uri: '' });
    },

    removeImpressionTracker(index) {
      if (this.impressiontrackers.length > 1) {
        this.impressiontrackers.splice(index, 1);
      } else {
        this.$set(this.impressiontrackers[index], 'uri', '');
      }
    },

    parseTrackersExcelAndChange(index) {
      const input = this.impressiontrackers?.[index]?.uri;
      if (!input) return;

      const uris = parseTrackerInput(input);
      if (uris.length === 0) return;

      if (uris.length === 1) {
        this.impressiontrackers[index].uri = uris[0];
        return;
      }

      // remove current value
      this.impressiontrackers.splice(index, 1);

      // push new items from parser
      for (const uri of uris) {
        this.impressiontrackers.push({
          action: 'impression',
          uri,
        });
      }
    },

    addLocationHandler(location) {
      if (!Array.isArray(this.cta.uri)) {
        this.$set(this.cta, 'uri', []);
      }
      this.cta.uri.push(location);
      this.$refs['tag_input_locations'].focusInput();
    },

    removeLocation(index) {
      this.cta.uri.splice(index, 1);
    },

    change() {
      if (this.cta.action === 'url' && this.cta.uri !== '') {
        this.cta.uri = validateURL(this.cta.uri);
      }

      if (
        (this.cta.action === 'call' || this.cta.action === 'sms') &&
        this.cta.uri !== ''
      ) {
        this.cta.uri = validatePhone(this.cta.uri);
      }
    },

    generatePayload() {
      let data = {
        creative_ids: this.creative_ids,
      };

      if (this.parameter === 'name') {
        if (this.name_option === 'add') {
          data['name'] = {
            replace: false,
            text: this.add_text,
            before: this.add_text_option === 'before',
          };
        } else if (this.name_option === 'replace') {
          data['name'] = {
            replace: true,
            text: this.find_text,
            replace_text: this.replace_text,
          };
        }
      }

      if (this.parameter === 'cta') {
        data['settings'] = {
          cta: this.cta,
        };
      }

      if (this.parameter === 'tracking') {
        data['settings'] = {
          cta: {
            impressiontracker: this.impressiontrackers,
          },
        };

        data['options'] = {
          state: this.state,
        };

        if (this.append_trackers) {
          data['options']['append'] = this.append_trackers;
        }
      }

      if (this.parameter === 'dsp') {
        data['dsp'] = this.dsp;
      }

      return data;
    },

    save() {
      if (this.loading) return;
      this.loading = true;

      this.$http
        .put(
          `campaigns/${this.$route.params.campaign_id}/creatives/bulk`,
          this.generatePayload(),
        )
        .then(_ => {
          // name and dsp option requires to load new data
          if (['name', 'dsp'].includes(this.parameter)) {
            CreativesService.load();
          }
        })
        .finally(() => {
          this.loading = false;
          this.close();
        });
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
