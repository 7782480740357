<template lang="pug">
div(v-if="$route?.params?.flight_id != null" :class="{[styles.rulesets]: true, 'cursor-pointer': !show_rulesets}" @click="openRulesets")
  div.fw-500 Creative showing rules:

  template(v-if="show_rulesets")
    div(v-for="ruleset in rulesets" :key="ruleset.name" :class="styles.ruleset")
      div {{ ruleset.name }}
      div Priority: {{ ruleset.priority }}%
      div(v-for="(sentence, index) of ruleset.sentences" :key="index") {{ sentence }}

  div.collapse-toggle(:class="styles.toggle" @click.stop="toggleRulesets")
    i.nexd-icon-32-arrow-down-small(aria-hidden="true" :class="{'active': show_rulesets}")
</template>

<script>
import styles from './FlightRulesets.module.scss';

import { clone } from '@helpers/Global';
import { filters } from '@master/Services/AnalyticsAPPService';
import FlightAnalyticsService from '@master/Services/FlightAnalyticsService';

export default {
  name: 'FlightRulesets',

  data() {
    return {
      styles,
      filters,

      show_rulesets: false,
      cloned_filters: null,
      rulesets: {},
    };
  },

  methods: {
    openRulesets() {
      if (!this.show_rulesets) {
        this.show_rulesets = true;
      }
    },

    toggleRulesets() {
      this.show_rulesets = !this.show_rulesets;
    },

    async getRulesets() {
      this.rulesets = await FlightAnalyticsService.getActiveRulesets(filters.flight_condition_key);
    },
  },

  watch: {
    filters: {
      handler() {
        if (this.cloned_filters === null || filters.flight_condition_key !== this.cloned_filters.flight_condition_key) {
          this.getRulesets();
        }
        this.cloned_filters = clone(filters);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
