<template>
  <div id="wrapper">
    <div v-if="creative != null" class="mb-16">
      <div class="row">
        <div class="col-12"></div>
        <div v-if="is_google_ads" class="col-12">
          <div class="googleads-info inline-flex no-gutter-right">
            <img :src="$cdn + 'dist/assets/cm/warning-blue.svg'" alt="warning">
            <div>This creative is exported for Google Ads. Only analytics for ads exported on Nexd are viewable here. Analytics for this creative can be viewed only in the&nbsp;<a href="https://ads.google.com/aw/overview" target="_blank" class="color-cyan-blue text-underline">Google Ads platform.</a></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="has_error" class="text-center">
      <p class="mb-8">Loading analytics failed...</p>
      <Button type="primary" :outline="true" label="Retry" @click="loadAnalytics" />
    </div>
    <div id="cards-list" v-else-if="analytics != null">
      <div class="analytics-layout">
        <section class="analytics-layout-content">
          <SummaryCard :creative="creative" :data="analytics.summary" :has-video="analytics.videoEngagement != null"/>
          <CarbonEmissions :data="carbon_data"/>
          <EventEngagement v-if="creative != null" :data="analytics.eventEngagement" :summary="analytics.summary"/>
          <VideoEngagement v-if="analytics.videoEngagement != null" :data="analytics.videoEngagement" :analytics="analytics"/>
          <PerformanceList :data="analytics.performance"/>
          <EventsList :creative="creative" :data="analytics.events" :list="analytics.eventsList"/>
          <EventsList v-if="admin_toggle && analytics.errors" :creative="creative" :label="'Hidden events list (admin only)'" :data="analytics.errors"/>
        </section>
        <Sidebar />
      </div>
    </div>
  </div>
</template>

<script>
import SummaryCard from '@analytics/Views/Creative/Cards/SummaryCard';
import PerformanceList from '@analytics/Views/Creative/Cards/PerformanceList';
import EventEngagement from '@analytics/Views/Creative/Cards/EventEngagement';
import VideoEngagement from '@analytics/Views/Creative/Cards/VideoEngagement';
import EventsList from '@analytics/Views/Creative/Cards/EventsList';

import AnalyticsService from '@master/Services/AnalyticsService';
import CreativeService from '@master/Services/CreativeService';
import { filters, Controller } from '@master/Services/AnalyticsAPPService';
import { DSP } from '@master/constants';
import CreativeTraits from '@master/Traits/CreativeTraits';

import { clone } from '@helpers/Global';

import SummaryInterface from '@analytics/Interfaces/SummaryInterface';
import PerformanceInterface from '@analytics/Interfaces/PerformanceInterface';
import EventEngagementInterface from '@analytics/Interfaces/EventEngagementInterface';
import EventsInterface from '@analytics/Interfaces/EventsInterface';
import CompareCreativesInterface from '@analytics/Interfaces/CompareCreativesInterface';
import Sidebar from '@analytics/UI/Sidebar/Sidebar';
import CarbonEmissions from '@analytics/UI/CarbonEmissions/CarbonEmissions.vue';

import Button from '@master/UI/Buttons/Button';

const analytics = {
  summary: SummaryInterface,
  performance: PerformanceInterface,
  eventEngagement: EventEngagementInterface,
  videoEngagement: null,
  events: EventsInterface,
  eventsList: {},
  compare: CompareCreativesInterface
};

export default {
  name: 'CreativeView',
  mixins: [CreativeTraits],

  components: {
    SummaryCard,
    PerformanceList,
    EventEngagement,
    VideoEngagement,
    EventsList,
    Sidebar,
    Button,
    CarbonEmissions,
  },

  props: {
    public: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      admin_toggle: false,

      filters,
      creative: null,
      analytics: clone(analytics),
      data: null,
      public_url: null,
      is_loading: false,
      has_error: false,

      // used only to check if filters did change
      cloned_filters: clone(filters),
      carbon_data: null
    };
  },

  computed: {
    is_google_ads() {
      return this.creative.dsp === DSP.GOOGLEADS;
    },

    request_data() {
      let data = {
        base: filters.base,
        startDate: filters.start,
        endDate: filters.end,
        incvtr: filters.incvtr,
        traffic: filters.traffic,
        device: filters.device,
        partner: this.$route.query.partner ?? null
      };

      if (this.$route?.params?.flight_id != null) {
        data['flight_condition_key'] = filters.flight_condition_key;
      }

      return data;
    }
  },

  async created() {
    CreativeService.subscribe(creative => {
      this.creative = creative;

      if (this.$route.query.base === 'viewable' && this.creative != null && this.isVast()) {
        this.$route.query.base = 'impressions';
      }
    }, this);

    this.$user.subscribe(_ => {
      this.admin_toggle = this.$user.adminMode();
    }, this);

    await this.$nextTick();
    this.loadAnalytics();
  },

  methods: {
    loadAnalytics(retry = true) {
      this.analytics = clone(analytics);

      if (this.is_loading === true || filters.start == null || filters.end == null) {
        return;
      }

      this.is_loading = true;
      this.has_error = false;

      let path = null;
      if (this.$route.params.public_id != null && this.$route?.params?.live_id != null) {
        path = `shared/campaigns/${this.$route.params.public_id}/creatives/live/${this.$route?.params?.live_id}`;
      } else if (this.$route?.params?.live_id != null) {
        path = `analytics/creatives/${this.$route?.params?.live_id}`;
      } else if (this.$route.params.public_live_id) {
        path = `shared/creatives/${this.$route.params.public_live_id}`;
      }

      if (path == null) return;

      // create API call
      this.$http.post(path, this.request_data)
        .then(response => {

          // update creative object
          CreativeService.set(response.creative);
          AnalyticsService.setupSidebarPreview();

          // update analytics object
          this.analytics = response.analytics;
          this.carbon_data = response.savetheplanet;

          Controller.setMetaData(response.metadata);

          // hack to remove chart tooltips if they exist
          this.removeChartTooltip();
          this.is_loading = false;
        })
        .catch(error => {
          // cors or timeout
          if (retry) {
            const code = error?.code ?? 500;
            if (code === 0 || code === 504) {
              // retry in 5 sec
              setTimeout(() => {
                this.loadAnalytics(false);
              }, 5000);
            }
          } else {
            this.has_error = true;
          }
        })
        .finally(_ => {
          this.is_loading = false;
        });
    },

    exportCreative(type) {
      if (this.creative_export_disabled === false) {
        this.creative_export_disabled = true;

        let path = null;
        if (this.$route.params.public_id != null && this.$route?.params?.live_id != null) {
          path = `shared/campaigns/${this.$route.params.public_id}/creatives/live/${this.$route.params.live_id}/export/${type}`;
        } else if (this.$route?.params?.live_id != null) {
          path = `analytics/creatives/${this.$route.params.live_id}/export/${type}`;
        } else if (this.$route.params.public_live_id) {
          path = `shared/creatives/${this.$route.params.public_live_id}/export/${type}`;
        }

        if (path == null) return;

        const data = {
          base: filters.base,
          startDate: filters.start,
          endDate: filters.end,
        };
        this.$http.post(path, data).then(() => {
          // enable button on success
          this.creative_export_disabled = false;
        }, () => {
          // enable button on error as well
          this.creative_export_disabled = false;
        });
      }
    },

    removeChartTooltip() {
      const tooltips = document.querySelectorAll('.ct-tooltip');
      for (let tooltip of tooltips) {
        document.body.removeChild(tooltip);
      }
    }
  },

  watch: {
    'filters': {
      handler() {
        if (this.cloned_filters === null ||
          filters.start !== this.cloned_filters.start ||
          filters.end !== this.cloned_filters.end ||
          filters.base !== this.cloned_filters.base ||
          filters.incvtr !== this.cloned_filters.incvtr ||
          filters.traffic !== this.cloned_filters.traffic ||
          filters.device !== this.cloned_filters.device ||
          filters.flight_condition_key !== this.cloned_filters.flight_condition_key
        ) {
          this.loadAnalytics();
        }
        this.cloned_filters = clone(filters);
      },
      deep: true
    }
  }
};
</script>
