<template lang="pug">
img(v-if="src != null" :src="src" :width="32" alt="card")
</template>

<script>
import Filters from '@master/Filters';

export default {
  name: 'CardIcon',

  props: {
    type: String
  },

  data() {
    return {
      src: null
    };
  },

  created() {
    const type = Filters.CCTypeFilter(this.type);

    if (type === 'Mastercard') {
      this.src = this.$cdn + 'dist/assets/payment-icons/mastercard.svg';
    } else if (type === 'Visa') {
      this.src = this.$cdn + 'dist/assets/payment-icons/visa.svg';
    }
  }
};
</script>
