<template lang="pug">
div.quick-navigation
  template(v-for="(link, icon) in links")
    a.quick-navigation-option(
      v-if="link.visible"
      :href="link.href"
      target="_blank"
      @click.stop=""
    )
      i(aria-hidden="true" :class="icon")
      span {{ link.label }}
  router-link.quick-navigation-option(
    v-if="campaign.flight_id != null"
    :to="{name: VIEW.FLIGHT, params: { campaign_id: campaign.campaign_id, flight_id: campaign.flight_id }}"
  )
    i.nexd-icon-32-flights(aria-hidden="true")
    span Flights
</template>

<script>
import { VIEW } from '@master/constants';
import CampaignMixins from '@cm/Views/Campaigns/Campaign/CampaignMixins';

export default {
  name: 'CampaignQuickNavigation',
  mixins: [CampaignMixins],

  props: {
    campaign: {
      type: Object,
      default: () => (null)
    }
  },

  computed: {
    links() {
      return {
        'nexd-icon-32-analytics': {
          visible: this.analytics_url != null && !this.free_or_basic_plan_user,
          href: this.analytics_url,
          label: 'Analytics',
          ga_key: 'analytics_quick'
        },
        'nexd-icon-32-preview': {
          visible: this.has_creatives,
          href: this.campaign.preview_url,
          label: 'Preview',
          ga_key: 'preview_quick'
        }
      };
    }
  },

  data() {
    return {
      VIEW
    };
  }
};
</script>
