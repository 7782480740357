<template lang="pug">
div(:class="styles.slider")
  div.color-primary.fw-500 {{ this.value }} million
  div.color-primary.fw-500 {{ formatted_price }} / mo

  section(:class="styles.input")
    div(:class="styles.background" :style="{ '--gap': `${gap}%` }")
      span(v-for="(value, index) in values" v-if="index > 0" :key="index" :class="styles.tick" :style="{ '--gap': `${index * gap}%` }")
    div(:class="styles.bubble" :style="{ '--percentage': `${percentage}%` }")
    input(type="range" :min="min" :max="max" :step="step" v-model="slider_value" @input="sliderHandler" @change="onChange")

  div.color-primary.fs-14 1M
  div.color-primary.fs-14 100M+
</template>

<script>
import styles from './ImpressionSlider.module.scss';

import { formatPrice } from '@cm/Views/Billing/helpers';

export default {
  name: 'ImpressionSlider',

  props: {
    impressions: {
      type: Number,
      default: 1000000,
    },

    currency: {
      type: String,
      default: null,
    },

    cpm_price: {
      type: Number,
      default: 0.4,
    },
  },

  computed: {
    formatted_price() {
      return formatPrice(this.value * this.cpm_price * 1000, {
        currency: this.currency,
      });
    },
  },

  data() {
    const values = [0.5, 1, 5, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100];

    return {
      styles,

      values,
      min: 0,
      max: values.length,
      step: 1,

      slider_value: 1,
      value: 1,

      percentage: 0,
      gap: 0,

      million: 1000000,
    };
  },

  created() {
    this.gap = this.calculateBubblePosition(1);
  },

  mounted() {
    this.sliderHandler();
  },

  methods: {
    onChange() {
      this.$emit('change', this.getImpressionsAmount());
    },

    sliderHandler() {
      const slider_value = parseFloat(this.slider_value);

      this.percentage = this.calculateBubblePosition(slider_value);
      this.value = this.values[slider_value];

      this.$emit('input', this.getImpressionsAmount());
    },

    calculateBubblePosition(position) {
      return Math.round(((100 * (position - this.min)) / (this.max - this.min)) * 100) / 100;
    },

    getImpressionsAmount() {
      return this.value * this.million;
    },
  },

  watch: {
    impressions: {
      handler() {
        this.slider_value = this.values.indexOf(this.impressions / this.million);
        this.sliderHandler();
      },
      immediate: true,
    },
  },
};
</script>
