<template lang="pug">
div#app-wrapper
  CreativesNavbar(
    v-if="!creative_not_found"
    :creatives="[creative]"
    :selection="selection"
    :creatives_view="false"
    :full_width="false"
    @save="save"
  )

  div(v-content)
    CampaignCreativeNotFound(v-if="creative_not_found" type="creative")
    Loading(v-else-if="creative == null")

    template(v-else)
      StickyHeader(v-if="!is_sdk_user" :wide="true")
        Breadcrumb(:items="component_breadcrumbs")

      AssetLibrary(
        v-if="isCMCreative()"
        :creative="creative"
        :disabled="live_creative_edit_disabled || isHTML5(active_creative.type)"
        @overwrite="save"
      )

      //- this is creative base (name|size,type,id|export_details)
      //- naming is awkward but since the same component is used when creating new creative then naming it to CreativeBase would be awkward too.
      CreativeBase(
        ref="creative-base"
        :creative="creative"
        :loading="loading"
        :active_import="active_import"
        @save="save"
        @delete="deleteHandler"
        @rerender="rerenderComponents"
        @changeActiveCreative="changeActiveCreative"
        @loadTemplate="loadTemplate"
        @closeQuantumSettings="closeFloatingSettings"
        @reloadAssetsLibrary="reloadAssetsLibrary"
        @showImport="showImport"
        @hideImport="hideImport"
        @showSocial="showSocial"
      )
        CardDivider(
          v-if="(hasLORT(active_creative) || isHTML5(active_creative.type)) && !social_widget && !active_import"
        )

        HTML5Import(
          v-if="active_import === IMPORT.HTML5"
          :replace="true"
          :creative="active_creative"
          :lightbox="isLightbox()"
          @onCreate="importReplaceHandler"
          @onClose="hideImport"
        )
        VASTImport(
          v-if="active_import === IMPORT.VAST"
          :replace="creative"
          @onCreate="importReplaceHandler"
          @onRemove="hideImport"
        )

        div.mt-16.mb-16(v-else-if="loading_template")
          Loading
        template(v-else-if="!social_widget && !active_import")

          //- quantum size selection
          template(v-if="isQuantum() && hasLORTLoaded()")
            QuantumSizeSelection(
              :creative="creative"
              @closeFloatingSettings="closeFloatingSettings"
            )
            CardDivider

          //- navigation bar (assets|extras|options|cta|tracking)
          SettingsTab(
            v-if="hasLORT(active_creative) || isHTML5(active_creative.type)"
            :creative="active_creative"
            :parent="creative"
            :isExpanded="show_expanded_layout"
            :responsive_settings_closed="responsive_settings_closed"
            :loading="loading"
            @loading="$refs.settings != null && $refs.settings.isLoading()"
            @closeResponsiveSettings="closeFloatingSettings"
            @openResponsiveSettings="openFloatingSettings"
          )

          PreviewHTML5(
            v-if="isHTML5(active_creative.type) && !is_cta_tab_open && !is_tracking_tab_open"
            ref="preview"
            :device="creative.settings?.preview_device"
            :creative="active_creative"
            @versionchange="handleVersionChange"
            @openOptimizerModal="openOptimizerModal(active_creative)"
          )

          PreviewCustom(
            v-else-if="isDevtoolCreative() && !is_cta_tab_open && !is_tracking_tab_open"
            :creative="creative"
            @versionchange="handleVersionChange"
          )

          //- cta and tracking tab (full width)
          CTATab.visible.pl-24.pr-24(
            v-else-if="(!isQuantum() || (isQuantum() && $store.get('quantum_state') !== 'overview')) && $store.get('active_tab') === 'cta'"
            :key="$store.get('quantum_state')"
            :creative="active_creative"
            :parent="creative"
            :isExpanded="show_expanded_layout"
          )
          TrackingTab.visible.pl-24.pr-24(
            v-else-if="(!isQuantum() || (isQuantum() && $store.get('quantum_state') !== 'overview')) && $store.get('active_tab') === 'tracking'"
            :key="$store.get('quantum_state')"
            :creative="active_creative"
            :parent="creative"
            :isExpanded="show_expanded_layout"
          )

          //- two column layout with preview and creative's details (assets|extras|options)
          div.pl-16.pr-16(v-else-if="hasLORT(active_creative)")
            div.row

              div(:class="[$store.get('quantum_state') === 'overview' && isQuantum() ? 'col-24' : 'col-12']")
                //- show quantum and its floating container with (assets|extras|options|cta|tracking)
                div.px-8.creative-preview(v-if="!loading_template && isQuantum()")
                  PreviewQuantumTemplate(
                    ref="preview"
                    :creative="creative"
                    :sizeset="$store.get('quantum_state') === 'overview' ? null : $store.get('quantum_state')"
                    @versionchange="handleVersionChange"
                  )
                  div.floating-settings(
                    v-if="$store.get('quantum_state') === 'overview' && hasLORT()"
                    :class="[responsive_settings_closed ? 'closed' : 'open', is_cta_or_tracking_tab_open ? 'floating-settings-wide' : '']"
                  )
                    Button.floating-settings-toggle(
                      type="primary"
                      :outline="true"
                      :label="responsive_settings_closed ? 'Assets & Options' : null"
                      :large="responsive_settings_closed"
                      @click="toggleFloatingSettings"
                    )
                      template(v-slot:prefix)
                        i.nexd-icon-16-arrow-right-small(aria-hidden="true" :class="{'active': responsive_settings_closed}")
                    Settings(
                      ref="settings"
                      :creative="creative"
                      :parent="creative"
                      :floating="true"
                      :show_expanded_layout="show_expanded_layout"
                      @change="save"
                      @assetsChange="assetsChangeHandler"
                      @DCOChange="DCOAssetChangeHandler"
                    )

                //- regular preview component
                PreviewTemplate(
                  v-else-if="!loading_template"
                  ref="preview"
                  :key="settings_key"
                  :device="creative.settings?.preview_device"
                  :creative="active_creative"
                  :isExpanded="show_expanded_layout"
                  @versionchange="handleVersionChange"
                  @openOptimizerModal="openOptimizerModal(creative)"
                )

              div.col-12(v-if="!isQuantum() || (isQuantum() && $store.get('quantum_state') !== 'overview')")
                //- template and layout settings
                Settings(
                  v-if="hasTemplateLoaded(active_creative)"
                  ref="settings"
                  :key="settings_key"
                  :sizeset="isQuantum() ? $store.get('quantum_state') : null"
                  :creative="active_creative"
                  :parent="creative"
                  :isVast="isVast(creative)"
                  :isVpaid="isVpaid(creative)"
                  :dcoFeed="[]"
                  :show_expanded_layout="show_expanded_layout"
                  @change="additionalAssetChangeHandler"
                  @assetsChange="assetsChangeHandler"
                  @DCOChange="DCOAssetChangeHandler"
                )
                ModularSettings(
                  v-else-if="hasLayoutLoaded(active_creative)"
                  ref="settings"
                  :key="settings_key"
                  :creative="active_creative"
                  :loading="loading"
                  @change="additionalAssetChangeHandler"
                  @assetsChange="assetsChangeHandler"
                )

          //- creative footer with buttons (save|open_preview|update_live|open_devtool)
          div.p-24(v-if="hasLORT(active_creative) || isHTML5(active_creative.type)")
            CreativeFooter.mt-16(
              :creative="creative"
              :loading="loading"
              @save="save"
            )

          CreativeDisabledOverlay(
            :creative="creative"
            :live_creative_edit_disabled="live_creative_edit_disabled"
            @enableLiveEditing="enableLiveEditing"
          )

  //- modals
  CreativeOptimizerModal(
    v-if="active_optimizer_creative != null"
    :creative="active_optimizer_creative"
    @save="handleCreativeOptimizerChanges"
    @close="closeCreativeOptimizerModal"
  )
</template>

<script>
import { clone } from '@helpers/Global';
import Selection from '@libs/Selection';

import CreativesService from '@master/Services/CreativesService';
import SaveService from '@master/Services/SaveService';
import CreativeSizeService from '@master/Services/CreativeSizeService';
import TemplatesService from '@master/Services/TemplatesService';
import RequestLimiter from '@libs/RequestLimiter';
import UserNotificationService from '@master/Services/Cache/UserNotificationService';
import AssetsLibraryService from '@master/Services/AssetsLibraryService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import CreativeMixins from '@cm/Views/Creatives/Creative/CreativeMixins.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import Button from '@master/UI/Buttons/Button.vue';
import CardDivider from '@master/UI/Divider/CardDivider.vue';
import Loading from '@master/UI/Loading.vue';
import CampaignCreativeNotFound from '@master/UI/CampaignCreativeNotFound.vue';
import Breadcrumb from '@cm/UI/Global/Breadcrumb.vue';
import StickyHeader from '@master/UI/StickyHeader/StickyHeader.vue';

import CreativesNavbar from '@cm/Views/Creatives/Components/CreativesNavbar.vue';
import AssetLibrary from '@cm/Views/Creatives/Cards/Components/Asset/AssetLibrary/AssetLibrary.vue';
import CreativeBase from '@cm/Views/Creatives/Creative/CreativeBase.vue';
import CreativeDisabledOverlay from '@cm/Views/Creatives/Creative/CreativeDisabledOverlay.vue';
import QuantumSizeSelection from '@cm/Views/Creatives/Creative/QuantumSizeSelection.vue';
import CreativeFooter from '@cm/Views/Creatives/Creative/CreativeFooter.vue';
import SettingsTab from '@cm/Views/Creatives/Cards/Components/TemplateSettings/SettingsTab.vue';
import Settings from '@cm/Views/Creatives/Cards/Components/Settings.vue';
import ModularSettings from '@cm/Views/Creatives/Cards/Components/ModularSettings.vue';
import TrackingTab from '@cm/Views/Creatives/Cards/Components/Tracking/TrackingTab.vue';
import CTATab from '@cm/Views/Creatives/Cards/Components/Tracking/CTATab.vue';

import PreviewHTML5 from '@cm/Views/Creatives/Cards/Components/PreviewHTML5.vue';
import PreviewCustom from '@cm/Views/Creatives/Cards/Components/PreviewCustom.vue';
import PreviewTemplate from '@cm/Views/Creatives/Cards/Components/PreviewTemplate.vue';
import PreviewQuantumTemplate from '@cm/Views/Creatives/Cards/Components/PreviewQuantumTemplate.vue';

import CreativeOptimizerModal from '@cm/Views/Creatives/Modals/CreativeOptimizerModal.vue';

import HTML5Import from '@cm/Views/Creatives/Cards/HTML5Import.vue';
import VASTImport from '@cm/Views/Creatives/Cards/VASTImport.vue';

import { IMPORT, LISTITEM } from '@master/constants';

export default {
  name: 'Creative',
  mixins: [CreativeTraits, CreativeMixins, SDKMixin],
  components: {
    CardDivider,
    Loading,
    Breadcrumb,
    CreativesNavbar,
    AssetLibrary,
    CreativeBase,
    CreativeDisabledOverlay,
    QuantumSizeSelection,
    CreativeFooter,
    SettingsTab,
    Settings,
    ModularSettings,
    TrackingTab,
    CTATab,
    PreviewHTML5,
    PreviewCustom,
    PreviewTemplate,
    PreviewQuantumTemplate,
    CreativeOptimizerModal,
    HTML5Import,
    VASTImport,
    CampaignCreativeNotFound,
    Button,
    StickyHeader,
  },

  computed: {
    folders() {
      if (!this.items) return this.items;
      return this.items.filter(item => item.item_type === LISTITEM.FOLDER);
    },

    creatives() {
      if (!this.items) return this.items;
      return this.items.filter(item => item.item_type === LISTITEM.CREATIVE);
    },

    component_breadcrumbs() {
      // make sure breadcurmbs are loaded
      if (this.breadcrumbs == null) return [];

      // modify breadcrumbs from the
      const breadcrumbs = clone(this.breadcrumbs);
      if (breadcrumbs.length > 0) {
        const last = breadcrumbs[breadcrumbs.length - 1];
        last.active = false;
        last.url = `/c/${this.creative.campaign_id}`;
        if (this.creative.folder_id) {
          last.url += `/f/${this.creative.folder_id}`;
        }
      }

      breadcrumbs.push({
        url: null,
        label: this.creative.name,
        active: true,
      });
      return breadcrumbs;
    },

    live_creative_edit_disabled() {
      switch (true) {
        case this.is_sdk_user:
        case !this.isCMCreative():
        case this.isDraft():
        case this.isArchived():
        case this.creative.update_required:
          return false;
        default:
          return this.update_live == null || !this.update_live;
      }
    },

    is_cta_tab_open() {
      return this.$store.get('active_tab') === 'cta';
    },

    is_tracking_tab_open() {
      return this.$store.get('active_tab') === 'tracking';
    },

    is_cta_or_tracking_tab_open() {
      return this.is_cta_tab_open || this.is_tracking_tab_open;
    },

    settings_key() {
      return [this.$store.get('quantum_state'), this.show_expanded_layout, this.rerender].join('_');
    },

    active_creative() {
      return this.show_expanded_layout && this.creative?.expanded != null ? this.creative.expanded : this.creative;
    },

    disable_create() {
      return this.creative.width === 0 || this.creative.height === 0 || this.size_warning;
    },
  },

  data() {
    return {
      IMPORT,

      user: null,
      creative: null,
      campaign: null,
      items: null,
      creative_not_found: false,
      loading: false,
      loading_template: true,
      update_live: false,
      active_optimizer_creative: null,
      rerender: false, // used to rerender components when social re-imports
      show_expanded_layout: false,
      responsive_settings_closed: true,
      original_assets: {},
      active_import: null,
      social_widget: false,
      breadcrumbs: null,
      is_processing: false,

      selection: new Selection(),
    };
  },

  created() {
    TemplatesService.load();

    this.$store.reset('active_tab');
    this.$store.reset('quantum_state');

    this.fetchCreative();

    this.$user.subscribe(user => {
      this.user = user;
    });

    CreativesService.reset();
    CreativesService.load();
    CreativesService.subscribe(state => {
      if (!state?.campaign) return;

      this.breadcrumbs = state?.breadcrumbs;
      this.campaign = state.campaign;
      this.items = state?.items;

      // only trigger sidebar setup if creative is loaded
      if (this.creative) {
        this.setupSidebar();
      }
    }, this);
  },

  methods: {
    fetchCreative() {
      if (this.is_processing) return;

      if (this.creative_not_found) {
        this.creative_not_found = false;
      }

      this.$http
        .get(`creatives/${this.$route.params.creative_id}`, {
          notification: false,
        })
        .then(creative => {
          this.creative = creative;
          this.update_live = creative.update_required;

          if (creative?.updated_on != null) {
            SaveService.creative.setTimestamp(creative.updated_on);
          }

          if (creative?.settings?.cta?.assets != null) {
            this.original_assets = clone(creative.settings.cta.assets);
          }

          this.loading_template = false;
          this.checkProcessing();

          if (this.creative.expanded) {
            this.checkProcessing(this.creative.expanded);
          }

          this.selection.data = [this.creative];
          this.selection.select({ item_id: this.creative.creative_id });
        })
        .catch(_ => {
          this.creative_not_found = true;
        })
        .finally(() => {
          // only trigger sidebar setup if campaign, breadcrumbs etc is loaded
          if (this.campaign) {
            this.setupSidebar();
          }
        });
    },

    /**
     * @param {Object} creative can be creative or its expanded state
     * @param {int} cycle track recursive cycles when retry is needed
     */
    checkProcessing(creative = this.creative, cycle = 0) {
      if (!this.isProcessing(creative.status)) return;
      this.is_processing = true;

      // if processing, check for new status
      const process_key = this.isDuplicationInProgress(creative.status) ? 'duplicate' : 'import';
      this.$http
        .get(`public/processing/${process_key}/${creative.creative_id}`, {
          notification: false,
        })
        .then(_ => {
          this.is_processing = false;
          this.fetchCreative();
        })
        .catch(({ code }) => {
          // retry on 408
          if (code === 408) {
            // should retry up to 10 minutes (1min per async wait cycle)
            if (cycle < 10) {
              this.checkProcessing(creative, ++cycle);
            }
          }
        })
        .finally(_ => {
          // reload library to show any added assets in the async process
          this.reloadAssetsLibrary();

          if (process_key === 'import') {
            UserNotificationService.load();
          }
        });
    },

    deleteHandler() {
      const breadcrumb = this.component_breadcrumbs[this.component_breadcrumbs.length - 2];
      this.$router.push(breadcrumb.url);
    },

    setupSidebar() {
      if (!this.$sidebar) return;
      this.$sidebar.reset();

      // 404
      if (this.campaign == null || this.creative == null) {
        this.$sidebar.setBack('My Campaigns', '/');
        return;
      }

      this.$sidebar.setAnalytics(this.creative?.analytics_url ?? false);
      this.$sidebar.setPreview(this.creative?.preview_url ?? false);
      this.$sidebar.setFolders(this.folders ?? null);

      if (!this.creatives?.length || !this.component_breadcrumbs.length) {
        this.$sidebar.setBack(this.campaign.name, `/c/${this.campaign.campaign_id}`);
        return;
      }

      const breadcrumb = this.component_breadcrumbs[this.component_breadcrumbs.length - 2];

      this.$sidebar.setBack(breadcrumb.label, breadcrumb.url);
      this.$sidebar.setCreatives(this.creatives ?? null, breadcrumb.url);
    },

    refreshCreativePacksize() {
      this.$http.get(`creatives/${this.creative.creative_id}/packsize`).then(response => {
        this.$set(this.creative, 'packsize', response);
      });
    },

    handleVersionChange() {
      this.refreshCreativePacksize();
    },

    save(timeout = 1500) {
      if (this.disable_save) return;

      this.$set(this.creative, 'name', this.creative.name.replace(/<[^>]+>/g, ''));

      if (this.$refs?.settings?.isLoading()) {
        this.$alert('Please wait until all assets are ready.', 'Assets are still processed');
        return;
      }

      if (!CreativeSizeService.validate(this.creative)) return;

      RequestLimiter.hook(
        'creative_save',
        () => {
          const path = `v2/creative/${this.creative.creative_id}`;

          // show notification if timeout is 0 (prolly save button pressed)
          const notification = timeout === 0;
          this.loading = true;

          const obj = {
            name: this.creative.name,
            dsp: this.creative.dsp,
            width: this.creative.width,
            height: this.creative.height,
          };

          this.$http.put(path, obj, { notification }, 'creative_save').finally(() => {
            this.loading = false;
          });
        },
        timeout,
      );
    },

    openOptimizerModal(creative) {
      this.active_optimizer_creative = creative;
    },

    closeCreativeOptimizerModal() {
      this.active_optimizer_creative = null;
    },

    handleCreativeOptimizerChanges(quality_lvl, assets = {}) {
      // Save new quality for creative and total load size as well
      this.creative.quality_lvl = quality_lvl || this.creative.quality_lvl;
      for (const asset_id in assets) {
        const target_assets = asset_id.startsWith('custom_') ? this.creative.additional_assets : this.creative.assets;
        const target_asset = target_assets[asset_id] || null;
        if (target_asset != null) {
          this.$set(target_asset, 'filesize', assets[asset_id].filesize);
          this.$set(target_asset, 'optimized', assets[asset_id].optimized);
        }
      }

      this.closeCreativeOptimizerModal();
    },

    rerenderComponents() {
      this.rerender = !this.rerender;
    },

    additionalAssetChangeHandler() {
      this.reloadAssetsLibrary();
    },

    reloadAssetsLibrary() {
      if (this.creative.assets_library_id != null) {
        AssetsLibraryService.load();
      }
    },

    assetsChangeHandler() {
      // check the length difference of the cta assets obj
      const original_size = Object.keys(this.original_assets).length;
      const new_size = Object.keys(this.creative.settings.cta.assets).length;

      // if the previous (original) list is bigger than the current list, check if we should request new tag
      if (original_size > new_size) {
        for (const key in this.original_assets) {
          if (this.creative.settings.cta.assets[key] == null) {
            const asset = this.original_assets[key];
            // if the asset cta objc had impressiontracker or uri, request new tag
            if (asset.uri.length !== 0 || (asset.fallback && asset.fallback.length !== 0) || asset.impressiontracker.length !== 0) {
              CreativesService.export.requestTagUpdate(this.creative.status);
              break;
            }
          }
        }
      }

      // clone new original obj
      this.original_assets = clone(this.creative.settings.cta.assets);

      this.reloadAssetsLibrary();
    },

    DCOAssetChangeHandler() {},

    toggleFloatingSettings() {
      this.responsive_settings_closed = !this.responsive_settings_closed;
    },

    closeFloatingSettings() {
      this.responsive_settings_closed = true;
    },

    openFloatingSettings() {
      this.responsive_settings_closed = false;
    },

    changeActiveCreative(value) {
      this.show_expanded_layout = value;
      this.hideImport();
    },

    enableLiveEditing() {
      this.update_live = true;
    },

    loadTemplate(load = true) {
      this.loading_template = load;
    },

    importReplaceHandler(_creative) {
      this.hideImport();

      // even if active creative changes when edpanded state got chagned
      // try finding the matching creative with its id
      const creatives = [this.creative, this.creative?.expanded];
      const creative = creatives.find(c => c?.creative_id === _creative.creative_id);
      if (!creative) return;

      for (const key in _creative) {
        this.$set(creative, key, _creative[key]);
      }

      this.checkProcessing(creative);
    },

    showImport(key) {
      this.active_import = key;
    },

    hideImport() {
      this.active_import = null;
    },

    showSocial(value) {
      this.social_widget = value;
    },
  },
};
</script>
