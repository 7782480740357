<template lang="pug">
div(v-if="$parent.published === true && ($parent.can_download_tag || $parent.can_download_zip || $parent.can_copy_xml_link)")
  template(v-if="$parent.can_download_zip")
    i.nexd-icon-32-download-tag(aria-hidden="true" v-tooltip="{value: 'Download Zip', appendTo: 'self'}" @click.stop="downloadZip")
  template(v-if="$parent.can_download_tag")
    i.nexd-icon-32-copy-to-clipboard(aria-hidden="true" v-tooltip="{value: 'Copy Tag To Clipboard', appendTo: 'self'}" @click.stop="copyTagToClipboard")
    DownloadTagsButton(:creative="creative")
  template(v-if="$parent.can_copy_xml_link")
    i.nexd-icon-32-share( aria-hidden="true" v-tooltip="{value: 'Copy XML Link', appendTo: 'self'}" @click="copyXmlLink")
</template>

<script>
import DownloadTagsButton from '@cm/Views/Creatives/Cards/Components/DownloadTagsButton';
import { getFileContents, setClipboard } from '@helpers/Global';

export default {
  name: 'CollapsedHeaderPublishing',

  components: {
    DownloadTagsButton,
  },

  props: {
    creative: Object,
  },

  data() {
    return {
      tag: null,
      xml: null,
    };
  },

  methods: {
    downloadZip() {
      this.$parent.$emit('publishModalShowLoading');
      const path = 'campaigns/' + this.creative.campaign_id + '/creatives/zip/' + this.creative.creative_id;
      this.$http
        .get(path)
        .then(() => {
          this.$parent.$emit('publishModalHideLoading');
        })
        .catch(() => {
          this.$notifications.add('error', 'Could not download creative ZIP');
          this.$parent.$emit('publishModalHideLoading');
        });
    },

    copyXmlLink() {
      if (this.creative.xml != null) {
        this.xml = this.creative.xml;
      }

      if (this.xml != null) {
        return this.copyUrl(this.xml);
      }

      this.$http.get(`creative/${this.creative.creative_id}/xml`).then(response => {
        this.xml = response.xml;
        this.copyUrl(this.xml);
      });
    },

    copyUrl(url) {
      setClipboard(url)
        .then(_ => {
          this.$notifications.add('primary', 'Preview URL copied to clipboard');
        })
        .catch(error => {
          this.$notifications.add('warning', error);
        });
    },

    copyTagToClipboard() {
      if (this.tag != null) {
        return this.notify(this.tag);
      }

      const path = `creative/${this.creative.creative_id}/tags`;
      this.$http.get(path).then(response => {
        if (response.length > 0) {
          this.tag = getFileContents(response[0]);
          this.notify(this.tag);
        }
      });
    },

    notify(value) {
      setClipboard(value)
        .then(_ => {
          this.$notifications.add('primary', 'Tag copied to clipboard');
        })
        .catch(error => {
          this.$notifications.add('warning', error);
        });
    },
  },
};
</script>
