class Cup {
  constructor(svg) {
    this.svg = svg;
    this.cup = this.svg.querySelector('#cup-vertical');
    this.spilled = this.svg.querySelector('#cup-horizontal');
    this.water = this.svg.querySelector('#water');
    this.onSpill = null;
    this.init();
  }
  init() {
    this.cup.classList.add('transition');
    this.spilled.classList.add('transition');
    this.water.classList.add('transition');
    this.cup.classList.add('medium');
    this.spilled.classList.add('medium');
    this.water.classList.add('medium');
    this.spilled.classList.add('hidden');
    this.water.classList.add('hidden');
    this.spilled.removeAttribute('opacity');
    this.water.removeAttribute('opacity');

    this.cup.classList.add('clickable');
    this.hoverHandler = this.spill.bind(this);
    this.cup.addEventListener('mouseover', this.hoverHandler);
  }
  spill() {
    this.cup.removeEventListener('mouseover', this.hoverHandler);
    this.cup.classList.add('hidden');
    this.spilled.classList.remove('hidden');
    setTimeout(() => {
      this.cup.parentNode.removeChild(this.cup);
      this.water.classList.remove('hidden');
      if (this.onSpill !== null) {
        this.onSpill();
      }
    }, 1000);
  }
}

export default Cup;
