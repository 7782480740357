import AnalyticsService from '@master/Services/AnalyticsService';
import AssetsLibraryService from '@master/Services/AssetsLibraryService';
import CampaignService from '@master/Services/CampaignService';
import CreativeService from '@master/Services/CreativeService';
import CreativesService from '@master/Services/CreativesService';
import FlightService from '@master/Services/FlightService';
import FolderService from '@master/Services/FolderService';
import GroupAssetsLibraryService from '@master/Services/GroupAssetsLibraryService';
import LayoutsSettingsService from '@master/Services/LayoutsSettingsService';
import ModulesService from '@master/Services/Cache/ModulesService';
import SearchService from '@master/Services/SearchService';
import TemplatesService from '@master/Services/TemplatesService';
import TemplatesSettingsService from '@master/Services/TemplatesSettingsService';
import UIGroupsService from '@master/Services/Cache/UIGroupsService';

export default {
  install: (Vue, router) => {
    AnalyticsService.init(Vue, router);
    AssetsLibraryService.init(Vue, router);
    CampaignService.init(Vue, router);
    CreativeService.init(Vue, router);
    CreativesService.init(Vue, router);
    FlightService.init(Vue, router);
    FolderService.init(Vue, router);
    GroupAssetsLibraryService.init(Vue, router);
    LayoutsSettingsService.init(Vue, router);
    ModulesService.init(Vue, router);
    SearchService.init(Vue, router);
    TemplatesService.init(Vue, router);
    TemplatesSettingsService.init(Vue, router);
    UIGroupsService.init(Vue, router);
  },
};
