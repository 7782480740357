<template lang="pug">
div.flex.column-gap-32.px-24
  Button(
    type="quantum"
    label="Global Overview"
    :active="$store.get('quantum_state') === 'overview'"
    :large="true"
    @click="changeQuantumState('overview')"
  )

  Buttons.flex-grow.column-gap-32(align="left")
    Button(
      v-if="creative?.settings?.quantum?.unique != null"
      v-for="(sizeset, index) of creative.settings.quantum.unique"
      :key="index"
      type="quantum"
      :label="sizeset"
      :active="$store.get('quantum_state') === sizeset"
      :large="true"
      style="overflow:visible;"
      @click="changeQuantumState(sizeset)"
    )
      RemoveIcon(
        v-if="$store.get('quantum_state') === sizeset && creative.settings.quantum.custom.indexOf(sizeset) !== -1"
        type="pill"
        @click="removeCustomQuantumSizeset(sizeset)"
      )

    div.relative
      Button.px-16(
        type="link"
        :active="show_custom_responsive_popup"
        :tooltip={value: 'Add a new custom size'}
        @click="toggleCustomResponsivePopup"
      )
        template(v-slot:prefix)
          IconAdd

      CustomResponsiveAddPopup(v-if="show_custom_responsive_popup" @add="addCustomQuantumSizeset" @close="closeCustomResponsivePopup")
</template>

<script>
import CreativesService from '@master/Services/CreativesService';

import Button from '@master/UI/Buttons/Button';
import Buttons from '@master/UI/Buttons/Buttons';
import IconAdd from '@master/UI/Buttons/Icons/IconAdd';
import RemoveIcon from '@master/UI/RemoveIcon';

import CustomResponsiveAddPopup from '@cm/Views/Creatives/Cards/Components/CustomResponsiveAddPopup';

export default {
  name: 'QuantumSizeSelection',

  components: {
    Button,
    Buttons,
    CustomResponsiveAddPopup,
    IconAdd,
    RemoveIcon
  },

  props: {
    creative: {
      type: Object,
      default: () => (null)
    }
  },

  computed: {
    unique_quantum_length() {
      return this.creative.settings.quantum.unique.length;
    }
  },

  data() {
    return {
      show_custom_responsive_popup: false
    };
  },

  methods: {
    changeQuantumState(state = 'overview') {
      this.$store.set('quantum_state', state);

      if (state === 'overview') {
        this.$emit('closeFloatingSettings');
      }
    },

    async removeCustomQuantumSizeset(sizeset) {
      const index = this.creative.settings.quantum.custom.indexOf(sizeset);

      if (index === -1) return;

      if (!await this.$confirm(`Are you sure you want to remove placement size ${sizeset}?`)) return;

      this.changeQuantumState('overview');
      this.creative.settings.quantum.custom.splice(index, 1);

      CreativesService.export.requestTagUpdate(this.creative.status);
      this.save();

      const unique_index = this.creative.settings.quantum.unique.indexOf(sizeset);
      if (unique_index !== -1) {
        this.creative.settings.quantum.unique.splice(unique_index, 1);
      }
    },

    toggleCustomResponsivePopup() {
      this.show_custom_responsive_popup = !this.show_custom_responsive_popup;
    },

    closeCustomResponsivePopup() {
      this.show_custom_responsive_popup = false;
    },

    addCustomQuantumSizeset(sizeset) {
      if (this.creative?.settings?.quantum?.custom == null) return;

      if (this.creative.settings.quantum.unique.includes(sizeset)) {
        return this.$alert('This size is already available. Please use existing one.', 'New custom size is not added');
      }

      this.creative.settings.quantum.custom.push(sizeset);

      CreativesService.export.requestTagUpdate(this.creative.status);
      this.save();

      if (this.$store.get('quantum_state') !== 'overview') {
        this.changeQuantumState(sizeset);
      }

      return this.closeCustomResponsivePopup();
    },

    save() {
      this.$http.put(`creative/${this.creative.creative_id}/settings/quantum`, {'value': this.creative.settings.quantum.custom})
        .then(response => {
          this.$set(this.creative.settings, 'quantum', response.quantum);
        });
    }
  },

};
</script>
