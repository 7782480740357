<template lang="pug">
div.bg-gray-100
  div.py-8.px-12.fs-14.cursor-pointer(@click="toggle") Toggle debug

  div.mt-16(v-if="show_debug_info")
    div.py-8.px-12(v-if="uploaded")
      div Assets
      a(:href="`${uploaded.uri}?v=${uploaded.version}`" target="_blank") Cropped
      span &nbsp;|&nbsp;
      a(:href="`${uploaded.original_url}?v=${uploaded.version}`" target="_blank") Original
    pre.px-12 Asset ID: {{asset_id || asset._fullpath || asset.slot_id || asset.asset_id}}
    pre.px-12 Asset size: {{asset.width}} x {{asset.height}}
    pre.px-12 Recommended info: {{recommended}}
    pre.px-12 Cropper options: {{options}}
</template>

<script>
export default {
  name: 'AssetDebug',
  props: {
    asset: Object,
    recommended: Object,
    options: Object,
    uploaded: {
      type: Object,
      default: null,
    },
    asset_id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show_debug_info: false,
    };
  },
  methods: {
    toggle() {
      this.show_debug_info = !this.show_debug_info;
    },
  },
};
</script>
