<template lang="pug">
div.campaign-card-icons
  i.nexd-icon-32-delete(@click="deleteAsset" aria-hidden="true")
</template>

<script>
import GroupAssetsLibraryService from '@master/Services/GroupAssetsLibraryService';

export default {
  name: 'AssetSiderbarIcons',

  props: {
    asset: Object
  },

  methods: {
    async deleteAsset() {
      if (await this.$confirm('Are you sure you want to delete this asset?')) {

        const path = `assets/library/${this.asset.library_id}/${this.asset.original_asset_id}`;
        this.$http.delete(path)
          .then(({original}) => {
            GroupAssetsLibraryService.removeItems([original]);
          });
      }
    }
  }
};
</script>
