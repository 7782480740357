<template lang="pug">
div(:class="[styles.card, styles.card_double]")
  section(:class="styles.header")
    span(:class="styles.label") Top layouts
    SearchSelect(v-model="option" :options="options" :search="false" :disabled="layouts == null" @change="changeOption" width="9rem")

  div.flex.flex-direction-column.row-gap-8.mt-16
    Loading(v-if="layouts == null")
    NoData(v-else-if="!layouts?.length")
    template(v-else)
      section.flex.flex-align-center.color-gray-800.fs-14
        span.flex-grow Layout
        span {{ options[option] }}

      section.flex.flex-align-center.column-gap-16.fs-14(
        v-for="layout of layouts"
        :key="layout.layout_name"
      )
        span.fw-500.flex-grow {{ layout.layout_name }}
        span.color-cyan-blue {{ layout?.[option] | PercentageFilter }}
</template>

<script>
import styles from '../Dashboard.module.scss';

import Queue from '@libs/Queue';

import Loading from '@master/UI/Loading';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect';

import NoData from '@cm/Views/Dashboard/Components/NoData';

const queue = new Queue();

export default {
  name: 'TopLayouts',

  props: {
    range: {
      type: String,
      default: 'current_month'
    },

    group: {
      type: String,
      default: null
    }
  },

  components: {
    Loading,
    NoData,
    SearchSelect
  },

  data() {
    return {
      styles,

      layouts: null,

      options: {
        engagement: 'Engagement',
        ctr: 'CTR'
      },
      option: null,
    };
  },

  created() {
    this.$user.subscribe(() => {
      const option = this.$user.getSetting('dashboard.layouts', 'engagement');

      if (option == null || option !== this.option) {
        this.option = option;
        this.loadLayouts();
      }
    }, this);
  },

  methods: {
    loadLayouts() {
      this.layouts = null;
      this.$emit('load', true);

      let path = `dashboard/layouts?period=${this.range}&group_by=${this.option}`;

      if (this.group && this.group !== '') {
        path += `&group_id=${this.group}`;
      }

      queue.get(path)
        .then(layouts => {
          this.layouts = layouts ?? [];
          this.$emit('load', false);
        })
        .catch(() => {/** suppress errors */});
    },

    changeOption({ value }) {
      this.$user.setSetting('dashboard.layouts', value);
      this.loadLayouts();
    }
  },

  watch: {
    range() {
      this.loadLayouts();
    },

    group() {
      this.loadLayouts();
    }
  }
};
</script>
