<template lang="pug">
div.col-12.flex.flex-direction-column.row-gap-8.fs-14
  div.color-gray-800.flex.flex-align-center {{ label }}
    template(v-if="tooltip") &nbsp;
      i.nexd-icon-16-help(v-tooltip="{value: tooltip}" aria-hidden="true")
  div.flex.flex-align-center.gap-8
    span {{ component_value }}
    Reveal(v-if="!is_empty && filter" v-model="revealed")
</template>

<script>
import Filters from '@master/Filters';

import Reveal from '@cm/Views/Profile/Blocks/Components/Reveal.vue';

export default {
  name: 'ProfileCardInfoColumn',

  components: {
    Reveal,
  },

  props: {
    label: {
      type: String,
      default: null,
    },

    value: {
      type: String,
      default: null,
    },

    filter: {
      type: String,
      default: null,
      validator: type => ['email'].includes(type),
    },

    tooltip: {
      type: String,
      default: null,
    },
  },

  computed: {
    component_value() {
      if (this.is_empty) return '-';
      if (!this.filter || this.revealed) {
        return this.value;
      }
      return Filters.SensitivityFilter(this.value, this.filter);
    },

    is_empty() {
      return !this.value || this.value === '';
    },
  },

  data() {
    return {
      revealed: false,
    };
  },
};
</script>
