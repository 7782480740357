import ContentService from '@master/Services/ContentService';
import UserService from '@master/Services/UserService';

class GroupAssetsLibraryCacheService extends ContentService {
  #folder_id = null;

  load(folder_id = null) {
    this.#folder_id = folder_id;

    const path = this.#getPath();

    if (path != null) {
      return super.load(path, true);
    }
  }

  loadMore() {
    const path = this.#getPath();

    if (path != null) {
      return super.loadMore(path, true);
    }
  }

  getLibraryID() {
    return UserService.getGroup()?.library?.library_id;
  }

  #getPath() {
    const library_id = this.getLibraryID();

    if (!library_id) {
      return null;
    }

    let path = `assets/library/${library_id}`;

    if (this.#folder_id != null) {
      path += `/folder/${this.#folder_id}`;
    }

    return path;
  }
}

export default new GroupAssetsLibraryCacheService();
