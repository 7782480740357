<template lang="pug">
  div.fs-14.color-cyan-blue.text-center(v-else style="width: 500px; margin: 0 auto;")
    img( :src="this.$cdn + 'dist/assets/cm/lightbulb.svg'")
    div.mt-16.mb-8 No extra assets have been added yet for this creative.
    div(v-if="is_cta") When assets have been added, you can select the action that you want to happen when the user clicks on the specific asset.
    div(v-else-if="is_tracking") When assets have been added, you can add the asset specific tracking.
    div
      a.mt-24(:href="is_tracking ? 'https://support.nexd.com/en/articles/3674591-using-pixel-trackers' : 'https://support.nexd.com/en/articles/1462801-add-a-cta-to-creatives'") Read more about
        span(v-if="is_tracking") &nbsp;tracking
        span(v-else-if="is_cta") &nbsp;click actions
</template>

<script>
export default {
  name: 'AssetSpecificError',
  props: {},
  computed: {
    is_tracking() {
      return this.$store.get('active_tab') === 'tracking';
    },

    is_cta() {
      return this.$store.get('active_tab') === 'cta';
    }
  }
};
</script>
