<template lang="pug">
Loading(v-if="loading")
BillingLayoutContainer(v-else :sidebar="true")
  template(v-slot:navbar)
    Buttons.flex-grow(align="right")
      Button(
        type="link-primary"
        label="Cancel"
        @click="$router.push({ name: VIEW.BILLING })"
      )
      Button(
        type="success"
        :animate="true"
        label="Purchase"
        :loading="loading_text != null"
        @click="purchase"
      )

  template(v-slot:sidebar)
    Cart(
      :subtotal="impressions_cost"
      :total="total"
    )
      template(v-slot:services)
        span Impressions cost
        span {{ formatValue(impressions_cost) }}

      template(v-slot:vat v-if="organization != null")
        span VAT ({{organization?.apply_vat ? 20 : 0}}%):
        span {{ formatValue(vat_addition) }}

  template(v-slot:content)
    Container(
      title="Purchase extra impressions"
      subtitle="Choose how many impression to add"
    )
      ImpressionSlider(
        :cpm_price="subscription?.topup_cpm_price ?? .4"
        @input="updateImpressionCost"
      )

    LoadingModal(
      v-if="loading_text != null"
      body="Please wait, it can take a little bit time to process your purchase"
    )
</template>

<script>
import BillingService from '@cm/Views/Billing/helpers/BillingService';
import { formatPrice } from '@cm/Views/Billing/helpers';
import { VIEW } from '@master/constants';

import Button from '@master/UI/Buttons/Button';
import Buttons from '@master/UI/Buttons/Buttons';
import Loading from '@master/UI/Loading';
import LoadingModal from '@master/UI/LoadingModal';

import BillingLayoutContainer from '@cm/Views/Billing/Components/BillingLayoutContainer/BillingLayoutContainer';
import Container from '@cm/Views/Billing/Components/Container/Container';
import ImpressionSlider from '@cm/Views/Billing/Components/ImpressionSlider/ImpressionSlider';
import Cart from '@cm/Views/Billing/Components/Cart/Cart';

export default {
  name: 'ExtraImpressions',

  components: {
    BillingLayoutContainer,
    Button,
    Buttons,
    Container,
    ImpressionSlider,
    Loading,
    LoadingModal,
    Cart
  },

  data() {
    return {
      VIEW,

      organization: null,
      subscription: null,

      impressions: 0,
      impressions_cost: 0,
      vat_addition: 0,
      total: 0,

      loading: false,
      loading_text: null,
    };
  },

  created() {
    this.loading = true;

    this.$user.subscribe(async user => {
      if (user == null) return;

      this.organization = await BillingService.getOrganization();
      this.subscription = await BillingService.getSubscription();

      if (this.subscription == null) {
        this.$router.push({ name: VIEW.BILLING });
      } else {
        this.loading = false;
      }
    });
  },

  methods: {
    updateImpressionCost(value) {
      this.impressions = value;
      this.impressions_cost = this.impressions / 1000 * (this.subscription?.topup_cpm_price ?? .4);
      this.vat_addition = this.organization?.apply_vat ? parseFloat(this.impressions_cost) * .2 : 0;

      this.total = this.impressions_cost + this.vat_addition;
    },

    formatValue(value) {
      return formatPrice(value, {
        currency: this.organization?.currency
      });
    },

    purchase() {
      if (this.organization == null) return;

      this.loading_text = 'Processing payment';

      this.$http.post(`v2/subscription/${this.organization.dynamic_subscription_id}/impressions/topup`, {
        amount: this.impressions * 1000000,
        customer_url: window.location.href
      })
        .then(response => {
          if (response?.form?.action != null) {
            this.loading_text = 'Redirecting to bank for payment confirmation';

            const params = new Proxy(new URLSearchParams(response.form.action), {
              get: (searchParams, prop) => searchParams.get(prop),
            });

            return setTimeout(() => {
              this.loading_text = 'Verifying payment';
              this.verifyPayment(params.order_reference, params.payment_reference);
            }, 2000);
          }
        })
        .catch(() => {
          this.loading_text = null;
          this.$alert('Purchase failed for some reason', 'Oops!');
        });
    },

    verifyPayment(order_reference, payment_reference) {
      if (order_reference == null || payment_reference == null) {
        return this.handleFailure();
      }

      this.$http.put(`callback/payment/${order_reference}/${payment_reference}`)
        .then(response => {
          if (response.successful === true) {
            this.$notifications.add('success', 'Payment successfully done!');
            this.$router.push({ name: VIEW.BILLING });
          } else {
            return this.handleFailure();
          }
        })
        .catch(() => {
          this.handleFailure();
        });
    },

    handleFailure() {
      this.loading_text = null;
      this.$alert('Purchase failed for some reason', 'Oops!');
    }
  }
};
</script>
