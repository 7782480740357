'user strict';
const filters = {
  start: null,
  end: null,
  base: 'impressions',
  start_week: null,
  incvtr: true,
  traffic: 'all',
  device: 'all',
  flight_condition_key: '',
};

const report = {
  emails: [],
  frequency: 'Choose',
};

// used for enagement and video cards, event list colour orders
const engagementColorMap = {
  touch: 'pink-primary',
  tilt: 'blue-primary',
  hover: 'purple-primary',
  gyro: 'yellow-primary',
  swipe: 'teal-primary',
  CTR: 'green-primary',
  'CTR Side 1': 'gray-700',
  'CTR Side 2': 'gray-600',
  'CTR Side 3': 'gray-500',
  'CTR Side 4': 'gray-400',
  'CTR Side 5': 'gray-300',
  'CTR Side 6': 'gray-200',

  'CTR: 0': 'gray-700',
  'CTR: 1': 'gray-600',
  'CTR: 2': 'gray-500',

  'Navigation Left': 'blue-dark',
  'Navigation Right': 'blue-light',
  'Unique turn': 'purple-primary',
};

const videoEngagementColorMap = {
  w25: 'blue-light',
  w50: 'blue-primary',
  w75: 'blue-dark',
  w100: 'blue-darker',
};

const colorOptions = {
  impressions: {
    class: 'blue-dark',
    value: 'Impressions',
  },
  viewable: {
    class: 'blue-dark',
    value: 'Viewable impr.',
  },
  engagement: {
    class: 'yellow-primary',
    value: 'Engagement',
  },
  ctr: {
    class: 'purple-primary',
    value: 'CTR',
  },
  clicks: {
    class: 'teal-dark',
    value: 'Clicks',
  },
  viewability: {
    class: 'green-primary',
    value: 'Viewability %',
  },
  // not used currently
  // uniqueImpressions: {
  //   class: 'pink-primary',
  //   value: 'Unique Impr.'
  // },
  // vtr: {
  //   class: 'teal-primary',
  //   value: 'VTR100%'
  // }
};

const Controller = {
  show_sidebar_impresion_type_switch: false,
  viewability_enabled: true,
  channel_type: 0,
  has_device_impressions: {
    mobile: false,
    desktop: false,
    tv: false,

    // validators always enabled, backend saves no summary about validators therefore it will never be outputted
    validators: true,
  },

  setMetaData(metadata) {
    if (metadata != null) {
      if (metadata.can_switch_impression_type != null) {
        this.show_sidebar_impresion_type_switch =
          metadata.can_switch_impression_type;
      }
      if (metadata.channel_type != null) {
        this.channel_type = metadata.channel_type;
      }
      if (metadata.viewability_enabled != null) {
        this.viewability_enabled = metadata.viewability_enabled;
      }
      if (metadata.has_device_impressions != null) {
        this.has_device_impressions = metadata.has_device_impressions;

        // validators always enabled, backend saves no summary about validators therefore it will never be outputted
        this.has_device_impressions.validators = true;
      }
    }
  },

  enabledDevices() {
    return this.has_device_impressions;
  },

  isViewabilityEnabled() {
    return this.viewability_enabled;
  },

  getImpressionType() {
    return this.channel_type;
  },
};

export {
  filters,
  report,
  colorOptions,
  engagementColorMap,
  videoEngagementColorMap,
  Controller,
};
