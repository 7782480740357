<template>
  <div class="card-title" :class="{'no-border': no_border}">
    <div class="title color-gray-800" :class="{'cursor-pointer': collapsed != null}" @click="toggle()">
      <span>{{title}}</span>
      <i v-if="tooltip != null" class="nexd-icon-32-help" aria-hidden="true" v-tooltip="{template: tooltip}"/>
    </div>
    <div class="icons">
      <div v-if="collapsed != null" class="collapse-toggle dark" @click="toggle">
        <i class="nexd-icon-32-arrow-down-small" aria-hidden="true" :class="{'active': collapsed === false}" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardHeader',

  model: {
    event: 'collapse'
  },

  props: {
    title: String,
    tooltip: {
      type: String,
      default: null
    },
    collapsed: {
      type: Boolean,
      default: null
    },
    no_border: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggle () {
      if (this.collapsed != null) {
        this.$emit('collapse', !this.collapsed);
      }
    }
  }
};
</script>
