<template lang="pug">
div(ref="modal")
  div.modal(:class="{'modal-unset-overflow': !show_renaming}")
    div.backdrop
    div.modal-content.small

      div.modal-head
        div.modal-title
          template(v-if="show_renaming") Edit duplicated creative names
          template(v-else-if="duplicating") Duplicating creative(s)
          template(v-else-if="multiple") Confirm duplicating creatives
          template(v-else) Confirm duplicating this creative

      div.modal-body
        span.color-gray-800
          template(v-if="show_renaming") Rename creatives:
          template(v-else-if="duplicating") Duplication can take some time, please wait..
          template(v-else-if="multiple") Are you sure you want to duplicate selected creatives?
          template(v-else) Choose how many duplicates to create:

        template(v-if="!multiple")
          div.mt-8.flex.flex-direction-column.row-gap-8(v-if="show_renaming")
            Input(v-for="(input, index) in inputs" :key="index" v-model="input.value" placeholder="Enter new name")
          div.row.mt-8(v-else-if="!duplicating")
            SearchSelect.col-6(v-model="nr_of_duplicates" :options="options" :disabled="duplicating" :search="false")

      Buttons.modal-footer
        Button(type="link-primary" :label="back_button_label" @click="handleClose")
        Button(type="primary" :label="show_renaming ? 'Apply' : 'Confirm'" :animate="true" :loading="duplicating" @click="duplicate")
</template>

<script>
import CreativesService from '@master/Services/CreativesService';

import { VIEW } from '@master/constants';
import Selection from '@libs/Selection';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Input from '@master/UI/Input/Input.vue';
import Modal from '@master/UI/Modal.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';

export default {
  name: 'CreativeConfirmDuplicate',

  mixins: [Modal],

  components: {
    Button,
    Buttons,
    Input,
    SearchSelect,
  },

  props: {
    creative: Object,

    // selection is for list view
    selection: Selection,
  },

  computed: {
    options() {
      return Array.from({ length: 20 }, (_, i) => ({ value: i + 1, label: String(i + 1) }));
    },

    back_button_label() {
      switch (true) {
        case this.show_renaming:
          return 'Back';
        case this.duplicating:
          return 'Close';
        default:
          return 'Cancel';
      }
    },

    multiple() {
      return this.selection?.selected_items?.length > 1;
    },
  },

  data() {
    return {
      show_renaming: false,
      duplicating: false,

      nr_of_duplicates: 1,
      inputs: [],
    };
  },

  methods: {
    handleClose() {
      if (this.duplicating || !this.show_renaming) {
        this.close();
      } else {
        this.show_renaming = false;
      }
    },

    close() {
      this.$emit('close');
    },

    async duplicate() {
      if (!this.show_renaming && this.nr_of_duplicates > 1) {
        this.createInputs();
        this.show_renaming = true;
        return;
      }

      this.show_renaming = false;
      this.duplicating = true;

      const payload = {
        ids: this.selection ? this.selection.selected_items : [this.$route.params.creative_id],
      };

      if (this.nr_of_duplicates > 1) {
        payload.names = this.inputs.map(input => input.value);
      }

      this.$http
        .post('creatives/duplicate', payload)
        .then(({ creatives }) => {
          this.close();

          if (this.selection) {
            CreativesService.addItems(creatives);
            this.selection.unselectAll();
          } else {
            this.confirmGoingBackToList();
          }
        })
        .finally(() => {
          this.duplicating = false;
        });
    },

    async confirmGoingBackToList() {
      if (await this.$confirm('Would you like to go to the creatives list?')) {
        this.$router.push({
          name: VIEW.CREATIVES,
          params: {
            campaign_id: this.$route.params.campaign_id,
          },
        });
      }
    },

    createInputs() {
      this.inputs = Array.from({ length: this.nr_of_duplicates }, (_, i) => ({
        value: `${this.creative.name} copy ${i + 1}`,
      }));
    },
  },
};
</script>
