<template lang="pug">
div#registration
  div#content-wrapper
    div#signup
      div#registration-head
        h1
          | Please verify your
          | email address

    div#form-content.flex.flex-direction-column.row-gap-16.text-center
      p.lh-25
        | Check your email inbox for a verification email.
        br
        template(v-if="can_request_verification")
          | Didn't receive an email?&nbsp;
          Button(
            type="link"
            :text="true"
            :disabled="loading"
            :loading="loading"
            label="Resend email"
            @click="requestVerificationEmail"
          )
      div
      div.back_to_login.text-center
        span Already verified?&nbsp;
        Button(
          type="link-primary"
          :text="true"
          label="Log In"
          @click="openLogin"
        )

</template>

<script>

import Button from '@master/UI/Buttons/Button.vue';
import Loading from '@master/UI/Loading';

import { VIEW } from '@master/constants';

export default {
  name: 'RegisterCompleted',
  components: {
    Button,
    Loading
  },

  computed: {
    can_request_verification() {
      return this.$route.query.ue != null;
    }
  },

  data() {
    return {
      loading: false
    };
  },

  methods: {
    openLogin() {
      this.$router.push({ name: VIEW.LOGIN });
    },

    requestVerificationEmail() {
      if (!this.can_request_verification || this.loading) return;

      this.loading = true;

      // url holds email in b64 format
      const email = atob(this.$route.query.ue);

      this.$http.post('user/verify', { email })
        .catch(_ => { /** error handled by notification */ })
        .finally(_ => {
          this.loading = false;
        });
    }
  }
};
</script>
