<template lang="pug">
Container(
  title="Billing details"
)
  section(:class="styles.layout")
    InputContainer(label="Company" :required="required_fields.includes('name')" :error="errors.includes('name')")
      input.form-control.error(
        type="text"
        name="name"
        v-model="name"
        @blur="blur"
      )

    InputContainer(label="VAT code (for EU)" :required="required_fields.includes('vat')" :error="errors.includes('vat')")
      input.form-control(
        type="text"
        name="vat"
        v-model="vat"
        @blur="blur"
      )

    InputContainer(label="Country" :required="required_fields.includes('country')" :error="errors.includes('country')")
      input.form-control(
        type="text"
        name="country"
        v-model="country"
        @blur="blur"
      )

    InputContainer(label="City" :required="required_fields.includes('city')" :error="errors.includes('city')")
      input.form-control(
        type="text"
        name="city"
        v-model="city"
        @blur="blur"
      )

    InputContainer(label="Address line 1" :required="required_fields.includes('address_1')" :error="errors.includes('address_1')")
      input.form-control(
        type="text"
        name="address_1"
        v-model="address_1"
        @blur="blur"
      )

    InputContainer(label="Address line 2" :required="required_fields.includes('address_2')" :error="errors.includes('address_2')")
      input.form-control(
        type="text"
        name="address_2"
        v-model="address_2"
        @blur="blur"
      )

    InputContainer(label="Zip code" :required="required_fields.includes('zip_code')" :error="errors.includes('zip_code')")
      input.form-control(
        type="text"
        name="zip_code"
        v-model="zip_code"
        @blur="blur"
      )
</template>

<script>
import styles from './BillingDetails.module.scss';

import BillingService from '@cm/Views/Billing/helpers/BillingService';

import Container from '@cm/Views/Billing/Components/Container/Container';
import InputContainer from '@cm/Views/Billing/Components/BillingDetails/InputContainer';

export default {
  name: 'BillingDetails',

  components: {
    Container,
    InputContainer
  },

  data() {
    return {
      styles,

      organization: null,

      required_fields: ['name', 'country', 'city', 'address_1', 'zip_code'],
      errors: [],

      name: '',
      country: '',
      city: '',
      address_1: '',
      address_2: '',
      zip_code: '',
      vat: '0%'
    };
  },

  created() {
    this.$user.subscribe(async user => {
      if (user == null) return;

      this.organization = await BillingService.getOrganization();

      if (this.organization?.city != null) {
        this.city = this.organization.city;
      }

      if (this.organization?.country != null) {
        this.country = this.organization.country;
      }

      if (this.organization?.name != null) {
        this.name = this.organization.name;
      }

      if (this.organization?.zip_code != null) {
        this.zip_code = this.organization.zip_code;
      }

      if (this.organization?.street_address != null) {
        this.address_1 = this.organization.street_address;
      }

      if (this.organization?.house_address != null) {
        this.address_2 = this.organization.house_address;
      }
    });
  },

  methods: {
    isFormValid() {
      this.errors = [];

      for (const field of this.required_fields) {
        if (this[field] == null || this[field] === '') {
          this.errors.push(field);
        }
      }

      return this.errors.length === 0;
    },

    blur(event) {
      if (event.target.value == null || event.target.value === '') {
        this.errors.push(event.target.name);
      } else {
        this.errors = this.errors.filter(error => error !== event.target.name);
      }
    }
  }
};
</script>
