<template lang="pug">
  div.effects
    UIGroup(title="Effects and Widgets")
      template(v-for="(effect, key) of effect_groups")
        div(v-if="settings?.[key] != null" :key="key")
          EffectComponent(
            :name="effect.name"
            :key="key"
            :enabled="key === 'countdown_widget'? settings[key].enabled : settings[key]"
            :collapsed="effect.collapsed"
            @toggleCard="toggleCollapseCard(key)"
            @toggled="toggleEffect(key)")
            div(:class="{'disabled': !settings[key] || (effect.type === 'countdown' && !settings[key].enabled)}")
              CountdownWidget( v-if="effect.type === 'countdown'" :item="settings[key]" :creative="creative" @change="change(key)")
              CustomParticlesEffect( v-else :creative="creative" :effect="effect" :settings="settings[key + '_settings']" @change="change(key + '_settings', $event)")
</template>

<script>
import UIGroup from '@master/UI/UIGroup';
import CountdownWidget from './Widgets/CountdownWidget';
import CustomParticlesEffect from './CustomParticlesEffect';
import EffectComponent from '@cm_modules/EffectComponent';

import { clone } from '@helpers/Global';

const effect_groups = {
  custom_effect: {
    name: 'Particles',
    id: 'custom',
    collapsed: true,
    type: 'toggle'
  },
  countdown_widget: {
    name: 'Countdown',
    id: 'countdown',
    collapsed: true,
    type: 'countdown'
  }
};

export default {
  name: 'EffectsBlock',
  components: {
    CountdownWidget,
    CustomParticlesEffect,
    EffectComponent,
    UIGroup
  },
  props: {
    creative: Object,
    settings: Object,
  },
  data() {
    return {
      effect_groups: clone(effect_groups),
    };
  },
  methods: {
    change(key, reload_assets_library = false) {
      const path = `creative/${this.creative.creative_id}/settings/${key}`;
      this.$http.put(path, {'value': this.settings[key]}).then(response => {

        // for mvp we skip full object changes right now
        if (response[key] != null && typeof response[key] !== 'object') {
          this.$set(this.settings, key, response[key]);
        }
        this.$emit('change', reload_assets_library);
      });
    },

    toggleEffect(key) {
      if (this.settings[key] != null) {
        if (this.settings[key].enabled != null) {
          // settings can have json data with enabled key
          this.settings[key].enabled = !this.settings[key].enabled;

          // reset state on off toggle
          if (!this.settings[key].enabled) {
            this.effect_groups[key].collapsed = true;
          }
        } else {
          // normal on/off toggle settings
          this.settings[key] = !this.settings[key];
          // reset state on off toggle
          if (!this.settings[key]) {
            this.effect_groups[key].collapsed = true;
          }
        }
      }

      if (this.settings[key]) {
        for (let item in this.effect_groups) {
          // collpase open cards when new one is being activated
          if (item !== key) {
            this.effect_groups[item].collapsed = true;
          } else {
            this.effect_groups[item].collapsed = false;
          }
        }
      }
      this.change(key);
    },

    toggleCollapseCard(key) {
      this.effect_groups[key].collapsed = !this.effect_groups[key].collapsed;
    }
  }
};
</script>
