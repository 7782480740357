<template lang="pug">
  EffectComponent(:name="module.name" :key="$vnode.key" :enabled="object.settings.enabled" @toggled="enabled")
    div.form-group
      div.row(:class="{'disabled': !is_enabled}")
        div.col-9
          p(style="line-height: 3rem")
            span Object
            i.nexd-icon-16-help.ml-8(v-tooltip="{value: 'Select the object icon from dropdown. You can also drag a custom icon from Asset library or reset to default.'}")
        div.col
          SlotMaker(v-if="module.slots != null" :creative="creative" :slots="module.slots" :key="module.module_id")
    div.row
      div.col(style="line-height: 3rem" @click="toggle")
        label.color-primary Settings
        i.nexd-icon-32-arrow-down-small.color-primary.ml-8(aria-hidden="true" :class="{'active': !collapsed}")
    template(v-for="(effect, key) in effect_settings" v-if="!collapsed")
      div.row(style="padding: 0.7rem 0;" :class="{'disabled': !object.settings.enabled}")
        div.col-9
          p
            span {{effect.title}}
            i.nexd-icon-16-help.ml-8(v-tooltip="{value: effect.tooltip}")
        div.col-13
          Slider(
            v-if="effect.type === 'slider'"
            v-model="object.settings[effect.key]"
            :options="effect.options"
            @change="change"
          )
          Toggle( v-if="effect.type === 'toggle'" v-model="object.settings[effect.key]" @input="change")
          AnglePicker( v-if="effect.type === 'chart'" :angle="object.settings[effect.key]" @change="angleChangeHandler" :reference="effect.key")
</template>

<script>
import Slider from '@master/UI/Slider';
import Toggle from '@master/UI/Toggle';
import AnglePicker from '@master/UI/AnglePicker';

import EffectComponent from '@cm_modules/EffectComponent';

import ModuleTraits from '@master/Traits/ModuleTraits';

const predefined_settings = [
  // in correct order in array
  {
    key: 'item_size_multiplier',
    title: 'Object size',
    tooltip: 'Choose the base size of the objects.',
    type: 'slider',
    options: {
      min: 0.1,
      max: 5,
      step: 0.1,
      min_text: 'Small',
      max_text: 'Big',
    },
    order: 0,
  },
  {
    key: 'randomize_item_size',
    title: 'Randomize size',
    tooltip:
      'If you would like the sizes of the objects to vary (up to 50% smaller or bigger than chosen object size).',
    type: 'toggle',
    order: 1,
  },
  {
    key: 'speed_multiplier',
    title: 'Speed',
    tooltip: 'Specify how slow or fast the objects should move on the ad.',
    type: 'slider',
    options: {
      min: 0.1,
      max: 5,
      step: 0.1,
      min_text: 'Slow',
      max_text: 'Fast',
    },
    order: 2,
  },
  {
    key: 'alpha_multiplier',
    title: 'Opacity',
    tooltip:
      'Select how transparent the objects are. (The lower the value, the more transparent the object is).',
    type: 'slider',
    options: {
      min: 0.1,
      max: 1,
      step: 0.05,
      min_text: '10%',
      max_text: '100%',
    },
    order: 3,
  },
  {
    key: 'randomize_alpha',
    title: 'Randomize opacity',
    tooltip: 'If you would like the opacity of the objects to vary.',
    type: 'toggle',
    order: 4,
  },
  {
    key: 'item_count',
    title: 'Density',
    tooltip: 'Choose the amount of objects that are animated on the ad.',
    type: 'slider',
    options: {
      min: 100,
      max: 10000,
      step: 100,
      min_text: 'Low',
      max_text: 'High',
    },
    order: 5,
  },
  {
    key: 'direction',
    title: 'Direction',
    tooltip: 'Specify the angle where the objects move onto the ad.',
    type: 'chart',
    order: 6,
  },
];

export default {
  name: 'EffectModule',
  mixins: [ModuleTraits],

  beforeCreate() {
    this.$options.components.SlotMaker =
      require('@cm_modules/SlotMaker').default;
  },

  components: {
    // CustomAsset,
    Slider,
    Toggle,
    AnglePicker,
    EffectComponent,
  },

  props: {
    creative: Object,
    module: Object,
  },

  data() {
    return {
      effect_settings: predefined_settings,
      collapsed: false,
      object: null,
      is_enabled: false,
      module_slots: {},
    };
  },

  created() {
    this.modularitySetup();
  },

  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },

    change() {
      this.$emit('update', 'object', this.module._fullpath, this.object);
    },

    customAssetChangeHandler(reload_assets_library = false) {
      this.$emit('change', reload_assets_library);
    },

    angleChangeHandler(value) {
      this.$set(this.object.settings, 'direction', value);
      this.change();
    },

    enabled(val) {
      this.$set(this.object.settings, 'enabled', val);
      this.change();
    },
  },

  watch: {
    'object.settings.enabled': {
      handler(val) {
        this.is_enabled = val;
      },
    },
  },
};
</script>
