<template lang="pug">
div(ref="modal")
  div.modal.schedule-modal
    div.backdrop
    div.modal-content(:class="{'hidden': schedules.length === 0, 'disabled': active_schedule != null}")
      div.modal-head
        div.modal-title.block
          span Scheduled reports
          div.row.flex-align-center
            div.col.flex-grow
              p.description Here you can see an overview of your scheduled reports.
                <br>
                | You can manage your existing reports and schedule new ones.
            div.col
              Button(type="primary" label="Schedule New" :animate="true" @click="new_schedule = true")
                template(v-slot:prefix)
                  IconAdd
      div.modal-body.bg-gray-100
        div.schedule-list
          div.schedule-head
            div.schedule-th Email
            div.schedule-th Report type
            div.schedule-th Frequency
            div.schedule-th Start date
            div.schedule-th Time
            div.schedule-th Timezone
            div.schedule-th Range
            div.schedule-th &nbsp;
          div.card
            div.card-body.schedule-body(v-for="(schedule, index) of schedules" :key="schedules.report_id")
              div.schedule-td
                div.overflow-ellipsis(v-for="email of schedule.email" :key="email") {{email}}
              div.schedule-td {{schedule.type_string}}
              div.schedule-td {{schedule.interval_string}}
              div.schedule-td {{schedule.start_date | DateFilter }}
              div.schedule-td {{schedule.ui_hour}}:00
              div.schedule-td {{schedule.ui_timezone}}
              div.schedule-td {{schedule.range_string}}
              div.schedule-td
                i.nexd-icon-32-edit(aria-hidden="true" v-tooltip="{value: 'Edit'}" @click="active_schedule = schedule")
                i.nexd-icon-32-delete(aria-hidden="true" v-tooltip="{value: 'Remove'}" @click="deleteReport(index)")
      div.modal-footer
        Button(type="primary" label="Done" @click="close")
      ScheduleEditor(v-if="active_schedule != null || new_schedule" :schedule="active_schedule" @save="scheduleSaveHandler" @create="newScheduleHandler" @close="closeScheduleEditor")
</template>
<script>
import Button from '@master/UI/Buttons/Button';
import IconAdd from '@master/UI/Buttons/Icons/IconAdd';
import ScheduleEditor from '@analytics/Modals/ScheduleEditor';
import AnalyticsService from '@master/Services/AnalyticsService';
import Modal from '@master/UI/Modal';

export default {
  name: 'SchedulerModal',
  extends: Modal,

  components: {
    Button,
    IconAdd,
    ScheduleEditor,
  },

  props: {
    schedules: Array,
  },

  data() {
    return {
      data: null,
      active_schedule: undefined,
      new_schedule: this.schedules.length === 0,
    };
  },

  created() {
    AnalyticsService.subscribe(data => {
      this.data = data;
    }, this);
  },

  methods: {
    close() {
      this.$emit('close');
    },

    closeScheduleEditor() {
      this.active_schedule = undefined;
      this.new_schedule = false;

      if (this.schedules.length === 0) {
        this.close();
      }
    },

    scheduleSaveHandler(schedule) {
      for (const key in schedule) {
        this.$set(this.active_schedule, key, schedule[key]);
      }
      this.closeScheduleEditor();
    },

    newScheduleHandler(schedule) {
      this.schedules.unshift(schedule);
      this.closeScheduleEditor();
    },

    async deleteReport(index) {
      const result = await this.$confirm('Are you sure you want to remove this scheduled report?');
      if (result) {
        const item = this.schedules[index];
        const path = `analytics/campaigns/${this.data.campaign_id}/schedule/${item.report_id}`;
        this.$http.delete(path, {}, { notification: false });
        this.schedules.splice(index, 1);

        if (this.schedules.length === 0) {
          this.close();
        }
      }
    },
  },
};
</script>
