<template lang="pug">
div(:class="[styles.card, styles.card_wide]")
  section(:class="styles.header")
    span(:class="styles.label") Top creatives
      i.nexd-icon-16-help.pl-4(v-tooltip="{value: tooltip}" aria-hidden="true")
    SearchSelect(v-model="option" :options="options" :search="false" :disabled="creatives == null" @change="changeOption" width="9rem")

  div.flex.flex-direction-column.row-gap-8.mt-16
    Loading(v-if="creatives == null")
    NoData(v-else-if="!creatives?.length")
    template(v-else)
      section.flex.flex-align-center.color-gray-800.fs-14
        span.flex-grow Creative
        span {{ options[option] }}

      a(
        v-for="creative of creatives"
        :key="creative.creative_id"
        :class="styles.creative"
        :href="creative.preview_url"
        target="_blank"
      )
        div.flex-grow.flex.flex-align-center.column-gap-8
          CreativeThumbnail(:asset="creative")
          section
            div.fs-14.fw-500(:class="styles.creative_name") {{ creative.name }}
            span.color-gray-800.fs-12 {{ creative.layout_name }}
        span.color-cyan-blue {{ creative?.[option] | PercentageFilter }}
</template>

<script>
import styles from '../Dashboard.module.scss';

import Queue from '@libs/Queue';

import CreativeThumbnail from '@cm/UI/Global/Thumbnail/CreativeThumbnail';
import Loading from '@master/UI/Loading';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect';

import NoData from '@cm/Views/Dashboard/Components/NoData';

const queue = new Queue();

export default {
  name: 'TopCreatives',

  props: {
    range: {
      type: String,
      default: 'current_month'
    },

    group: {
      type: String,
      default: null
    }
  },

  components: {
    CreativeThumbnail,
    Loading,
    NoData,
    SearchSelect
  },

  computed: {
    tooltip() {
      return 'We show top 5 creatives by engagement or CTR. You can change the metric by clicking on the dropdown. NB! Creative must have at least 1000 impressions to be shown here.';
    }
  },

  data() {
    return {
      styles,

      creatives: null,

      options: {
        engagement: 'Engagement',
        ctr: 'CTR'
      },
      option: null,
    };
  },

  created() {
    this.$user.subscribe(() => {
      const option = this.$user.getSetting('dashboard.creatives', 'engagement');

      if (option == null || option !== this.option) {
        this.option = option;
        this.loadCreatives();
      }
    }, this);
  },

  methods: {
    loadCreatives() {
      this.creatives = null;
      this.$emit('load', true);

      let path = `dashboard/creatives?period=${this.range}&group_by=${this.option}`;

      if (this.group && this.group !== '') {
        path += `&group_id=${this.group}`;
      }

      queue.get(path)
        .then(creatives => {
          this.creatives = creatives ?? [];
          this.$emit('load', false);
        })
        .catch(() => {/** suppress errors */});
    },

    changeOption({ value }) {
      this.$user.setSetting('dashboard.creatives', value);
      this.loadCreatives();
    }
  },

  watch: {
    range() {
      this.loadCreatives();
    },

    group() {
      this.loadCreatives();
    }
  }
};
</script>
