<template lang="pug">
div#registration
  div#content-wrapper
    div#signup
      div#registration-head
        h1 Welcome to Nexd!
        hr
        p We just need a few more details and you are good to go!
      div#form-content.flex.flex-direction-column.row-gap-16
        div.row
          Input.col-12(
            placeholder="First Name"
            variant="empty"
            v-model="form.first_name"
            :medium="true"
            :error_msg="errors.first_name"
            @error="error => errors.first_name = error"
          )
          Input.col-12(
            placeholder="Last Name"
            variant="empty"
            v-model="form.last_name"
            :medium="true"
            :error_msg="errors.last_name"
            @error="error => errors.last_name = error"
          )

        Input(
          v-if="is_group_admin"
          variant="empty"
          placeholder="Company"
          v-model="form.company_name"
          :medium="true"
          :error_msg="errors.company_name"
          @error="error => errors.company_name = error"
        )

        Buttons(align="center")
          Button(
            type="success"
            :animate="true"
            :pill="true"
            :medium="true"
            :disabled="save_disabled"
            label="Confirm"
            :style="{width: '192px'}"
            @click="saveUser"
          )
</template>

<script>

import Input from '@master/UI/Input/Input';
import Buttons from '@master/UI/Buttons/Buttons';
import Button from '@master/UI/Buttons/Button';

export default {
  name: 'FinalizeRegistration',

  components: {
    Input,
    Buttons,
    Button
  },

  computed: {
    is_valid_first_name() {
      return this.form.first_name !== '' && this.errors.first_name == null;
    },

    is_valid_last_name() {
      return this.form.last_name !== '' && this.errors.last_name == null;
    },

    is_valid_company_name() {
      return !this.is_group_admin || this.form.company_name !== '' && this.errors.company_name == null;
    },

    save_disabled() {
      return !this.is_valid_first_name || !this.is_valid_last_name || !this.is_valid_company_name;
    }
  },

  data() {
    return {
      is_group_admin: false,
      form: {
        first_name: '',
        last_name: '',
        company_name: '',
      },
      errors: {
        first_name: null,
        last_name: null,
        company_name: null
      }
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (!user) return;
      this.form.first_name = user.first_name;
      this.form.last_name = user.last_name;
      this.form.company_name = user.group?.name ?? '';
      this.is_group_admin = this.$user.isGroupAdmin();
    }, this);
  },

  methods: {
    saveUser() {
      if (this.save_disabled) return;

      const data = this.form;

      // no need to send company name when not group admin
      if (!this.is_group_admin) {
        delete data.company_name;
      }

      this.$http.post('v2/user/finalize', data, { notification: false })
        .then(response => {
          this.$user.set(response);
        })
        .catch(error => {
          if (error.response?.result?.errors) {
            this.errors = error.response.result.errors;
          }
        });
    }
  }
};

</script>
