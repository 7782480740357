<template lang="pug">
div(:class="styles.container")
  template(v-if="card")
    div(:class="styles.card")
      section(:class="styles.info")
        CreditCardIcon(type="master_card")
        | **** **** **** {{card.cc_last_four_digits ? card.cc_last_four_digits : '****'}}
      div(:class="styles.label") {{ 'master_card' | CCTypeFilter }}
      div(:class="styles.replace")
        Button(type="primary" label="Replace" :disabled="!can_manage_group_billing" :animate="true" @click="addCard")
    Button(type="link-primary" label="Remove card" @click="removeCard")
  template(v-else)
    div No card added yet!
    Button(type="primary" :animate="true" label="Add Card" :disabled="!can_manage_group_billing" @click="addCard")

  LoadingModal(
    v-if="adding_card_processing"
    head="Redirecting..."
    body="You will be redirected shortly to payment provider to continue adding card details"
  )
</template>

<script>

import styles from './CreditCard.module.scss';

import Payment from '@master/Services/PaymentService';
import PermissionMixin from '@master/Services/mixins/PermissionMixin';

import Button from '@master/UI/Buttons/Button';
import LoadingModal from '@master/UI/LoadingModal';

import CreditCardIcon from '@cm/Views/Billing/Components/CreditCard/CreditCardIcon';

export default {
  name: 'CreditCard',
  mixins: [PermissionMixin],

  components: {
    Button,
    CreditCardIcon,
    LoadingModal
  },

  props: {
    organization: Object,

    simple: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    card() {
      return this.organization?.cards?.[0] ?? null;
    }
  },

  data() {
    return {
      styles,
      adding_card_processing: false
    };
  },

  methods: {
    addCard() {
      this.adding_card_processing = true;
      const payment = new Payment();

      // give user some indicator about whats going to happen
      window.setTimeout(_ => {
        payment.addCard();
      }, 500);
    },

    removeCard() {
      if (!this.card) return;
      const payment = new Payment();
      payment.removeCard();
      this.organization.cards = [];
    }
  }
};
</script>
