<template lang="pug">
  EffectComponent(:name="module.name" :key="$vnode.key" :enabled="object.settings.enabled" @toggled="enabled")
    CountdownWidget(:creative="creative" :item="object.settings" @change="changeHandler")
</template>
<script>

import CountdownWidget from '@cm/Views/Creatives/Cards/Components/TemplateSettings/Widgets/CountdownWidget';
import EffectComponent from '@cm_modules/EffectComponent';
import ModuleTraits from '@master/Traits/ModuleTraits';

export default {
  name: 'CountdownModule',
  mixins: [ModuleTraits],
  components: {
    CountdownWidget,
    EffectComponent,
  },

  props: {
    creative: Object,
    module: Object
  },

  data() {
    return {
      object: null,
      is_enabled: false,
      module_slots: {}
    };
  },

  created () {
    this.modularitySetup();
  },

  methods: {
    changeHandler() {
      this.$emit('update', 'object', this.module._fullpath, this.object);
    },

    enabled(val) {
      this.$set(this.object.settings, 'enabled', val);
      this.changeHandler();
    }
  },

  watch: {
    'object.settings.enabled': {
      handler (val) {
        this.is_enabled = val;
      }
    }
  }
};
</script>
