<template lang="pug">
div(:class="[styles.row, styles[align]]")
  span.fs-14(
    v-if="label != null"
    :class="{ 'disabled': disabled }"
    :style="{ 'grid-column': !$slots.default ? '1 / -1' : 'auto' }"
  ) {{ label }}:&nbsp;
    i.nexd-icon-16-help(v-if="tooltip != null" aria-hidden="true" v-tooltip="{'value': tooltip}")

  section(v-if="$slots.default")
    slot
</template>

<script>
import styles from './Settings.module.scss';

export default {
  name: 'SettingsRow',

  props: {
    label: {
      type: String,
      default: null,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: null,
    },

    align: {
      type: String,
      default: 'center',
      validator: value => ['top', 'center'].includes(value),
    },
  },

  data() {
    return {
      styles,
    };
  },
};
</script>
