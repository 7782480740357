<template lang="pug">
div(:class="styles.package")
  CustomCheckbox(
    :radio="support"
    :value="checked"
    @change="$emit('change', $event)"
  )
    section(:class="styles.package_content")
      div {{ title }}
      div.fs-14.color-gray-800 {{ description }}
      div(:class="styles.price") {{ formatted_price }}
</template>

<script>
import styles from './PackageCalculator.module.scss';

import { formatPrice } from '@cm/Views/Billing/helpers';

import CustomCheckbox from '@master/UI/CustomCheckbox';

export default {
  name: 'Package',

  components: {
    CustomCheckbox
  },

  props: {
    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: true
    },

    price: {
      type: [String, Number],
      required: true
    },

    support: {
      type: Boolean,
      default: false
    },

    currency: {
      type: String,
      default: null
    },

    checked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    formatted_price() {
      return formatPrice(this.price, {
        currency: this.currency,
        included: true
      });
    }
  },

  data() {
    return {
      styles
    };
  }
};
</script>
