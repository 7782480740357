'user strict';

// initial strings, must have them defined if they're also changable through translations, so VUE would bind them without errors
// VUE wont bind keys that are undefined :(
let _strings = {
  gyro: 'Gyroscope',
  touch: 'Touch',
  hover: 'Hover',
  CTR: 'Click',

  // video engagement
  w0: 'Started watching',
  w25: 'Watched 25%',
  w50: 'Watched 50%',
  w75: 'Watched 75%',
  w100: 'Watched 100%',

  // Event translations for engagement
  // CTR side n
  'CTR Side 1': 'CTR Side 1',
  'CTR Side 2': 'CTR Side 2',
  'CTR Side 3': 'CTR Side 3',
  'CTR Side 4': 'CTR Side 4',
  'CTR Side 5': 'CTR Side 5',
  'CTR Side 6': 'CTR Side 6',

  // events tooltips
  tooltips: {
    touch:
      'When an finger/mouse interaction was recorded including click, swipe, scratch, etc',
    gyro: 'When motion sensor was used',
    swipe: 'When ad was touched, pressed and let go while moving',
    CTR: 'When clickable element was tapped once',
    drag: 'When ad was touched, pressed and hold while moving',
    click: 'When ad was tapped once',
    scratched: 'When the top surface was scratched',
    first_tilt: 'Direction of first engagement (unique)',
    page_seen: 'Which pages were seen, how many times (unique)',
    page_change: 'How many times a page was changed',
    first_direction: 'Direction of the first rotation (unique)',
  },
};

export default {
  _strings,
  append(key, value) {
    this._strings[key] = value;
  },
  get(key = null) {
    // use this for dynamic translation on the go, access the correct value from the component, so changes will apply
    if (key === null) {
      return this._strings;
    } else {
      if (this._strings[key] != null) {
        return this._strings[key];
      } else {
        return key;
      }
    }
  },
};
