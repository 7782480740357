<template lang="pug">
div(:class="styles.card")
  div(:class="styles.card_image")
    slot(name="image")

  div(:class="styles.card_body")
    slot
</template>

<script>
import styles from './ProfileCard.module.scss';

export default {
  name: 'ProfileCard',

  data() {
    return {
      styles
    };
  }
};
</script>
