<template lang="pug">
section.col-8.flex.gap-8
  template(v-if="equivalent.key && equivalent.label")
    img(
      :src="`${$cdn}dist/assets/cm/analytics/co2/${equivalent.key}.svg`"
      :alt="equivalent.key"
      width="80"
    )
    small.color-cyan-blue(style="max-width: 80%") {{ equivalent.label }}
</template>

<script>
export default {
  name: 'CarbonEquivalent',

  props: {
    equivalent: {
      type: Object,
      required: true,
    },
  },
};
</script>
