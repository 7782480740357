class PasswordInput {
  constructor(svg) {
    this.svg = svg;
    this.node = this.svg.querySelector('#passcode');
    this.asterisks = this.node.children;
  }
  set(n) {
    let total = this.asterisks.length;
    for (let i = total - 1; i >= 0; i--) {
      let node = this.asterisks[i];
      if (total - n <= i) {
        node.style.opacity = 1;
      } else {
        node.style.opacity = 0;
      }
    }
  }
}

export default PasswordInput;
