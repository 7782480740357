class Navigator {
  constructor(start) {
    if (Array.isArray(start)) {
      this.current = start[start.length - 1];
      this.history = start;
    } else {
      this.current = start;
      this.history = [start];
    }
  }

  goToRoot() {
    if (this.history.length > 1) {
      const root = this.history[0];
      this.current = root;
      this.history = [root];
    }
  }

  goToDir(directory) {
    if (directory != null && directory != this.current) {
      this.current = directory;
      this.history.push(directory);
    }
  }

  goBack() {
    if (this.history.length > 1) {
      this.history.pop();
      this.current = this.history[this.history.length - 1];
    }
  }
}

export default Navigator;
