<template lang="pug">
  div.row.effects
    div.col-24.mb-14
      div.card.effect-card(:class="{'selected': local_enabled, 'collapsed': local_collapsed}")
        div.effect-head.row.justify-content-left(@click="toggleCollapse")
          div.img.col-4.flex
            img(alt="effect-icon" :src="`${$cdn}dist/assets/cm/effects-widgets/${illustration || $vnode.key}.svg`")
          div
            span.title.col-6 {{ name | CapitalizeFirstLetterFilter }}
            i.nexd-icon-32-help(v-if="tooltip != null" v-tooltip="{value: tooltip}")
          div.col.flex-grow
            Toggle(v-if="enabled != null" v-model="local_enabled" @input="$emit('toggled', $event)")
          div.collapse-card-toggle
            i.nexd-icon-32-arrow-down-small(aria-hidden="true" :class="{'active': !local_collapsed}")
        div.effect-body(v-if="!local_collapsed")
          slot
</template>

<script>

import Toggle from '@master/UI/Toggle';

export default {
  name: 'EffectComponent',
  components: {
    Toggle
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    enabled: {
      type: Boolean,
      default: null
    },
    collapsed: {
      type: Boolean,
      default: true
    },
    illustration: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      local_collapsed: this.collapsed,
      local_enabled: this.enabled,
    };
  },
  methods: {
    toggleCollapse() {
      this.local_collapsed = !this.local_collapsed;
      this.$emit('toggleCard', this.local_collapsed);
    }
  },
  watch: {
    'local_enabled': {
      handler (val) {
        // Auto collapse/uncollapse block if enabled/disabled
        this.local_collapsed = !val;
      }
    }
  }
};

</script>
