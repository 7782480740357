<template lang="pug">
ClickOutPage.mt-0
</template>

<script>
import ClickOutPage from '@master/UI/ClickOutPage/ClickOutPage.vue';

export default {
  name: 'ClickOutPageTest',

  components: {
    ClickOutPage,
  },
};
</script>
