<template lang="pug">
TestLayout(title="User Image")

  TestSection(subtitle="default")
    UserImage

  TestSection(subtitle="user")
    UserImage(:name="user.name" :img="user.img")

  TestSection(subtitle="collaborator")
    template(v-slot:tooltip)
      Toggle.ml-8(v-model="loading")
    UserImage(:name="user.name" color="#486a9b" background="#e5ecf5" :loading="loading" v-tooltip="{value: user.email}")

  div

  TestSection(subtitle="default illustration")
    Illustration

  TestSection(subtitle="custom illustration")
    Illustration(img="billing-icon.svg")

  TestSection(subtitle="custom illustration fill")
    Illustration(img="org-profile.svg" :fill="true")

  TestSection(subtitle="illustration w/ loading")
    template(v-slot:tooltip)
      Toggle.ml-8(v-model="loading")
    Illustration(:name="user.name" :loading="loading")
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Illustration from '@master/UI/UserImage/Illustration.vue';
import UserImage from '@master/UI/UserImage/User.vue';
import Toggle from '@master/UI/Toggle.vue';

export default {
  name: 'UserImageTest',

  components: {
    TestLayout,
    TestSection,

    Illustration,
    Toggle,
    UserImage,
  },

  data() {
    return {
      user: null,
      loading: false,
    };
  },

  created() {
    this.$user.subscribe(user => {
      this.user = user;
    });
  },

  methods: {},
};
</script>
