<script>

import FolderService from '@master/Services/FolderService';
import Filters from '@master/Filters';
import { STATUS_CLASS, FOLDER, VIEW } from '@master/constants';
import Selection from '@libs/Selection';

export default {
  name: 'Folder',

  props: {
    folder: {
      type: Object,
      default: () => (null)
    },

    selection: {
      type: Selection
    }
  },

  computed: {
    path() {
      const active = this.folder ?? FolderService.active.get();
      if (active?.folder_id == null) return { name: VIEW.CAMPAIGNS };

      switch (active.type) {
        case FOLDER.CAMPAIGN: return { name: VIEW.CAMPAIGNS_FOLDERS, params: { folder_id: active.folder_id }};
        case FOLDER.CREATIVE: return { name: VIEW.CREATIVES_FOLDERS, params: { campaign_id: active.campaign_id, folder_id: active.folder_id }};
        case FOLDER.ASSET_ORIGINAL: return { name: VIEW.GROUP_ASSETS_FOLDER, params: { folder_id: active.folder_id }};
        default: return { name: VIEW.CAMPAIGNS };
      }
    },

    folder_status() {
      if (this.isDraft(this.folder.status)) return STATUS_CLASS.DRAFT;
      if (this.isWaiting(this.folder.status)) return STATUS_CLASS.PENDING;
      if (this.isPaused(this.folder.status)) return STATUS_CLASS.PAUSED;
      if (this.isLive(this.folder.status)) return STATUS_CLASS.LIVE;
      if (this.isFinished(this.folder.status)) return STATUS_CLASS.FINISHED;
      if (this.isArchived(this.folder.status) || this.isDuplicationInProgress(this.folder.status) || this.isImportInProgress(this.folder.status)) return STATUS_CLASS.ARCHIVED;
      return '';
    },

    status() {
      return Filters.StatusFilter(this.folder.status, false);
    },

    add_to_folder_in_progress() {
      return this.$store.get('add_to_folder_in_progress') && !this.add_to_folder_folderids.includes(this.folder.folder_id);
    },

    add_to_folder_folderids() {
      return this.$store.get('add_to_folder_folderids');
    },

    is_new() {
      return this.folder.folder_id.indexOf('new-') !== -1;
    },

    is_owner() {
      return this.folder?.owner?.user_id == null || this.folder?.owner?.user_id === this.$user?.user?.user_id;
    },

    can_change_owner() {
      return this.is_owner || this.$user.isNexdAdmin();
    },

    can_delete() {
      return !!this.folder?.actions?.can_remove;
    },

    can_update() {
      return this.is_new || !!this.folder?.actions?.can_update;
    },

    is_active_folder() {
      return this.active_folder?.folder_id === this.folder?.folder_id;
    },

    is_campaign_folder() {
      return this.folder.type === FOLDER.CAMPAIGN;
    },

    is_creative_folder() {
      return this.folder.type === FOLDER.CREATIVE;
    },

    is_asset_library_folder() {
      return this.folder.type === FOLDER.ASSET_ORIGINAL;
    },
  },

  data() {
    return {
      active_folder: null,
      user: null,
      analytics_keys: {
        impressions: false,
        engagement: true,
        ctr: true
      },
      show_drag_message: false
    };
  },

  created() {
    this.$user.subscribe(user => {
      this.user = user;
    }, this);

    FolderService.active.subscribe(folder => {
      this.active_folder = folder;
    }, this);
  },

  methods: {

    addToFolderStopped() {
      this.$store.reset('add_to_folder_in_progress');
      this.$store.reset('add_to_folder_folderids');

      this.removeDraggable();
    },

    dragEnd() {
      this.addToFolderStopped();
    },

    dragEnter() {
      this.show_drag_message = true;
    },

    dragLeave() {
      this.show_drag_message = false;
    },

    open() {
      this.$router.push(this.path).catch(_ => { /** supress duplicate route errors */ });
    },

    async setActiveFolder() {
      if (this.is_active_folder) return;
      FolderService.active.set(this.folder);
    }
  }
};

</script>
