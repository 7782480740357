<template lang="pug">
div
  h1 NEXD is currently in maintenance mode.
  br
  h3 Sorry for the inconvenience
</template>

<script>
export default {
  name: 'Maintenance',
};
</script>
