class CSSAnimation {
  constructor(node, className, variableValue = 0) {
    if (!node) return;
    this.node = node;
    this.removed = false;
    this.node.classList.add(className);
    this.node.style.setProperty('--animation-var', variableValue);
  }
  clickEvent(fn) {
    let hitbox = this.node.querySelector('[id^=HITBOX]');
    if (hitbox == null) {
      hitbox = this.node;
    }
    hitbox.addEventListener('click', () => fn());
    hitbox.classList.add('clickable');
  }
  destroy() {
    this.node.classList.add('transition');
    this.node.classList.add('hidden');
    this.removed = true;
    // remvove the node to free up CPU
    setTimeout(() => {
      if (this.node.parentNode != null) {
        this.node.parentNode.removeChild(this.node);
      }
    }, 2000);
  }
}

export default CSSAnimation;
