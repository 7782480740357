import { TIMELINE, MODULES } from '@master/constants';

import Animations from './Animations';
import $http from '@master/Services/HttpService';

export default class Timeline {

  // sometimes we want to disable update, eg: when testing
  #updates = true;

  constructor(creative) {
    const { DIRECTION } = TIMELINE;

    this.creative_id = creative.creative_id;
    this.value = creative.settings?.[MODULES.ANIMATIONS] ?? {};

    // timeline is actually inside animations module
    const timeline = this.value.timeline;

    this.enabled = timeline?.enabled ?? false;
    this.duration = timeline?.duration ?? 15000;
    this.iterations = timeline?.iterations ?? 0;
    this.direction = timeline?.direction ?? DIRECTION.NORMAL;

    this.setup(timeline?.animations);
  }

  setup(obj) {
    this.animations = new Map();
    if (!obj) return;
    for (const [id, animations] of Object.entries(obj)) {
      this.animations.set(id, new Animations(this, animations));
    }
  }

  get(id) {
    if (!this.animations.has(id)) {
      // create new animation object when needed
      this.animations.set(id, new Animations(this, null));
    }
    return this.animations.get(id);
  }

  checkStatus() {
    if (this.animations.size === 0) {
      this.enabled = false;
      return;
    }

    for (const [, animation] of this.animations) {
      // at least one enabled means timeline is enabled
      if (animation.enabled) {
        this.enabled = true;
        return;
      }
    }

    this.enabled = false;
  }

  update() {
    if (!this.#updates) return;

    const obj = { value: this.toJson() };
    const path = `creative/${this.creative_id}/settings/${MODULES.ANIMATIONS}`;
    $http.put(path, obj).then().catch(_ => { /** supress any errors */ });
  }

  disableUpdates() {
    this.#updates = false;
  }

  toJson() {

    // convert animations objects into json objects
    const animations = Object.fromEntries(this.animations);
    for (const id in animations) {
      animations[id] = animations[id].toJson();
    }

    return {
      // timeline is actually inside animations module
      timeline: {
        enabled: this.enabled,
        duration: this.duration,
        iterations: this.iterations,
        direction: this.direction,
        animations
      }
    };
  }
}
