import { clone } from '@helpers/Global';
import AssetHelper from '@helpers/Asset';

let AssetHelperLayouts = {};

AssetHelperLayouts.getSize = (creative, slot, options = {}) => {
  const layout = creative.layout;
  const asset_object = slot != null ? clone(slot) : {};
  return AssetHelper.getSizeDimensions(creative, options, layout, asset_object);
};

AssetHelperLayouts.createCropperBackground = async (slot_vnode, callback) => {
  return AssetHelper.createCropperBackground(slot_vnode, callback, slot_vnode.$vnode.key);
};

export default AssetHelperLayouts;
