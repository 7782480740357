<template lang="pug">
div.creative-overlay(v-if="live_creative_edit_disabled || is_processing")
  template(v-if="is_duplicating")
    div.text Please wait, this creative is being duplicated, therefore editing is disabled
  template(v-else-if="is_importing")
    div.text Please wait, this creative is being imported, therefore editing is disabled
  template(v-else)
    div.text Use caution when editing live creatives! Also, don't forget to click the
      br
      | Update Live button, or download new tags if needed, to push any changes live.
    Button(type="primary" label="Click to edit the Live Creative" :animate="true" @click="$emit('enableLiveEditing')")
</template>

<script>
import CreativeTraits from '@master/Traits/CreativeTraits';
import Button from '@master/UI/Buttons/Button';

export default {
  name: 'CreativeDisabledOverlay',
  mixins: [CreativeTraits],

  components: {
    Button,
  },

  props: {
    creative: {
      type: Object,
      default: () => null,
    },

    live_creative_edit_disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    is_processing() {
      if (this.isProcessing()) return true;
      return (
        this.creative.expanded &&
        this.isProcessing(this.creative.expanded.status)
      );
    },

    is_duplicating() {
      if (this.isDuplicationInProgress()) return true;
      return (
        this.creative.expanded &&
        this.isDuplicationInProgress(this.creative.expanded.status)
      );
    },

    is_importing() {
      if (this.isImportInProgress()) return true;
      return (
        this.creative.expanded &&
        this.isImportInProgress(this.creative.expanded.status)
      );
    },
  },
};
</script>
