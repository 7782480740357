<template>
  <div class="analytics-card">
    <div v-if="creative != null" class="title">
      <div class="flex mb-4">
        <div class="flex-grow">
          <h1>{{ card_title }}</h1>
        </div>
        <div>
          <i class="nexd-icon-32-help text-left" aria-hidden="true" v-tooltip="{ template: 'analytics.summaryCard', position: 'left', public: is_public }" />
        </div>
      </div>
      <div class="mb-24 header">
        <div class="small-text">Exported on {{ creative.live_created_on | dateFilter }}</div>
      </div>

      <SummaryCardHeader :creative="creative" />
      <SummaryCardAdminHeader v-if="admin_toggle" :creative="creative" />
    </div>
    <FlightRulesets />
    <div v-if="data != null && creative != null" class="split-cards">
      <div class="analytics-card left-side">
        <div class="card-body">
          <div class="image-wrapper">
            <div class="image">
              <Thumbnail v-if="creative.device === DEVICE.QUANTUM" :fill="true" bg="unset" :asset="{ image_uri: $cdn + 'dist/assets/cm/quantum-group-icon.svg' }" />
              <Thumbnail v-else :fill="true" bg="unset" :asset="{ image_uri: creative.thumbnail_url }" />
            </div>
            <Pill class="status-pill" :status="creative.live_status" :google_ads="creative.live_dsp === DSP.GOOGLEADS" />
            <Button
              v-if="this.$whitelabel?.analytics?.preview_links !== false"
              class="mt-8"
              type="primary"
              :outline="true"
              :block="true"
              label="View Creative in Preview site"
              :disabled="creative.live_preview_url == null"
              @click="openPreview"
            />
          </div>
        </div>
        <div class="totals-wrapper">
          <div class="total">
            <div class="summary-stats">
              <div v-if="data.totals.impressions != null && Controller.getImpressionType() !== 1">
                <div class="inline-block" v-tooltip="{ template: 'analytics.impressions' }">
                  <h1 v-if="filters.base !== 'viewable'">{{ data.totals.impressions | NumberFilter }}</h1>
                  <h3 v-else>{{ data.totals.impressions | NumberFilter }}</h3>
                  <span class="color-cyan-blue lh-32 fs-14">Impressions</span>
                </div>
              </div>
              <div v-else-if="(filters.base === 'viewable' || Controller.getImpressionType() === 1) && data.totals.viewable != null && Controller.isViewabilityEnabled()" class="mb-16">
                <div class="inline-block" v-tooltip="{ template: 'analytics.viewable' }">
                  <h1 :class="{ 'color-primary': Controller.getImpressionType() !== 1 }">{{ data.totals.viewable | NumberFilter }}</h1>
                  <span class="color-cyan-blue lh-32 fs-14">Viewable impressions</span>
                </div>
              </div>
              <i class="nexd-icon-32-help" aria-hidden="true" v-tooltip="{ template: 'analytics.impressions1' }" />
            </div>
            <div v-if="toggle_panel" class="summary-stats collapsable-info">
              <div class="row">
                <div class="col-12">
                  <div class="inline-block" v-tooltip="{ template: 'analytics.loadedImp' }">
                    <h3>{{ data.totals.trackable | NumberFilter }}</h3>
                    <span class="color-cyan-blue lh-32 fs-14">Tracked Ads</span>
                  </div>
                  &nbsp;<i v-if="creative.billing != null && creative.billing.type === 0 && !is_sdk_user" class="nexd-icon-16-money money" aria-hidden="true" v-tooltip="{ template: 'analytics.dollarSign' }" />
                </div>
                <div v-if="admin_toggle" class="col-12">
                  <div class="inline-block">
                    <h3>{{ data.totals.billable | NumberFilter }}</h3>
                    <span class="color-cyan-blue lh-32 fs-14">Billable</span>
                  </div>
                </div>
              </div>
              <div v-if="data.totals.loaded != null" class="row">
                <div class="col-12">
                  <div class="inline-block" v-tooltip="{ template: 'analytics.loadedImp' }">
                    <h3>{{ data.totals.loaded | NumberFilter }}</h3>
                    <span class="color-cyan-blue lh-32 fs-14">Loaded Impr.</span>
                  </div>
                  &nbsp;<i v-if="creative.billing != null && creative.billing.type === 2 && !is_sdk_user" class="nexd-icon-16-money money" aria-hidden="true" v-tooltip="{ template: 'analytics.dollarSign' }" />
                </div>
                <div class="col-12">
                  <div class="inline-block" v-tooltip="{ template: 'analytics.loaded' }">
                    <h3>{{ data.totals.load_rate | PercentageFilter }}</h3>
                    <span class="color-cyan-blue lh-32 fs-14">Load rate</span>
                  </div>
                </div>
              </div>
              <div v-if="data.totals.viewable != null && Controller.isViewabilityEnabled()" class="row">
                <div class="col-12">
                  <div class="inline-block" v-tooltip="{ template: 'analytics.viewable' }">
                    <h3 :class="[filters.base === 'viewable' ? 'color-primary' : '']">{{ data.totals.viewable | NumberFilter }}</h3>
                    <span class="color-cyan-blue lh-32 fs-14">Viewable Impr.</span>
                  </div>
                  &nbsp;<i v-if="creative.billing != null && creative.billing.type === 1 && !is_sdk_user" class="nexd-icon-16-money money" aria-hidden="true" v-tooltip="{ template: 'analytics.dollarSign' }" />
                </div>
                <div class="col-12">
                  <div class="inline-block" v-tooltip="{ template: 'analytics.viewability' }">
                    <h3>{{ data.totals.viewability | PercentageFilter }}</h3>
                    <span class="color-cyan-blue lh-32 fs-14">Viewability</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="collapse-toggle toggle-position impression-details" @click="toggle">
              <span class="fs-12">View {{ toggle_panel ? 'less' : 'more' }}</span>
              <i class="nexd-icon-32-arrow-down-small" aria-hidden="true" :class="{ active: toggle_panel }"></i>
            </div>
          </div>
          <div class="summary-stats">
            <div class="row">
              <div class="col" v-tooltip="{ template: 'analytics.engagement' }">
                <h1>{{ data.totals.engagement.value | PercentageFilter }}</h1>
                <span class="color-cyan-blue lh-32 fs-14">Engagement</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="inline-block" v-tooltip="{ template: 'analytics.engagementImpressions' }">
                  <h3>{{ data.totals.engagement.clicks | NumberFilter }}</h3>
                  <span class="color-cyan-blue lh-32 fs-14">Engaged Impr.</span>
                </div>
              </div>
              <div class="col-12">
                <div class="inline-block" v-tooltip="{ template: 'analytics.exposureTime' }">
                  <h3>{{ data.totals.dwell | timeFilter }}</h3>
                  <span class="color-cyan-blue lh-32 fs-14">Exposure Time</span>
                </div>
              </div>
            </div>
            <i class="nexd-icon-32-help" aria-hidden="true" v-tooltip="{ template: 'analytics.engagementPanel' }" />
          </div>
          <div class="summary-stats">
            <div class="row">
              <div class="col" v-tooltip="{ template: 'analytics.ctr' }">
                <h1>{{ data.totals.ctr.value | PercentageFilter }}</h1>
                <span class="color-cyan-blue lh-32 fs-14">CTR</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="inline-block" v-tooltip="{ template: 'analytics.clicks' }">
                  <h3>{{ data.totals.ctr.clicks | NumberFilter }}</h3>
                  <span class="color-cyan-blue lh-32 fs-14">Clicks</span>
                </div>
              </div>
            </div>
            <i class="nexd-icon-32-help" aria-hidden="true" v-tooltip="{ template: 'analytics.ctrPanel' }" />
          </div>
        </div>
      </div>
      <div class="analytics-card right-side" data-intercom-target="analytics-compare-stats">
        <div class="summary-graph-wrapper">
          <div class="sub-headline">Compare datapoints</div>
          <div class="row graph-filters">
            <div class="col-12">
              <FilterSelect v-model="selections[0][0]" :has-video="hasVideo" :default="0" />
            </div>
            <div class="col-12 text-right">
              <FilterSelect v-model="selections[0][1]" :has-video="hasVideo" :default="3" />
            </div>
          </div>
          <div v-if="filters.base !== 'impressions'" class="chart-footer">All chart data is based on viewable impressions.</div>
          <SummaryChart :chart="data.chart" :series="selections[0]" />
        </div>
        <div class="summary-graph-wrapper">
          <div class="sub-headline">Compare datapoints</div>
          <div class="row graph-filters">
            <div class="col-12">
              <FilterSelect v-model="selections[1][0]" :has-video="hasVideo" :default="0" />
            </div>
            <div class="col-12 text-right">
              <FilterSelect v-model="selections[1][1]" :has-video="hasVideo" :default="2" />
            </div>
          </div>
          <div v-if="filters.base !== 'impressions'" class="chart-footer">All chart data is based on viewable impressions.</div>
          <SummaryChart :chart="data.chart" :series="selections[1]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filters, Controller } from '@master/Services/AnalyticsAPPService';
import { DEVICE, DSP } from '@master/constants';
import { breakLongStrings } from '@helpers/Global';

import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import FilterSelect from '@analytics/UI/FilterSelect.vue';
import FlightRulesets from '@analytics/Views/Creative/Cards/Components/FlightRulesets/FlightRulesets.vue';
import SummaryCardHeader from '@analytics/Views/Creative/Cards/Components/SummaryCardHeader.vue';
import SummaryCardAdminHeader from '@analytics/Views/Creative/Cards/Components/SummaryCardAdminHeader.vue';
import SummaryChart from '@analytics/UI/Charts/Creative/SummaryChart.vue';
import Thumbnail from '@cm/UI/Global/Thumbnail/Thumbnail.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Pill from '@master/UI/Pill/Pill.vue';

export default {
  name: 'SummaryCard',

  mixins: [SDKMixin],

  components: {
    SummaryCardHeader,
    SummaryCardAdminHeader,
    Thumbnail,
    SummaryChart,
    FilterSelect,
    Pill,
    Button,
    FlightRulesets,
  },

  props: {
    creative: Object,
    data: Object,
    hasVideo: Boolean,
  },

  computed: {
    card_title() {
      let title = this.creative.live_name;
      if (this.creative.device === DEVICE.QUANTUM) {
        title = ' - summary';
      }
      return breakLongStrings(title);
    },

    is_public() {
      return this.$route.params.public_id != null || this.$route.params.public_live_id != null;
    },
  },

  data() {
    return {
      admin_toggle: false,

      DSP,
      DEVICE,
      Controller,
      filters,

      selections: [
        [null, null],
        [null, null],
      ],
      toggle_panel: false,
    };
  },

  created() {
    this.$user.subscribe(() => {
      this.admin_toggle = this.$user.adminMode();
    }, this);
  },

  methods: {
    openPreview() {
      window.open(this.creative.live_preview_url, '_blank');
    },

    toggle() {
      this.toggle_panel = !this.toggle_panel;
    },
  },
};
</script>
