<template lang="pug">
canvas
</template>

<script>
export default {
  name: 'CardChart',

  props: {
    chart: Array,

    color: {
      type: String,
      default: null,
    },

    shadow: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    let canvas = this.$el;
    let ctx = canvas.getContext('2d');
    canvas.height = 44;
    canvas.width = Math.min(this.$parent.$el.clientWidth, 120);

    let max = Math.max(...this.chart);
    if (max === 0) {
      max = 1;
    }

    const w = canvas.width;
    const h = canvas.height;
    const x_step = w / this.chart.length + 1;
    const y_step = h / max;

    let color = this.color;
    if (
      color == null &&
      this.$whitelabel &&
      this.$whitelabel.color &&
      this.$whitelabel.color['primary-color'] != null
    ) {
      color = this.$whitelabel.color['primary-color'];
    }

    // fallback if not WL, as it was default before
    if (color == null) {
      color = '#006AFF';
    }

    ctx.strokeStyle = color;
    ctx.shadowColor = color;
    ctx.shadowBlur = 6;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 2;
    ctx.beginPath();
    ctx.translate(0, h);
    let x = 0;
    let y = -this.chart[0] * y_step;
    ctx.moveTo(x, y);

    for (let i = 1; i < this.chart.length; i++) {
      y = -this.chart[i] * y_step;
      x = i * x_step;

      // draw last step on the edge, whenever some rounding errors occur
      if (i === this.chart.length - 1) {
        x = w;
      }

      let cx = x;
      let cy = y;
      if (i !== this.chart.length - 1) {
        cx = (x + (i + 1) * x_step) / 2;
        cy = (y + -this.chart[i + 1] * y_step) / 2;
      }
      ctx.quadraticCurveTo(x, y, cx, cy);
    }

    ctx.restore();
    ctx.lineTo(w * 2, h);
    ctx.lineTo(-w, h);

    if (this.shadow) {
      let gradient = ctx.createLinearGradient(w, -h, w, h);

      gradient.addColorStop(0, color);
      gradient.addColorStop(0.5, 'white');

      // Set the fill style and draw a rectangle
      ctx.fillStyle = gradient;
      ctx.fill();
    }

    ctx.stroke();
    ctx.closePath();
  },
};
</script>
