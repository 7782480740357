<template lang="pug">
SearchSelectCustom(
  variant="creative_export"
  :search="false"
  :handler="handleOptions"
  @select="change"
)
  template(v-slot:placeholder="{opened}")
    Button(
      type="success"
      label="Export"
      :loading="loading"
      :disabled="disabled"
      :active="opened"
      :flip="true"
      :tooltip="{'value': 'Export selected creatives'}"
      v-google-analytics="{ key: 'click', element_name: 'open export options' }"
      v-user-guiding="'export-button'"
      v-tutorial="'export-button'"
    )
      template(v-slot:suffix)
        IconDropdown
</template>

<script>
import Button from '@master/UI/Buttons/Button.vue';
import IconDropdown from '@master/UI/Buttons/Icons/IconDropdown.vue';
import SearchSelectCustom from '@master/UI/SearchSelect/SearchSelectCustom.vue';

export default {
  name: 'CreativesExport',

  components: {
    Button,
    IconDropdown,
    SearchSelectCustom,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    handleOptions() {
      return Promise.resolve(this.options);
    },

    change({ label, onclick }, close) {
      onclick();
      close();

      window?.ga?.e({
        element_name: label,
        interaction_type: 'click',
      });
    },
  },
};
</script>
