<template lang="pug">
div#pp-wrapper
  div#profile-wrapper(v-content)
    div(v-if="user != null && organization != null")
      div(v-if="group_base_loaded === true && organization.dynamic_subscription_id == null")
        div(
          v-if="organization.dynamic_subscription_id == null"
          class="container"
          ref="details"
        )
          div.card.icon-child
            div.card-body
              Illustration(img="billing-icon.svg")
          div.card.profile-card.flex-grow
            div.card-body
              div.flex.flex-align-center.mb-16
                div.flex-grow
                  h2.main-title.mb-0 Subscription Plan
                Buttons
                  Button(
                    v-if="can_change_plan"
                    type="primary"
                    :label="subscription_plan?.subscription != null ? 'VIEW AND EDIT PLAN' : 'CHOOSE PLAN' "
                    :animate="true"
                    @click="openPlanEditorModal"
                  )
              template(v-if="subscription_plan != null")
                template(v-if="subscription_plan.subscription != null && subscription_plan.stats != null")
                  p.color-gray-800.mb-8 Your current active plan is&nbsp;
                    span.color-primary {{ subscription_plan.subscription.name }}

                    template(v-if="subscription_plan.stats.plan_usage != null && !has_unlimited_plan") &nbsp;of which you are using&nbsp;
                      span.color-primary {{ subscription_plan.stats.plan_usage | PercentageFilter }}

                  div.row(v-if="subscription_plan.subscription.features != null && !has_unlimited_plan")
                    div.col-6
                      PlanStatsCard(label="Impressions per month" :value="$options.filters.NumberFilter(subscription_plan.stats.impressions.value)")
                        template(v-slot:chart)
                          PieChart(
                            :class="{infinity: subscription_plan.subscription.features.impressions_per_month === 'unlimited'}"
                            :percentage="subscription_plan.stats.impressions.percentage"
                          )
                        template(v-slot:footer)
                          div.color-primary.fs-12
                            template(v-if="subscription_plan.subscription.features.impressions_per_month === 'unlimited'") of unlimited
                            template(v-else) of {{ subscription_plan.subscription.features.impressions_per_month | NumberFilter }}
                    div.col-6
                      PlanStatsCard(label="Active users" :value="`${$options.filters.NumberFilter(subscription_plan.stats.users.value)} users`")
                    div.col-6
                      PlanStatsCard(label="Live campaigns" :value="`${$options.filters.NumberFilter(subscription_plan.stats.live_campaigns.value)} live`")
                    div.col-6
                      PlanStatsCard(label="Live creatives in campaigns" :value="`${$options.filters.NumberFilter(subscription_plan.stats.live_creatives.value)} total`")

                template(v-else)
                  div
                    | You have no active plan, please activate a subscription or
                    br
                    | contact our support through live chat.

              Loading(v-else)

              h2.main-title.mt-16 Billing

              template(v-if="invoices != null")
                template(v-if="invoices.outstanding != null")

                  p.invoice-block-title Outstanding invoice
                  div.invoice-table
                    div.form-group
                      InvoiceRow(:invoice="invoices.outstanding")
                template(v-if="invoices.next != null && !no_recurring_payment_plan")
                  p.invoice-block-title
                    span(v-if="invoices.next.due > 0") Next invoice in&nbsp;
                      span.color-primary {{ invoices.next.due }} {{ invoices.next.due > 1 ? 'days' : 'day' }}

                    span(v-else) Next invoice overdue&nbsp;
                      span.color-primary {{ -invoices.next.due }} {{ -invoices.next.due > 1 ? 'days' : 'day' }}

                  div.invoice-table
                    div.form-group
                      InvoiceRow(:invoice="invoices.next")
                template(v-if="invoices.unpaid != null")
                  p.invoice-block-title Unpaid invoices
                  template(v-if="invoices.unpaid.length > 0")
                    div.invoice-table(:class="invoice_table_class.unpaid" @click="invoice_table_class.unpaid = ''")
                      div.form-group(v-for="invoice in invoices.unpaid" :key="invoice.number")
                        InvoiceRow(:invoice="invoice")
                  template(v-else)
                    div No upcoming invoices
                template(v-if="invoices.paid != null")
                  p.invoice-block-title Paid invoices
                  template(v-if="invoices.paid.length > 0")
                    div.invoice-table(:class="invoice_table_class.paid" @click="invoice_table_class.paid = ''")
                      div.form-group(v-for="invoice in invoices.paid" :key="invoice.number")
                        InvoiceRow(:invoice="invoice")
                  template(v-else)
                    h4 No invoices found
                template(v-else)
                  We have not sent you any invoices.
              Loading(v-else)
              template(v-if="can_read_group_billing")

                h2.main-title Payment Method
                template(v-if="organization.cards.length === 0")
                  p.mb-8(v-if="is_enterprise_plan")
                    |Currently you are billed using bank transfer, if you want
                    | to use credit card for billing, please add a payment
                    | method.

                  p.mb-8(v-else) Please add a payment method.
                CreditCard(
                  :organization="organization"
                  @addCard="fetchGroupDetails"
                )
        PlanEditorModal(v-if="show_plan_editor === true && subscription_plan != null && invoices != null"
          :key="subscription_plan.plan_id"
          :plan="subscription_plan.subscription"
          :invoice="invoices.next"
          :organization="organization"
          @close="closePlanEditorModal"
          @change="planChangeHandler"
        )
      Overview(v-if="group_base_loaded === true && organization.dynamic_subscription_id != null")
</template>

<script>
import CreditCard from '@cm/Views/Billing/Components/CreditCard/CreditCard.vue';
import InvoiceRow from '@cm/Views/Profile/Blocks/Components/InvoiceRow.vue';
import Overview from '@cm/Views/Billing/Views/Overview.vue';
import PieChart from '@cm/Views/Profile/Blocks/Components/PieChart.vue';
import PlanEditorModal from '@cm/Views/Profile/Modals/PlanEditorModal.vue';
import PlanStatsCard from '@cm/Views/Profile/Blocks/Components/PlanStatsCard.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Loading from '@master/UI/Loading.vue';
import Illustration from '@master/UI/UserImage/Illustration.vue';

import Payment from '@master/Services/PaymentService';
import PermissionMixin from '@master/Services/mixins/PermissionMixin';

import { VIEW, SUBSCRIPTION } from '@master/constants';

export default {
  name: 'Billing',
  mixins: [PermissionMixin],

  components: {
    Button,
    Buttons,
    CreditCard,
    InvoiceRow,
    Loading,
    Overview,
    PieChart,
    PlanEditorModal,
    PlanStatsCard,
    Illustration,
  },

  data() {
    return {
      organization: null,
      subscription_plan: null,
      user: null,
      invoices: null,
      current_balance: null,
      show_plan_editor: false,
      can_change_plan: false,
      is_enterprise_plan: false,
      invoice_table_class: {
        paid: 'hidden',
        unpaid: 'hidden',
      },
      group_base_loaded: false,
    };
  },

  computed: {
    has_unlimited_plan() {
      if (this.subscription_plan == null) return;
      const plan_id = this.subscription_plan.subscription.plan_id;
      return this.isEnterpriseUser(plan_id) || this.$user.isIntegrationPlanUser(plan_id) || this.$user.isPayAsYouGoUser(plan_id);
    },

    no_recurring_payment_plan() {
      if (this.subscription_plan == null) return;
      const plan_id = this.subscription_plan.subscription.plan_id;
      return this.isIntegrationPlanUser(plan_id) || this.isPayAsYouGoUser(plan_id);
    },
  },

  created() {
    this.$user.subscribe(user => {
      this.user = user;
      this.can_change_plan = this.$user.canChangePlan();
      this.is_enterprise_plan = this.$user.isEnterpriseUser();

      if (this.user != null) {
        // validate if user is allowed to see this
        this.fetchGroupDetails();
      }
    }, this);
  },

  methods: {
    fetchGroupDetails() {
      if (!this.can_read_group_billing) {
        this.$router.push({ name: VIEW.PROFILE });
        return;
      }

      this.$http
        .get(`groups/${this.user.group.group_id}`, { notification: false })
        .then(response => {
          this.organization = response;

          // load extra info only once + only for non dynamic sub users
          if (!this.group_base_loaded && this.organization.dynamic_subscription_id == null) {
            this.fetchGroupSubscriptions();
            this.fetchGroupInvoices();
            this.fetchGroupBalance();
          }
          this.group_base_loaded = true;
        })
        .catch(_ => {
          // probably user has no permissions to come here, redirect to profile page
          this.$router.push({ name: VIEW.PROFILE });
        });
    },

    fetchGroupSubscriptions() {
      const path = `groups/${this.organization.group_id}/subscription`;
      this.$http.get(path).then(response => {
        this.subscription_plan = response;
      });
    },

    fetchGroupInvoices() {
      const path = `groups/${this.organization.group_id}/invoices`;
      this.$http
        .get(path, { notification: false })
        .then(response => {
          this.invoices = response;
        })
        .catch(() => {
          this.invoices = false;
        });
    },

    fetchGroupBalance() {
      const path = `groups/${this.organization.group_id}/balance/current`;
      this.$http
        .get(path, { notification: false })
        .then(response => {
          this.current_balance = response;
        })
        .catch(() => {
          this.current_balance = false;
        });
    },

    async openPlanEditorModal() {
      if (!this.$user.groupHasActiveCreditCard()) {
        const result = await this.$confirm('Missing credit card', 'Before changing or activating a plan, credit card must be added');
        if (result) {
          const payment = new Payment();
          payment.addCard();
        }
        return;
      }

      this.show_plan_editor = true;
    },

    closePlanEditorModal() {
      this.show_plan_editor = false;
    },

    planChangeHandler() {
      this.fetchGroupSubscriptions();
      this.fetchGroupInvoices();
      this.fetchGroupBalance();
      this.closePlanEditorModal();
    },

    isEnterpriseUser(plan_id) {
      if (plan_id != null) {
        return plan_id === SUBSCRIPTION.ENTERPRISE;
      }
      return false;
    },

    isIntegrationPlanUser(plan_id) {
      if (plan_id != null) {
        return plan_id === SUBSCRIPTION.INTEGRATION;
      }
      return false;
    },

    isPayAsYouGoUser(plan_id) {
      if (plan_id != null) {
        return plan_id === SUBSCRIPTION.PAYASYOUGO;
      }
      return false;
    },
    dynamicPricingPurchased() {
      window.location.reload();
    },
  },
};
</script>
