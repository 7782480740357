<template lang="pug">
div#wrapper
  div.text-center(v-if="has_error")
    p.mb-8 Loading analytics failed...
    Button(type="primary" :outline="true" label="Retry" @click="loadAnalytics")
  div.campaign-analytics-header(v-if="data != null")
    div.row
      div.col-12
        div.view-header.flex.flex-align-center
          h1 {{data.name}}
          Pill.ml-16(:status="data.status")
        small.block.color-cyan-blue(v-if="campaign_description != null") {{ campaign_description }}
        small.mt-4.block.color-cyan-blue Analytics are updated on an hourly basis.
      div.col-12(v-if="has_google_ads_creatives")
        div.googleads-info.inline-flex.no-gutter-right
          img(:src="$cdn + 'dist/assets/cm/warning-blue.svg'" alt="warning")
          div
            | This campaign includes creatives exported for external platforms. Analytics summary&nbsp;
            strong does not
            |  include data from external platforms. Analytics for these creatives can be viewed only in the&nbsp;
            a.color-cyan-blue.text-underline(href="https://ads.google.com/aw/overview" target="_blank") Google Ads platform.

  div.analytics-layout
    Loading(v-if="analytics == null")
    section.analytics-layout-content.campaign(v-else)
      div.row(data-intercom-target="analytics-overall-stats")
        div.col-8
          SummaryCard(
            ref="impressions"
            type="impressions"
            :analytics="analytics.summary.impressions"
            :validators="analytics.summary.validators"
            :chart="summary_charts.impressions"
            @toggle="summaryCardToggleHandler")

        div.col-8
          SummaryCard(
            ref="engagement"
            type="engagement"
            :analytics="analytics.summary.engagement"
            :chart="summary_charts.engagements"
            @toggle="summaryCardToggleHandler")

        div.col-8
          SummaryCard(
            ref="ctr"
            type="ctr"
            :analytics="analytics.summary.ctr"
            :chart="summary_charts.clicks"
            @toggle="summaryCardToggleHandler")
      CarbonEmissions(:data="carbon_data")
      AnalyticsCardCreative(v-for="(group, key) of analytics.groups" :key="key" :item="group")
      // compare component will check if its visible before fetching the data
      CompareCreatives(v-if="enable_compare_charts")
    Sidebar
</template>

<script>
import AnalyticsCardCreative from '@analytics/Views/Campaign/Components/AnalyticsCardCreative';
import SummaryCard from '@analytics/Views/Campaign/Cards/SummaryCard';
import CompareCreatives from '@analytics/Views/Campaign/Cards/CompareCreatives';

import CarbonEmissions from '@analytics/UI/CarbonEmissions/CarbonEmissions.vue';
import Sidebar from '@analytics/UI/Sidebar/Sidebar';

import Button from '@master/UI/Buttons/Button';
import Loading from '@master/UI/Loading';
import Pill from '@master/UI/Pill/Pill.vue';

import AnalyticsService from '@master/Services/AnalyticsService';
import CreativeService from '@master/Services/CreativeService';

import { filters, Controller } from '@master/Services/AnalyticsAPPService.js';
import { clone } from '@helpers/Global';

export default {
  name: 'CampaignSummary',

  components: {
    CompareCreatives,
    SummaryCard,
    AnalyticsCardCreative,
    Loading,
    Pill,
    CarbonEmissions,
    Sidebar,
    Button,
  },

  computed: {
    request_data() {
      return {
        base: filters.base,
        startDate: filters.start,
        endDate: filters.end,
        incvtr: filters.incvtr,
        traffic: filters.traffic,
        partner: this.$route?.query?.partner ?? null,
      };
    },

    published_creatives_count() {
      if (this.data?.meta?.creatives == null) {
        return 0;
      }

      return this.data.meta.creatives.total - this.data.meta.creatives.status.draft;
    },

    campaign_description() {
      if (this.data?.started_on == null) {
        return null;
      }

      if (this.data.ended_on != null) {
        const start_date = this.$options.filters.DateFilter(this.data.started_on);
        const end_date = this.$options.filters.DateFilter(this.data.ended_on);
        return `
          This campaign was active from ${start_date}
          to ${end_date} with ${this.published_creatives_count} creatives.
        `;
      }

      return `Started on ${this.$options.filters.DateFilter(this.data.started_on)}, currently ongoing with ${this.published_creatives_count} creatives.`;
    },
  },

  data() {
    return {
      filters,

      cloned_filters: null,
      data: null,
      analytics: null,
      loading: false,
      has_error: false,
      enable_compare_charts: false,
      summary_charts: {
        impressions: null,
        engagements: null,
        clicks: null,
      },
      carbon_data: null,
      has_google_ads_creatives: false,
    };
  },

  created() {
    CreativeService.set(null);

    AnalyticsService.load();
    AnalyticsService.subscribe(async data => {
      this.data = data;
      this.has_google_ads_creatives = AnalyticsService.getHasGoogleAdsCreatives();
      AnalyticsService.setupSidebarPreview();

      if (this.data != null) {
        await this.$nextTick();
        this.loadAnalytics();
      }
    }, this);
  },

  methods: {
    loadAnalytics(retry = true) {
      const path = AnalyticsService.getActiveParentAnalyticsPath();

      if (this.loading === true || filters.start == null || filters.end == null || path == null) {
        return;
      }

      this.loading = true;
      this.has_error = false;
      this.analytics = null;

      this.$http
        .post(path, this.request_data)
        .then(async response => {
          this.loadAnalyticCharts();

          this.analytics = response.analytics;
          this.carbon_data = response.savetheplanet;
          Controller.setMetaData(response.metadata);

          // since compare chart watches its viewport before fetching data, make sure the content is loaded before adding it to the view
          await this.$nextTick();
          this.enable_compare_charts = true;
        })
        .catch(error => {
          AnalyticsService.loadAgain(error?.code ?? 500, retry, () => this.loadAnalytics(false));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadAnalyticCharts(retry = true) {
      const path = AnalyticsService.getActiveParentChartPath();
      if (path == null) return;

      // make sure to reset charts
      this.summary_charts.impressions = null;
      this.summary_charts.engagements = null;
      this.summary_charts.clicks = null;

      // start loading summary charts after the main analytics, to no clog up analytics api
      this.$http
        .post(path, this.request_data)
        .then(chart_data => {
          for (const key in this.summary_charts) {
            if (chart_data?.charts?.[key]) {
              this.summary_charts[key] = chart_data.charts[key];
            }
          }
        })
        .catch(error => {
          AnalyticsService.loadAgain(error?.code ?? 500, retry, () => this.loadAnalyticCharts(false));
        });
    },

    summaryCardToggleHandler() {
      for (const key in this.$refs) {
        if (this.$refs[key].toggle != null) {
          this.$refs[key].toggle();
        }
      }
    },
  },

  watch: {
    filters: {
      handler() {
        for (const filter_key in filters) {
          if (filters?.[filter_key] !== this.cloned_filters?.[filter_key]) {
            return this.loadAnalytics();
          }
        }

        this.cloned_filters = clone(filters);
      },
      deep: true,
    },
  },
};
</script>
