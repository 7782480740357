import Lamp from './InteractiveElements/Lamp';
import Cup from './InteractiveElements/Cup';
import Cactus from './InteractiveElements/Cactus';
import Monitor from './InteractiveElements/Monitor';
import Mouse from './InteractiveElements/Mouse';
import Sun from './InteractiveElements/Sun';
import PasswordInput from './InteractiveElements/PasswordInput';
import CSSAnimation from './InteractiveElements/CSSAnimation';

class InteractiveBG {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.svg = this.wrapper.querySelector('svg');
    this.cloudNodes = [];
    this.code = '';
    this.check = 2716;
  }

  init() {
    this.cup = new Cup(this.svg);
    this.monitor = new Monitor(this.svg);
    this.sun = new Sun(this.svg);
    this.passcode = new PasswordInput(this.svg);

    try {
      new Lamp(this.svg);
      new Cactus(this.svg);
      new Mouse(this.svg, this.monitor);
    } catch {}

    // this.sun.show();
    // animate keyboard lights
    const keyboardLights = this.wrapper.querySelectorAll('.keyboard-light');
    for (let i = 0; i < keyboardLights.length; i++) {
      let node = keyboardLights[i];
      setTimeout(() => {
        let button = new CSSAnimation(node, 'animation-opacity');
        button.clickEvent(() => {
          this.passwordHandler(i);
        });
      }, i * 300);
    }
    // animate tablet screens
    let tabletScreens = this.wrapper.querySelectorAll('[id^=tablet-scr-]');
    for (let i = 0; i < tabletScreens.length; i++) {
      let node = tabletScreens[i];
      setTimeout(() => {
        try {
          new CSSAnimation(node, 'animation-opacity-hover', (i + 1) * -4 + 'px');
        } catch {}
      }, i * 100);
    }
    // start mobile screen animation with a delay
    setTimeout(() => {
      let mobileScreens = this.wrapper.querySelectorAll('[id^=mobile-scr-]');
      for (let i = 0; i < mobileScreens.length; i++) {
        let node = mobileScreens[i];
        setTimeout(() => {
          try {
            new CSSAnimation(node, 'animation-opacity-hover', (i + 1) * -4 + 'px');
          } catch {}
        }, i * 100);
      }
    }, 1000);
    // animate clouds
    let clouds = this.wrapper.querySelectorAll('[id^=cloud-]');
    for (let i = 0; i < clouds.length; i++) {
      let node = clouds[i];
      setTimeout(() => {
        let cloud = new CSSAnimation(node, 'animation-hover', 8 + 'px');
        cloud.clickEvent(() => {
          cloud.destroy();
          this.checkSun();
        });
        this.cloudNodes.push(cloud);
      }, i * 900);
    }
    this.cup.onSpill = () => this.monitor.initFlicker();
  }

  passwordHandler(val) {
    // only register key events when the window is on the screen
    let status = this.monitor.passcodeWindow.active;
    if (status === true) {
      if (this.monitor.on === true && this.monitor.error === false) {
        this.code += val;
        if (this.code.length > 4) {
          this.code = '' + val;
        }
        if (parseInt(this.code) === this.check) {
          this.end();
        }
      } else {
        // when monitor is off or close, and keys are pressed, reset code
        this.code = '';
      }
      this.passcode.set(this.code.length);
    }
  }

  checkSun() {
    let initSun = true;
    for (let cloud of this.cloudNodes) {
      if (cloud.removed === false) {
        initSun = false;
        break;
      }
    }
    if (initSun === true) {
      this.sun.show();
    }
  }

  end() {
    this.monitor.successScreen.show();
    this.svg.classList.add('infinite');
    let title = document.body.querySelector('#magic-title');
    title.innerHTML = 'This is where<br>the magic happened';
    let inputs = document.body.querySelectorAll('#login input');
    for (let input of inputs) {
      input.setAttribute('disabled', true);
      input.classList.add('transition');
      input.classList.add('hidden');
    }
  }
}

export default InteractiveBG;
