import BillingService from '@cm/Views/Billing/helpers/BillingService';

let discount_constant = 1;
let period_constant = 1;
let currency = 'EUR';

export async function createCart(payload) {
  updateDiscount(payload.payment_period);
  updateCurrency(payload.currency);

  const impressions = await getImpressionsPrices(payload.featured_impressions);
  const features = await getFeaturesPrices(payload.features);

  let subtotal = calculateSubtotal(features, impressions);
  const saving = calculateAnnaualSaving(subtotal);

  if (saving > 0) {
    subtotal -= saving;
  }

  const unpaid_subtotal = calculateUnPaidSubtotal(features, impressions);
  const vat = await calculateVAT(subtotal);
  const total = calculateTotal(subtotal, vat);
  const unpaid_total = calculateTotal(unpaid_subtotal, vat);

  return {
    impressions,
    features,
    subtotal,
    unpaid_subtotal,
    unpaid_total,
    saving,
    vat,
    total
  };
}

async function getFeaturesPrices(features = []) {
  const pricing_details = await BillingService.getPricingDetails();
  const subscription = await BillingService.getSubscription();

  const amount = await getAmount();

  return features.map(feature_key => {
    const feature_details = pricing_details?.additional_features?.[feature_key];

    const full = feature_details?.price?.[currency] ?? 0;
    const is_feature_paid = subscription?.features?.includes(feature_key) ?? false;
    const unpaid = is_feature_paid ? 0 : full * amount;

    const discount = {
      full: full * discount_constant,
      unpaid: unpaid * discount_constant
    };

    const annual = {
      full: discount.full * 12,
      unpaid: discount.unpaid * 12
    };

    return {
      key: feature_key,
      name: feature_details?.name,
      amount,
      full,
      unpaid,
      discount,
      annual
    };
  });
}

async function getAmount() {
  const subscription = await BillingService.getSubscription();

  const day = 86400;
  const unix_date = Date.now() / 1000;

  const expires_on = subscription?.expires_on ?? unix_date;
  const valid_from = subscription?.valid_from ?? unix_date;

  const subscription_length = (expires_on - valid_from) / day;
  const subscription_remaining = (expires_on - unix_date) / day;

  if (subscription_length <= 0) return 0;

  return Math.round(subscription_remaining / subscription_length * 100) / 100;
}

async function getImpressionsPrices(impressions = 1000000) {
  const pricing_details = await BillingService.getPricingDetails();
  const subscription = await BillingService.getSubscription();

  const impressions_details = Object.values(pricing_details?.impression_products ?? {})
    ?.find(product => impressions <= product.max_impressions);

  const full = (impressions_details?.price?.[currency] ?? 0) * impressions / 1000;

  const unpaid_impressions = impressions - (subscription?.featured_impressions ?? 0);

  const unpaid = unpaid_impressions < 0
    ? 0
    : (impressions_details?.price?.[currency] ?? 0) * unpaid_impressions / 1000;

  const discount = {
    full: full * discount_constant,
    unpaid: unpaid * discount_constant
  };

  return {
    full,
    unpaid,
    discount
  };
}

function calculateSubtotal(features = [], impressions = 0) {
  return features.reduce((subtotal, feature) => {
    subtotal += (feature?.full ?? 0);
    return subtotal;
  }, (impressions?.full ?? 0)) * period_constant;
}

function calculateUnPaidSubtotal(features = [], impressions = 0) {
  return features.reduce((subtotal, feature) => {
    subtotal += (feature?.unpaid ?? 0);
    return subtotal;
  }, (impressions?.unpaid ?? 0)) * period_constant;
}

function calculateAnnaualSaving(subtotal) {
  if (discount_constant === 1) return 0;
  return subtotal * (1 - discount_constant);
}

async function calculateVAT(subtotal = 0) {
  const organization = await BillingService.getOrganization();

  if (organization?.apply_vat) {
    return subtotal * .2;
  }

  return 0;
}

function calculateTotal(subtotal = 0, vat = 0) {
  return subtotal + vat;
}

function updateDiscount(payment_period) {
  if (payment_period === 'annual') {
    discount_constant = .85;
    period_constant = 12;
  } else {
    discount_constant = 1;
    period_constant = 1;
  }
}

function updateCurrency(new_currency) {
  if (new_currency != null) {
    currency = new_currency;
  } else {
    currency = 'EUR';
  }
}
