<template lang="pug">
ImportCard(:active="true")
  template(v-slot:title) Responsive Creative

  section
    small.lh-32 Upload assets
      i.nexd-icon-32-help(v-if="!is_sdk_user" aria-hidden="true" v-tooltip="{template: 'cm.quantumImport'}")

    ImportDropArea(:textarea="false" :mime="['image/*', 'video/*', 'application/zip']" :exclude="['.gif']" @change="fileChangeHandler")

  LoadingModal(
    v-if="loading_percentage != null"
    :head="loading_head"
    body="Please wait while files are uploaded and optimized for better performance"
    :percentage="loading_percentage"
  )

  template(v-slot:buttons)
    Button(type="link-primary" label="Cancel" @click="cancel")
</template>

<script>
import { newCreative } from '@helpers/Creative';
import Upload from '@helpers/Upload';

import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import ImportCard from '@cm/Views/Creatives/Cards/Import/ImportCard.vue';
import ImportDropArea from '@cm/Views/Creatives/Cards/Import/ImportDropArea.vue';

import Button from '@master/UI/Buttons/Button.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';

export default {
  name: 'QuantumImport',

  mixins: [SDKMixin],

  components: {
    Button,
    ImportCard,
    ImportDropArea,
    LoadingModal,
  },

  data() {
    return {
      creative: null,
      files: null,
      errors: null,
      loading_head: null,
      loading_percentage: null,
    };
  },

  created() {
    newCreative({ type: 30, device: 3, imported: true }).then(creative => (this.creative = creative));
  },

  methods: {
    fileChangeHandler(event) {
      const { files, errors } = event;
      this.files = files;
      this.errors = errors;
      if (this.files.length > 0) {
        this.importFiles();
      } else {
        this.finish();
      }
    },

    async cancel() {
      // reset all changes to original creative
      let result = await this.$confirm('Are you sure?', 'All changes will be discarded');
      if (result) {
        this.$emit('onRemove');
      }
    },

    importFiles() {
      if (this.files != null && this.files.length > 0) {
        const file_uploads = [];

        const quantum = {
          type: 'quantum',
          assets: [],
        };

        let loadings = [];

        this.loading_percentage = 0;
        this.loading_head = 'Uploading assets';

        for (const file of this.files) {
          let index = loadings.push(0) - 1;
          file_uploads.push(
            new Promise((resolve, reject) => {
              const handler = new Upload(file);
              handler.upload(({ percentage, error, done, upload_id }) => {
                loadings[index] = percentage;
                this.loading_percentage = Math.min(loadings.reduce((a, b) => a + b, 0) / loadings.length, 0.99);

                if (error?.message != null) {
                  reject(error.message);
                } else if (done) {
                  resolve({
                    size: file.size,
                    upload_id: upload_id,
                  });
                }
              });
            }),
          );
        }

        Promise.allSettled(file_uploads).then(promises => {
          for (const promise of promises) {
            if (promise.status === 'fulfilled') {
              quantum.assets.push(promise.value);
            } else if (promise.status === 'rejected') {
              // no error handling in quantum
            }
          }

          const obj = {
            campaign_id: this.$route.params.campaign_id ?? null,
            folder_id: this.$route.params.folder_id ?? null,
            import: [quantum],
          };
          this.create(obj);
        });
      }
    },

    create(obj) {
      this.loading_head = 'Optimizing...';
      const path = 'v2/import/async';
      this.$http
        .post(path, obj)
        .then(response => {
          this.$emit('onCreate', response);
        })
        .finally(() => {
          this.loading_percentage = null;
          this.loading_head = null;
        });
    },
  },
};
</script>
