<template lang="pug">
div.quick-navigation
  router-link.quick-navigation-option(
    :to="{name: 'folder_creatives', params: {campaign_id: folder.campaign_id, folder_id: folder.folder_id}}"
  )
    i.nexd-icon-32-notification-list(aria-hidden="true")
    span Open
</template>

<script>

export default {
  name: 'FolderQuickNavigation',

  props: {
    folder: {
      type: Object,
      default: () => (null)
    }
  }
};
</script>
