<template>
  <div class="tooltip top analytics-total-popup">
    <div class="advanced">
      <template v-if="trackable[0].data != null">
        <div class="row">
          <div :class="[trackable[1] != null ? 'col-14' : 'col-24']">
            <div v-if="trackable[0].filter === 'number'" class="analytics-popup-number">{{trackable[0].data | NumberFilter}}</div>
            <div v-else class="analytics-popup-number">{{trackable[0].data | PercentageFilter}}</div>
            <div class="analytics-popup-label">{{trackable[0].label}}</div>
          </div>
          <div v-if="trackable[1] != null && trackable[1].data != null" class="col-10">
            <div v-if="trackable[1].filter === 'number'" class="analytics-popup-number">{{trackable[1].data | NumberFilter}}</div>
            <div v-else class="analytics-popup-number">{{trackable[1].data | PercentageFilter}}</div>
            <div class="analytics-popup-label">{{trackable[1].label}}</div>
          </div>
        </div>
      </template>
      <template v-for="(item, index) in analytics">
        <div class="row" :key="index">
          <div :class="[item[1] != null ? 'col-14' : 'col-24']">
            <template v-if="item[0] != null && item[0].data != null">
              <div v-if="item[0].filter === 'number'" class="analytics-popup-number">{{item[0].data | NumberFilter}}</div>
              <div v-else class="analytics-popup-number">{{item[0].data | PercentageFilter}}</div>
              <div class="analytics-popup-label">{{item[0].label}}</div>
            </template>
          </div>
          <div class="col-10">
            <template v-if="item[1] != null && item[1].data != null">
              <div v-if="item[1].filter === 'number'" class="analytics-popup-number">{{item[1].data | NumberFilter}}</div>
              <div v-else class="analytics-popup-number">{{item[1].data | PercentageFilter}}</div>
              <div class="analytics-popup-label">{{item[1].label}}</div>
            </template>
          </div>
        </div>
      </template>
      <a v-if="!is_public" href="https://support.nexd.com/en/articles/1462799-view-creative-analytics-data" target="_blank">Read more about impressions</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AnalyticsTotalInfoPopup',
  props: {
    trackable: Array,
    analytics: Array
  },

  computed: {
    is_public() {
      return this.$route.params.public_id != null || this.$route.params.public_live_id != null;
    }
  }
};
</script>
