<template lang="pug">
div.quick-navigation
  router-link.quick-navigation-option(
    :to="folder.path"
  )
    i.nexd-icon-32-notification-list(aria-hidden="true")
    span Open
</template>

<script>

export default {
  name: 'FolderQuickNavigation',

  props: {
    folder: {
      type: Object,
      default: () => (null)
    }
  }
};
</script>
