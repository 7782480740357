<template lang="pug">
div.creative-card-name
  div.status(v-if="!is_sdk_user" :class="creative_status" v-tooltip="{value: status, position: 'right'}")
  div.input
    input(
      ref="input"
      type="text"
      v-model="creative.name"
      :disabled="isProcessing()"
      @blur="save"
    )

  //- icons list when it is not new creative
  div.icons(v-if="!is_new && !isProcessing()")
    template(v-for="(details, icon) in icons")
      template(v-if="details.visible")
        QRCodeModal.cm(v-if="details.qr" :url="creative.preview_url")
          a(
            v-if="creative.qr_code != null"
            :href="creative.qr_code"
            title="QR-code"
            download="qr-image"
            target="_blank"
          ) Open large QR Code in new tab
        DownloadTagsButton(v-else-if="details.tags" :creative="creative")
        i(
          v-else
          :class="[icon, {'disabled': details.disabled === true}]"
          aria-hidden="true"
          v-tooltip="details.tooltip"
          @click="details.cta"
        )
  div.pills(v-if="!is_sdk_user")
    Pill(v-if="admin_toggle && creative.region_id != null" :region="creative.region_id" :google_ads="is_google_ads")
    Pill(v-if="isDeprecatedLayout()" title="Deprecated Layout" variant="subscription-cancelled")
    Pill(:status="creative.status" :google_ads="is_google_ads")
  LoadingModal(v-if="global_loading" :head="global_loading.head" :body="global_loading.body")
  CreativeConfirmDuplicate(v-if="show_duplicate_confirm" :creative="creative" @close="show_duplicate_confirm = false")
</template>

<script>
import DSPService from '@master/Services/Cache/DSPService';
import CreativesService from '@master/Services/CreativesService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import CreativeMixins from '@cm/Views/Creatives/Creative/CreativeMixins.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import QRCodeModal from '@master/UI/QRCodeModal.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';
import Pill from '@master/UI/Pill/Pill.vue';

import CreativeConfirmDuplicate from '@cm/Views/Creatives/Creative/CreativeConfirmDuplicate.vue';
import DownloadTagsButton from '@cm/Views/Creatives/Cards/Components/DownloadTagsButton.vue';

import { setClipboard, getURLForEnv } from '@helpers/Global';
import { DSP, STATUS_CLASS } from '@master/constants';
import Filters from '@master/Filters';

export default {
  name: 'CreativeName',

  mixins: [CreativeTraits, CreativeMixins, SDKMixin],

  components: {
    CreativeConfirmDuplicate,
    DownloadTagsButton,
    LoadingModal,
    Pill,
    QRCodeModal,
  },

  props: {
    creative: {
      type: Object,
    },
  },

  computed: {
    is_google_ads() {
      return this.creative.dsp === DSP.GOOGLEADS;
    },

    can_download_tag() {
      return this.creative.status > 0 && !this.isVideo() && DSPService.hasTag(this.creative.live_dsp || this.creative.dsp);
    },

    can_open_analytics_page() {
      return this.creative.analytics_url != null && !this.free_or_basic_plan_user && !this.is_google_ads;
    },

    icons() {
      return {
        'nexd-icon-32-asset-download': {
          visible: this.admin_toggle,
          tooltip: { value: 'Download all creative assets' },
          disabled: this.downloading,
          cta: () => this.downloadAssets(),
        },
        'nexd-icon-32-unarchive': {
          visible: this.can_archive_creative && this.isArchived() && !this.is_sdk_user,
          tooltip: { value: 'Unarchive creative' },
          cta: () => this.unarchive(),
        },
        'nexd-icon-32-archive1': {
          visible: this.can_archive_creative && !this.isArchived() && !this.is_sdk_user,
          tooltip: { value: 'Archive creative' },
          cta: () => this.archive(),
        },
        'nexd-icon-32-share': {
          visible: this.creative.preview_url != null,
          tooltip: { value: 'Copy link to clipboard' },
          cta: () => this.copyToClipboard(this.creative.preview_url),
        },
        'nexd-icon-32-qr': {
          qr: true,
          visible: true,
        },
        'nexd-icon-32-analytics': {
          visible: this.can_open_analytics_page,
          tooltip: { value: 'Analytics' },
          cta: () => this.openAnalytics(),
        },
        'nexd-icon-32-duplicate': {
          visible: !this.isDeprecatedLayout() && !this.is_sdk_user,
          tooltip: { value: 'Duplicate' },
          cta: () => this.duplicate(),
        },
        'nexd-icon-32-delete': {
          visible: (this.isDraft() || this.isArchived()) && !this.is_sdk_user,
          tooltip: { value: 'Delete' },
          cta: () => this.remove(),
        },
        'nexd-icon-32-tag': {
          tags: true,
          visible: this.can_download_tag && !this.is_sdk_user,
        },
      };
    },

    creative_status() {
      if (this.is_live_with_google_ads) return STATUS_CLASS.GOOGLE_ADS;
      if (this.isDraft()) return STATUS_CLASS.DRAFT;
      if (this.isWaiting()) return STATUS_CLASS.PENDING;
      if (this.isPaused()) return STATUS_CLASS.PAUSED;
      if (this.isLive()) return STATUS_CLASS.LIVE;
      if (this.isFinished()) return STATUS_CLASS.FINISHED;
      if (this.isArchived() || this.isProcessing()) return STATUS_CLASS.ARCHIVED;
      return '';
    },

    status() {
      if (this.is_live_with_google_ads) {
        return 'Exported for: Google Ads';
      }
      return Filters.StatusFilter(this.creative.status, false);
    },

    is_live_with_google_ads() {
      return this.creative.status > 0 && this.is_google_ads;
    },
  },

  data() {
    return {
      global_loading: null,
      admin_toggle: false,
      can_archive_creative: false,
      free_or_basic_plan_user: false,
      downloading: false,
      show_duplicate_confirm: false,
    };
  },

  created() {
    this.$user.subscribe(_ => {
      this.admin_toggle = this.$user.adminMode();
      this.can_archive_creative = this.$user.canArchiveCreative();
      this.free_or_basic_plan_user = this.$user.isFreeOrBasicPlanUser();
    }, this);
  },

  mounted() {
    if (this.is_new) {
      this.$refs['input'].focus();
      this.$refs['input'].select();
    }
  },

  methods: {
    save() {
      CreativesService.updateItems([this.creative]);
      this.$sidebar?.updateCreative(this.creative?.creative_id, this.creative?.name);
      this.$emit('save', 10);
    },

    copyToClipboard(url) {
      if (this.creative?.preview_url == null) return;

      setClipboard(url)
        .then(_ => {
          this.$notifications.add('primary', 'Preview link copied to clipboard');
        })
        .catch(error => {
          this.$notifications.add('warning', error);
        });
    },

    openAnalytics() {
      if (!this.can_open_analytics_page) return;
      window.open(getURLForEnv(this.creative.analytics_url), '_blank');
    },

    duplicate() {
      this.show_duplicate_confirm = true;
    },

    async remove() {
      if (!(await this.$confirm('Are you sure you want to delete this creative?'))) return;

      this.global_loading = {
        head: 'Deleting creative…',
        body: 'Please wait while we remove creative. This may take some time ...',
      };

      this.$http.delete('creatives', { ids: [this.creative.creative_id] }).finally(() => {
        this.global_loading = null;
        this.$emit('delete');
      });
    },

    archive() {
      if (!this.can_archive_creative) return;

      this.$http.put('creatives/archive', { ids: [this.creative.creative_id] }).then(({ creatives }) => {
        CreativesService.updateItems(creatives);
        this.$set(this.creative, 'status', creatives[0]?.status);
      });
    },

    unarchive() {
      if (!this.can_archive_creative) return;

      this.$http.put('creatives/unarchive', { ids: [this.creative.creative_id] }).then(({ creatives }) => {
        CreativesService.updateItems(creatives);
        this.$set(this.creative, 'status', creatives[0]?.status);
      });
    },

    downloadAssets() {
      if (this.downloading) {
        return;
      }

      this.downloading = true;
      this.$http
        .get(`admin/creatives/${this.creative.creative_id}/assets/library/download`)
        .catch(() => {
          /** suppress errors */
        })
        .finally(() => {
          this.downloading = false;
        });
    },
  },
};
</script>
