<template lang="pug">
div.card.campaign-analytics-card(:class="{'collapsed': collapsed === true}")
  div.card-head.pt-16.pb-0
    div.title.co2 emissions data

  template(v-if="!has_data || has_nexd_data")
    div.card-body
      div.row(v-if="has_data")
        CarbonValue(label="CO2 saved" :data="data?.nexd")
        CarbonEquivalent.flex-align-center(v-if="has_data" :equivalent="equivalent_nexd")
      div(v-else) Insufficient data to show CO2 savings

  template(v-else-if="has_scope3_data")
    div.card-body
      div.row
        CarbonValue(label="CO2 saved" :data="data?.scope3?.saved" :tooltip="{value: tooltips.co2_saved}")
        CarbonValue(label="NET CO2 emissions" :data="data?.scope3?.net" :tooltip="{value: tooltips.net_co2_emissions}")
        CarbonValue(label="Data transfer savings" :data="data?.scope3?.datatransfer?.saved" :tooltip="{value: tooltips.data_transfer_savings}")

    template(v-if="!collapsed")
      hr
      div.card-body
        div.row
          CarbonEquivalent.flex-direction-column(:equivalent="equivalent_scope3")
          CarbonValue(label="Gross emissions" :data="data?.scope3?.gross" :secondary="true" :tooltip="{value: tooltips.gross_emissions}")
          section.col-8.flex.flex-direction-column.row-gap-8
            CarbonValue(
              label="Unoptimized data transferred"
              :data="data?.scope3?.datatransfer?.unoptimized"
              :col="false"
              :secondary="true"
              :tooltip="{value: tooltips.unoptimized_data_transferred}"
            )
            CarbonValue.mt-16(
              label="Optimized data transferred"
              :data="data?.scope3?.datatransfer?.optimized"
              :col="false"
              :secondary="true"
              :tooltip="{value: tooltips.optimized_data_transferred}"
            )

    div.card-body.pt-0(v-if="has_scope3_data")
      div.flex.flex-align-center.column-gap-4
        i.nexd-icon-16-check-mini.color-co2-main.bg-co2-light(style="border-radius: 50%;")
        span.fs-14.color-gray-800.flex.flex-align-center Powered by&nbsp;
          Scope3
          | &nbsp;data.

    div.collapse-toggle.toggle-position
      i.nexd-icon-32-arrow-down-small(
        :class="{'active': collapsed === false}"
        aria-hidden="true"
        @click="collapsed = !collapsed"
      )
</template>

<script>
import CarbonEquivalent from '@analytics/UI/CarbonEmissions/CarbonEquivalent.vue';
import CarbonValue from '@analytics/UI/CarbonEmissions/CarbonValue.vue';
import Scope3 from '@analytics/UI/CarbonEmissions/Scope3.vue';

export default {
  name: 'CarbonEmissions',

  components: {
    CarbonEquivalent,
    CarbonValue,
    Scope3,
  },

  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  computed: {
    has_scope3_data() {
      return this.data?.scope3?.saved != null && this.data.scope3.saved.value > 0;
    },

    has_nexd_data() {
      return this.data?.nexd != null && this.data.nexd.value > 0;
    },

    has_data() {
      return this.has_scope3_data || this.has_nexd_data;
    },

    equivalent_scope3() {
      if (!this.has_scope3_data) {
        return null;
      }

      return this.getEquivalent(this.data.scope3.saved.equivalents);
    },

    equivalent_nexd() {
      if (!this.has_nexd_data) {
        return null;
      }

      return this.getEquivalent(this.data.nexd.equivalents);
    },
  },

  data() {
    return {
      collapsed: true,

      tooltips: {
        co2_saved: 'The CO₂ saved, original asset emissions - (minus) our optimized asset emissions.',
        net_co2_emissions: 'These are the net emissions generated by the campaign/creatives. However, since we use green energy, the emissions are always 0.',
        data_transfer_savings: 'The amount of data saved by Nexd in bytes. Unoptimized data transfer - (minus) optimized data transfer.',
        gross_emissions: "Total emissions which would have emerged if we didn't use zero emission energy.",
        unoptimized_data_transferred: 'The amount of unoptimized data transferred, measured in bytes.',
        optimized_data_transferred: 'The amount of data transferred after Nexd optimization, measured in bytes.',
      },
    };
  },

  methods: {
    randomElFromArray(array) {
      return array[Math.floor(Math.random() * array.length)];
    },

    getEquivalent(data) {
      const equivalent = this.randomElFromArray(Object.keys(data));

      return {
        key: equivalent,
        label: this.randomElFromArray(data[equivalent]),
      };
    },
  },
};
</script>
