import ContentService from '@master/Services/ContentService';

class AssetsLibraryService extends ContentService {
  #assets_library_id;

  setID(assets_library_id) {
    this.#assets_library_id = assets_library_id;
  }

  getLibraryID() {
    return this.#assets_library_id;
  }

  load() {
    const path = this.#getPath();

    if (path != null) {
      return super.load(path, false, {
        sort: 'name',
        order: 'asc',
      });
    }
  }

  loadMore() {
    const path = this.#getPath();

    if (path != null) {
      return super.loadMore(path);
    }
  }

  #getPath() {
    if (this.#assets_library_id == null) {
      return null;
    }

    return `assets/library/${this.#assets_library_id}`;
  }
}

export default new AssetsLibraryService();
