<template lang="pug">
TestLayout(title="Notification Box")

  TestSection(subtitle="Default")
    NotificationBox(info="This is default notification box.")

  TestSection(subtitle="Type")
    NotificationBox(
      title="Standard Infeed creative type"
      info="Standard infeed ads are the most common ad type that appears inside of content feeds. They are part of the page flow and scroll along just like the rest of the content."
      href="https://www.nexd.com/ad-types/#mobile-infeed"
    )

  TestSection(subtitle="API Key")
    NotificationBox(title="Make sure to copy your personal API key now." info="You won’t be able to see it again.")

  TestSection(subtitle="Custom width")
    NotificationBox(title="Make sure to copy your personal API key now." info="You won’t be able to see it again." width="120px")
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import NotificationBox from '@master/UI/NotificationBox/NotificationBox.vue';

export default {
  name: 'NotificationBoxTest',

  components: {
    TestLayout,
    TestSection,

    NotificationBox,
  },
};
</script>
