<template lang="pug">
section(:class="{'col-8': col}")
  template(v-if="data?.value != null")
    h2(:class="{'fs-20': secondary}") {{ data.value }}
      template(v-if="data.unit") &nbsp;{{ data.unit }}

    small.color-cyan-blue(v-if="label") {{ label }}
      i.nexd-icon-16-help.ml-4(
        v-if="tooltip"
        aria-hidden="true"
        v-tooltip="tooltip"
      )
</template>

<script>
export default {
  name: 'CarbonValue',

  props: {
    data: Object,
    label: String,

    tooltip: {
      type: Object,
      default: () => null,
    },

    col: {
      type: Boolean,
      default: true,
    },

    secondary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
