<template lang="pug">
TestLayout(title="AnglePicker")

  TestSection(subtitle="Default")
    AnglePicker
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import AnglePicker from '@master/UI/AnglePicker.vue';

export default {
  name: 'AnglePickerTest',

  components: {
    TestLayout,
    TestSection,

    AnglePicker,
  },
};
</script>
