/**
 * @module FlightHelpers
 * @description - Helper functions for flights
 * @test `npm run test:flight`
 */

import { TYPE } from '@master/constants';
import FlightService from '@master/Services/FlightService';

function isExportTypeXML(statements) {
  for (const statement of statements ?? []) {
    if (statement?.creatives?.length > 0) {
      for (const creative of statement.creatives) {
        return creative?.type === TYPE.VAST || creative?.type === TYPE.VPAID;
      }
    }

    if (statement?.statements?.length > 0) {
      return isExportTypeXML(statement.statements);
    }
  }

  return false;
}

export function validateNewCreatives(statement = null, new_creatives = []) {
  const flight = FlightService.get();

  const response = { valid: [], invalid: [] };

  if (flight == null) {
    return response;
  }

  let is_instream = isExportTypeXML(flight?.statements);

  if (!is_instream) {
    is_instream = isExportTypeXML([flight.fallback]);
  }

  const creatives_ids = new Set((statement?.creatives ?? []).map(creative => creative?.creative_id));

  return new_creatives.reduce((acc, creative) => {
    if (creatives_ids.has(creative?.creative_id)) {
      return acc;
    }

    if (creatives_ids.size === 0) {
      response.valid.push(creative);
      return acc;
    }

    const _is_instream = creative?.type === TYPE.VAST || creative?.type === TYPE.VPAID;

    if (is_instream === _is_instream) {
      response.valid.push(creative);
    } else {
      response.invalid.push(creative);
    }

    return acc;
  }, response);
}
