<template lang="pug">
div.card.template-card(:class="{'active': selected }")
  div.template-card-pills
    Pill(v-if="template.exclusive === 1" variant="template-exclusive" title="exclusive")
    Pill(v-if="is_template_new" :status="13")
  div.card-head(@click="toggleCollapse")
    div.template-info
      div.fw-500 {{ template.name }}
    i.nexd-icon-32-right-small.text-right.hover-primary(aria-hidden="true")
</template>

<script>
import { clone } from '@helpers/Global';

import TemplatesService from '@master/Services/TemplatesService';
import CreativeTraits from '@master/Traits/CreativeTraits';

import Pill from '@master/UI/Pill/Pill.vue';

export default {
  name: 'CreativeTemplate',
  mixins: [CreativeTraits],

  components: {
    Pill,
  },

  props: {
    template: Object,

    selected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    is_template_new() {
      return TemplatesService.isNew(this.template);
    },
  },

  data() {
    return {
      recomended_formats: 'leaderboard, tower, square.',
    };
  },

  methods: {
    select() {
      this.$emit('select', clone(this.template));
    },

    toggleCollapse() {
      if (!this.selected) {
        return this.select();
      }
      this.$emit('select', null);
    },
  },
};
</script>
