<template lang="pug">
  div.module-slots
    SlotMaker(v-if="module.slots != null" :creative="creative" :parent_slot="parent_slot" :parent="parent" :slots="module.slots" :key="module.module_id")
</template>

<script>

import ModuleTraits from '@master/Traits/ModuleTraits';

export default {
  name: 'LogoModule',
  mixins: [ModuleTraits],
  beforeCreate () {
    this.$options.components.SlotMaker = require('@cm_modules/SlotMaker').default;
  },
  props: {
    creative: Object,
    module: Object,
    parent: {
      type: Object,
      default: null
    },
    parent_slot: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      object: null,
      module_slots: {}
    };
  },
  created() {
    this.modularitySetup();
  }
};
</script>
