class Mouse {
  constructor(svg, monitor) {
    this.mouse = svg.querySelector('#mouse');
    this.hitbox = this.mouse.querySelector('[id^=HITBOX]');
    this.monitor = monitor;
    this.init();
  }
  init() {
    this.hitbox.classList.add('clickable');
    this.hitbox.addEventListener('click', () => this.toggleRandomWindow());
  }
  toggleRandomWindow() {
    let total = this.monitor.screenItems.length;
    let random = Math.floor(Math.random() * total);
    let item = this.monitor.screenItems[random];
    item.toggle();
  }
}

export default Mouse;
