<template lang="pug">
Container(
  title="Checkout"
  subtitle="Here you can see the changes you have made to your plan"
)
  div(v-if="checkout == null")
    Loading

  div(v-else-if="checkout?.upgrade == null")
    div You haven't made any changes to your plan

  template(v-else)
    section(:class="styles.layout_header")
      div Feature name
      div Quantity
      div Unit price
      div Total
    section(:class="styles.layout")
      template(
        v-if="checkout?.upgrade?.purchase_items != null"
        v-for="(item, index) of checkout?.upgrade?.purchase_items"
      )
        div {{ item?.comment }}
        div {{ item?.amount }}
        div {{ formatValue(item?.raw?.unit_price, true) }}
        div {{ formatValue(item?.amount * item?.raw?.unit_price, true) }}

    section(:class="styles.layout")
      div
      div
      div Subtotal
      div {{ formatValue(checkout?.upgrade?.price_details?.cost_before_vat) }}
      div
      div
      div VAT (20%)
      div {{ formatValue(checkout?.upgrade?.price_details?.vat_cost) }}
      div
      div
      div.fw-500 Total to pay
      div.fs-22.color-primary {{ formatValue(checkout?.upgrade?.price_details?.total_cost) }}
</template>

<script>
import styles from './Checkout.module.scss';

import { formatPrice } from '@cm/Views/Billing/helpers';

import Loading from '@master/UI/Loading';

import Container from '@cm/Views/Billing/Components/Container/Container';

export default {
  name: 'BillingDetails',

  components: {
    Container,
    Loading,
  },

  props: {
    checkout: {
      type: Object,
      default: () => null,
    },

    currency: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      styles,
    };
  },

  methods: {
    formatValue(value, free = false) {
      return formatPrice(value, {
        currency: this.currency,
        free,
      });
    },
  },
};
</script>
