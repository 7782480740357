<template lang="pug">
Card(:class="styles.library")
  div(v-if="disabled" :class="styles.disabled")
  div.fw-500 Creative library
  SearchInput(v-model="query" @input="search")

  div(v-if="campaign != null" :class="{[styles.clickable]: navigator.current != null, [styles['campaign-name']]: true}" @click="goBack")
    i(:class="navigator.current == null ? 'nexd-icon-32-campaign' : 'nexd-icon-32-arrow-right'")
    i.nexd-icon-16-folder.mr-4(v-if="navigator.current != null")
    span.fw-500 {{ navigator.current?.name ?? campaign.name }}

  Loading(v-if="items == null")
  section(v-else ref="scrollable" :class="styles.creatives")
    template(v-for="item of items")
      div(
        :key="item.creative_id ?? item.folder_id"
        :class="{[styles.folder]: item.item_type === LISTITEM.FOLDER, [styles.creative]: true}"
        draggable
        @dragstart.stop="drag($event, item)"
        @dragend.stop="dragEnd"
      )
        span(:class="{[getFlightStatus(item.status)]: true, [styles.status]: true}")
        div(:class="styles.checkbox")
          CustomCheckbox(
            :value="selection.selected_items?.includes(item.item_id) || selection.selected_folders.includes(item.item_id)"
            :padded="true"
            @change="selection.toggle(item)"
          )
        i.nexd-icon-16-folder(v-if="item.item_type === LISTITEM.FOLDER" :class="styles['icon-folder']")
        div.fw-500 {{ item?.name }}
        div.color-gray-800.fs-12
          span(v-if="item?.creative_id != null") {{ item.type_string }}
          span(v-if="item?.creative_id != null && !isSomeSortOfFullscreen(item?.type) && !isQuantum(item?.type)") &nbsp;•&nbsp;
          template(v-if="item.item_type === LISTITEM.FOLDER")
            span {{ item?.meta?.creatives?.title }} creatives
          span(v-else-if="!isSomeSortOfFullscreen(item?.type) && !isQuantum(item?.type)") {{ item.width }}x{{ item.height }}
        i.nexd-icon-32-arrow-right(v-if="item.item_type === LISTITEM.FOLDER" :class="styles['icon-arrow']" @click="goToDir(item)")

    span.color-cyan-blue(v-if="!items.length")
      span(v-if="trimQuery(query) !== ''") No results found for "{{ query }}"
      span(v-else) You haven't created any creatives.
    Buttons.mt-4(v-else-if="can_load_more" align="left")
      Button(type="link" label="Load more creatives" :disabled="items == null" :text="true" @click="loadMoreCreatives")
  Loading.small.mt-4(v-if="loading_more")
</template>

<script>
import styles from './CreativeLibrary.module.scss';

import Queue from '@libs/Queue';
import Navigator from '@libs/Navigator';
import Selection from '@libs/Selection';

import CreativesService from '@master/Services/CreativesService';
import FlightService from '@master/Services/FlightService';
import RequestLimiter from '@libs/RequestLimiter';

import CreativeTraits from '@master/Traits/CreativeTraits';
import { STATUS_CLASS, SEARCH, LISTITEM } from '@master/constants';
import { addFiltersToPath, clone, trimQuery } from '@helpers/Global';
import DraggableListItemMixin from '@root/src/global/mixins/DraggableListItemMixin';

import Button from '@master/UI/Buttons/Button';
import Buttons from '@master/UI/Buttons/Buttons';
import CustomCheckbox from '@master/UI/CustomCheckbox';
import Loading from '@master/UI/Loading';
import SearchInput from '@master/UI/Input/SearchInput';

import Card from '@cm/Views/Flights/Components/Card.vue';

export default {
  name: 'CreativeLibrary',
  mixins: [CreativeTraits, DraggableListItemMixin],

  components: {
    Button,
    Buttons,
    Card,
    CustomCheckbox,
    Loading,
    SearchInput,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    request_key() {
      if (this.navigator.current == null) {
        return `v2/campaigns/${this.$route.params.campaign_id}/creatives/view`;
      }

      return `v2/folder/${this.navigator.current?.folder_id}/creatives/view`;
    },
  },

  data() {
    return {
      styles,
      trimQuery,
      LISTITEM,

      navigator: new Navigator(null),
      queue: new Queue(),
      selection: new Selection(),

      campaign: null,
      items: null,
      can_load_more: false,

      loading_more: false,
      query: '',
    };
  },

  created() {
    CreativesService.subscribe(response => {
      this.items = response?.items;
      this.campaign = response?.campaign;
      this.can_load_more = response?.can_load_more ?? false;

      this.selection.data = this.items;

      this.updateFlightService();

      if (this.loading_more) {
        this.loading_more = false;
      }
    }, this);

    this.loadCreatives();
  },

  methods: {
    initLoad() {
      this.selection.unselectAll();
      this.scrollToTop();
      this.items = null;
    },

    scrollToTop() {
      if (this.$refs?.scrollable != null) {
        this.$refs.scrollable.scrollTop = 0;
      }
    },

    loadCreatives() {
      this.query = '';
      this.initLoad();

      CreativesService.load(this.request_key);
    },

    loadMoreCreatives() {
      if (this.can_load_more) {
        this.loading_more = true;
        CreativesService.loadMore(this.request_key);
      }
    },

    searchCreatives() {
      this.navigator.goToRoot();
      this.initLoad();

      const options = {
        search: this.query,
        scope: SEARCH.CAMPAIGN,
        id: this.campaign.campaign_id,
      };

      // not ading search more option right now
      this.can_load_more = false;

      this.queue
        .get(addFiltersToPath('search/advanced', options), options)
        .then(({ result }) => {
          const { creatives, folders } = result;
          const items = [];

          if (folders?.length > 0) {
            items.push(...folders);
          }

          if (creatives?.length > 0) {
            items.push(...creatives);
            FlightService.creatives.set(creatives);
          }

          this.items = items;
        })
        .catch(() => {
          /** suppress error */
        });
    },

    search() {
      // when clearing search field, start from view root instead
      if (!this.query || trimQuery(this.query) === '') {
        this.loadCreatives();
        return;
      }

      RequestLimiter.hook('creative-library-search', () => this.searchCreatives(), 500);
    },

    goToDir(dir) {
      this.navigator.goToDir(dir);
      this.loadCreatives();
    },

    goBack() {
      this.navigator.goBack();
      this.loadCreatives();
    },

    getFlightStatus(status) {
      if (this.isDraft(status)) return this.styles[STATUS_CLASS.DRAFT];
      if (this.isWaiting(status)) return this.styles[STATUS_CLASS.PENDING];
      if (this.isPaused(status)) return this.styles[STATUS_CLASS.PAUSED];
      if (this.isLive(status)) return this.styles[STATUS_CLASS.LIVE];
      if (this.isFinished(status)) return this.styles[STATUS_CLASS.FINISHED];
      if (this.isArchived(status) || this.isDuplicationInProgress(status) || this.isImportInProgress(status)) return this.styles[STATUS_CLASS.ARCHIVED];
      return '';
    },

    drag(event, item) {
      event.dataTransfer.setData('action', 'add-creatives-to-flight');

      if (!this.selection.isSelected(item)) {
        this.selection.unselectAll();
        this.selection.select(item);
      }

      const payload = clone({
        creative_ids: this.selection.selected_items,
        folder_ids: this.selection.selected_folders,
      });

      event.dataTransfer.setData('application/payload', JSON.stringify(payload));

      event.dataTransfer.setDragImage(this.createDraggable(item?.name ?? 'Creative', payload.creative_ids?.length + payload.folder_ids?.length, event), 0, 0);

      this.$store.set('flights_drag_creatives_in_progress', true);
      this.$store.set('clear_creative_library_selection', () => {
        this.selection.unselectAll();
      });
    },

    dragEnd() {
      this.$store.reset('flights_drag_creatives_in_progress');
      this.$store.reset('clear_creative_library_selection');
    },

    updateFlightService() {
      if (this.items?.length > 0) {
        FlightService.creatives.set(this.items?.filter(item => item.item_type === LISTITEM.CREATIVE));
      }
    },
  },
};
</script>
