import '@root/src/styles/main.scss';

import Vue from 'vue';
import App from './App.vue';
import router from './router/Router';
import WhitelabelService from '@master/Services/WhitelabelService';
import constants from '@master/constants';
import { loadEngageBay, initGoogleAnalytics } from '@helpers/Global';

import GoogleAnalyticsPlugin from '@master/Plugins/GoogleAnalyticsPlugin';
Vue.use(GoogleAnalyticsPlugin);

import GlobalServicePlugin from '@master/Plugins/GlobalServicePlugin';
Vue.use(GlobalServicePlugin, router);

import ServicePlugin from './ServicePlugin';
Vue.use(ServicePlugin, router);

import NexdFilters from '@master/Plugins/NexdFilters';
Vue.use(NexdFilters);

import NexdDirectives from '@master/Plugins/NexdDirectives';
Vue.use(NexdDirectives);

import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);

import AppStore from '@root/src/store/AppStore';
Vue.use(AppStore);

import CropperPlugin from '@master/Plugins/CropperPlugin';
Vue.use(CropperPlugin);

import AlertPlugin from '@master/Plugins/AlertPlugin';
Vue.use(AlertPlugin);

const date = new Date();
const month = date.getMonth();
const day = date.getDate();

if (month === 3 && day === 1) {
  // includes sparkles / emojis following mouse
  // window._april_fools_sparkles = true;
  if (window._april_fools_sparkles) {
    // april fools to have mouse cursor followed by emojis
    // require('@libs/sparkles');
  }

  // includes elevator music and 60s login timer
  window._april_fools_elevator_login = true;

  // add button kill animation
} else if (month === 11) {
  window._christmas_8bit = true;
}

// enable debug messages from libs, cropper & preview component etc
if (process.env.NODE_ENV === 'development') {
  window.debug = false;
}

function run() {
  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app');
}

loadEngageBay();
initGoogleAnalytics();

constants.ready(_ => {
  WhitelabelService.ready(whitelabel => {
    Vue.prototype.$whitelabel = whitelabel;
    run();
  });
});
