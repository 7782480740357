<template lang="pug">
div.card.campaign-analytics-card(:class="{'collapsed': collapsed === true}")
  div.card-head
    div.title {{title}}
    i(:class="icon" aria-hidden="true")
  div.card-body
    CardChart(v-if="chart" :chart="chart")
    Loading(v-else)

    template(v-if="type === 'impressions'")
      div.mb-16.total-popup
        AnalyticsTotalInfoPopup(
          :trackable="analytics_popup_data"
          :analytics="info_popup_content")
        h1(:class="[filters.base !== 'impressions' ? '' : analytics.total.viewable != null && analytics.total.viewable !== 0 && filters.base === 'viewable' ? 'impressions' : '']")
          | {{analytics.total.impressions | NumberFilter}}
        small.color-cyan-blue Impressions

      div(v-if="analytics.total.viewable != null && Controller.isViewabilityEnabled() === true && filters.base !== 'viewable'")
        div.inline-block(v-tooltip="{template: 'analytics.viewable'}")
          h3(:class="[filters.base === 'viewable' ? 'viewable' : '']") {{analytics.total.viewable | NumberFilter}}
          small.color-cyan-blue Viewable Impressions
      template(v-else)
        h3 &nbsp;
        small &nbsp;

    template(v-else-if="type === 'engagement'")
      div.mb-16
        div.inline-block(v-tooltip="{template: 'analytics.engagement'}")
          h1 {{analytics.total.engagement | PercentageFilter}}
          small.color-cyan-blue Overall Engagement
      div.row
        div.col.flex-grow(v-tooltip="{template: 'analytics.engagementImpressions'}")
          h3 {{analytics.total.engagements | NumberFilter}}
          small.color-cyan-blue Engaged Impressions
        div.col-10(v-if="analytics.vtr != null")
          h3 {{analytics.vtr.total.vtr_100 | PercentageFilter}}
          small.color-cyan-blue VTR 100%

    template(v-else-if="type === 'ctr'")
      div.mb-16
        div.inline-block(v-tooltip="{template: 'analytics.ctr'}")
          h1 {{analytics.total.ctr | PercentageFilter}}
          small.color-cyan-blue Overall CTR
      div
        div.inline-block(v-tooltip="{template: 'analytics.clicks'}")
          h3 {{analytics.total.clicks | NumberFilter}}
          small.color-cyan-blue Total Clicks

  template(v-if="type === 'impressions'")
    hr
    ImpressionsAddon(:analytics="analytics")
  template(v-else-if="type === 'engagement' && analytics.vtr != null")
    hr
    EngagementsAddon(:analytics="analytics")
  hr
  div.card-body.transparent
    SummaryCardFooter(:analytics="analytics.division")
  div.collapse-toggle.toggle-position
    i.nexd-icon-32-arrow-down-small(aria-hidden="true" :class="{'active': collapsed === false}" @click="toggleHandler")
</template>

<script>
import CardChart from '@analytics/Views/Campaign/Components/CardChart';
import SummaryCardFooter from '@analytics/Views/Campaign/Components/SummaryCardFooter';
import { filters, Controller } from '@master/Services/AnalyticsAPPService';
import AnalyticsTotalInfoPopup from '@analytics/Views/Campaign/Components/AnalyticsTotalInfoPopup';
import ImpressionsAddon from './Components/ImpressionsAddon';
import EngagementsAddon from './Components/EngagementsAddon';
import Loading from '@master/UI/Loading';

export default {
  name: 'SummaryCard',

  components: {
    CardChart,
    SummaryCardFooter,
    AnalyticsTotalInfoPopup,
    ImpressionsAddon,
    EngagementsAddon,
    Loading,
  },

  props: {
    type: String,

    analytics: Object,

    chart: Array,

    validators: {
      type: Object,
      default: null,
    },
  },

  computed: {
    icon() {
      if (this.type === 'impressions') return 'nexd-icon-32-seen';
      if (this.type === 'engagement') return 'nexd-icon-32-engagement';
      if (this.type === 'ctr') return 'nexd-icon-32-ctr-mobile';
      return null;
    },

    analytics_popup_data() {
      const out = [
        {
          data: this.analytics.total.trackable,
          filter: 'number',
          label: 'Tracked ads',
        },
      ];
      if (this.admin_toggle) {
        out.push({
          data: this.analytics.total.billable,
          filter: 'number',
          label: 'Billable',
        });
      }
      return out;
    },

    info_popup_content() {
      let content = [
        [
          {
            data: this.analytics.total.loaded,
            filter: 'number',
            label: 'Loaded Impr.',
          },
          {
            data: this.analytics.total.load_rate,
            filter: 'percentage',
            label: 'Load rate',
          },
        ],
        [
          {
            data: !Controller.isViewabilityEnabled() ? null : this.analytics.total.viewable,
            filter: 'number',
            label: 'Viewable Impr.',
          },
          {
            data: !Controller.isViewabilityEnabled() ? null : this.analytics.total.viewability,
            filter: 'percentage',
            label: 'Viewability',
          },
        ],
      ];

      if (this.admin_toggle && this.validators) {
        content.push([
          {
            data: this.validators.total.impressions,
            filter: 'number',
            label: 'Robot & Validator Impressions',
          },
        ]);
      }

      return content;
    },
  },
  data() {
    return {
      admin_toggle: false,

      collapsed: true,
      title: null,
      filters,
      Controller,
    };
  },

  created() {
    if (this.type === 'impressions') {
      this.title = 'Impressions';
    } else if (this.type === 'engagement') {
      this.title = 'Engagement';
    } else if (this.type === 'ctr') {
      this.title = 'Click-Through-Rate';
    }

    this.$user.subscribe(_ => {
      this.admin_toggle = this.$user.adminMode();
    }, this);
  },

  methods: {
    // called from parent component
    toggle() {
      this.collapsed = !this.collapsed;
    },

    toggleHandler() {
      this.$emit('toggle');
    },
  },
};
</script>
