<template lang="pug">
div.flex.flex-align-center.flex-justify-center.flex-direction-column.row-gap-8
  img(:src="`${$cdn}dist/assets/cm/analytics-icon.svg`")
  span.fs-14.color-gray-800.fw-400 You do not have enough data yet.
</template>

<script>
export default {
  name: 'NoData'
};
</script>
