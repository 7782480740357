<template lang="pug">
div.col.flex-grow(v-if="creative != null")
  div.template-name
    span(v-if="creative.imported") Imported&nbsp;
    span VAST
  div.template-description
    | Upload an video and we will convert and optimize it to fit&nbsp;
    a(
      href="https://www.iab.com/guidelines/digital-video-ad-serving-template-vast/"
      target="_blank"
    ) VAST standard&nbsp;
    | (for viewing on different sizes and devices).
    | Furthermore, most features like resizing, cropping, and effects are available
  div.row
    div.col.flex-grow
      Button.fs-12(v-if="!is_sdk_user" type="link" label="Specs and examples" href="https://www.nexd.com/ad-types/#instream-video-ad")
        template(v-slot:prefix)
          IconReport
    div.col(v-if="creative.imported")
      Button(
        type="primary"
        :outline="true"
        :label="has_main_asset ? 'Replace VAST' : 'Import VAST'"
        @click="$emit('showVASTImport')"
      )
</template>

<script>
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import Button from '@master/UI/Buttons/Button.vue';
import IconReport from '@master/UI/Buttons/Icons/IconReport.vue';

export default {
  name: 'CreativeLayoutBlockVAST',

  mixins: [SDKMixin],

  components: {
    Button,
    IconReport,
  },

  props: {
    creative: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    main_media_id() {
      if (this.creative.template == null) return null;
      for (let asset in this.creative.template.assets) {
        if (this.creative.template.assets[asset].name === 'Main media') {
          return this.creative.template.assets[asset].asset_id;
        }
      }
      return null;
    },

    has_main_asset() {
      for (let asset in this.creative.assets) {
        if (asset === this.main_media_id) return true;
      }
      return false;
    },
  },
};
</script>
