<script>
export default {
  name: 'Modal',

  created() {
    // make body non scrollable
    document.body.style.overflow = 'hidden';
  },

  mounted() {
    if (this.$refs.modal != null) {
      this._modal_referenced_child = this.$refs.modal.firstChild;
      document.body.appendChild(this._modal_referenced_child);
    } else {
      document.body.appendChild(this.$el);
    }
  },

  beforeDestroy() {
    if (this.$refs.modal != null && this._modal_referenced_child != null) {
      this.$el.appendChild(this._modal_referenced_child);
    }
  },

  destroyed() {
    // add scroll back to body
    document.body.style.overflow = null;
  },
};
</script>
