<template lang="pug">
div
  h3.mb-16(v-if="title != null") {{ title }}
  section(:class="styles.packages")
    slot
</template>

<script>
import styles from './PackageCalculator.module.scss';

export default {
  name: 'Packages',

  props: {
    title: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      styles
    };
  }
};
</script>
