<template lang="pug">
div#app-wrapper.full-width
  div#billing-navbar(v-if="navbar" ref="campaigns-navbar" :class="styles.navbar")
    div#app-wrapper.full-width(:class="{[styles.navbar_content]: true, [styles.navbar_content_sidebar]: sidebar}")
      slot(name="navbar")

  div(:class="styles.content_container" :style="{ 'margin-top': content_top + 'px' }")
    section(:class="styles.content")
      slot(name="content")

    slot(name="sidebar")
</template>

<script>
import styles from './BillingLayoutContainer.module.scss';

export default {
  name: 'BillingLayoutContainer',

  props: {
    sidebar: {
      type: Boolean,
      default: false
    },

    navbar: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      styles,
      content_top: 0
    };
  },

  async mounted() {
    if (this.$refs?.['campaigns-navbar'] != null && this.navbar) {
      this.content_top = this.$refs['campaigns-navbar'].offsetHeight;
    }
  }
};
</script>
