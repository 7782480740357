<template lang="pug">
div(v-observe-visibility="toggleVisibility")
  div.view-creative-header
    div.row.flex-align-center
      h1.col.no-gutter-right Compare Results
      div.col.flex-grow.relative
        div.collapse-card-toggle
          i.nexd-icon-32-arrow-down-small(:class="{'active': collapsed === false}" aria-hidden="true" @click="toggleCollapse")
        hr.line
  template(v-if="!collapsed")
    div.card.campaign-analytics-card(v-if="compare_analytics")
      div.card-body
        div.row.mb-14
          div.col.fs-14 Show data for:
          label.col.checkbox(v-for="placement_type of compare_analytics.types" :key="placement_type.value")
            input(type="checkbox" v-model="selections.types[placement_type.value]")
            span {{placement_type.label}}
        div.row.mb-24
          div.col.fs-14 Shown in:
          label.col.checkbox(v-for="device of compare_analytics.devices" :key="device.value")
            input(type="checkbox" v-model="selections.devices[device.value]")
            span {{device.label}}
        div.flex.mb-16
          div.flex-grow
            FilterSelect(v-model="selections.charts[0]")
          div
            FilterSelect(v-model="selections.charts[1]" :default="3")
        CompareChart(v-if="compare_analytics.analytics" :labels="compare_analytics.labels" :analytics="compare_analytics.analytics" :selections="selections")
    div.text-center(v-else-if="has_error")
      p.mb-8 Loading analytics failed...
      Button(type="primary" :outline="true" label="Retry" @click="loadAnalytics")
    Loading(v-else)
</template>

<script>

import { filters } from '@master/Services/AnalyticsAPPService';
import AnalyticsService from '@master/Services/AnalyticsService';
import FilterSelect from '@analytics/UI/FilterSelect';
import CompareChart from '@analytics/UI/Charts/Campaign/CompareChart';
import Loading from '@master/UI/Loading';
import Button from '@master/UI/Buttons/Button';

export default {
  name: 'CompareCreatives',
  components: {
    FilterSelect,
    CompareChart,
    Loading,
    Button
  },

  computed: {
    request_data() {
      return {
        base: filters.base,
        startDate: filters.start,
        endDate: filters.end,
        incvtr: filters.incvtr,
        traffic: filters.traffic,
        partner: this.$route.query.partner || null
      };
    }
  },

  data() {
    return {
      compare_analytics: null,
      has_error: false,
      visible: false,
      selections: {
        types: {},
        devices: {},
        charts: [
          null, null
        ],
      },
      collapsed: true
    };
  },

  methods: {
    loadAnalytics(retry = true) {
      const path = AnalyticsService.getActiveParentComparePath();

      if (this.compare_analytics || !this.visible || this.collapsed || path == null) return;

      this.has_error = false;

      this.$http.post(path, this.request_data)
        .then(response => {
          this.compare_analytics = response.comparisson;

          const keys = ['types', 'devices'];

          for (const key of keys) {
            for (const item of this.compare_analytics[key]) {
              if (item.value === 'total') {
                this.$set(this.selections[key], item.value, true);
              } else {
                this.$set(this.selections[key], item.value, false);
              }
            }
          }
        })
        .catch(error => {
          if (retry) {
            AnalyticsService.loadAgain(error?.code ?? 500, retry, () => this.loadAnalytics(false));
          } else {
            this.has_error = true;
          }
        });
    },

    toggleVisibility(visible) {
      this.visible = visible;
      this.loadAnalytics();
    },

    toggleCollapse() {
      this.collapsed = !this.collapsed;
      this.loadAnalytics();
    }
  }
};
</script>
