<script>

import { truncateString } from '@helpers/Global';

const DRAG_IGNORE_OFFSET = 200;
const SCROLL_TICK = 35;
const SCROLL_DIST = 8;

export default {
  data() {
    return {
      drag_start_y: 0,
      scrolling_speed: 0,
      draggable_canvas: null,
      draggable_image: null,
      scrolling_interval: null
    };
  },

  methods: {
    prepareDraggableImage() {
      const image = new Image();
      image.src = `${this.$cdn}dist/assets/cm/stack.svg`;
      image.onload = () => {
        this.draggable_image = image;
      };
    },

    createDraggable(name, count = 1, event = null) {
      this.drag_start_y = event?.y ?? null;
      this.scrolling_interval = window.setInterval(_ => {
        window.scrollBy(0, SCROLL_DIST * this.scrolling_speed);
      }, SCROLL_TICK);

      const SCALE = window.devicePixelRatio ?? 1;

      // padding for the numeric value to be over the edge
      const PADDING = 8;
      const WIDTH = 240;
      const HEIGHT = 48;
      const THUMBNAIL_WIDTH = HEIGHT - 2 * PADDING;
      const THUMBNAIL_HEIGHT = HEIGHT - 2 * PADDING;
      const THUMBNAIL_X = 2 * PADDING;
      const THUMBNAIL_Y = 2 * PADDING;

      // canvas
      let canvas = document.createElement('canvas');
      canvas.width = (WIDTH + 2 * PADDING) * SCALE;
      canvas.height = (HEIGHT + 2 * PADDING) * SCALE;

      canvas.style.width = WIDTH + PADDING * 2 + 'px';
      canvas.style.height = HEIGHT + PADDING * 2 + 'px';

      // hide canvas from screen
      canvas.style.position = 'fixed';
      canvas.style.zIndex = -1;
      canvas.style.left = '-1000px';

      // context
      let ctx = canvas.getContext('2d');
      ctx.setTransform(SCALE, 0, 0, SCALE, 0, 0);

      // background
      ctx.beginPath();
      ctx.fillStyle = '#fff';
      ctx.fillRect(PADDING, PADDING, WIDTH, HEIGHT);

      // border
      ctx.beginPath();
      ctx.strokeStyle = '#3388FF';
      ctx.lineWidth = 1;
      ctx.roundRect?.(PADDING, PADDING, WIDTH, HEIGHT, PADDING * 0.5);
      ctx.stroke();

      // name
      ctx.fillStyle = '#000';
      ctx.font = '500 14px Roboto';
      ctx.textBaseline = 'top';

      const TEXT_OFFSET_FIX = 2;
      ctx.fillText(truncateString(name, 25), PADDING * 3 + THUMBNAIL_WIDTH, HEIGHT * 0.5 + PADDING * 0.5 - TEXT_OFFSET_FIX);

      // rectangle
      ctx.beginPath();
      ctx.roundRect?.(2 * PADDING, 2 * PADDING, THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT, PADDING * 0.5);
      ctx.fillStyle = '#C8D2E1';
      ctx.fill();

      if (this.draggable_image) {
        ctx.drawImage(this.draggable_image, THUMBNAIL_X + PADDING / 2, THUMBNAIL_Y + PADDING / 2);
      }

      if (count > 1) {
        const BUBBLE_RADIUS = PADDING * 1.5;
        const BUBBLE_X = WIDTH + PADDING * .5;
        const BUBBLE_Y = BUBBLE_RADIUS;

        // bubble
        ctx.beginPath();
        ctx.fillStyle = '#486A9B';
        ctx.arc(BUBBLE_X, BUBBLE_Y, BUBBLE_RADIUS, 0, 2 * Math.PI, false);
        ctx.fill();

        // bubble text
        const text = count > 99 ? '99+' : count;
        ctx.font = '500 12px Roboto';
        ctx.fillStyle = '#fff';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.fillText(text, BUBBLE_X, PADDING);
      }

      this.$el.appendChild(canvas);
      this.draggable_canvas = canvas;

      return canvas;
    },

    removeDraggable() {
      this.drag_start_y = null;
      this.scrolling_speed = 0;
      window.clearInterval(this.scrolling_interval);
      if (!this.draggable_canvas) return;
      this.draggable_canvas.remove();
      this.draggable_canvas = null;
    },

    isDraggableActive() {
      return this.draggable_canvas !== null;
    },

    dragScroll(e) {
      if (!this.isDraggableActive()) return;
      if (this.drag_start_y == null) return 0;

      // vue triggers x = 0 before end, weird...
      if (e.x === 0) {
        this.scrolling_speed = 0;
        return 0;
      }

      let offset = DRAG_IGNORE_OFFSET;
      const up = e.y < this.drag_start_y;
      const down = e.y > this.drag_start_y;

      // if drag starts near top, add smaller offset towards up movement
      if (up && this.drag_start_y < DRAG_IGNORE_OFFSET) {
        offset = this.drag_start_y * 0.5;
      }

      // if drag starts near bottom, add smaller offset towards down movement
      if (down && this.drag_start_y + DRAG_IGNORE_OFFSET > window.innerHeight) {
        offset = (window.innerHeight - this.drag_start_y) * 0.5;
      }

      const dy = e.y - this.drag_start_y;

      this.scrolling_speed = 0;
      if (up && (dy <= offset * -1 || e.y <= offset)) {
        // up
        this.scrolling_speed = Math.floor(dy / offset);
      } else if (down && (dy >= offset || e.y + offset >= window.innerHeight)) {
        // down
        this.scrolling_speed = Math.floor(dy / offset);
      }
    }
  }
};
</script>
