<template lang="pug">
div.countdown-widget.effect-body
  div.form-group
    div.row.flex-align-center
      div.col.flex.flex-align-center
        label.color-black End date and time:
        i.nexd-icon-16-help.ml-8(v-tooltip="{value: 'Select the future deadline that the countdown is counting towards'}")
      SelectDateRange.col-12(
        :start="item.end_date"
        :timezone="item.timezone"
        :options="{time: true, single: true, range: false}"
        :placeholder="item.end_date | DateFilter({ time: true, utc: true})"
        @change="calendarChangeHandler"
      )
  div.flex.flex-align-center.mb-4
    span Show:
    i.nexd-icon-16-help.ml-8(v-tooltip="{template: 'cm.countDownLabels'}")
  div.row
    div.col-12
      div.form-group
        div.row
          div.col.flex.flex-align-center
            CustomCheckbox(:value="item.numbers.days" @change="changeNumbersHandler($event, 'days')")
          div.col.flex-grow
            input.form-control(type="text" v-model="item.labels.days" placeholder="Days label" :disabled="!item.numbers.days" @change="changeHandler")
    div.col-12
      div.form-group
        div.row
          div.col.flex.flex-align-center
            CustomCheckbox(:value="item.numbers.hours" @change="changeNumbersHandler($event, 'hours')")
          div.col.flex-grow
            input.form-control(type="text" v-model="item.labels.hours" placeholder="Hours label" :disabled="!item.numbers.hours" @change="changeHandler")
  div.row
    div.col-12
      div.form-group
        div.row
          div.col.flex.flex-align-center
            CustomCheckbox(:value="item.numbers.minutes" @change="changeNumbersHandler($event, 'minutes')")
          div.col.flex-grow
            input.form-control(type="text" v-model="item.labels.minutes" placeholder="Minutes label" :disabled="!item.numbers.minutes" @change="changeHandler")
    div.col-12
      div.form-group
        div.row
          div.col.flex.flex-align-center
            CustomCheckbox(:value="item.numbers.seconds" @change="changeNumbersHandler($event, 'seconds')")
          div.col.flex-grow
            input.form-control(type="text" v-model="item.labels.seconds" placeholder="Seconds label" :disabled="!item.numbers.seconds" @change="changeHandler")
  p
    span Settings:
    i.nexd-icon-16-help.ml-8(v-tooltip="{value: 'Select which color the time unit numbers and labels are shown'}")
  div.row.flex-align-center.mb-12
    small.col-5 Numbers:
    div.col-7
      ColorOption(v-model="item.numbers.color" @input="changeHandler")
    small.col-5 Opacity:
    StyleInput.col-7(v-model="item.numbers.alpha" :default="1" :min="0" :max="1" :scale="100" label="%" @input="changeHandler")

  div.row.flex-align-center.mb-12
    small.col-5 Labels:
    div.col-7
      ColorOption(v-model="item.labels.color" @input="changeHandler")
    small.col-5 Opacity:
    StyleInput.col-7(v-model="item.labels.alpha" :default="1" :min="0" :max="1" :scale="100" label="%" @input="changeHandler")

  div.row.flex-align-center.mb-16
    small.col-5 Background:
    div.col-7
      ColorOption(v-model="item.background.color" @input="changeHandler")
    small.col-5 Opacity:
    StyleInput.col-7(v-model="item.background.alpha" :default="1" :min="0" :max="1" :scale="100" label="%" @input="changeHandler")

  Button(type="primary" :outline="true" label="View & Edit" @click="openEditor")
    template(v-slot:prefix)
      IconEdit
</template>

<script>
import StyleInput from '@cm/UI/Inputs/StyleInput';
import ColorOption from '@master/UI/ColorOption';
import AssetHelper from '@helpers/Asset';
import CustomCheckbox from '@master/UI/CustomCheckbox';
import Button from '@master/UI/Buttons/Button';
import IconEdit from '@master/UI/Buttons/Icons/IconEdit';
import SelectDateRange from '@master/UI/SearchSelect/SelectDateRange.vue';
import RequestLimiter from '@libs/RequestLimiter';

import moment from 'moment';

export default {
  name: 'CountdownWidget',

  components: {
    StyleInput,
    ColorOption,
    CustomCheckbox,
    Button,
    IconEdit,
    SelectDateRange,
  },

  props: {
    creative: Object,
    item: Object,
  },

  data() {
    return {
      Cropper: this.$cropper(),
      change_timeout: null,
    };
  },

  methods: {
    calendarChangeHandler({ start, timezone }) {
      this.$set(this.item, 'end_date', start);
      this.$set(this.item, 'timezone', timezone);
      this.changeHandler();
    },

    openEditor() {
      this.Cropper.onsave = settings => {
        this.$set(this.item, 'settings', settings);
        this.changeHandler();
      };

      const sizes = AssetHelper.getSize(this.creative);

      const cropper_options = {
        cropSize: sizes.dimensions,
        achor: 'center',
        position: 'absolute',
        module: 'countdown',
        placement: {
          size: sizes.total,
          adsize: sizes.total,
        },
      };

      this.Cropper.init(cropper_options);

      const module_options = {
        size: {
          original: {
            height: 80, // used by croptool to determine output scale
          },
        },
        ratio: 19 / 6, // fallback ratio if preview component is not found
      };

      // MVP before sorting out Teele getting module size, use this sizes

      // checked all sizes options, there was an actual pattern
      // all options: 4.8
      // 3 options: 3.6
      // 2 options: 2.4
      // 1 options: 1.2
      const ratio_per_item = 1.2;

      const items = ['days', 'hours', 'minutes', 'seconds'];
      let active_items = 0;
      for (const item of items) {
        if (this.item?.numbers?.[item]) {
          active_items++;
        }
      }
      module_options.ratio = ratio_per_item * active_items;

      this.Cropper.editModule(this.item.settings, module_options);
    },

    changeNumbersHandler(checked, key) {
      this.item.numbers[key] = checked;
      this.changeHandler();
    },

    changeHandler() {
      RequestLimiter.hook('countdown-change', () => {
        this.$emit('change');
      });
    },
  },

  watch: {
    'item.end_date': {
      handler(newval) {
        if (newval == null) {
          this.$set(this.item, 'end_date', moment.utc().add(14, 'days').set('hour', 12).set('minutes', 0).unix());
        }
      },
      immediate: true,
    },
  },
};
</script>
