import ContentService from '@master/Services/ContentService';
import UserService from '@master/Services/UserService';
import ActiveState from '@libs/ActiveState';

class GroupAssetsLibraryService extends ContentService {
  active = new ActiveState();

  load() {
    const path = this.#getPath();

    if (path != null) {
      return super.load(path);
    }
  }

  loadMore() {
    const path = this.#getPath();

    if (path != null) {
      return super.loadMore(path);
    }
  }

  updateSorting(sort, reverse) {
    const path = this.#getPath();

    if (path != null) {
      return super.updateSorting(sort, path, reverse);
    }
  }

  getLibraryID() {
    return UserService.getGroup()?.library?.library_id;
  }

  deleteSelected(original_asset_ids, folder_ids) {
    const library_id = this.getLibraryID();

    if (!library_id) {
      return;
    }

    this.removeItems([...original_asset_ids, ...folder_ids]);

    return this.$http.delete(`assets/library/${library_id}/bulk`, { original_asset_ids, folder_ids });
  }

  removeFromFolder(original_assets, folders) {
    const folder_id = this.getId('folder_id');

    if (folder_id == null) {
      return;
    }

    this.removeItems([...original_assets, ...folders]);

    return this.$http.delete(`v2/folder/${folder_id}/items`, { original_assets, folders });
  }

  #getPath() {
    const library_id = this.getLibraryID();

    if (!library_id) {
      return null;
    }

    let path = `assets/library/${library_id}`;
    const folder_id = this.getId('folder_id');

    if (folder_id != null) {
      path += `/folder/${folder_id}`;
    }

    return path;
  }

  columns = [null, null, { name: 'Name', key: 'name' }, { name: 'Size', key: 'size' }, { name: 'Uploaded By', key: 'owner' }, { name: 'Date Added', key: 'created_on' }];
}

export default new GroupAssetsLibraryService();
