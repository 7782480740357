<template lang="pug">
div(:class="[styles.card, active ? styles.active : '', replace ? styles.replace : '']")

  div.fw-500.fs-16(v-if="$slots.title?.length > 0" :class="styles.title")
    slot(name="title")

  slot

  Buttons(v-if="$slots.buttons?.length > 0")
    slot(name="buttons")
</template>

<script>
import styles from './Import.module.scss';

import Buttons from '@master/UI/Buttons/Buttons';

export default {
  name: 'Card',

  components: {
    Buttons
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },

    replace: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      styles
    };
  }
};
</script>
