<template lang="pug">
div.relative
  input.form-control(
    type="text"
    v-model="component_value"
    placeholder="Enter Advertiser"
    :disabled="disabled"
    @keyup="searchHandler"
    @focus="searchHandler"
    @blur="save"
  )

  OptionsContainer(v-if="visible_list.length > 0")
    template(v-slot="{scope}")
      div(
        v-for="(option, index) of visible_list"
        :key="index"
        :class="scope.styles.option"
        @mousedown="selectAdvertiser(option)"
      )
        div(:class="scope.styles.option_row")
          span {{ option.label ?? option.value }}
</template>

<script>
import AdvertisersService from '@master/Services/Cache/AdvertisersService';

import { trimQuery, matchQuery } from '@helpers/Global';

import OptionsContainer from '@master/UI/SearchSelect/Components/OptionsContainer.vue';

export default {
  name: 'CampaignAdvertisersInput',

  components: {
    OptionsContainer,
  },

  props: {
    value: String,

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      component_value: this.value,
      advertisers: [],
      visible_list: [],
    };
  },

  created() {
    AdvertisersService.subscribe(advertisers => {
      this.advertisers = advertisers;
    }, this);
  },

  methods: {
    save() {
      if (this.component_value !== this.value) {
        if (this.value !== '') {
          AdvertisersService.removeAdvertiser(this.value);
        }

        if (this.component_value !== '') {
          AdvertisersService.addAdvertiser(this.component_value);
        }

        this.$emit('input', this.component_value);
      }

      this.close();
    },

    close() {
      this.visible_list = [];
    },

    searchHandler(event) {
      let query = trimQuery(event.target?.value ?? '');
      this.visible_list = [];

      if (query === '') {
        this.visible_list = this.advertisers;
        return;
      }

      for (const advertiser of this.advertisers) {
        if (matchQuery(advertiser.value, query)) {
          this.visible_list.push(advertiser);
        }
      }
    },

    selectAdvertiser(advertiser) {
      this.component_value = advertiser.value;
    },
  },
};
</script>
