<template lang="pug">
div#no-creatives-container
  div.img
    img(:src="$cdn + 'dist/assets/pointy-arrow.svg'" alt='')
  div.text-center
    template(v-if="is_sdk_user")
      div Create new
      div or import existing one
    template(v-else)
      div Looks like you don&apos;t have any {{ type }} yet
      div Add some here!
</template>

<script>
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

export default {
  name: 'NoListItem',

  mixins: [SDKMixin],

  props: {
    type: {
      type: String,
      default: 'campaigns',
    },
  },
};
</script>
