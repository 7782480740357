const EventEngagementInterface = {
  values: {
    total: 0,
    swipe: 0,
    hover: 0,
    gyro: 0,
    touch: 0
  },
  engagements: {
    averageDwell: 0,
    totalCtr: 0,
    totalClicks: 0,
    engagement: 0,
    engagementImpressions: 0,
    chart: {
      labels: null,
      series: null,
      meta: []
    }
  }
};
export default EventEngagementInterface;
