<template lang="pug">
div.row.flex-align-end.mb-32
  div.flex.flex-align-end.flex-grow

    div.col-4(v-if="creative.type_string")
      h4.mb-2 {{creative.type_string}}
      div.small-text Type

    div.col-4
      h4.mb-2
        template(v-if="show_quantum_size") {{quantum_size}}
        template(v-else) {{creative.width}} x {{creative.height}}
      div.small-text Size

    div.col-4
      h4.mb-2 {{creative.template?.name ?? 'Custom'}}
      div.small-text Layout

    div.col(v-if="show_dsp")
      h4.mb-2 {{creative.live_dsp_title}}
      div.small-text Platform
</template>

<script>
import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

export default {
  name: 'SummaryCardHeader',

  mixins: [CreativeTraits, SDKMixin],

  props: {
    creative: Object,
  },

  computed: {
    show_dsp() {
      return this.creative.live_dsp !== 'None' && this.creative.live_dsp_title != null && !this.is_sdk_user;
    },

    show_quantum_size() {
      return this.isQuantum() && this.creative.quantum_live_sizes?.length > 0;
    },

    quantum_size() {
      return this.creative.quantum_live_sizes.join(', ');
    },
  },
};
</script>
