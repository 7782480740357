<template lang="pug">
SidebarContainer(
  title="Asset Settings"
  :timestamp="asset.updated_on"
  :can_open="false"
  @close="close"
)
  template(v-slot:actions)
    AssetSiderbarIcons(:asset="asset")

  template(v-slot:content)
    div.label-group
      h4 Name:
    TextareaInput(
      ref="title"
      placeholder="Folder title"
      v-model="asset.name"
      @change="saveAsset"
    )
</template>

<script>
import GroupAssetsLibraryService from '@master/Services/GroupAssetsLibraryService';

import AssetSiderbarIcons from '@root/src/global/sidebar/edit/asset/AssetSiderbarIcons';
import SidebarContainer from '@root/src/global/sidebar/edit/container/SidebarContainer';

import TextareaInput from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TextareaInput';

export default {
  name: 'AssetSidebar',

  components: {
    AssetSiderbarIcons,
    SidebarContainer,
    TextareaInput,
  },

  props: {
    asset: {
      type: Object,
      default: () => (null)
    }
  },

  methods: {
    saveAsset() {
      const path = `assets/library/${this.asset.library_id}/${this.asset.original_asset_id}`;
      this.$http.put(path, { name: this.asset.name })
        .then(response => {
          GroupAssetsLibraryService.updateItems([response]);
        });
    },

    close() {
      GroupAssetsLibraryService.active.set(null);
    }
  }
};
</script>
