class Cactus {
  constructor(svg) {
    this.svg = svg;
    this.cactus = this.svg.querySelector('#cactus');
    this.hitbox = this.cactus.querySelector('[id^=HITBOX]');
    this.ouchNodes = [];
    let nodes = this.cactus.querySelectorAll('[id^=ouch-]');
    for (let node of nodes) {
      this.ouchNodes.push(node);
    }
    this.ouchTriggerTime = 600;
    this.lastOutch = new Date().getTime();
    this.init();
  }
  init() {
    for (let node of this.ouchNodes) {
      node.classList.add('transition');
      node.classList.add('hidden');
      node.removeAttribute('opacity');
      node._visible = false;
      // fix rotate transform
      let tspan = node.querySelector('tspan');
      let x = tspan.getAttribute('x');
      let y = tspan.getAttribute('x');
      let text = node.querySelector('text');
      text.style.transformOrigin = Math.round(x) + 'px ' + Math.round(y) + 'px';
    }
    this.hitbox.classList.add('clickable');
    this.hitbox.addEventListener('mouseover', () => this.showOuch());
  }
  showOuch() {
    let currentTime = new Date().getTime();
    if (currentTime - this.lastOutch > this.ouchTriggerTime) {
      for (let node of this.ouchNodes) {
        if (node._visible === false) {
          this.lastOutch = new Date().getTime();
          node.classList.remove('hidden');
          node._visible = true;
          setTimeout(() => {
            node.classList.add('hidden');
            node._visible = false;
          }, 2000);
          this.nudge();
          break;
        }
      }
      // shuffle ouches for next use
      this.ouchNodes.sort(() => Math.random() - 0.5);
    }
  }
  nudge() {
    window.clearTimeout(this.nudgeTimeout);
    this.nudgeTimeout = setTimeout(() => {
      this.cactus.style.transform = 'rotate3d(0, 0, 1, -10deg) translate(-145px, 116px)';
      this.nudgeTimeout = setTimeout(() => {
        this.cactus.style.transform = 'rotate3d(0, 0, 1, 10deg) translate(120px, -140px)';
        this.nudgeTimeout = setTimeout(() => {
          this.cactus.style.transform = '';
        }, 100);
      }, 100);
    }, 100);
  }
}

export default Cactus;
