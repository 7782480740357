<template lang="pug">
div.assets-tab
  template(v-for="group of asset_groups")
    UIGroup(v-if="(!group.optional || show_optional_title)" :key="group.title" :title="group.title" :quantum="sizeset != null && !group.optional && admin_toggle" :extras="extras")
      template(v-slot:quantum)
        div.col(v-if="sizeset != null && !group.optional && admin_toggle" :class="{'no-gutter-left': sizeset != null && !group.optional}")
          i.nexd-icon-32-reload.cursor-pointer.hover-primary(v-if="has_modified_module" aria-hidden="true" v-tooltip="{value: 'Clear module custom position'}" @click="clearModulePlacement")
          i.nexd-icon-16-edit.cursor-pointer.hover-primary(aria-hidden="true" v-tooltip="{value: 'Set module custom position'}" @click="openModulePlacement")
      template(v-slot:extras)
        SettingsContainer.mb-16(v-if="timeline?.enabled" :columns="1" :background="true" :group="false")
          h4 Global animations settings:
          SettingsRow(
            label="Loop"
            tooltip="Enable looping to repeat the animations you set below, so that the animations would still continue after the first cycle is done"
          )
            Toggle(v-model="timeline.iterations" :on="0" :off="1" @change="timeline.update()")
          SettingsRow(
            label="Animation length"
            tooltip="Choose how many seconds one cycle of animations should take."
          )
            Slider(v-model="timeline.duration" :input="true" :input_suffix="'s'" :options="{ min: 1000, max: 30000, step: 100, scale: 0.001 }" @change="timeline.update()")

      template(v-for="(asset, asset_id, index) in group.assets")
        //- show endcard only if the video has upload to at least 1 required assets
        AssetSlot(
          v-if="(show_video_endcard || !asset.filename.includes('endcard')) && !asset.options_tab"
          :key="index"
          :creative="creative"
          :template_asset="asset"
          :sizeset="sizeset"
          :arno="arno"
          :timeline="timeline"
          @change="change('asset')"
          @reload="reload"
        )

      AssetsTabAdditional.mt-16(v-if="extras" :arno="arno" :creative="creative" :type="ASSET.OVERLAY" :timeline="timeline" @reload="reload")
      AssetsTabAdditional.mt-16(v-if="isCustomFeedEnabled(group)" :arno="arno" :creative="creative" :type="ASSET.NORMAL" @reload="reload")
  AssetsTabAdditional(v-if="extras && show_hotspot_assets" :arno="arno" :creative="creative" :type="ASSET.HOTSPOT" @reload="reload")
</template>

<script>
import UIGroup from '@master/UI/UIGroup';
import AssetsTabAdditional from '@cm/Views/Creatives/Cards/Components/TemplateSettings/AssetsTabAdditional';
import AssetSlot from '@cm/Views/Creatives/Cards/Components/Asset/Slot/Slot';
import AssetHelper from '@helpers/Asset';
import Toggle from '@master/UI/Toggle';
import Slider from '@master/UI/Slider';

import AssetTraits from '@cm/Views/Creatives/Traits/AssetTraits';
import CreativeTraits from '@master/Traits/CreativeTraits';
import { clone } from '@helpers/Global';
import { ASSET, GLOBAL_ASSET } from '@master/constants';

import SettingsContainer from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsContainer';
import SettingsRow from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsRow';

import Timeline from '@cm/Views/Creatives/Cards/Components/Asset/Animations/Timeline';

export default {
  name: 'AssetsTab',
  mixins: [CreativeTraits, AssetTraits],
  components: {
    AssetsTabAdditional,
    Toggle,
    Slider,
    UIGroup,
    AssetSlot,
    SettingsContainer,
    SettingsRow,
  },
  props: {
    creative: Object,
    extras: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },

    // quantum can have some optionals grouped as backgrounds. if this is true optionals will be sepperated by type
    background: {
      type: Boolean,
      default: false,
    },

    // include cta-s in the search, used for quantum to sepperate optional ctas
    cta: {
      type: Boolean,
      default: false,
    },

    // should main assets be rquired or not
    main: {
      type: Boolean,
      default: false,
    },

    sizeset: {
      type: String,
      default: null,
    },

    // if assets are grouped by fixed rule
    // can be removed if all templates will get ui_groups
    fixed: {
      type: Boolean,
      default: false,
    },

    arno: Object,
  },

  computed: {
    assets_library() {
      return this.$store.get('active_assets_library');
    },

    show_video_endcard() {
      if (!this.creative.has_video) {
        return false;
      }

      for (const [asset_id, asset] of Object.entries(this.creative.assets)) {
        // skip global overlays
        if ([GLOBAL_ASSET.CTA, GLOBAL_ASSET.LOGO, GLOBAL_ASSET.SPLASH].includes(asset_id)) continue;

        // if any main asset slot has duration, we have a video in main slots
        if (asset.duration > 0) {
          return true;
        }
      }

      for (const [, asset] of Object.entries(this.creative.additional_assets)) {
        // skip custom overlays
        if (asset.custom_type === ASSET.OVERLAY) continue;

        // if any main asset slot has duration, we have a video in main slots
        if (asset.duration > 0) {
          return true;
        }
      }

      return false;
    },

    show_optional_title() {
      // im not sure about this part anymore, but it seems to work the way that endcard is visible if the cr has video, as well quantum crs have normal titles :/
      for (const group of this.asset_groups) {
        // look ofnly for optional asset groups
        if (group.optional) {
          for (const asset_id in group.assets) {
            // if at least one optional asset that is not endcard, or creative has video
            if (!group.assets[asset_id].filename.includes('endcard') || this.show_video_endcard) {
              return true;
            }
          }
        }
      }
      return false;
    },

    has_modified_module() {
      return this.creative?.settings?.module?.[this.sizeset] != null;
    },

    show_hotspot_assets() {
      return !this.isVast() && !this.isQuantum() && this.creative?.layout == null;
    },

    is_additional_feed_supported() {
      return this.creative?.template?.keywords?.includes('additional_feed_assets');
    },
  },

  data() {
    return {
      ASSET,
      Cropper: this.$cropper(),
      admin_toggle: false,
      custom_splash_enabled: false,
      asset_groups: [],

      timeline: null,
    };
  },

  created() {
    // only extras tab is using timeline
    if (this.extras) {
      if (!this.creative.settings.animations) {
        this.$set(this.creative.settings, 'animations', {
          timeline: null,
        });
      }

      this.timeline = new Timeline(this.creative);
    }

    this.$user.subscribe(() => {
      this.admin_toggle = this.$user.adminMode();

      // when admin toggle is on, we see this option as well
      this.custom_splash_enabled = this.admin_toggle || this.$user.isCustomSplashEnabled();
    }, this);

    this.createAssetGroups();
  },

  methods: {
    isCustomFeedEnabled(ui_group) {
      return (ui_group?.title === 'Feed' || ui_group?.title == null) && this.is_additional_feed_supported;
    },

    toggleGroupCollapse(group) {
      group.collapsed = !group.collapsed;
      if (group.title === 'Overlay assets') {
        this.additional_block_collapsed = !this.additional_block_collapsed;
      }
    },

    change(type = 'asset') {
      if (!this.$parent.isLoading()) {
        this.$emit('change', type);
      }
    },

    reload() {
      this.$emit('reload');
    },

    clearModulePlacement(e) {
      if (this.sizeset != null) {
        e.stopPropagation();
        if (this.has_modified_module) {
          this.$delete(this.creative.settings.module, this.sizeset);
          this.$emit('change');
        }
      }
    },

    openModulePlacement(e) {
      if (this.sizeset != null) {
        e.stopPropagation();

        this.Cropper.onsave = settings => {
          if (this.creative.settings.module == null) {
            this.$set(this.creative.settings, 'module', {});
          }
          this.$set(this.creative.settings.module, this.sizeset, clone(settings));
          this.$emit('change');
        };

        let width = this.creative.width;
        let height = this.creative.height;

        if (this.sizeset != null) {
          [width, height] = this.sizeset.split('x');
          width = parseInt(width, 10);
          height = parseInt(height, 10);
        }

        const placement_size = {
          width,
          height,
        };

        // edgy to put type this way, but currently we have all the main groups singular words matching the types
        let type = 'cube';
        if (this.creative.template.required_assets_group != null) {
          type = this.creative.template.required_assets_group.toLowerCase();
        }

        const cropper_options = {
          cropSize: placement_size,
          achor: 'center',
          position: 'absolute',
          module: type,
          resize: false,
          placement: {
            size: placement_size,
            adsize: placement_size,
          },
        };

        const responsive_type = AssetHelper.getResponsiveType(cropper_options.placement.size.width, cropper_options.placement.size.height);

        // module for 1 = right side
        // module for others = center(default)
        if (responsive_type === 1) {
          cropper_options.reset_behaviour = ['right'];
        }

        this.Cropper.init(cropper_options);

        let settings = null;
        if (this.creative?.settings?.module?.[this.sizeset] != null) {
          settings = this.creative.settings.module[this.sizeset];
        }

        this.Cropper.editModule(settings);
      }
    },

    createAssetGroups() {
      if (this.fixed) {
        return this.createFixedGroups();
      }
      return this.createAutoGroups();
    },

    createFixedGroups() {
      if (!this.creative?.template?.assets) {
        this.asset_groups = [];
        return;
      }

      const asset_groups = {};
      for (const [asset_id, asset] of Object.entries(this.creative.template.assets)) {
        // dont show optiosn tab assets anywhere
        if (asset.options_tab) continue;

        // only add extras tab assets to extras tab view
        if (asset.extras_tab && !this.extras) continue;

        // assets that dont belong to extras tab, should not be shown there
        if (!asset.extras_tab && this.extras) continue;

        // splash has special case, only shown when feature is enabled and or when not responsive
        if (this.isSplash(asset.type) && (!this.custom_splash_enabled || this.isQuantum())) continue;

        const title = asset.ui_group;
        if (!asset_groups[title]) {
          asset_groups[title] = {
            title: title,
            assets: {},
            optional: true,
            collapsed: false,
          };
        }

        asset_groups[title].assets[asset_id] = asset;
      }

      // if we deal with responsive sizeset tab
      // we only show closest or universal asset
      if (this.sizeset != null && asset_groups.Background) {
        const { closest_asset, universal_asset } = this.getSizesetSpecificBackground(asset_groups.Background);

        for (const asset_id in asset_groups.Background.assets) {
          if (asset_id !== closest_asset?.asset_id) {
            this.$delete(asset_groups.Background.assets, asset_id);
          } else if (universal_asset != null && asset_id !== universal_asset?.asset_id) {
            this.$set(asset_groups.Background.assets[asset_id], 'alternative', universal_asset);
          }
        }
      }

      this.asset_groups = Object.values(asset_groups);
    },

    createAutoGroups() {
      if (!this.creative?.template?.assets) {
        this.asset_groups = [];
        return;
      }

      const asset_groups = [
        {
          title: null,
          assets: {},
          optional: false,
          collapsed: false,
        },
        {
          title: 'Overlay assets',
          assets: {},
          optional: true,
          collapsed: false,
        },
      ];

      // non cta block is probably an additional block
      // case for responsive carousel additionals vs normal creative extra tab
      if (!this.cta) {
        asset_groups[1].title = 'Additional';
      }

      // sort assets dependin if we show required, optional or additional assets
      for (const [asset_id, asset] of Object.entries(this.creative.template.assets)) {
        const is_required_asset = this.isRequired(asset.required);
        const is_additional_asset = this.isAdditional(asset.required);

        if ((this.optional && !is_required_asset && !is_additional_asset) || (!this.optional && is_required_asset) || (this.extras && is_additional_asset)) {
          if ((this.main && this.isMain(asset.type)) || (this.background && this.isBackground(asset.type))) {
            if (is_required_asset) {
              asset_groups[0].assets[asset_id] = asset;
            } else {
              asset_groups[1].assets[asset_id] = asset;
            }
          } else if (this.cta && this.isOverlay(asset.type)) {
            // if asked to show additionals, only show additional cta-s
            if (!this.extras || is_additional_asset) {
              if (is_required_asset) {
                asset_groups[0].assets[asset_id] = asset;
              } else {
                asset_groups[1].assets[asset_id] = asset;
              }
            }
          } else if (this.isMain(asset.type) && this.extras && is_additional_asset) {
            // VR has main additional assets, show them under additional tab
            if (is_required_asset) {
              asset_groups[0].assets[asset_id] = asset;
            } else {
              asset_groups[1].assets[asset_id] = asset;
            }
          } else if (this.isSplash(asset.type) && !this.extras && this.custom_splash_enabled) {
            asset_groups[1].assets[asset_id] = asset;
          }
        }
      }

      if (this.background) {
        // if all backgroundsa are optional, show them in the main group
        if (Object.keys(asset_groups[0].assets).length === 0 && Object.keys(asset_groups[1].assets).length > 0) {
          if (this.main) {
            // if main param is true, its probably a group of additionals
            asset_groups[0].title = 'Additional';
          } else {
            // less probably its a bg group since bg is true
            asset_groups[0].title = 'Background';
          }
          asset_groups[0].assets = asset_groups[1].assets;
          asset_groups[1].assets = {};
          if (this.optional) {
            asset_groups[0].optional = true;
          }
        }
      }

      this.asset_groups = [];

      // remove if s empty, there should not be case where both are empty
      if (Object.keys(asset_groups[0].assets).length > 0) {
        if (this.background && this.sizeset != null) {
          const { closest_asset, universal_asset } = this.getSizesetSpecificBackground(asset_groups[0]);

          // take out all bgs except the closest
          for (const asset_id in asset_groups[0].assets) {
            if (asset_id !== closest_asset?.asset_id) {
              this.$delete(asset_groups[0].assets, asset_id);
            } else if (universal_asset != null && asset_id !== universal_asset?.asset_id) {
              this.$set(asset_groups[0].assets[asset_id], 'alternative', universal_asset);
            }
          }
        }

        this.asset_groups.push(asset_groups[0]);
      }

      if (Object.keys(asset_groups[1].assets).length > 0) {
        this.asset_groups.push(asset_groups[1]);
      }
    },

    getBackgroundAssetGroup(width, height) {
      const groups = {
        smallleaderboard: {
          alt: 'leaderboard',
          aspect: [
            {
              op: '>=',
              value: 4,
            },
          ],
          height: [
            {
              op: '<',
              value: 100,
            },
          ],
        },
        tower: {
          aspect: [
            {
              op: '<=',
              value: 0.7,
            },
          ],
        },
        leaderboard: {
          aspect: [
            {
              op: '>=',
              value: 1.5,
            },
          ],
        },
        rectangle: {
          aspect: [
            {
              op: '>',
              value: 0.7,
            },
            {
              op: '<',
              value: 1.5,
            },
          ],
        },
      };

      for (const key in groups) {
        const group = groups[key];
        for (const type in group) {
          let compare_value = null;
          if (type === 'aspect') {
            compare_value = width / height;
          } else if (type === 'width') {
            compare_value = width;
          } else if (type === 'height') {
            compare_value = height;
          }

          const comparissons = group[type];
          let match = true;
          for (const compare of comparissons) {
            const { op, value } = compare;
            if ((op === '>' && compare_value > value) || (op === '<' && compare_value < value) || (op === '>=' && compare_value >= value) || (op === '<=' && compare_value <= value)) {
              // all good
            } else {
              // not good
              match = false;
              break;
            }
          }

          if (match) {
            return group.alt || key;
          }
        }
      }

      // return null if no matches found
      return null;
    },

    getSizesetSpecificBackground(asset_group) {
      let [sizeset_asset_width, sizeset_asset_height] = this.sizeset.split('x');
      sizeset_asset_width = parseInt(sizeset_asset_width, 10);
      sizeset_asset_height = parseInt(sizeset_asset_height, 10);
      const sizeset_asset_aspect_ratio = sizeset_asset_width / sizeset_asset_height;
      const sizeset_group = this.getBackgroundAssetGroup(sizeset_asset_width, sizeset_asset_height);

      let closest_asset = null;

      // universal asset is the one that fits all background
      // it is used as placeholder for sizeset specific views where the speficic asset has no file, therefore universal bg is used
      // if universal is edited it will actually save as this size speficic asset
      let universal_asset = null;
      for (const asset_id in asset_group.assets) {
        const asset = asset_group.assets[asset_id];

        if (asset.filename === 'bg.jpg') {
          universal_asset = asset;
        } else {
          // if we have group of background and singe responsive sizeset active, show only bg that fits its size
          const asset_width = parseInt(asset.width.substring(1), 10);
          const asset_height = parseInt(asset.height.substring(1), 10);
          const asset_aspect_ratio = asset_width / asset_height;
          const group = this.getBackgroundAssetGroup(asset_width, asset_height);

          // since there can be multiple assets that fall into same goup, find the nearest to show
          const diff = Math.abs(sizeset_asset_aspect_ratio - asset_aspect_ratio);

          if (sizeset_group === group && (closest_asset == null || closest_asset.diff > diff)) {
            closest_asset = {
              asset_id,
              diff,
            };
          }
        }
      }

      if (closest_asset == null && universal_asset != null) {
        closest_asset = {
          asset_id: universal_asset.asset_id,
        };
      }

      return { closest_asset, universal_asset };
    },
  },
};
</script>
