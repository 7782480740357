<template lang="pug">
Thumbnail(v-bind="$props" v-on="$listeners")
</template>

<script>
import Thumbnail from '@cm/UI/Global/Thumbnail/Thumbnail';

export default {
  name: 'FolderThumbnail',

  components: {
    Thumbnail
  },

  props: {
    ...Thumbnail.props,

    small: {
      type: Boolean,
      default: true
    },

    variant: {
      type: String,
      default: 'folder',
      validator: value => value === 'folder'
    },

    bg: {
      type: String,
      default: 'unset',
      validator: value => value === 'unset'
    }
  }
};
</script>
