import $http from '@master/Services/HttpService';
import UserService from '@master/Services/UserService';

import router from '@root/src/router/Router';

const ORGANIZATION = 'organization';
const SUBSCRIPTION = 'subscription';
const UPCOMING_SUBSCRIPTIONS = 'upcoming_subscriptions';
const PRICING_DETAILS = 'pricing_details';

/**
 * @module BillingService
 * @description Billing service handles organization and subscription data.
 */
export default {
  _organization: null,
  _subscription: null,
  _upcoming_subscriptions: null,
  _pricing_details: null,

  async getOrganization () {
    await this._waitFor(ORGANIZATION);

    this.queue.setToQueue(ORGANIZATION);

    if (this._organization == null) {
      await this._loadOrganization();
    }

    this.queue.removeFromQueue(ORGANIZATION);

    return this._organization;
  },

  async getSubscription () {
    await this._waitFor(SUBSCRIPTION);

    this.queue.setToQueue(SUBSCRIPTION);

    if (this._subscription == null) {
      await this._loadSubscription();
    }

    this.queue.removeFromQueue(SUBSCRIPTION);

    return this._subscription;
  },

  async getUpcomingSubscriptions () {
    await this._waitFor(UPCOMING_SUBSCRIPTIONS);

    this.queue.setToQueue(UPCOMING_SUBSCRIPTIONS);

    if (this._upcoming_subscriptions == null) {
      await this._loadUpcomingSubscriptions();
    }

    this.queue.removeFromQueue(UPCOMING_SUBSCRIPTIONS);

    return this._upcoming_subscriptions;
  },

  reloadSubscriptions() {
    this._organization = null;
    this._subscription = null;
    this._upcoming_subscriptions = null;
  },

  async getPricingDetails () {
    await this._waitFor(PRICING_DETAILS);

    this.queue.setToQueue(PRICING_DETAILS);

    if (this._pricing_details == null) {
      await this._loadPricingDetails();
    }

    this.queue.removeFromQueue(PRICING_DETAILS);

    return this._pricing_details;
  },

  _loadOrganization () {
    this._organization = null;

    return $http.get(`groups/${this._getOrgId()}`)
      .then(response => {
        this._organization = response;

        if (this._organization?.dynamic?.subscription_id != null) {
          this._loadSubscription();
        }
      })
      .catch(() => { /** Failed to load organization */ });
  },

  async _loadSubscription () {
    if (this._organization?.dynamic_subscription_id == null) {
      await this.getOrganization();
    }

    if (this._organization?.dynamic_subscription_id == null) return;

    this._subscription = null;

    return $http.get(`v2/subscription/${this._organization.dynamic_subscription_id}`)
      .then(response => {
        this._subscription = response;
      })
      .catch(() => {
        /**
         * Failed to load subscription
         * Probably new user
         */
      });
  },

  async _loadUpcomingSubscriptions () {
    if (this._organization?.dynamic_subscription_id == null) {
      await this.getOrganization();
    }

    if (this._organization?.dynamic_subscription_id == null) return;

    this._upcoming_subscriptions = [];

    return $http.get(`v2/subscription/${this._organization.dynamic_subscription_id}/upcoming`)
      .then(response => {
        if (response == null) return;

        for (const subscription of response) {
          this._upcoming_subscriptions.push(subscription);
        }
      })
      .catch(() => { /** Failed to load upcoming subscriptions */ });
  },

  _loadPricingDetails () {
    this._pricing_details = null;

    return $http.get('/public/subscriptions/dynamic/pricing')
      .then(response => {
        this._pricing_details = response;
      })
      .catch(() => { /** Failed to load public pricing details */ });
  },

  _getOrgId() {
    let org_id = UserService.getGroupId();

    if (router?.history?.current?.params?.org_id != null && UserService.isNexdAdmin()) {
      org_id = router.history.current.params.org_id;
    }

    return org_id;
  },

  _waitFor(key) {
    return new Promise(resolve => {
      const interval = setInterval(() => {
        if (this.queue._requests[key] === false) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  },

  queue: {
    _requests: {
      [ORGANIZATION]: false,
      [SUBSCRIPTION]: false,
      [UPCOMING_SUBSCRIPTIONS]: false,
      [PRICING_DETAILS]: false
    },

    setToQueue (key) {
      this._requests[key] = true;
    },

    removeFromQueue (key) {
      this._requests[key] = false;
    }
  }
};
