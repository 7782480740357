<template lang="pug">
SettingsContainer(v-if="animations" :columns="1" title="Animation settings")
  SettingsRow(
    label="Animations"
  )
    Toggle(v-model="enabled" @input="toggleStatus")
  template(v-if="enabled")
    template(v-for="(animation, index) of items")

      div.flex.flex-align-center.gap-8
        div.cursor-pointer(:class="styles.animation" @click="animation.toggle()")
          img(:src="$cdn + 'dist/assets/cm/animation.svg'" title="animation" alt="animation")
          h4.lh-28.overflow-ellipsis Animation {{index + 1}} ({{animation_type_options[animation.type] ?? 'unknown'}})
          i.nexd-icon-16-arrow-down-small.color-primary(:class="{'active': !animation.collapsed}" )
        div.flex-grow
          AnimationTimelineLine.flex-grow(:animation="animation")
        img(
          v-if="timeline_warnings?.[index]"
          :src="$cdn + 'dist/assets/cm/warning.svg'"
          width="28"
          v-tooltip="{value: timeline_warnings[index], class: 'simple warning'}")
        i.nexd-icon-16-delete.cursor-pointer.color-primary(v-if="items.length > 1" @click="removeAnimation(animation)")

      SettingsContainer(v-if="!animation.collapsed" :group="false" :key="`${animation.type}_${index}`")
        SettingsRow(
          label="Animation Type"
        )
          div.flex.column-gap-16
            SearchSelect.flex-grow(:value="animation.type" :search="false" :options="animation_type_options" @change="animation.changeType($event.value)")
            SearchSelect.flex-grow(v-model="animation.ease" :search="false" :options="animation_easing_options" @change="change")

        SettingsRow(
          label="Start time"
          tooltip="Delay before the animation starts"
        )
          Slider(v-model="animation.delay" :input="true" :input_suffix="'s'" :options="{ min: 0, max: 30000, step: 100, scale: 0.001 }" @change="change")

        SettingsRow(
          label="Duration"
          tooltip="Duration of the animation"
        )
          Slider(v-model="animation.duration" :input="true" :input_suffix="'s'" :options="{ min: 100, max: 10000, step: 10, scale: 0.001 }" @change="change")

        template(v-if="animation.type === TIMELINE.ANIMATION.MOVEIN || animation.type === TIMELINE.ANIMATION.MOVEOUT")
          SettingsRow(
            :label="animation.type === TIMELINE.ANIMATION.MOVEIN ? 'Move in from' : 'Move out to'"
          )
            div.flex.column-gap-16
              SearchSelect.flex-grow(v-model="animation.side" :search="false" :options="animation_side_options" @change="change")
              div.flex-grow

          SettingsRow(label="Amplitude" :tooltip="amplitude_tooltip")
            Slider(v-model="animation.amplitude" :input="true" :input_suffix="'%'" :options="{ min: 0, max: 1, step: .01, scale: 100 }" @change="change")

        template(v-if="animation.type === TIMELINE.ANIMATION.SCALE")
          SettingsRow(
            label="Start size"
          )
            Slider(v-model="animation.from" :input="true" :input_suffix="'%'" :options="{ min: 0, max: 2, step: .01, scale: 100 }" @change="change")

          SettingsRow(
            label="End size"
          )
            Slider(v-model="animation.to" :input="true" :input_suffix="'%'" :options="{ min: 0, max: 2, step: .01, scale: 100 }" @change="change")

    ButtonWithDescription(
      label="Add animation"
      :disabled="items.length >= 6"
      :large="false"
      :description="`You can add ${6 - items.length} more animations`"
      @click="addAnimation"
    )
</template>

<script>
import styles from './Settings.module.scss';

import CreativeTraits from '@master/Traits/CreativeTraits';

import SettingsContainer from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsContainer';
import SettingsRow from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsRow';

import SearchSelect from '@master/UI/SearchSelect/SearchSelect';
import Slider from '@master/UI/Slider';
import Toggle from '@master/UI/Toggle';

import ButtonWithDescription from '@master/UI/Buttons/ButtonWithDescription';

import AnimationTimelineLine from './../Animations/AnimationTimelineLine';
import Animations from './../Animations/Animations';

import { TIMELINE } from '@master/constants';

export default {
  name: 'SettingsAsset',
  mixins: [CreativeTraits],

  components: {
    SearchSelect,
    SettingsContainer,
    SettingsRow,
    Slider,
    Toggle,
    ButtonWithDescription,
    AnimationTimelineLine,
  },

  props: {
    animations: {
      type: Animations,
      required: true,
    },
  },

  computed: {
    animation_type_options() {
      return this.createOptionsObj(Object.values(TIMELINE.ANIMATION), this.getAnimationTypeLabel);
    },

    animation_easing_options() {
      return this.createOptionsObj(Object.values(TIMELINE.EASING), this.getAnimationEasingLabel);
    },

    animation_side_options() {
      return this.createOptionsObj(Object.values(TIMELINE.SIDE), this.getAnimationSideLabel);
    },

    timeline_warnings() {
      const warnings = [];
      const duration = this.animations.timeline.duration;
      for (const animation of this.items) {
        if (animation.delay > duration) {
          warnings.push('This animation will never be visible because it starts after the global animation ends');
        } else if (animation.delay + animation.duration > duration) {
          warnings.push("This animation cannot play through fully because it's longer than the global animations set");
        } else {
          warnings.push(null);
        }
      }
      return warnings;
    },

    amplitude_tooltip() {
      return `
        A higher amplitude signifies a larger movement or shift,
        leading to more noticeable changes, while a lower amplitude represents minimal movement,
        maintaining closeness to the image's initial location.
      `;
    },
  },

  data() {
    return {
      TIMELINE,
      styles,
      enabled: false,
      items: [],
    };
  },

  methods: {
    toggleStatus() {
      this.animations.setStatus(this.enabled);
      this.change();

      this.$ga.e({
        element_name: `toggle animations ${this.enabled ? 'on' : 'off'}`,
        element_type: 'toggle',
        interaction_type: 'change',
      });
    },

    addAnimation() {
      this.animations.add();
      this.change();
    },

    async removeAnimation(animation) {
      const result = await this.$confirm('Are you sure you want to remove this animation?');
      if (result) {
        this.animations.remove(animation);
        this.change();
      }
    },

    change() {
      this.animations.timeline.update();
    },

    createOptionsObj(values, getLabel) {
      const options = {};
      for (const value of values) {
        options[value] = getLabel(value);
      }
      return options;
    },

    getAnimationTypeLabel(value) {
      const { ANIMATION } = TIMELINE;

      switch (value) {
        case ANIMATION.FADEIN:
          return 'Fade in';
        case ANIMATION.FADEOUT:
          return 'Fade out';
        case ANIMATION.MOVEIN:
          return 'Move in';
        case ANIMATION.MOVEOUT:
          return 'Move out';
        case ANIMATION.SCALE:
          return 'Scale';
        default:
          return value;
      }
    },

    getAnimationEasingLabel(value) {
      const { EASING } = TIMELINE;

      switch (value) {
        case EASING.LINEAR:
          return 'No ease';
        case EASING.INQUAD:
          return 'Ease in';
        case EASING.OUTQUAD:
          return 'Ease out';
        case EASING.INOUTQUAD:
          return 'Ease in out';
        default:
          return value;
      }
    },

    getAnimationSideLabel(value) {
      const { SIDE } = TIMELINE;

      switch (value) {
        case SIDE.TOP:
          return 'Top';
        case SIDE.RIGHT:
          return 'Right';
        case SIDE.BOTTOM:
          return 'Bottom';
        case SIDE.LEFT:
          return 'Left';
        default:
          return value;
      }
    },
  },

  watch: {
    animations: {
      handler() {
        this.enabled = this.animations.enabled;
        this.items = this.animations.animations;
      },
      immediate: true,
    },
  },
};
</script>
