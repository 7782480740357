<template lang="pug">
TestLayout(title="Notifications")

  TestSection(v-for="(button, type) in types" :subtitle="type" :key="type")
    Button(:type="button" label="Show Notification" @click="$notifications.add(type, `This is a ${type} notification!`)")
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Button from '@master/UI/Buttons/Button.vue';

export default {
  name: 'NotificationsTest',

  components: {
    TestLayout,
    TestSection,

    Button,
  },

  data() {
    const danger = 'danger';
    const warning = 'warning';
    const success = 'success';
    const primary = 'primary';

    return {
      types: {
        danger,
        error: danger,
        warning,
        success,
        primary,
      },
    };
  },
};
</script>
