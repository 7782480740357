export default class FilterSelection {
  constructor() {
    this.reset();
  }

  select(key, value) {
    if (this.#validateKey(key)) {
      this[key] = value;
    }
  }

  reset() {
    this.layouts = {};
    this.status = {};
    this.types = {};
    this.keywords = {};
    this.advertisers = {};
  }

  init(filters) {
    for (const key in filters) {
      if (this.#validateKey(key)) {
        this[key] = filters[key];
      }
    }
  }

  getParams() {
    let params = {};

    for (const key in this) {
      if (!Object.keys(this[key]).length) continue;

      for (const value in this[key]) {
        const { filter } = this[key][value];

        if (!params.hasOwnProperty(filter)) {
          params[filter] = [];
        }

        params[filter].push(value);
      }
    }

    return params;
  }

  #validateKey(key) {
    return this.hasOwnProperty(key);
  }
}
