<template lang="pug">
UIGroup.mb-0(:title="`${index === 0 && first  ? '' : index > 0 ? 'or' : 'and'}`" :quantum="true" :uppercase="true")
  template(v-slot:quantum)
    Button.ml-8(type="link-accent" @click="duplicate")
      template(v-slot:prefix)
        i.nexd-icon-32-duplicate
    Button(type="link-accent" @click="removeStatement")
      template(v-slot:prefix)
        i.nexd-icon-32-delete

  div(:class="styles.container")
    section(:class="{[styles.statement]: true, [styles.accent]: index % 2 === 0 && statement?.parent_id == null, [styles.active]: false}")
      div(:class="styles.conditions")
        template(v-for="(condition, condition_index) of statement.conditions")
          FlightCondition(
            :key="condition_index"
            :statement="statement"
            :condition="condition"
            :index="condition_index"
            @change="change"
          )

      section(:class="styles.creatives")
        div.fw-500.color-cyan-blue Then show following creatives:
        FlightStatementCreatives(:statement="statement" @change="change")

      template(v-if="statement.statements.length > 0" v-for="(sub_statements, idx) of statement.statements")
        FlightStatement(
          :key="idx"
          :index="idx"
          :statement="sub_statements"
          :parent="statement.statements"
          @change="change"
        )
          Buttons(align="left")
            Button(type="primary" label="New condition group" @click="addStatement")
              template(v-slot:prefix)
                IconAdd

      Buttons(v-if="!statement.statements.length" align="left")
        Button(type="cyan" label="Nested condition(s)" :outline="true" @click="addNestedStatement")
          template(v-slot:prefix)
            IconAdd

    Buttons(align="left")
      Button(type="primary" label="New condition group" @click="addStatement")
        template(v-slot:prefix)
          IconAdd
</template>

<script>
import styles from './FlightStatement.module.scss';

import { FLIGHT } from '@master/constants';
import { newStatement, duplicateStatement } from '@cm/Views/Flights/helpers/mocks';
import FlightService from '@master/Services/FlightService';

import Button from '@master/UI/Buttons/Button';
import Buttons from '@master/UI/Buttons/Buttons';
import IconAdd from '@master/UI/Buttons/Icons/IconAdd';
import UIGroup from '@master/UI/UIGroup';

import FlightCondition from '@cm/Views/Flights/Components/FlightStatements/FlightCondition/FlightCondition';
import FlightStatementCreatives from '@cm/Views/Flights/Components/FlightStatements/FlightStatementCreatives/FlightStatementCreatives';

export default {
  name: 'FlightStatement',

  components: {
    Button,
    Buttons,
    FlightCondition,
    FlightStatementCreatives,
    IconAdd,
    UIGroup,
  },

  props: {
    statement: Object,
    parent: Array,

    index: {
      type: Number,
      default: 0,
    },

    first: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      styles,

      FLIGHT,
    };
  },

  methods: {
    addNestedStatement() {
      if (!Array.isArray(this.statement.statements)) {
        this.$set(this.statement, 'statements', []);
      }

      this.statement.statements.push(
        newStatement({
          parent_id: this.statement?.id ?? null,
          prev_id: this.statement?.statements?.[this.index]?.id,
          next_id: this.statement?.statements?.[this.index + 1]?.id ?? null,
        }),
      );

      this.change();
    },

    addStatement() {
      /** create new statement */
      const new_statement = newStatement({
        parent_id: this.statement?.parent_id ?? null,
        prev_id: this.parent?.[this.index]?.id,
        next_id: this.parent?.[this.index + 1]?.id ?? null,
      });

      /** get previous and next statements */
      const prev_statement = this.parent?.[this.index] ?? null;
      const next_statement = this.parent?.[this.index + 1] ?? null;

      /** update previous statement's next_id */
      if (prev_statement != null) {
        this.parent.splice(this.index, 1, { ...prev_statement, next_id: new_statement.id });
      }

      /** update next statements's prev_id */
      if (next_statement != null) {
        this.parent.splice(this.index + 1, 1, { ...next_statement, prev_id: new_statement.id });
      }

      /** add new statement */
      this.parent.splice(this.index + 1, 0, new_statement);

      this.change();
    },

    async removeStatement() {
      /** reset if top layer statements array has one statement */
      const reset = this.parent.length === 1 && this.statement?.parent_id == null;

      if (!(await this.$confirm(`Are you sure you would like to ${reset ? 'reset' : 'delete'} condition group?`))) {
        return;
      }

      /** get previous and next statements */
      const prev_statement = this.parent?.[this.index - 1] ?? null;
      const next_statement = this.parent?.[this.index + 1] ?? null;

      /** update previous statement's next_id */
      if (prev_statement != null) {
        this.parent.splice(this.index - 1, 1, { ...prev_statement, next_id: next_statement?.id ?? null });
      }

      /** update next statements's prev_id */
      if (next_statement != null) {
        this.parent.splice(this.index + 1, 1, { ...next_statement, prev_id: prev_statement?.id ?? null });
      }

      /** remove statement */
      this.parent.splice(this.index, 1);

      /** add new statement if reset */
      if (reset) {
        this.parent.push(
          newStatement({
            parent_id: this.statement.parent_id ?? null,
            prev_id: null,
            next_id: null,
          }),
        );
      }

      /** delete statement if it has an id from API */
      if (FlightService.hasValidId(this.statement?.id)) {
        this.$http.delete(`v2/flights/${FlightService.getFlightId()}/statements/${this.statement?.id}`).then(() => {
          /** To make sure local storage updated_on value is bigger (more recent) than the DB updated_on value */
          setTimeout(() => {
            this.change();
          }, 100);
        });
      }

      /** save updated flight to local storage and lock flight */
      this.change();
    },

    async duplicate() {
      if (!(await this.$confirm('Are you sure you would like to duplicate condition group?'))) {
        return;
      }

      /** create new statement */
      const new_statement = duplicateStatement(this.statement);

      /** get previous and next statements */
      const prev_statement = this.parent?.[this.index] ?? null;
      const next_statement = this.parent?.[this.index + 1] ?? null;

      /** update previous statement's next_id */
      if (prev_statement != null) {
        this.parent.splice(this.index, 1, { ...prev_statement, next_id: new_statement.id });
      }

      /** update next statements's prev_id */
      if (next_statement != null) {
        this.parent.splice(this.index + 1, 1, { ...next_statement, prev_id: new_statement.id });
      }

      /** add new statement */
      this.parent.splice(this.index + 1, 0, new_statement);

      this.change();
    },

    change() {
      this.$emit('change');
    },
  },
};
</script>
