<template lang="pug">
TestLayout(title="Loading")

  TestSection(subtitle="Default")
    Loading

  TestSection(subtitle="Default Small")
    Loading.small

  TestSection(v-for="(name, type) in variances" :key="type" :subtitle="name")
    Loading(:type="type")

  TestSection(subtitle="Percentage")
    Loading(:percentage=".25")

  TestSection(subtitle="Percentage Small")
    Loading.small(:percentage=".9")

  TestSection(subtitle="Text & Subtext")
    Loading(text="Hello world" subtext="Lorem Ipsum is simply dummy text of the printing and typesetting industry.")

  TestSection(subtitle="Fill")
    div.relative(style="width: 100%; height: 5rem")
      Loading(:fill="true")

  TestSection(subtitle="Loading Modal")
    Button(type="primary" label="Open Loading Modal" @click="openLoadingModal")
    LoadingModal(v-if="loading_modal")

  TestSection(subtitle="Closable Loading Modal")
    Button(type="primary" label="Open Loading Modal" @click="closable_loading_modal = true")
    LoadingModal(v-if="closable_loading_modal" :closable="true" @close="closable_loading_modal = false")
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Button from '@master/UI/Buttons/Button';
import Loading from '@master/UI/Loading';
import LoadingModal from '@master/UI/LoadingModal';

export default {
  name: 'LoadingTest',

  components: {
    TestLayout,
    TestSection,

    Button,
    Loading,
    LoadingModal,
  },

  data() {
    return {
      variances: {
        audio: 'Audio',
        'ball-triangle': 'Ball Triangle',
        bars: 'Bars',
        circles: 'Circles',
        grid: 'Grid',
        hearts: 'Hearts',
        oval: 'Oval',
        puff: 'Puff',
        rings: 'Rings',
        'spinning-circles': 'Spinning Circles',
        'three-dots': 'Three Dots',
      },

      loading_modal: false,
      closable_loading_modal: false,
    };
  },

  methods: {
    openLoadingModal() {
      this.loading_modal = true;

      setTimeout(() => {
        this.loading_modal = false;
      }, 3000);
    },
  },
};
</script>
