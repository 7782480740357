<template lang="pug">
TestLayout(title="CreativeSizes")
  TestSection(subtitle="default" style="grid-column: span 2;")
    CreativeSizes(:optimizable="true")

  TestSection(subtitle="imported" style="grid-column: span 2;")
    CreativeSizes(:optimizable="true" :creative="imported_creative")

  TestSection(subtitle="live" style="grid-column: span 2;")
    CreativeSizes(:optimizable="true" :creative="live_creative")

  TestSection(subtitle="still optimizing" style="grid-column: span 2;")
    CreativeSizes(:optimizable="true" :creative="optimizing_creative")

  TestSection(subtitle="dsp -> google ads" style="grid-column: span 2;")
    CreativeSizes(:optimizable="true" :creative="google_creative")
</template>

<script>
import { DSP } from '@master/constants';

import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import CreativeSizes from '@master/UI/CreativeSizes.vue';

export default {
  name: 'CreativeSizesTest',

  components: {
    TestLayout,
    TestSection,

    CreativeSizes,
  },

  data() {
    return {
      imported_creative: {
        imported: true,
      },

      live_creative: {
        packsize: {
          live: 0,
        },
      },

      optimizing_creative: {
        packsize: {
          optimization_active: true,
        },
      },

      google_creative: {
        dsp: DSP.GOOGLEADS,
        packsize: {
          total: {
            original: 600001,
          },
          draft: {
            total_size: 700001,
          },
        },
      },
    };
  },
};
</script>
