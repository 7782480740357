<template lang="pug">
div.flight-card(:class="{main}")
  slot
</template>

<script>
export default {
  name: 'Card',

  props: {
    main: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.flight-card {
  /* $gray-200 */
  border: 1px solid #e5ecf5;
  border-radius: 3px;
  background-color: #ffffff;

  &.main {
    /* $blue-lighter */
    border-color: #cce1ff;
  }
}
</style>
