<template lang="pug">
div.tab-content
  ModuleMaker(:creative="creative" :modules="modules" :key="'extrastab_modules'")
</template>

<script>
import ModuleMaker from '@cm_modules/ModuleMaker';

export default {
  name: 'TabExtra',

  components: {
    ModuleMaker,
  },

  props: {
    creative: Object,
    group: Object,
  },

  data() {
    return {
      modules: [],
    };
  },

  created() {
    if (this.group?.modules != null) {
      for (let module of this.group.modules) {
        this.modules.push(module);
      }
    }
  },
};
</script>
