<template lang="pug">
div.modal-scroll
  div.row.templates-list(v-if="all_templates != null")

    div.mt-32.px-16.text-center(v-if="!visible_templates.length")
      img(:src="`${$cdn}dist/assets/cm/search.svg`" alt="search")
      div.mt-16 Could not find any layouts to match your search or filters.

    div.col-24(v-else)
      CreativeTemplate(
        v-for="template in visible_templates"
        :key="template.layout_id || template.template_id"
        :template="template"
        :selected="selected_template != null && template.layout_id === selected_template.layout_id"
        @select="selectTemplate"
      )

  Loading(v-else)
  UpsellingFooter.text-center(type="templates")
</template>

<script>
import { TYPE } from '@master/constants';
import { search } from '@helpers/Global';
import TemplatesService from '@master/Services/TemplatesService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import CreativeTemplate from './CreativeTemplate.vue';
import Loading from '@master/UI/Loading.vue';
import UpsellingFooter from '@master/UI/UpsellingFooter.vue';

export default {
  name: 'TemplatesList',
  mixins: [CreativeTraits, SDKMixin],

  components: {
    CreativeTemplate,
    Loading,
    UpsellingFooter,
  },

  props: {
    creative: Object,

    query: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selected_template: null,
      // arrays that hold filtered templates
      all_templates: null,
      filtered_templates: [],
      visible_templates: [],
    };
  },

  created() {
    TemplatesService.subscribe(state => {
      if (state.items == null) return;

      if (this.creative?.device === 2) {
        this.all_templates = state.items.filter(item => item.is_video);
      } else {
        this.all_templates = state.items;
      }

      this.filterList();
      this.initTemplateSelect();
    }, this);
  },

  methods: {
    searchHandler() {
      const visible_templates = [];
      const popular_templates = [];

      const search_output = search(this.filtered_templates, template => template.name, this.query);

      for (const template of search_output) {
        if (this.is_sdk_user && TemplatesService.isSocialLayout(template)) {
          continue;
        }

        if (TemplatesService.isPopular(template)) {
          popular_templates.push(template);
        } else {
          visible_templates.push(template);
        }
      }

      this.visible_templates = [...popular_templates, ...visible_templates];
    },

    filterList() {
      // since templates are combined, desktop and mobiel ones have same template_ids
      let added_ids = [];

      this.filtered_templates = [];

      // allow self type always? eg skin, responsive, infeed, interstitial, interscroller
      let accepted_types = [this.creative.type];

      // handle other mixed types that use templates 0 and 1 types (but are not 0 or 1 themselves)
      if ([TYPE.EXPANDABLE_INFEED, TYPE.DYN_END_INFEED, TYPE.LIGHTBOX_INFEED, TYPE.HTML5_INFEED].includes(this.creative.type)) {
        // for expandables or dyn. encard infeeds, make sure infeed is accepted
        accepted_types.push(TYPE.INFEED);
      } else if ([TYPE.EXPANDABLE_INTERSTITIAL, TYPE.DYN_END_INTERSTITIAL, TYPE.LIGHTBOX_INTERSTITIAL, TYPE.HTML5_INTERSTITIAL].includes(this.creative.type)) {
        // for expandables or dyn. encard fullscreens, make sure fs is accepted
        accepted_types.push(TYPE.INTERSTITIAL);
      } else if (this.isInterscroller()) {
        // for interscroller, make sure fs is accepted
        accepted_types.push(TYPE.INTERSTITIAL);
      } else if (this.isVast() || this.isVpaid()) {
        // for vast and vpaid types, make sure infeed is accepted
        accepted_types.push(TYPE.INFEED);
      }

      for (const template of this.all_templates) {
        const unique_key = template.template_id ?? template.layout_id;
        if (!added_ids.includes(unique_key) && accepted_types.includes(template.type)) {
          added_ids.push(unique_key);
          this.filtered_templates.push(template);
        }
      }

      this.filtered_templates.sort((a, b) => {
        if (a.name > b.name) return 0;
        if (a.name) return -1;
        return 1;
      });

      // fire searchHandler, so it will generate visible list from filtered list
      this.searchHandler();
    },

    initTemplateSelect() {
      if (!this.visible_templates.length) return;

      if (this.creative?.template_id != null || this.creative?.layout_id != null) {
        for (const template of this.visible_templates) {
          if (template.template_id === this.creative.template_id || template.layout_id === this.creative.layout_id) {
            this.selectTemplate(template);
          }
        }
      } else {
        // if creative has no template_id, select first one
        this.selectTemplate(this.visible_templates[0]);
      }
    },

    selectTemplate(template) {
      // user cannot deselect layout
      if (template != null) {
        // reset preview URL if template is selected
        let preview_url = null;
        this.selected_template = template;

        // check for demos
        if (template.demo_creatives.length > 0 && template.demo_creatives[0].preview_url.length > 0) {
          preview_url = template.demo_creatives[0].preview_url;
        }

        this.$emit('change', {
          preview_url,
          template,
        });
      }
    },
  },
  watch: {
    query: {
      handler() {
        this.searchHandler();
      },
    },
  },
};
</script>
