import { VIEW } from '@master/constants';

import Billing from '@cm/Views/Profile/Blocks/Billing.vue';
import ChoosePackage from '@cm/Views/Billing/Views/ChoosePackage.vue';
import ExtraImpressions from '@cm/Views/Billing/Views/ExtraImpressions.vue';

const meta = {
  app_name: 'Campaign Manager',
  app_key: 'campaign-manager',
  requires_auth: true,
  show_navbar: true,
  validate_adblock: true,
  show_sidebar: true,
  intercom: true,
  view: VIEW.BILLING,
  sdk_client: false,
};

export default [
  {
    path: `/${VIEW.BILLING}`,
    name: `${VIEW.BILLING}`,
    component: Billing,
    meta,
    props: true,
  },
  {
    path: `/${VIEW.BILLING}/impressions`,
    name: `${VIEW.BILLING}.impressions`,
    component: ExtraImpressions,
    meta,
    props: true,
  },
  {
    path: `/${VIEW.BILLING}/package`,
    name: `${VIEW.BILLING}.package`,
    component: ChoosePackage,
    meta,
    props: true,
  },
];
