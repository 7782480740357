<template>
  <div class="summary-line-stat" :class="{'extra-margin': change}">
    <div class="content">
      <div v-if="!change" class="title" v-tooltip="{value: tooltip}">{{title}}</div>
      <h1 v-else class="value-change flex-grow">{{value | PercentageFilter}}</h1>
      <span class="flex-grow"></span>
    </div>
    <div class="content">
      <div v-if="change" class="title flex-grow" v-tooltip="{value: tooltip}">{{title}}</div>
      <h1 v-if="!change" class="value flex-grow">{{value | PercentageFilter}}</h1>
      <div v-if="total !== null" class="total" v-tooltip="{value: tooltipTotal}">{{total | NumberFilter}}</div>
    </div>
    <div class="bottom-content">
      <div class="line">
        <span class="active-line" :class="this.class" :style="{opacity: values.opacity, width: values.width + '%'}" />
      </div>
      <div class="footer" v-if="footer != null">{{percentageTo | PercentageFilter}}</div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';
import { round } from '@helpers/Global';

export default {
  name: 'SummaryStats',
  props: {
    title: String,
    tooltip: {
      type: String,
      default: null
    },
    danger: {
      type: Number,
      default: null
    },
    warning: {
      type: Number,
      default: null
    },
    value: Number,
    total: {
      type: Number,
      default: null
    },
    footer: String,
    compareTo: {
      type: Number,
      default: 1
    },
    change: Boolean,
    tooltipTotal: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      percentageTo: 0,
      values: {
        opacity: 0,
        width: 0
      },
      class: ''
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      if (this.compareTo === 0) {
        this.percentageTo = 0;
      } else {
        this.percentageTo = round(this.value / this.compareTo);
      }

      if (this.danger != null && this.percentageTo <= this.danger) {
        this.class = 'danger';
      } else if (this.warning != null && this.percentageTo <= this.warning) {
        this.class = 'warning';
      } else {
        this.class = '';
      }
      this.values = {
        // opacity: 0,
        width: 0
      };
      anime({
        targets: this.values,
        // opacity: this.percentageTo,
        width: this.percentageTo * 100,
        easing: 'easeOutSine'
      });
    }
  },
  watch: {
    value: {
      handler: function () {
        this.init();
      }
    }
  }
};
</script>
