<template lang="pug">
  div.card.card-rounded.mt-14
    div.asset-upload-wrapper
      div.asset-settings.col-24.no-gutter
        div.row.header-row.col.flex-justify-between
          div.row.col.flex-justify-start.flex-align-center
            slot(name="header_title")
            i.nexd-icon-16-help.pl-8(aria-hidden="true" v-if="tooltip != null" v-tooltip="tooltip")
          div.row.col.flex-align-center
            slot(name="additional_header_controls")
            i.nexd-icon-16-counter-clockwise.cursor-pointer(
              style="vertical-align:unset;"
              aria-hidden="true"
              v-if="reset_btn_visible"
              @click.stop="$emit('reset_btn_click')"
              v-tooltip="reset_btn_tooltip")
        slot(name="additional_header_content")
        div.collpase-indicator.cursor-pointer(v-if="indicator_icon != null || indicator_label != null" @click="toggle")
          hr
          i(v-if="indicator_icon != null" :class="indicator_icon")
          span.fs-14.color-primary(v-if="indicator_label != null") {{ indicator_label }}
          hr
    div.card-body(v-if="!collapsed")
      slot(name="collapsable_body")
    div.remove(v-if="has_delete_btn" v-tooltip="{value: 'Delete'}" @click.stop="$emit('delete_btn_click')")
      i.nexd-icon-32-close-small
</template>

<script>
export default {
  name: 'CollapsableCard',
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Object,
      default: null
    },
    reset_btn_visible: {
      type: Boolean,
      default: false
    },
    reset_btn_tooltip: {
      type: Object,
      default () {
        return {};
      }
    },
    has_delete_btn: {
      type: Boolean,
      default: false
    },
    indicator_icon: {
      type: String,
      default: 'nexd-icon-16-edit'
    },
    indicator_label: {
      type: String,
      default: 'Edit styles'
    }
  },
  data () {
    return {
      collapsed: !this.opened
    };
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    }
  },
  watch: {
    opened: {
      handler (val) {
        this.collapsed = !val;
      }
    }
  }
};
</script>
