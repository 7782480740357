export default {
  data: new Map(),

  set(key, value) {
    this.data.set(key, value);
  },

  get(key) {
    return this.data.get(key);
  },

  registrationFields() {
    const fields = {
      email: {
        label: 'Business Email *',
        type: 'email',
        value: '',
        placeholder: 'someone@domain.com'
      },
      password: {
        label: 'Password *',
        type: 'password',
        value: '',
        placeholder: 'password',
        hidden: true
      }
    };

    if (process.env.NODE_ENV === 'development') {
      const { email, password } = this.devRegistration();
      fields.email.value = email;
      fields.password.value = password;
    }

    return fields;
  },

  devRegistration() {
    const ts = Date.now();
    return {
      email: `${ts}@nexd.localhost`,
      password: 'olenerik',
    };
  }
};
