<template lang="pug">
div.p-16.b-gray-200.border-radius.flex.flex-direction-column.flex-justify-between(style="height:9rem;")
  span.fs-14.color-gray-600 {{ label }}

  div
    slot(name="chart")
    div.color-primary.fs-20.fw-500 {{ value }}
    slot(name="footer")
</template>

<script>
export default {
  name: 'PlanStatsCard',

  props: {
    label: {
      type: String,
      required: true,
    },

    value: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>
