const SummaryInterface = {
  imageUrl: '',
  chart: {
    labels: null,
    series: {
      impressions: null,
      engagement: null,
      ctr: null,
      clicks: null,
      'unique-impressions': null,
      viewability: null,
      vtr: null,
      dwell: null
    },
    averages: {
      ctr: null
    }
  },
  totals: {
    impressions: 0,
    loaded: 0,
    viewable: 0,
    ctr: {
      value: 0,
      clicks: 0
    },
    engagement: {
      value: 0,
      clicks: 0
    },
    dwell: 0
  }
};
export default SummaryInterface;
