import { VIEW } from '@master/constants';

import AnalyticsView from '@analytics/Views/AnalyticsView.vue';

const default_meta = {
  app_name: 'Analytics',
  app_key: 'analytics',
  requires_auth: true,
  show_navbar: true,
  validate_adblock: false,
  show_sidebar: true,
  intercom: true,
  view: VIEW.ANALYTICS,
  sdk_client: false,
};

const public_meta = {
  ...default_meta,
  requires_auth: false,
  intercom: false,
};

export default [
  {
    path: `/${VIEW.ANALYTICS}/c/:campaign_id`,
    name: `${VIEW.ANALYTICS}-campaign`,
    component: AnalyticsView,
    props: { suffix: '' },
    meta: { ...default_meta },
  },
  {
    path: `/${VIEW.ANALYTICS}/c/:campaign_id/creative/:live_id`,
    name: `${VIEW.ANALYTICS}-creative`,
    component: AnalyticsView,
    meta: { ...default_meta, sdk_client: true },
  },
  {
    path: `/${VIEW.ANALYTICS}/p/:public_id`,
    name: `${VIEW.ANALYTICS}-campaign-public`,
    component: AnalyticsView,
    meta: { ...public_meta },
  },
  {
    path: `/${VIEW.ANALYTICS}/p/:public_id/creative/:live_id`,
    name: `${VIEW.ANALYTICS}-creative-public`,
    component: AnalyticsView,
    meta: { ...public_meta },
  },
  {
    path: `/${VIEW.ANALYTICS}/f/:flight_id`,
    name: `${VIEW.ANALYTICS}-flight`,
    component: AnalyticsView,
    props: { suffix: '' },
    meta: { ...default_meta },
  },
  {
    path: `/${VIEW.ANALYTICS}/f/:flight_id/creative/:live_id`,
    name: `${VIEW.ANALYTICS}-flight-creative`,
    component: AnalyticsView,
    props: { suffix: '' },
    meta: { ...default_meta },
  },
  {
    path: `/${VIEW.ANALYTICS}/pc/:public_live_id`,
    name: `${VIEW.ANALYTICS}-single-creative-public`,
    component: AnalyticsView,
    meta: { ...public_meta },
  },
];
