<template lang="pug">
div(:class="classes" @click="handleClick")
  i(v-if="active" :class="['nexd-icon-16-check-mini', styles.check]" aria-hidden="true")
  div(:class="styles.icon")
    i.fs-32.color-primary(v-if="icon" :class="icon")
  center
    h3.fs-14 {{ title }}
    p.mt-8.color-cyan-blue.fs-12.lh-16 {{ description }}
  Loading(v-if="loading" :class="styles.loading_spinner")
</template>

<script>
import styles from './DCOActionCard.module.scss';

import Loading from '@master/UI/Loading.vue';

export default {
  name: 'DCOActionCard',

  components: {
    Loading,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    icon: String,

    active: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    secondary: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        [styles.card]: true,
        [styles.active]: this.active,
        [styles.disabled]: this.disabled,
        [styles.loading]: this.loading,
        [styles.secondary]: this.secondary,
      };
    },
  },

  data() {
    return {
      styles,
    };
  },

  methods: {
    handleClick() {
      if (!this.disabled && !this.loading) {
        this.$emit('click');
      }
    },
  },
};
</script>
