import { confirm } from '@libs/alerts';
import { searchLongStringsAndBreak } from '@helpers/Global';

/**
 * @param {string[]} duplicated_assets_names - Array of duplicated assets names
 * @returns {Promise<boolean | null>}
 */
export async function confirmAssetUpload(duplicated_assets_names = []) {
  const stringified_assets = duplicated_assets_names?.map(string => searchLongStringsAndBreak(string)).join(', ');

  const body = `
    <div>
      <div class="overflow-ellipsis-3">
        Please revise the following asset(s) before uploading:&nbsp;
        <span class="color-cyan-blue">${stringified_assets}</span>
      </div>
      <div class="mt-16">What would you like to do?</div>
    </div>
  `;

  const multiple_assets = duplicated_assets_names?.length > 1;
  const keep = multiple_assets ? 'Keep all' : 'Keep both';
  const replace = multiple_assets ? 'Replace all' : 'Replace';

  return await confirm(undefined, 'Duplicate asset(s)', body, {
    buttons: [
      { type: 'link-primary', label: 'Cancel', action: null },
      { type: 'primary', label: keep, action: false },
      { type: 'primary', label: replace, action: true },
    ],
  });
}
