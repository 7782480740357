<template lang="pug">
TestLayout(title="Search Select")

  TestSection(subtitle="Default")
    SearchSelect(v-model="model_ss_default" :options="options_short")
    pre {{model_ss_default}}

  TestSection(subtitle="No Search/Filter")
    SearchSelect(v-model="model_ss_nosearch" :options="options_short" :search="false")
    pre {{model_ss_nosearch}}

  TestSection(subtitle="Disabled")
    SearchSelect(v-model="model_ss_disabled" :options="options_short" :disabled="true")
    pre {{model_ss_disabled}}

  TestSection(subtitle="options - null")
    SearchSelect(v-model="model_ss_disabled" :options="null")
    pre {{model_ss_disabled}}

  TestSection(subtitle="options - []")
    SearchSelect(v-model="model_ss_disabled" :options="[]")
    pre {{model_ss_disabled}}

  TestSection(subtitle="options - {}")
    SearchSelect(v-model="model_ss_disabled" :options="{}")
    pre {{model_ss_disabled}}

  TestSection(subtitle="No first")
    SearchSelect(v-model="model_ss_nofirst" :options="options_types" :search="false" :first="false")
    pre {{model_ss_nofirst}}

  TestSection(subtitle="Placeholder")
    SearchSelect(v-model="model_ss_placeholder" placeholder="Select value" :options="options_long")
    pre {{model_ss_placeholder}}

  TestSection(subtitle="Scrollable")
    SearchSelect(v-model="model_ss_scrollable" :options="options_long")
    pre {{model_ss_scrollable}}

  TestSection(subtitle="Creative types")
    SearchSelect(v-model="model_ss_types" :first="false" :search="false" :options="options_types2")
    pre {{model_ss_types}}

  TestSection(subtitle="w/ url")
    SearchSelect(v-model="model_ss_url" url="framework/versions" :search="false")
    pre {{model_ss_url}}

  TestSection(subtitle="w/ disabled option")
    SearchSelect(v-model="model_ss_opt_disabled" :options="options_short_disabled" :search="false")
    pre {{model_ss_opt_disabled}}

  TestSection(subtitle="filter")
    SearchSelect(v-model="model_ss_filter" filter="Type" :options="options_short_disabled" :search="false")
    pre {{model_ss_filter}}

  TestSection(subtitle="lazy load")
    SearchSelect(v-model="model_ss_lazy" :options="options_longest")
    pre {{model_ss_lazy}}

  div(style="grid-column: 1 / -1")
    hr
    TestLayout(title="Search Select Multiple")

      TestSection(subtitle="Default")
        SearchSelectMultiple(v-model="model_ssm_default" :options="options_short")
        pre {{model_ssm_default}}

      TestSection(subtitle="No Search/Filter")
        SearchSelectMultiple(v-model="model_ssm_nosearch" :options="options_short" :search="false")
        pre {{model_ssm_nosearch}}

      TestSection(subtitle="w/ counts")
        SearchSelectMultiple(v-model="model_ssm_count" :options="options_long" :search="false" :counts="counts")
        pre {{model_ssm_count}}

      TestSection(subtitle="w/o empty")
        SearchSelectMultiple(v-model="model_ssm_empty" :empty="false" :options="options_long" :search="false")
        pre {{model_ssm_empty}}

      TestSection(subtitle="w/ placeholder")
        SearchSelectMultiple(v-model="model_ssm_placeholder" placeholder="Select custom values" :options="options_short")
        pre {{model_ssm_placeholder}}

      TestSection(subtitle="Disabled")
        SearchSelectMultiple(v-model="model_ssm_disabled" :options="options_short" :disabled="true")
        pre {{model_ssm_disabled}}

      TestSection(subtitle="options - null")
        SearchSelectMultiple(v-model="model_ssm_disabled" :options="null")
        pre {{model_ssm_disabled}}

      TestSection(subtitle="@Change - No immediate update")
        SearchSelectMultiple(:value="model_ssm_change" :options="options_short" @change="model_ssm_change = $event")
        pre {{model_ssm_change}}

      TestSection(subtitle="w/ disabled option")
        SearchSelectMultiple(v-model="model_ssm_opt_disabled" :options="options_short_disabled")
        pre {{model_ssm_opt_disabled}}

      TestSection(subtitle="cannot bulk select")
        SearchSelectMultiple(v-model="model_ssm_select_all" :bulk="false" :options="options_short_disabled")
        pre {{model_ssm_select_all}}

  div(style="grid-column: 1 / -1")
    hr
    TestLayout(title="Search Select Custom")

      TestSection(subtitle="Google Locations")
        SearchSelectGoogleLocations(type="select" v-model="model_ssc_locations")
        pre {{model_ssc_locations}}

      TestSection(subtitle="Google Locations - Disabled")
        SearchSelectGoogleLocations(type="select" :disabled="true")
        pre {{[]}}

      TestSection(subtitle="Time Selection")
        SelectTimeRange(:start="model_ssc_timerange[0]" :end="model_ssc_timerange[1]" :timezone="model_ssc_timerange[2]" @change="model_ssc_timerange = $event")
        pre {{model_ssc_timerange}}

      TestSection(subtitle="Time Selection - Disabled")
        SelectTimeRange(:start="null" :end="null" :timezone="null" :disabled="true" @change="model_ssc_timerange = $event")
        pre {{[null, null, null]}}

      TestSection(subtitle="Date Selection")
        SelectDateRange(
          :start="model_ssc_daterange.start"
          :end="model_ssc_daterange.end"
          :timezone="model_ssc_daterange.timezone"
          @change="model_ssc_daterange = $event"
        )
        pre {{ model_ssc_daterange }}
        pre {{ model_ssc_daterange.start | DateRangeFilter(model_ssc_daterange.end, { utc: true }) }}

      TestSection(subtitle="Date Selection - Time")
        SelectDateRange(
          :start="model_ssc_daterange_time.start"
          :end="model_ssc_daterange_time.end"
          :timezone="model_ssc_daterange_time.timezone"
          :options="{time: true, timezone: true, start_day: true}"
          @change="model_ssc_daterange_time = $event"
        )
        pre {{ model_ssc_daterange_time }}
        pre {{ model_ssc_daterange_time.start | DateRangeFilter(model_ssc_daterange_time.end, { time: true, utc: true }) }}

      TestSection(subtitle="Date Selection - Single")
        SelectDateRange(
          :start="model_ssc_daterange_single.start"
          :end="model_ssc_daterange_single.end"
          :timezone="model_ssc_daterange_single.timezone"
          :options="{timezone: true, start_day: true, single: true}"
          @change="model_ssc_daterange_single = $event"
        )
        pre {{ model_ssc_daterange_single }}
        pre {{ model_ssc_daterange_single.start | DateRangeFilter(model_ssc_daterange_single.end, { time: true, utc: true }) }}

      TestSection(subtitle="Date Selection - Single w/o range")
        SelectDateRange(
          :start="model_ssc_daterange_single_worange.start"
          :timezone="model_ssc_daterange_single_worange.timezone"
          :options="{timezone: true, start_day: true, single: true, range: false}"
          @change="model_ssc_daterange_single_worange = {start: $event.start, timezone: $event.timezone}"
        )
        pre {{ model_ssc_daterange_single_worange }}
        pre {{ model_ssc_daterange_single_worange.start | DateFilter({ time: true, utc: true }) }}

      TestSection(subtitle="Default")
        SearchSelectCustom(
          variant="user_dashboard_sort"
          :search="false"
          :handler="customOptionsHandler"
          @select="model_ssc_default = $event.value"
        )
          template(v-slot:placeholder)
            i.nexd-icon-32-add(aria-hidden="true")
        pre {{model_ssc_default}}

  div(style="grid-column: 1 / -1")
    hr
    TestLayout(title="Search Select Input")

      TestSection(subtitle="Default")
        SearchSelectInput(
          :handler="fetchOptions"
          @select="e => model_ssi_default.push(e)"
        )
        pre {{model_ssi_default}}

      TestSection(subtitle="Disabled")
        SearchSelectInput(:handler="fetchOptions" :disabled="true")
        pre {{[]}}

      TestSection(subtitle="Keywords")
        KeywordsInput(v-model="model_ssi_keywords")

      TestSection(subtitle="Collaborators")
        CollaboratorsInput(v-model="model_ssi_collaborators")
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import CollaboratorsInput from '@master/UI/CollaboratorsInput.vue';
import KeywordsInput from '@master/UI/KeywordsInput.vue';
import Pill from '@master/UI/Pill/Pill.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import SearchSelectCustom from '@master/UI/SearchSelect/SearchSelectCustom.vue';
import SearchSelectGoogleLocations from '@master/UI/SearchSelect/SearchSelectGoogleLocations';
import SearchSelectInput from '@master/UI/SearchSelect/SearchSelectInput.vue';
import SearchSelectMultiple from '@master/UI/SearchSelect/SearchSelectMultiple.vue';
import SelectDateRange from '@master/UI/SearchSelect/SelectDateRange.vue';
import SelectTimeRange from '@master/UI/SearchSelect/SelectTimeRange.vue';

export default {
  name: 'SearchSelectTest',

  components: {
    TestLayout,
    TestSection,

    CollaboratorsInput,
    KeywordsInput,
    Pill,
    SearchSelect,
    SearchSelectCustom,
    SearchSelectGoogleLocations,
    SearchSelectInput,
    SearchSelectMultiple,
    SelectDateRange,
    SelectTimeRange,
  },

  data() {
    const options_short = [];
    const options_long = [];
    const options_longest = [];

    for (let i = 1; i <= 1000; i++) {
      if (i < 5) {
        options_short.push({
          label: `Short ${i}`,
          value: i,
        });
      }
      if (i < 50) {
        options_long.push({
          label: `Medium ${i}`,
          value: i,
        });
      }
      options_longest.push({
        label: `Long ${i}`,
        value: i,
      });
    }

    return {
      counts: {
        foo: 1,
        bar: 2,
        baz: 3,
        quz: 4,
        qux: 5,
        quux: 6,
        corge: 7,
        grault: 8,
        garply: 9,
        waldo: 10,
      },
      options_short,
      options_short_disabled: [
        {
          value: 'foo',
          label: 'Foo',
        },
        {
          value: 'baz',
          label: 'Baz',
          disabled: true,
        },
        {
          value: 'bar',
          label: 'Bar with a very long label (this is a very long label)',
        },
      ],
      options_long,
      options_longest,
      options_types2: [
        {
          value: -1,
          label: 'Select type',
        },
        {
          value: 0,
          label: 'Standard Infeed',
          description: 'It appears inside of content feeds.',
          icons: ['nexd-icon-16-mobile', 'nexd-icon-16-desktop'],
        },
        {
          value: 1,
          label: 'Interstitial',
          description: 'Fullscreen ads that overlay the content.',
          icons: ['nexd-icon-16-mobile'],
        },
        {
          value: 2,
          label: 'Interscroller',
          description: 'Scroll-in ad, where creative is fullscreen size, but visible though a viewport (aka understitial or underlay).',
          icons: ['nexd-icon-16-mobile'],
        },
        {
          value: 3,
          label: 'VAST Video',
          description: 'Can use our still layout.',
          icons: ['nexd-icon-16-video'],
        },
        {
          value: 4,
          label: 'VPAID Video',
          description: 'Is available on all desktop layouts with video.',
          icons: ['nexd-icon-16-video'],
        },
      ],
      options_types: [
        {
          value: -1,
          label: 'Select type',
        },
        {
          value: 0,
          label: 'Standard Infeed',
        },
        {
          value: 1,
          label: 'Interstitial',
        },
        {
          value: 2,
          label: 'Interscroller',
        },
        {
          value: 3,
          label: 'VAST Video',
        },
        {
          value: 4,
          label: 'VPAID Video',
        },
      ],

      model_ss_default: 1,
      model_ss_nosearch: 2,
      model_ss_disabled: 3,
      model_ss_nofirst: '',
      model_ss_placeholder: 1,
      model_ss_scrollable: 11,
      model_ss_types: null,
      model_ss_url: null,
      model_ss_opt_disabled: null,
      model_ss_filter: null,
      model_ss_lazy: 789,

      model_ssm_default: [],
      model_ssm_nosearch: [],
      model_ssm_disabled: [],
      model_ssm_change: [],
      model_ssm_placeholder: [],
      model_ssm_count: [],
      model_ssm_empty: ['foo'],
      model_ssm_opt_disabled: [],
      model_ssm_select_all: [],

      model_ssc_default: null,
      model_ssc_locations: [],
      model_ssc_timerange: [null, null, null],
      model_ssc_daterange: {
        start: null,
        end: null,
        timezone: null,
      },
      model_ssc_daterange_time: {
        start: null,
        end: null,
        timezone: null,
      },
      model_ssc_daterange_single: {
        start: null,
        end: null,
        timezone: null,
      },
      model_ssc_daterange_single_worange: {
        start: null,
        timezone: null,
      },

      model_ssi_default: [],
      model_ssi_keywords: [],
      model_ssi_collaborators: [],
    };
  },

  methods: {
    customOptionsHandler() {
      return Promise.resolve(
        Array.from({ length: 25 }, (_, i) => ({
          value: i,
          name: `Option ${i}`,
          active: this.model_ssc_default === i,
        })),
      );
    },

    async fetchOptions(query) {
      return this.options_long.filter(option => !this.isSelectedOption(option) && option.label.toLowerCase().includes(query.trim().toLowerCase()));
    },

    isSelectedOption(option) {
      for (const selected of this.model_ssi_keywords) {
        if (selected.value === option.value) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>
