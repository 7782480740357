<template lang="pug">
div
  template(v-for="(type, index) of [undefined, 'creative', 'folder', 'flight']")
    hr.gray-200(v-if="index > 0")
    CampaignCreativeNotFound(:type="type")
</template>

<script>
import CampaignCreativeNotFound from '@master/UI/CampaignCreativeNotFound.vue';

export default {
  name: 'CampaignCreativeNotFoundTest',

  components: {
    CampaignCreativeNotFound,
  },
};
</script>
