<template lang="pug">
div(
  :class="styles.drop_area"
  :data-highlight="highlight_drag_area || loading_folder_data"
  :data-placeholder="loading_folder_data ? 'Loading...' : 'Drop your creatives here'"
  @drop="drop"
  @dragover.prevent=""
  @dragleave.prevent=""
)
  section(v-if="!statement.creatives.length && !loading_folder_data" :class="styles.drop_area_placeholder" data-placeholder="Drop your creatives here")
  section(v-else)
    div(:class="styles['creatives-header']")
      div(:class="styles.whitespace")
      div(v-if="!is_dco") Priority
        i.nexd-icon-16-help.ml-4(
          aria-hidden="true"
          v-tooltip="{value: 'The priority determines which creatives will be served first. Enter a percentage between 0-100%. The higher the percentage, the higher the priority.'}"
        )
      div
    div(:class="styles.creatives")
      template(v-for="(creative, index) of statement.creatives")
        FlightCreative(:key="creative.id" :index="index" :statement="statement" :creative="creative" :is_dco="is_dco" @delete="validatePriorities")

          template(v-slot:priority)
            div(v-tooltip="{value: last_item?.id === creative.id ? 'This field is disabled because we automatically calculate the value so it adds up to 100%' : null}")
              input.form-control(
                type="number"
                :value.number="roundify(creative.priority)"
                :disabled="last_item?.id === creative.id"
                @keyup.enter="priorityUpdateHandler($event, creative)"
                @blur="priorityUpdateHandler($event, creative)"
              )
</template>

<script>
import styles from './FlightStatementCreatives.module.scss';

import CustomCheckbox from '@master/UI/CustomCheckbox';

import FlightService from '@master/Services/FlightService';
import { validateNewCreatives } from '@cm/Views/Flights/helpers';
import { FLIGHTS } from '@master/constants';

import FlightCreative from '@cm/Views/Flights/Components/FlightStatements/FlightCreative/FlightCreative';

export default {
  name: 'FlightStatementCreatives',

  components: {
    CustomCheckbox,
    FlightCreative,
  },

  props: {
    statement: Object,
  },

  computed: {
    highlight_drag_area() {
      return this.$store.get('flights_drag_creatives_in_progress');
    },

    last_item() {
      return this.statement?.creatives?.[this.statement?.creatives?.length - 1] ?? null;
    },

    is_dco() {
      return this.statement?.type === FLIGHTS.STATEMENT_TYPE.TYPE_DCO;
    },
  },

  data() {
    return {
      styles,

      flight: null,
      loading_folder_data: false,
    };
  },

  created() {
    FlightService.subscribe(data => {
      this.flight = data?.flight;
    }, this);
  },

  methods: {
    roundify(number) {
      return parseFloat(number.toFixed(2));
    },

    async drop(event) {
      if (event.dataTransfer.getData('action') !== 'add-creatives-to-flight' || this.loading_folder_data) {
        return;
      }

      this.$store.get('clear_creative_library_selection')();

      const { creative_ids, folder_ids } = JSON.parse(event.dataTransfer.getData('application/payload'));

      let creatives = creative_ids.map(creative_id => FlightService.creatives.get(creative_id));

      if (folder_ids?.length > 0) {
        this.loading_folder_data = true;

        try {
          const folders_creatives = await this.$http.post(`v2/flights/${this.statement?.flight_id}/folders`, { folder_ids }, { flight: false });

          if (folders_creatives?.length > 0) {
            FlightService.creatives.set(folders_creatives);

            for (const creative of folders_creatives) {
              creatives.push(FlightService.creatives.get(creative?.creative_id));
            }
          }
        } catch (_) {
          /** suppress errors */
        }

        this.loading_folder_data = false;
      }

      const { valid, invalid } = validateNewCreatives(this.statement, creatives);

      if (valid.length > 0) {
        this.statement?.creatives?.unshift(...valid);
        this.validatePriorities();
      }

      if (invalid.length > 0) {
        this.$alert(
          `
            Using instream creatives and standard display creatives in one flight is not supported. \
            We suggest creating 2 campaigns - one with instream creatives, \
            the other one with standard display creatives, and setting up separate flights. \
          `,
          'Some creative(s) cannot be added to this flight',
        );
      }
    },

    priorityUpdateHandler(event, creative) {
      this.$set(creative, 'priority', this.roundify(Math.min(creative.priority + this.last_item.priority, Math.max(0, event?.target?.value ?? 0))));
      this.validatePriorities();
    },

    validatePriorities() {
      if (!this.statement?.creatives?.length) {
        this.change();
        return;
      }

      const last_item_priority = this.statement?.creatives?.reduce((_leftover, _creative) => {
        if (_creative.id !== this.last_item.id) {
          _leftover -= _creative.priority;
        }
        return _leftover;
      }, 100);

      this.statement?.creatives?.splice(this.statement.creatives.length - 1, 1, {
        ...this.last_item,
        priority: this.roundify(last_item_priority ?? 0),
      });
      this.change();
      this.$forceUpdate();
    },

    change() {
      this.$emit('change');
    },
  },
};
</script>
