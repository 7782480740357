export default {
  install: Vue => {
    const _defaults = {
      active_tab: 'assets',
      quantum_state: 'overview',
      social_counts: null,
      active_assets_library: null,
      layout_filters: {},
      swapping_asset_ids: [],
      swapping_asset: { type: -1, width: -1, height: -1, asset_id: null },
      swapping_asset_in_progress: false,
      add_to_folder_in_progress: false,
      add_to_folder_folderids: [],

      /** --- FLIGHTS --- */
      clear_creative_library_selection: null,
      flights_statements: [], /** to keep track of statements that are hovered and display blue line for last one in array */
      flights_drag_creatives_in_progress: false,
      /** --- */
    };

    const store = Vue.observable({
      _data: { ..._defaults },

      set(key, value) {
        this._data[key] = value;
      },

      get(key, _default) {
        return this._data[key] !== undefined ? this._data[key] : _default;
      },

      reset(key) {
        this._data[key] = _defaults[key] || null;
      },

      has(key) {
        return this._data[key] != null;
      }
    });

    Vue.mixin({
      beforeCreate() {
        this.$store = store;
      }
    });
  }
};
