<template lang="pug">
  div.module-slots
    SlotMaker(v-if="module.slots != null" :creative="creative" :slots="module.slots" :parent="parent" :parent_slot="parent_slot" :key="module._fullpath")
</template>

<script>

import ModuleTraits from '@master/Traits/ModuleTraits';

export default {
  name: 'DefaultModule',
  mixins: [ModuleTraits],
  beforeCreate () {
    this.$options.components.SlotMaker = require('@cm_modules/SlotMaker').default;
  },
  props: {
    creative: Object,
    module: Object,
    parent: {
      type: Object,
      default: null
    },
    parent_slot: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      object: null,
      module_slots: {}
    };
  },
  created() {
    this.modularitySetup();
  }
};
</script>
