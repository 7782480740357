<template lang="pug">
div(:class="styles.asset")
  img(:src="generateURL(file)" width="40" height="40")
  span.flex-grow.fs-12.fw-500 {{ file.name }}
  i.nexd-icon-32-delete(aria-hidden="true" @click="$emit('remove')")
</template>

<script>
import styles from '../AdCreator.module.scss';

export default {
  name: 'Asset',

  props: {
    file: File
  },

  data() {
    return {
      styles
    };
  },

  methods: {
    generateURL() {
      return URL.createObjectURL(this.file);
    }
  }
};
</script>
