import Subscriber from '@libs/Subscriber';

import CreativeService from '@master/Services/CreativeService';
import SidebarService from '@root/src/global/sidebar/global/Service';
import WhitelabelService from '@master/Services/WhitelabelService';

import { groupCreativesByType } from '@helpers/Creative';

class AnalyticsService extends Subscriber {
  #has_google_ads_creatives = false;
  #public_creative = null;
  #error = false;

  load() {
    if (this.router.history.current.params?.public_live_id != null) {
      this.#loadPublicCreative();
    } else {
      this.#load();
    }
  }

  loadAgain(code, retry = true, cb) {
    if (retry && (code === 0 || code === 504)) {
      setTimeout(() => cb(), 5000);
    }
  }

  getHasGoogleAdsCreatives() {
    return this.#has_google_ads_creatives;
  }

  getPublicCreative() {
    return this.#public_creative;
  }

  getErrorState() {
    return this.#error;
  }

  setupSidebarPreview() {
    const active_creative = CreativeService?.get();

    SidebarService.setPreview(
      active_creative?.live_preview_url ?? active_creative?.preview_url ?? this.data?.live_preview_url ?? this.data?.preview_url ?? this._public_creative?.live_preview_url ?? this._public_creative?.preview_url ?? null,
    );
  }

  getCampaignId() {
    return this.data?.campaign_id;
  }

  getActiveParentChartPath() {
    return this.#pathClosure((key, id) => {
      switch (key) {
        case 'flight_id':
          return `v2/flights/${id}/analytics/charts`;
        case 'campaign_id':
          return `analytics/campaigns/${id}/charts`;
        case 'public_id':
          return `shared/campaigns/${id}/charts`;
        default:
          return null;
      }
    });
  }

  getActiveParentComparePath() {
    return this.#pathClosure((key, id) => {
      switch (key) {
        case 'flight_id':
          return `v2/flights/${id}/analytics/compare`;
        case 'campaign_id':
          return `analytics/campaigns/${id}/compare`;
        case 'public_id':
          return `shared/campaigns/${id}/compare`;
        default:
          return null;
      }
    });
  }

  getActiveParentAnalyticsPath() {
    return this.#pathClosure((key, id) => {
      switch (key) {
        case 'flight_id':
          return `v2/flights/${id}/analytics`;
        case 'campaign_id':
          return `analytics/campaigns/${id}`;
        case 'public_id':
          return `shared/campaigns/${id}`;
        default:
          return null;
      }
    });
  }

  getActiveParentExportPath(type) {
    return this.#pathClosure((key, id) => {
      switch (key) {
        case 'flight_id':
          return `v2/flights/${id}/analytics/export/async/${type}`;
        case 'campaign_id':
          return `analytics/campaigns/${id}/export/async/${type}`;
        case 'public_id':
          return `shared/campaigns/${id}/export/${type}`;
        default:
          return null;
      }
    });
  }

  getActiveParentName() {
    return this.#pathClosure(key => {
      switch (key) {
        case 'flight_id':
          return 'Flight';
        case 'campaign_id':
        case 'public_id':
          return 'Campaign';
        default:
          return null;
      }
    });
  }

  #getActiveParentAnalyticsURL() {
    return this.#pathClosure((key, id) => {
      switch (key) {
        case 'flight_id':
          return `/analytics/f/${id}`;
        case 'campaign_id':
          return `/analytics/c/${id}`;
        case 'public_id':
          return `/analytics/p/${id}`;
        default:
          return null;
      }
    });
  }

  #getActiveParentPath() {
    return this.#pathClosure((key, id) => {
      switch (key) {
        case 'flight_id':
          return `v2/flights/${id}/analytics`;
        case 'campaign_id':
          return `v2/campaigns/${id}`;
        case 'public_id':
          return `shared/campaigns/${id}`;
        default:
          return null;
      }
    });
  }

  #getActiveParentCreativesPath() {
    return this.#pathClosure((key, id) => {
      switch (key) {
        case 'flight_id':
          return `v2/flights/${id}/analytics/creatives/live`;
        case 'campaign_id':
          return `campaigns/${id}/creatives/live`;
        case 'public_id':
          return `shared/campaigns/${id}/creatives/live`;
        default:
          return null;
      }
    });
  }

  #getActiveParentKey() {
    for (const id of ['campaign_id', 'flight_id', 'public_id']) {
      if (id in this.router.history.current.params) {
        return id;
      }
    }
  }

  #getActiveParentId() {
    return this.router.history.current.params[this.#getActiveParentKey()];
  }

  #pathClosure(func) {
    const active_id = this.#getActiveParentId();

    if (active_id == null) {
      return null;
    }

    const active_key = this.#getActiveParentKey();

    return func(active_key, active_id);
  }

  #setupSidebar() {
    if (SidebarService == null) return;

    const params = this.router.history.current.params;
    const active_creative = CreativeService?.get();

    const campaign_id = params?.campaign_id ?? this.data?.campaign_id ?? active_creative?.campaign_id ?? null;

    if (params?.public_id == null && params?.public_live_id == null) {
      let path;
      let label = 'My Campaigns';

      if (WhitelabelService.enabled()) {
        label = 'Analytics';
      } else {
        path = '/';
        SidebarService.setCreatives(null, campaign_id != null ? `/c/${campaign_id}` : null);
      }

      SidebarService.setBack(label, path);
    }

    if (params?.flight_id != null) {
      SidebarService.setFlight({
        visible: true,
        url: `/c/${campaign_id}/flights/${params.flight_id}`,
      });
    }

    if (WhitelabelService?.analytics?.preview_links == false) return;

    this.setupSidebarPreview();
  }

  #setupSidebarCreatives(creatives) {
    if (creatives == null) {
      return;
    }

    if (creatives != null && this.router.history.current.params?.public_live_id == null) {
      SidebarService?.setCampaignAnalytics(
        Object.values(creatives)
          ?.map(group => group?.creatives)
          ?.flat() ?? null,
        this.data?.analytics_url ?? this.#getActiveParentAnalyticsURL(),
        this.getActiveParentName(),
      );
    }
  }

  #load() {
    const path = this.#getActiveParentPath();

    if (path == null) {
      return;
    }

    super
      .load(path)
      .then(() => this.#loadCreatives())
      .catch(() => {
        this.#error = true;
        this.data = null;
        super.sendAll();
      })
      .finally(() => this.#setupSidebar());
  }

  #loadCreatives() {
    const path = this.#getActiveParentCreativesPath();

    if (path == null) {
      return;
    }

    this.$http
      .get(path)
      .then(response => {
        if (response?.creatives == null) return;

        const info = groupCreativesByType(response?.creatives);

        this.#has_google_ads_creatives = info.has_google_ads_creatives ?? false;

        this.#setupSidebarCreatives(info.creatives);
      })
      .catch(() => {
        /** suppress errors */
      });
  }

  #loadPublicCreative() {
    const public_live_id = this.router.history.current.params.public_live_id;

    if (public_live_id == null) {
      return;
    }

    this.$http
      .get(`shared/creatives/${public_live_id}`)
      .then(response => {
        this.data = null;
        this.#public_creative = response;
        super.sendAll();
      })
      .catch(() => {
        /** suppress errors */
      });
  }
}

export default new AnalyticsService();
