<template lang="pug">
div.facebook-connect
  Button(type="primary" :label="connected ? 'Disconnect' : 'Facebook Connect'" :animate="true" :loading="loading" @click="handleClick")
    template(v-slot:prefix)
      i.nexd-icon-32-facebook
  div.color-cyan-blue.fs-12.mt-16(v-if="label")
    template(v-if="connected")
      span(v-if="user.name != null") Connected to Facebook as {{ user.name }}
    span(v-else) Connect to Facebook to choose a post you have created
</template>

<script>
import { addFacebookScript, required_user_scopes, required_user_scopes_readable } from '@helpers/Facebook';

import Button from '@master/UI/Buttons/Button';

export default {
  name: 'FacebookConnect',

  components: {
    Button,
  },

  props: {
    label: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
      connected: false,
      token: null,
      user: {
        name: null,
      },
    };
  },

  mounted() {
    window.fbAsyncInit = () => {
      this.handleFacebookStart();
    };

    addFacebookScript(() => {
      this.handleFacebookStart();
    });
  },

  methods: {
    async handleFacebookStart() {
      window.FB.init({
        appId: process.env.VUE_APP_FACEBOOK_API_KEY,
        status: true,
        cookie: true,
        xfbml: true,
        version: 'v20.0',
      });

      window.FB.Event.subscribe('auth.statusChange', response => {
        this.handleFacebookStatusChange(response);
      });

      this.tradeTokens();
    },

    handleClick() {
      if (this.loading) return;

      this.loading = true;

      if (this.connected) {
        this.logout();
      } else {
        this.login();
      }
    },

    logout() {
      window.FB.logout();
      this.connected = false;
      this.user.name = null;
      this.token = null;
      this.loading = false;
      return this.$emit('tokenReceived', { token: this.token, connected: this.connected });
    },

    login() {
      window.FB.login(
        _ => {
          // check for required permissions
          window.FB.api('me/permissions', response => {
            // closed login
            if (!response?.data) {
              this.loading = false;
              return;
            }

            let missing_permissions = [];
            for (const permission of response.data) {
              for (const title of required_user_scopes_readable) {
                if (permission.status === 'declined' && required_user_scopes.includes(permission.permission) && title.value === permission.permission) {
                  missing_permissions.push(title.label);
                } else if (permission.status === 'declined' && permission.permission === 'pages_show_list') {
                  this.logout();
                  return this.$emit(
                    'error',
                    '<div>You did not select or do not have any Facebook pages. Please check that you have enough permissions on Facebook and you gave us the needed permissions. <a href="https://support.nexd.com/en/articles/5844205-import-from-social-media#h_cb56cdbcb6">Read more</a></div>',
                  );
                }
              }
            }

            if (missing_permissions.length > 0) {
              this.logout();
              return this.$emit('error', 'To import all the assets, number of likes and comments you need to grant us the following rights to your Facebook pages: ' + missing_permissions.join(', '));
            } else {
              // trade token and after trade success, open modal
              this.tradeTokens();
              this.loading = false;
            }
          });
        },
        { scope: required_user_scopes.join(',') },
      );
    },

    async tradeTokens() {
      const validateFB = await this.validateFacebookConnectionStatus();

      if (validateFB == null) return;

      const {
        status,
        authResponse: { accessToken, userID },
      } = validateFB;

      this.token = accessToken;

      if (status === 'connected') {
        this.getFBAccountName(userID);

        return this.$emit('tokenReceived', { token: this.token, connected: this.connected });
      }

      this.logout();
    },

    getFBAccountName(user_id = null) {
      if (user_id == null || this.token == null) return;

      window.FB.api('/me', ({ name }) => {
        if (name != null) {
          this.user.name = name;
        }
      });
    },

    validateFacebookConnectionStatus() {
      return new Promise(resolve => {
        window.FB.getLoginStatus(response => {
          // emit only on init, as when connected is null
          this.handleFacebookStatusChange(response);
          if (this.connected) {
            return resolve(response);
          }
          resolve(null);
        });
      });
    },

    handleFacebookStatusChange(response) {
      const new_status = response.status === 'connected';
      const status_changed = new_status !== this.connected;

      // set state before emit
      this.connected = new_status;

      // emit status only if it changes
      if (status_changed) {
        this.$emit('statusChange', new_status);
      }
    },
  },
};
</script>
