<template lang="pug">
div.tracking-row-header(:class="{'global': global}")
  template(v-if="is_tracking")
    span(v-if="!global") Asset
    span Event
    span Tracker
  template(v-else-if="is_cta")
    span(v-if="!global") Asset
    span Type
    span Output
</template>

<script>
export default {
  name: 'TrackingRow',
  props: {
    global: {
      type: Boolean,
      default: true
    },

    tab: {
      type: String,
      default: null
    }
  },
  computed: {
    active_tab() {
      return this.tab ?? this.$store.get('active_tab');
    },

    is_tracking() {
      return this.active_tab === 'tracking';
    },

    is_cta() {
      return this.active_tab === 'cta';
    }
  }
};
</script>
