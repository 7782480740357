<template lang="pug">
div.slot-content
  div.slot(v-for="(slot, slot_id) in slots")
    AssetSlot(v-if="getSlotName(slot) === 'asset'"                    :creative="creative" :parent="parent" :parent_slot="parent_slot" :item="slot" :key="slot._fullpath")
    EffectAssetSlot(v-else-if="getSlotName(slot) === 'effect_asset'"  :creative="creative" :parent="parent" :parent_slot="parent_slot" :item="slot" :key="slot._fullpath")
    //- Here modulemaker needs slot as parent slot - since it can generate slot's itself as well - so heres the difference between using exact parent_slot for asset/Effect slot, but not for modulemaker
    CombinedAssetModule(v-if="slot.modules != null && combinedModulesRequired(slot.modules)"     :creative="creative" :parent="parent" :parent_slot="slot" :key="slot._fullpath")
    ModuleMaker(v-else-if="slot.modules != null"                           :creative="creative" :parent="parent" :parent_slot="slot" :key="slot._fullpath" :modules="slot.modules")
</template>

<script>

import AssetSlot from '../Modules/Asset/Module';
import CombinedAssetModule from '../Modules/CombinedAsset/Module';
import EffectAssetSlot from '../Modules/Effects/Asset/Slot';

export default {
  name: 'SlotMaker',
  beforeCreate () {
    this.$options.components.ModuleMaker = require('@cm_modules/ModuleMaker').default;
  },
  components: {
    AssetSlot,
    CombinedAssetModule,
    EffectAssetSlot,
  },
  props: {
    creative: Object,
    slots: Object,
    parent: {
      type: Object,
      default: null
    },
    parent_slot: {
      type: Object,
      default: null
    }
  },
  data () {
    return {};
  },

  methods: {
    combinedModulesRequired (modules) {
      if (modules == null) {
        return false;
      }
      let count = 0;
      for (const k in modules) {
        if (modules[k].module_id === 'image' || modules[k].module_id === 'video') {
          count++;
          if (count > 1) return true;
        }
      }
      return false;
    },
    getSlotName (slot) {
      if (slot.slot_type === 'asset') {
        if (slot.module_id.indexOf('_effect') > -1) {
          return 'effect_asset';
        } else {
          return 'asset';
        }
      }
      return null;
    }
  }
};
</script>
