import Chartist from '@libs/Chartist';

class BarTooltip {
  constructor (context) {
    this.context = context;
    this.tooltipContent = this.context.meta;
    this._tooltip = null;
    this.init();
  }
  init () {
    const hitboxSize = 30;
    this.group = new Chartist.Svg('g', {}, 'ct-point-with-hitbox');
    this.hitbox = new Chartist.Svg('rect', {
      x: this.context.x2 - hitboxSize * 0.5,
      y: this.context.y2 - hitboxSize * 0.5,
      height: this.context.y1 - this.context.y2 + hitboxSize * 0.5,
      width: hitboxSize,
      fill: 'transparent'
    }, 'ct-hitbox', this.group);

    this.line = new Chartist.Svg('line', this.context, 'ct-bar', this.group);
    this.group.getNode().addEventListener('mouseover', () => {
      this.show();
    });
    this.group.getNode().addEventListener('mouseout', () => {
      this.hide();
    });
    return this.group;
  }
  create () {
    this._tooltip = document.createElement('div');
    this._tooltip.className = 'ct-tooltip bar-tooltip';
    this._tooltip.innerHTML = this.tooltipContent;
  }
  position () {
    const pointPosition = this.line.getNode().getBoundingClientRect();
    const top = pointPosition.bottom + window.scrollY - 16;
    const left = pointPosition.left + window.scrollX;
    const offsetY = pointPosition.height;
    const offsetX = pointPosition.width * 0.5;
    this._tooltip.style.top = top - offsetY + 'px';
    this._tooltip.style.left = left + offsetX + 'px';
  }
  show () {
    this.create();
    this.position();
    if (this._tooltip != null) {
      document.body.appendChild(this._tooltip);
    }
  }
  hide () {
    if (this._tooltip != null) {
      document.body.removeChild(this._tooltip);
    }
  }
}
export default BarTooltip;
