<template lang="pug">
div#registration
  div#content-wrapper
    div#signup

      Loading.p-32(v-if="is_invite && invite == null" text="Preparing...")

      template(v-else)
        div#registration-head
          h1
            | {{header_title[0]}}
            | {{header_title[1]}}
          hr
          p
            | {{header_body[0]}}
            br
            | {{header_body[1]}}

        form#form-content.flex.flex-direction-column.row-gap-16(@submit.prevent="signUp")
          OAuthSignIn(:login="false" :outline="true")

          Divider.fs-14(type="solid" text="OR" style="--border-color: #DDE3EB")

          template(v-for="(field, field_key) in form_layout")
            div.flex.flex-direction-column.row-gap-4(v-if="field_key === 'email' && !is_invite")
              label(:for="field_key") {{field.label}}
              Input(
                :key="field_key"
                :variant="field.type"
                :disabled="is_invite"
                :placeholder="field.placeholder"
                :medium="true"
                :error_msg="errors[field_key]"
                autocomplete="email"
                v-model="form_layout[field_key].value"
                @error="error => errors[field_key] = error"
              )
            div.flex.flex-direction-column.row-gap-4(v-else-if="field_key === 'password'")
              label(:for="field_key") {{field.label}}
              Input(
                :key="field_key"
                :variant="field.type"
                :placeholder="field.placeholder"
                :medium="true"
                :error_msg="errors[field_key]"
                autocomplete="password"
                v-model="form_layout[field_key].value"
                @error="error => errors[field_key] = error"
              )

          div.privacy_policy.text-center By signing up, I agree to the Nexd&nbsp;
            a(href="https://nexd.com/privacy-policy" target="_blank") Privacy Policy
            | &nbsp;and&nbsp;
            a(href="https://www.nexd.com/terms-conditions/" target="_blank") Terms of Service.

          Buttons(align="center")
            Button(
              type="success"
              :animate="true"
              :pill="true"
              :medium="true"
              label="Sign Up"
              :style="{width: '192px'}"
              :tooltip="button_tooltip"
              :disabled="loading || sign_up_disabled"
              :loading="loading"
              :submit="true"
            )

          div.back_to_login.text-center
            span Already a member?&nbsp;
            Button(type="link-primary" :text="true" label="Log In" @click="close")

</template>

<script>
import LoginService from '@login/LoginService';
import APP from '@login/app';
import CustomCheckbox from '@master/UI/CustomCheckbox';

import OAuthSignIn from '@master/UI/OAuthSignIn.vue';
import Loading from '@master/UI/Loading.vue';
import Input from '@master/UI/Input/Input';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Button from '@master/UI/Buttons/Button.vue';
import Divider from '@master/UI/Divider/Divider.vue';

import { VIEW } from '@master/constants';

export default {
  name: 'Register',
  extends: APP,

  components: {
    CustomCheckbox,
    Loading,
    OAuthSignIn,
    Input,
    Buttons,
    Button,
    Divider,
  },

  computed: {
    header_title() {
      if (this.is_invite && this.invite != null) {
        return [
          'You have been invited to join team',
          `${this.invite.group_name}`,
        ];
      }
      return ['Try Nexd Campaign Manager', 'for free'];
    },

    header_body() {
      if (this.is_invite) {
        return [
          'This invite will register an account in NEXD for',
          `${this.invite.email}`,
        ];
      }
      return ['14-days. Free of charge.', 'No credit card required'];
    },

    is_valid_email() {
      return this.errors.email == null;
    },

    is_valid_password() {
      return this.errors.password == null;
    },

    sign_up_disabled() {
      return !this.is_valid_email || !this.is_valid_password;
    },

    button_tooltip() {
      if (!this.is_valid_email) {
        return { value: this.errors.email };
      }
      if (!this.is_valid_password) {
        return { value: this.errors.password };
      }
      return null;
    },

    is_invite() {
      return this.$route.query.invitee != null;
    },
  },

  data() {
    return {
      loading: false,

      errors: {
        email: null,
        password: null,
      },

      // fields
      form_layout: LoginService.registrationFields(),

      invite: null,
    };
  },

  created() {
    if (this.is_invite) {
      this.getInvite();
    }
  },

  methods: {
    getInvite() {
      const path = `invite/${this.$route.query.invitee}`;
      this.$http
        .get(path)
        .then(response => {
          this.invite = response;
          this.form_layout.email.value = this.invite.email;
        })
        .catch(() => {
          // redirect to auth login screen
          this.$router.push({ name: VIEW.LOGIN });
        });
    },

    signUp() {
      if (this.loading) return;
      const data = this.generatePayload();
      this.loading = true;
      this.$http
        .post('user/register', data)
        .then(response => {
          if (response.path != null) {
            // for lcl env, save the randomly created email to ease testing
            if (process.env.NODE_ENV === 'development') {
              LoginService.set('email', data.email);
            }

            this.$router.push(response.path);
          } else {
            window.location.href = response.redirect_url;
          }
        })
        .catch(error => {
          if (error.response?.result?.errors) {
            this.errors = error.response.result.errors;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    generatePayload() {
      let payload = {};

      if (this.is_invite) {
        payload.invite_id = this.$route.query.invitee;
      }

      for (const field_key in this.form_layout) {
        const field = this.form_layout[field_key];
        if (field != null) {
          payload[field_key] = field.value;
        }
      }

      // tell backend where to redirect
      if (process.env.NODE_ENV === 'development') {
        payload.env = process.env.NODE_ENV;
      }

      return payload;
    },

    close() {
      this.open(VIEW.LOGIN);
    },
  },
};
</script>
