<template lang="pug">
  div.custom-preview.mt-32
    div.mb-16
      LivePreview(
        ref="preview"
        :creative="creative"
        :options="preview_options"
        :height="component_height"
        :width="component_width"
        :key="preview_options.force_placement_device"
        :poll="true"
        @versionchange="handleVersionChange")
    CreativeSizes(:creative="creative" @reload="reloadDevice")
</template>

<script>
import LivePreview from '@master/UI/LivePreview/LivePreview.vue';
import CreativeSizes from '@master/UI//CreativeSizes';
import CreativeTraits from '@master/Traits/CreativeTraits';

export default {
  name: 'PreviewCustom',
  mixins: [CreativeTraits],
  components: {
    LivePreview,
    CreativeSizes,
  },

  props: {
    creative: Object,
  },

  computed: {
    size() {
      return this.creative.packsize.total;
    },

    preview_options() {
      return {
        screen_height: null,
        expandable: false,
        browser_bars: true,
        force_placement_device: this.creative?.settings?.preview_device ?? null,
      };
    },

    component_height() {
      return '500px';
    },

    component_width() {
      return 'auto';
    },
  },

  methods: {
    reloadDevice() {
      if (this.$refs.preview != null) {
        this.$refs.preview.reloadDevice();
      }
    },

    handleVersionChange(version) {
      this.$emit('versionchange', version);
    },
  },
};
</script>
