/**
 * @module FlightMocks
 * @description - Functions to mock flight data when adding new flight components
 * @test `npm run test:flight`
 */

import UserService from '@master/Services/UserService';
import FlightService from '@master/Services/FlightService';
import { FLIGHT, STATUS, TYPE } from '@master/constants';

export function newCondition(
  args = {
    next_id: null,
    prev_id: null,
    statement_id: null,
    relation_type: null,
  },
) {
  return {
    created_by: UserService.get()?.user_id ?? null,
    id: FlightService.id(),
    key: FLIGHT.TYPES.LOCATION,
    next_id: args?.next_id ?? null,
    op: Object.keys(FLIGHT.OPERATORS)[0],
    prev_id: args?.prev_id ?? null,
    relation_type: args?.relation_type ?? Object.keys(FLIGHT.SEPARATORS)[0],
    statement_id: args?.statement_id ?? null,
    value: [],
  };
}

export function newStatement(
  ids = {
    next_id: null,
    prev_id: null,
    parent_id: null,
  },
) {
  const statement_id = FlightService.id();

  return {
    conditions: [newCondition({ statement_id })],
    created_by: UserService.get()?.user_id ?? null,
    creatives: [],
    flight_id: FlightService.getFlightId(),
    id: statement_id,
    next_id: ids?.next_id ?? null,
    parent_id: ids?.parent_id ?? null,
    prev_id: ids?.prev_id ?? null,
    statements: [],
  };
}

export function duplicateStatement(data) {
  if (data == null) return null;

  let new_statement = structuredClone(data);
  new_statement.id = FlightService.id();
  new_statement.prev_id = data.id;

  function validateStatements(statement) {
    statement.creatives = [];
    statement.conditions = statement?.conditions ?? [];

    for (let condition of statement.conditions) {
      if (condition.statement_id !== statement.id) {
        condition.statement_id = statement.id;
      }
    }

    if (statement.statements.length > 0) {
      for (const _statement of statement.statements) {
        validateStatements(_statement);
      }
    }
  }

  validateStatements(new_statement);

  return new_statement;
}

export function newCreative(creative) {
  return {
    creative_id: creative?.creative_id ?? null,
    id: FlightService.id(),
    live_id: creative?.live_id ?? null,
    name: creative?.name ?? null,
    priority: creative?.priority ?? 0,
    status: creative?.status ?? STATUS.DRAFT,
    type: creative?.type ?? TYPE.INFEED,
    thumbnail_url: creative?.thumbnail_url ?? null,
  };
}
