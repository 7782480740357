<template lang="pug">
div.effects
  EffectComponent(name="Page info" :collapsed="false" illustration="info" tooltip="Here you can modify and stylize the page info.")
    div(v-for="(setting, name, index) in widgets" :class="{'mb-24': index < Object.keys(setting).length}")
      div.flex.flex-align-center.mb-8(style="gap: 8px")
        div.fs-14.color-gray-800 {{ labels[name] || name | CapitalizeFirstLetterFilter }}
        i.nexd-icon-16-help(v-tooltip="{value: tooltips[name]}")
        div.flex-grow
          Toggle(v-model="setting.enabled" @input="__update")
      Input(v-model="setting.value" :textarea="true" :disabled="!setting.enabled" @blur="__update")
      div.row.mt-8.flex-align-center
        small.col Color:
        ColorOption.full-width.col-7(v-model="setting.color" @input="__update")
        small.col Font size:
        StyleInput.col-5(v-model="setting.size" label="px" :default="defaults[getWidgetParent(name)][name].size" @input="update($event, getWidgetParent(name), name, 'size')")
    div.mt-16(v-for="(setting, name, index) in counts")
      UIGroup(:title="name | CapitalizeFirstLetterFilter")
        CustomCheckbox.mb-16(:value="object.settings.counts.sync" @change="toggleSync")
          span.color-gray-800.ml-4 Automatic Syncing
        div(v-for="(count, _name, _index) in setting" :class="{'mb-24': _index < Object.keys(count).length}")
          div.flex.flex-align-center.mb-8(style="gap: 8px")
            div.fs-14.color-gray-800 {{ count.title | CapitalizeFirstLetterFilter }}
            i.nexd-icon-16-help(v-tooltip="{value: tooltips[count.title]}")
            div.flex-grow
              Toggle(v-model="count.enabled" @input="update($event, name, _name, 'enabled')")
          input.form-control(v-model="count.value" :disabled="!count.enabled || object.settings.counts.sync" @blur="update(count.value, name, _name, 'value')" v-number-input)
          div.row.mt-8.flex-align-center
            small.col Color:
            ColorOption.full-width.col-7(v-model="count.color" @input="update($event, name, _name, 'color')")
            small.col Font size:
            StyleInput.col-5(v-model="count.size" label="px" :default="defaults[name][_name].size" @input="update($event, name, _name, 'size')")
</template>

<script>
import ModulesService from '@master/Services/Cache/ModulesService';
import ModuleTraits from '@master/Traits/ModuleTraits';

import EffectComponent from '@cm_modules/EffectComponent';
import UIGroup from '@master/UI/UIGroup';
import Toggle from '@master/UI/Toggle';
import Input from '@master/UI/Input/Input';
import ColorOption from '@master/UI/ColorOption';
import StyleInput from '@cm/UI/Inputs/StyleInput';
import CustomCheckbox from '@master/UI/CustomCheckbox';

import { clone } from '@helpers/Global';

export default {
  name: 'SocialModule',
  mixins: [ModuleTraits],
  components: {
    EffectComponent,
    Toggle,
    UIGroup,
    Input,
    ColorOption,
    StyleInput,
    CustomCheckbox,
  },

  props: {
    creative: Object,
    module: Object,
  },

  data() {
    return {
      object: null,
      tooltips: {
        name: 'This name will be shown next to the profile image.',
        hashtags: 'Optional text that will be shown in the bottom-right corner of the ad.',
        sync: 'When enabled, updates the following fields periodically without the need of republishing.',
        likes: 'Number of likes that will be shown in the bottom-left corner of the ad.',
        comments: 'Number of comments that will be shown in the bottom-left corner of the ad',
      },
      labels: {
        name: 'page name',
      },
      defaults: null,
    };
  },

  computed: {
    widgets() {
      const settings = this.object.settings;
      const included = ['profile', 'content'];

      const objects = this.constructObjects(settings, included);

      return { name: objects.profile.name, hashtags: objects.content.hashtags };
    },

    counts() {
      const included = ['reactions', 'comments'];
      let settings = {
        counts: {},
      };

      // pre create object to maintain order
      for (const key of included) {
        settings.counts[key] = {};
      }

      for (let key in this.object.settings.counts) {
        if (settings.counts[key] != null) {
          let title = key;

          // show reactions as the likes count, because likes are actually only thumb up reactions, but we want to have all combined reactions which are all under like button
          if (key === 'reactions') {
            title = 'likes';
          }

          settings.counts[key] = clone(this.object.settings.counts[key]);
          settings.counts[key].title = title;
        }
      }
      return settings;
    },
  },

  created() {
    this.modularitySetup();
    this.defaults = ModulesService.getModuleSettings('social_widget');
  },

  methods: {
    constructObjects(object, included, name) {
      return Object.fromEntries(
        Object.entries(object).filter(([key, value]) => {
          if ((name != null && key === name) || included.includes(key)) {
            return value;
          }
        }),
      );
    },

    toggleSync(checked) {
      this.object.settings.counts.sync = checked;

      if (checked && this.$store.has('social_counts')) {
        this.$set(this.object.settings.counts.comments, 'value', this.$store.get('social_counts').comments);
        this.$set(this.object.settings.counts.likes, 'value', this.$store.get('social_counts').likes);
        this.$set(this.object.settings.counts.reactions, 'value', this.$store.get('social_counts').reactions);
      }

      this.__update();
    },

    update(value, parent, key, target) {
      this.$set(this.object.settings[parent][key], target, value);
      this.__update();
    },

    getWidgetParent(key) {
      for (const parent_key in this.object.settings) {
        if (this.object?.settings?.[parent_key]?.hasOwnProperty(key)) {
          return parent_key;
        }
      }
    },
  },
};
</script>
