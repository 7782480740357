<template lang="pug">
  div.survey-question-settings
    div.row
      div.col-24.mb-12
        h4.mb-8.mt-14 Question text:
        TextSettings(:object="settings.global.question.text" @update="$emit('update')")
      div.col-24.mb-12
        h4.mb-8.mt-14 Choice text:
        TextSettings(:object="settings.global.choice.text" @update="$emit('update')")
      div.col-24.mb-12
        h4.mb-8.mt-14 Choice Style:
        ButtonSettings(:object="settings.global.choice" @update="$emit('update')")
</template>
<script>

import TextSettings from '../Text/TextSettings';
import ButtonSettings from '../Button/ButtonSettings';


export default {
  name: 'QuestionsSettings',
  components: {
    TextSettings,
    ButtonSettings
  },
  props: {
    settings: Object
  },
  data() {
    return {
      collapsed: true
    };
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
    update () {
      this.$emit('update');
    }
  }
};
</script>
