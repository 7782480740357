<template lang="pug">
div.campaign-tags
  div.tag(v-for="item in labels")
    span(:class="item.class") {{ item.count }}&nbsp;
    span {{ item.label }}
</template>

<script>

const LABELS = {
  draft: 'Draft',
  waiting_for_traffic: 'Waiting for traffic',
  live: 'Live',
  paused: 'Paused',
  finished: 'Finished',
  google_ads: 'Published for Google'
};

export default {
  name: 'CreativeTags',
  props: {
    totals: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    labels() {

      let output = [];

      // list statuses
      for (const key in this.totals.status) {
        if (this.totals.status[key] > 0) {
          output.push({
            label: LABELS[key] ?? 'unknown',
            count: this.totals.status[key],
            class: `${key}-tag`
          });
        }
      }

      // also list google ads publish
      if (this.totals?.dsp?.google_ads > 0) {
        output.push({
          label: LABELS.google_ads,
          count: this.totals.dsp.google_ads,
          class: 'google_ads-tag'
        });
      }

      return output;
    }
  }
};
</script>
