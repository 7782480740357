<template>
  <div id="logo">
    <img :src="logo" alt="NEXD">
    <div id="support-blog">
      <a class="link" href="https://support.nexd.com/" target="_blank">Support</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a class="link" href="https://www.nexd.com/blog" target="_blank">Blog</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data() {
    return {
      logo: this.$cdn + (!window._christmas ? 'dist/assets/login/logo.svg' : 'dist/assets/nexd-8bit-logo.svg')
    };
  }
};
</script>
