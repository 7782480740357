<template lang="pug">
div.modal.modal-xs
  div.backdrop
  div.modal-content
    div.modal-head
      div.modal-title Change password

    div.modal-body
      form.flex.flex-direction-column.row-gap-16
        input(type="text" hidden name="username" autocomplete="username email" :value="user.email")

        div(v-for="field in fields" :key="field.name")
          label.mb-4 {{ field.label }}&nbsp;
            sup.color-danger *
          Input(variant="password" :placeholder="field.label" v-model="input[field.name]" :autocomplete="field.autocomplete")

    Buttons.modal-footer
      Button(type="link-primary" label="Cancel" @click="close")
      Button(type="primary" :animate="true" label="Save" :disabled="!is_valid" :tooltip="{value: tooltip}" @click="changePassword")
</template>

<script>
import { trimQuery } from '@helpers/Global';

import Modal from '@master/UI/Modal';

import Button from '@master/UI/Buttons/Button';
import Buttons from '@master/UI/Buttons/Buttons';
import Input from '@master/UI/Input/Input';

export default {
  name: 'ChangePasswordModal',
  extends: Modal,

  components: {
    Button,
    Buttons,
    Input
  },

  props: {
    user: Object
  },

  computed: {
    is_valid() {
      return this.input.new === this.input.repeat && Object.values(this.input)
        .every(value => trimQuery(value ?? '').length >= 8 && value.length <= 64);
    },

    tooltip() {
      if (this.input.new !== this.input.repeat) {
        return 'New password must match repeated password.';
      }

      return this.is_valid ? null : 'All passwords must be at least 6 characters long.';
    }
  },

  data () {
    return {
      input: {
        current: '',
        new: '',
        repeat: ''
      },

      fields: [
        { label: 'Current password', name: 'current', autocomplete: 'current-password' },
        { label: 'New password', name: 'new', autocomplete: 'new-password' },
        { label: 'Repeat new password', name: 'repeat', autocomplete: 'new-password' }
      ]
    };
  },

  methods: {
    changePassword() {
      if (this.is_valid) {
        this.$http.post('auth/password', this.input)
          .then(() => {
            this.close();
          });
      }
    },

    close() {
      this.$emit('close');
    }
  }
};
</script>
