<template lang="pug">
div.campaign-card-icons
  i.nexd-icon-32-delete(:class="{disabled: !can_delete}" v-tooltip="{value: tooltip, align: 'right', width: 240}" @click="deleteFolder" aria-hidden="true")
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import FolderMixin from '@root/src/global/mixins/FolderMixin';
import CampaignService from '@master/Services/CampaignService';
import CreativesService from '@master/Services/CreativesService';
import GroupAssetsLibraryService from '@master/Services/GroupAssetsLibraryService';

import LoadingModal from '@master/UI/LoadingModal';

export default {
  name: 'FolderSiderbarIcons',
  mixins: [clickaway, FolderMixin],
  components: {
    LoadingModal
  },

  props: {
    folder: Object
  },

  computed: {
    tooltip() {
      if (this.can_delete) return 'Delete';
      return 'Only empty folders can be deleted. Please move or delete the contents of the folder in order to delete it.';
    }
  },

  methods: {
    async deleteFolder() {
      if (!this.can_delete) return;

      if (await this.$confirm('Are you sure you want to delete this folder?')) {
        this.$http.delete(`v2/folder/${this.folder.folder_id}`)
          .then(() => {
            if (this.is_campaign_folder) {
              CampaignService.removeItems([this.folder.folder_id]);
            } else if (this.is_creative_folder) {
              CreativesService.removeItems([this.folder.folder_id]);
            } else if (this.is_asset_library_folder) {
              GroupAssetsLibraryService.removeItems([this.folder.folder_id]);
            }
          });
      }
    }
  }
};
</script>
