<template lang="pug">
div.p-16(:style="styles")
  div.flex.flex-align-center.flex-justify-between
    div
      div.flex.flex-align-center
        div.fs-14 {{token.object_id}}
        i.nexd-icon-32-duplicate.hover-primary(v-if="token.visible" v-tooltip="{value: 'Copy key to clipboard'}" @click="copy")
      small.color-gray-800 {{valid_until}}
    i.nexd-icon-32-delete.color-required.cursor-pointer(v-tooltip="{value: 'Delete key'}" @click="deleteToken")
</template>

<script>
import { setClipboard } from '@helpers/Global';
import DateFilter from '@master/Filters/_filters/DateFilter';
import moment from 'moment';

export default {
  name: 'ApiKeyRow',

  props: {
    token: {
      type: Object,
      validator: obj => {
        return (
          obj.id != null &&
          obj.valid_until != null &&
          obj.object_id != null &&
          obj.visible != null
        );
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    valid_until() {
      return this.token.valid_until <= moment().unix()
        ? 'Expired'
        : 'Valid until: ' + DateFilter(this.token.valid_until);
    },

    styles() {
      if (this.index > 0) {
        return 'border-top: 1px solid #DDE3EB';
      }
      return null;
    },
  },

  methods: {
    deleteToken() {
      this.$emit('delete', this.token);
    },

    copy() {
      setClipboard(this.token.object_id, true);
    },
  },
};
</script>
