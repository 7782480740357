<template lang="pug">
div.modal.modal-md.plan-editor-modal
  div.backdrop
  div.modal-content
    div.row
      div.col-8
        div.modal-head
          div.modal-title Subscription Plan
        div.modal-body

          div.mb-16
            label.mb-4
              template(v-if="active_plan_id === selected_plan_id")
                span Your currently active
                | <template v-if="plan.trial">&nbsp;trial</template>
                | plan is
              span(v-else) Your new plan will be

            SearchSelect(
              v-if="subscription_plans != null"
              :options="subscription_plans"
              :value="selected_plan_id"
              :search="false"
              array-key="name_with_price"
              @input="planChangeHandler")
            Loading( v-else)

          Buttons(align="left")
            Button(type="success" label="ACTIVATE" :loading="loading" :disabled="!making_a_purchase" :animate="true" @click="activateButtonHandler")

          a.fs-14.mt-16( href="https://www.nexd.com/pricing/" target="_blank") Compare plans in detail

          ul(v-if="selected_plan?.features")
            li(v-for="feature of selected_plan.features" :key="feature.feature_id")
              label {{feature.name}} - {{feature.string_value ?? feature.value}}

          // disabled because currently we dont actually bill on plan change
          template(v-if="active_plan_id == null")
            div.plan-inline-notification.mt-16
              div.notification-icon !
              div.notification-content
                | The billing period begins on the day the subscription is activated. The current payment for this activation covers only the period from the activation date until the 1st day of the next month.

        Buttons.modal-footer(align="left")
          Button(type="link-primary" label="Back" @click="$emit('close')")
      div.right-side.col-16.flex.flex-align-center
        div.flex-grow.text-center
          img( :src="$cdn + 'dist/assets/cm/subscription-modal-icon.svg'" alt="subscription")
          template(v-if="invoice != null")
            h1 {{invoice.due}} days
            p Next payment will be reserved
              br
              | from your card on {{invoice.date | DateFilter}}
</template>

<script>

import CreditCard from '@cm/Views/Billing/Components/CreditCard/CreditCard.vue';

import SearchSelect from '@master/UI/SearchSelect/SearchSelect';
import Loading from '@master/UI/Loading';
import Button from '@master/UI/Buttons/Button';
import Buttons from '@master/UI/Buttons/Buttons';

import Modal from '@master/UI/Modal';

export default {
  name: 'PlanEditorModal',
  extends: Modal,

  components: {
    CreditCard,
    SearchSelect,
    Loading,
    Button,
    Buttons
  },

  props: {
    organization: Object,
    plan: Object,
    invoice: Object
  },

  computed: {
    active_plan_id() {
      return this.plan?.plan_id ?? null;
    },

    no_active_plan() {
      return this.plan == null;
    },

    making_a_purchase() {
      if (this.user_has_trial_plan) return true;
      return this.active_plan_id !== this.selected_plan_id;
    },

    selected_plan() {
      return this.subscription_plans?.find(plan => plan.value === this.selected_plan_id) ?? null;
    }
  },

  data() {
    return {
      user_has_trial_plan: false,
      selected_plan_id: this.plan?.plan_id ?? 'eTJdZcJTvCXl',
      subscription_plans: null,
      loading: false
    };
  },

  created() {
    this.$user.subscribe(_ => {
      this.user_has_trial_plan = this.$user.hasTrialSubscription();
    }, this);

    const currency = this.$user.getCurrency();
    const path = `public/subscriptions?currency=${currency}`;

    this.$http.get(path)
      .then(response => {
        this.subscription_plans = [];

        for (const plan_id in response) {

          // exclude enterprise
          if (plan_id === 'OuK4HirThi3P') continue;

          // exclude free plan, unless user has the free plan
          if (plan_id === 'b4wUUnGFketB' && this.active_plan_id !== 'b4wUUnGFketB') continue;

          // show any other public plan
          const plan = response[plan_id];

          // most of the features are total bs and not actually valid
          // but since this sub endpoint might be part of whats used in landing
          // will filter the results for UI to have meaningful features only
          // can be removed after Product makes plans meaningful
          const valid_features = [
            'MhEGHfOth69p', // price
            'Qsoy0GSG3c93', // live creatives
            'bw7GZCPj904f', // live campaigns
            'hqPqskuZyS3e', // impressions per month
            'lZM9F1JR4AxI', // number of users
          ];

          this.subscription_plans.push({
            value: plan_id,
            label: plan.name,
            description: plan.description,
            features: plan.features.filter(feature => valid_features.includes(feature.feature_id))
          });
        }
      });
  },

  methods: {
    planChangeHandler(e) {
      this.selected_plan_id = e;
    },

    activateButtonHandler() {
      // trigger buy when on trial or when current plan is not active
      if (this.user_has_trial_plan || this.no_active_plan) {
        return this.changePlan();
      }

      if (this.selected_plan_id !== this.active_plan_id) {
        // trigger buy when selected plan is different
        return this.changePlan();
      }
    },

    changePlan() {
      this.loading = true;
      const path = `group/${this.organization.group_id}/subscriptions/plan/${this.selected_plan_id}`;
      this.$http.put(path)
        .then(_ => {
          this.$emit('change');
        })
        .finally(() => { this.loading = false; });
    }
  }
};
</script>
