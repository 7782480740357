<template lang="pug">
  div.survey-question-options
    div.row
      div.col.flex-grow.flex-justify-middle
        label.mb-16 Choices:
        i.nexd-icon-16-help.pl-4(aria-hidden="true" v-tooltip="{value: tooltip_text}")
    template(v-for="(choice, index) of question.choices")
      Choice(
        ref="choices"
        :type="choice.other ? 'other' : 'choice'"
        :choice="choice"
        :question="question"
        :key="index"
        :data-index="index"
        :index="index"
        :dragging="active_drag_item === index"
        :dragover="active_drag_hover === index"
        @remove="removeChoice"
        @update="$emit('update')"
        @dragStart="dragStartHandler"
      )
    div.flex.flex-align-center.flex-justify-end(style="height: 32px;")
      CustomCheckbox(:value="question.personal_information" @change="updatePersonallyIdentifiableInfo")
        span.fs-14.color-gray-800.ml-4(v-tooltip="{value: tooltip_pii}") Personally Identifiable Information
      i.nexd-icon-32-warning.color-warning(v-if="question.personal_information && !choices_have_trackers" v-tooltip="{template: 'cm.personallyIdentifiableInformation'}")
    div.row.mt-20.flex-justify-center
      div.col.flex-grow
        Button(type="primary" :outline="true" :block="true" label="Add Choice" :disabled="!any_choices_left" @click="addChoice")
          template(v-slot:prefix)
            IconAdd
      div.col
        Button(
          type="primary"
          :outline="true"
          :block="true"
          label="Add \"Other\""
          :disabled="other_index != null || !any_choices_left"
          :tooltip="{value: 'Other is an input field type of choice, so ad viewer can type in their answer'}"
          @click="addOther"
        )
          template(v-slot:prefix)
            IconAdd
      div.col.mt-14
        label.color-gray-600
          | You can add&nbsp;
          span.color-primary {{ left_choices }}
          | &nbsp;more {{ choices_string }}
</template>
<script>

import SurveyHelper from './Helper.js';
import Choice from './Choice';
import CustomCheckbox from '@master/UI/CustomCheckbox';
import Button from '@master/UI/Buttons/Button';
import IconAdd from '@master/UI/Buttons/Icons/IconAdd';

export default {
  name: 'QuestionChoices',
  components: {
    Choice,
    CustomCheckbox,
    Button,
    IconAdd
  },
  props: {
    question: Object,
  },

  data() {
    return {
      tooltip_text: 'You can choose up to 6 choices per question. Each choice can be up to 90 characters long. The last choice can be input by the ad viewer (see the “Other” choice below)',
      tooltip_pii:
        `Is the information you collect from this input field defined as Personally Identifiable Information (e.g. email, name, phone number, address)?
        Nexd does not collect PII in it's analytics and collecting this data has to be stored and processed by your service.`,
      active_drag_hover: null,
      active_drag_item: null,
      max_choices_per_question: 6
    };
  },

  computed: {
    left_choices () {
      return this.max_choices_per_question - this.question.choices.length;
    },

    any_choices_left () {
      return this.left_choices !== 0;
    },

    choices_string () {
      if (this.left_choices === 0) {
        return 'choices';
      }
      return (this.left_choices < 2 ? 'choice' : 'choices') + (this.other_index != null ? '' : ' or "Other"');
    },

    other_index () {
      if (this.question.choices != null) {
        for (const k in this.question.choices) {
          if (this.question.choices[k].other === true) {
            return k;
          }
        }
      }
      return null;
    },

    next_choice_index () {
      if (this.other_index != null) {
        return this.other_index;
      }
      return this.question.choices.length;
    },

    choices_have_trackers() {
      if (this.question?.choices == null || !Array.isArray(this.question.choices)) return false;

      for (const choice of this.question.choices) {
        if (!choice?.impressiontracker?.length) continue;
        for (const tracker of choice.impressiontracker) {
          if (tracker.uri == null || tracker.uri === '') continue;
          return true;
        }
      }

      return false;
    }
  },

  methods: {
    addChoice() {
      if (!this.question.choices) {
        this.$set(this.question, 'choices', []);
      }
      if (!this.any_choices_left) return;
      this.question.choices.splice(this.next_choice_index, 0, SurveyHelper.getChoiceBase());
      this.$emit('update');
    },

    removeChoice(index) {
      if (this.question.choices.length === 1) {
        this.$set(this.question.choices[index].text, 'value', '');
      } else {
        this.question.choices.splice(index, 1);
      }
      this.$emit('update');
    },

    addOther() {
      if (this.other_index != null) return;
      if (!this.any_choices_left) return;
      this.question.choices.splice(this.next_choice_index, 0, {other: true, ...SurveyHelper.getChoiceBase()} );
      this.$emit('update');
    },

    dragStartHandler(index) {
      this.$set(this, 'active_drag_item', index);

      // drag enter event for row, set as "active" drop row
      const dragenterHandler = async (ev) => {
        ev.stopPropagation();
        let data_index = parseInt(ev.currentTarget.getAttribute('data-index'), 10);
        if (this.other_index != null && data_index === this.question.choices.length - 1) {
          data_index -= 1;
        }
        this.$set(this, 'active_drag_hover', data_index);
      };

      // prevent activating assets library or assets slots
      const globalDragoverHandler = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
      };

      // on drop, remove events and reorder options array
      const dropHandler = () => {
        this.$el.removeEventListener('dragover', globalDragoverHandler);

        // remove dragenter and drop events from all rows
        for (const el of this.$refs.choices) {
          el.$el.removeEventListener('dragenter', dragenterHandler);
          el.$el.removeEventListener('drop', dropHandler);
        }

        // move array
        if (this.active_drag_item !== this.active_drag_hover) {
          this.question.choices.splice(this.active_drag_hover, 0, this.question.choices.splice(this.active_drag_item, 1)[0]);
          this.$emit('update');
        }

        this.$set(this, 'active_drag_item', null);
        this.$set(this, 'active_drag_hover', null);
      };

      this.$el.addEventListener('dragover', globalDragoverHandler);

      // add dragenter and drop listeners for all rows
      for (const el of this.$refs.choices) {
        el.$el.addEventListener('dragenter', dragenterHandler);
        el.$el.addEventListener('drop', dropHandler);
      }
    },

    updatePersonallyIdentifiableInfo(checked) {
      this.question.personal_information = checked;
      this.$emit('update');
    }
  }
};
</script>
