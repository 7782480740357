import DateFilter from '@master/Filters/_filters/DateFilter';

export function formatNumber (number) {
  if (number == null) return '';

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0
  })
    .format(number);
}

export function formatPrice (price, options = { currency: 'EUR', free: false, included: false, suffix: null }) {
  if (price == null) return '';

  if (price === 0 || price === '0') {
    if (options?.free === true) return 'FREE';
    if (options?.included === true) return 'Included';
  }

  const formatted_price = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: options?.currency ?? 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })
    .format(price);

  if (options?.suffix != null) {
    return `${formatted_price}${options.suffix}`;
  }

  return formatted_price;
}

export function formatDate (unix) {
  if (unix == null) return '';

  return DateFilter(unix, {
    format: 'D. MMM YYYY'
  });
}

export function getSubscriptionFeatures (
  features = {},
  subscription_features = [],
  currency = null
) {
  const response = {
    features: [],
    support: null
  };

  if (subscription_features == null || subscription_features.length === 0 || features === null) return response;

  return subscription_features.reduce((acc, feature_id) => {
    let feature = features?.[feature_id];

    if (feature == null) return acc;

    feature = {
      ...feature,
      price: feature.price[currency ?? 'EUR']
    };

    if (feature.key.includes('sla-')) {
      acc.support = feature;
    } else {
      acc.features.push(feature);
    }

    return acc;
  }, response);
}

export function aggregateFeatures (features = {}) {
  const aggregated_features = {
    features: {},
    supports: {}
  };

  if (features == null) return aggregated_features;

  for (const feature_id in features) {
    const feature = features[feature_id];

    if (feature.key.includes('sla-')) {
      aggregated_features.supports[feature_id] = feature;
    } else {
      aggregated_features.features[feature_id] = feature;
    }
  }

  return aggregated_features;
}
