<template lang="pug">
div(:class="{ [styles.field]: true, [styles.error]: error }")
  label(v-if="label != null" :class="{ [styles.not_required]: !required }") {{ label }}&nbsp;
    span(v-if="required") *
  slot
</template>

<script>
import styles from './BillingDetails.module.scss';

export default {
  name: 'InputContainer',

  props: {
    label: {
      type: String,
      required: true
    },

    required: {
      type: Boolean,
      default: false
    },

    error: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      styles
    };
  }
};
</script>
