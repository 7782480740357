<template lang="pug">
div
  label(
    :class="[styles.asset_container, { [styles.overlay]: overlay }]"
    @dragenter.prevent.stop="onDragEnter"
    @dragover.prevent.stop
    @dragleave.prevent.stop="onDragLeave"
    @drop.prevent.stop="onDrop"
  )
    input(type="file" :accept="accepted_extensions" :multiple="max > 1" :disabled="max === files.length" @change="change")
    svg(width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg")
      rect(width="72" height="72" rx="3")
      path(d="M36.0071 23L36.0071 49")
      path(d="M35.9932 23L28.0001 31.171")
      path(d="M36.0071 23L44.0001 31.171")
    h2 {{ label }}

    div.color-gray-600.fs-12
      span Supported file types are
        br
        | {{ accepted_extensions }}
        br
      span.block.mt-8 Assets: {{ files.length }} out of {{ max }}

  div(v-if="files.length" :class="styles.assets")
    Asset(
      v-for="(file, index) in files"
      :key="index"
      :file="file"
      @remove="removeFile(index)"
    )
</template>

<script>
import styles from '../AdCreator.module.scss';

import AssetHelper from '@helpers/Asset';

import Asset from '@cm/Views/AdCreator/Components/Asset.vue';

export default {
  name: 'AssetContainer',

  components: {
    Asset,
  },

  props: {
    value: Array,
    label: String,
    max: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      styles,

      accepted_mimes: [],
      accepted_extensions: [],
      files: [],
      overlay: false,
    };
  },

  created() {
    this.accepted_mimes = AssetHelper.getAcceptedMimes(null, true).image;
    this.accepted_extensions = AssetHelper.mimeToExtension(this.accepted_mimes);
  },

  methods: {
    change(e) {
      this.uploadFiles(e.target.files);
    },

    async uploadFiles(files) {
      if (!files.length) {
        return;
      }

      if (files.length + this.files.length > this.max) {
        return this.$alert(
          null,
          `You can only add a maximum of ${this.max} ${this.max > 1 ? 'files' : 'file'}.`,
        );
      }

      this.files.push(...files);
      this.$emit('input', this.files);
    },

    removeFile(index) {
      this.files.splice(index, 1);
      this.$emit('input', this.files);
    },

    onDragEnter() {
      if (!this.overlay) {
        this.overlay = true;
      }
    },

    onDragLeave(e) {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        this.overlay = false;
      }
    },

    onDrop(e) {
      this.overlay = false;
      const files = e.dataTransfer.files;

      const accepted_files = [];
      const rejected_files = [];

      for (const file of files) {
        if (AssetHelper.isAcceptedMime(this.accepted_mimes, file.type)) {
          accepted_files.push(file);
        } else {
          rejected_files.push(file);
        }
      }

      if (accepted_files.length > 0) {
        this.uploadFiles(accepted_files);
      }

      if (rejected_files.length > 0) {
        this.$alert(
          `The following files were rejected because they are not supported: ${rejected_files.map(file => file.name).join(', ')}`,
          'Unsupported file types',
        );
      }
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.files = value;
      },
    },
  },
};
</script>
