<template lang="pug">
router-link.search-list-item.cursor-pointer(:to="item.uri")
  div.list-item-column
    div.status(v-if="item.status != null" :class="status" v-tooltip="{value: status, position: 'right'}")
  div.list-item-column.list-item-name
    slot(name="thumbnail")
    div.pr-8.list-item-name-container.fw-500
      div.flex(:class="{'clamp': !item.location}")
        div(v-tooltip="{value: item.name}") {{ item.name }}
      div.list-item-location(v-if="item.location") in {{ item.location }}
  div.list-item-column.color-primary
    i.nexd-icon-16-shared-by(v-if="!is_owner" aria-hidden="true" v-tooltip="{value: item?.owner?.name != null ? item.owner.name : null}")
  div.list-item-column
    //- only creatives have layout name
    div(v-if="item?.layout_name")
      span.color-gray-800 {{ item.type_string }}, {{ item.width }}x{{ item.height }}, {{item.layout_name}}

    //- only campaign folders have campaigns
    span.color-gray-800(v-else-if="item?.meta?.campaigns") {{ item.meta.campaigns.total }} campaigns, {{ item.meta.creatives.total }} creatives

    //- only creative folders and campaigns have creatives
    span.color-gray-800(v-else-if="item?.meta?.creatives") {{ item.meta.creatives.total }} creatives
  div.list-item-column
    span.color-gray-800 Last edit: {{ item.updated_on | DateFilter }}

  div.list-item-column.edit
    i.nexd-icon-32-edit(
      aria-hidden="true"
      v-tooltip="{value: 'Edit'}"
      @click.stop.prevent="setActiveItem"
    )
</template>

<script>
import { LISTITEM, STATUS, STATUS_CLASS } from '@master/constants';

import CampaignService from '@master/Services/CampaignService';
import CreativesService from '@master/Services/CreativesService';
import FolderService from '@master/Services/FolderService';

export default {
  name: 'ResultItem',

  props: {
    item: Object,
  },

  computed: {
    is_owner() {
      return this.item?.owner?.user_id != null && this.$user?.user?.user_id != null && this.item.owner.user_id === this.$user.user.user_id;
    },

    status() {
      switch (this.item.status) {
        case STATUS.DRAFT:
          return STATUS_CLASS.DRAFT;
        case STATUS.PENDING:
          return STATUS_CLASS.PENDING;
        case STATUS.PAUSED:
          return STATUS_CLASS.PAUSED;
        case STATUS.LIVE:
          return STATUS_CLASS.LIVE;
        case STATUS.FINISHED:
        case STATUS.DEMO_FINISHED:
          return STATUS_CLASS.FINISHED;
        case STATUS.ARCHIVED:
          return STATUS_CLASS.ARCHIVED;
        default:
          return '';
      }
    },
  },

  methods: {
    setActiveItem() {
      switch (this.item.item_type) {
        case LISTITEM.FOLDER:
          return FolderService.active.set(this.item);
        case LISTITEM.CAMPAIGN:
          return CampaignService.active.set(this.item);
        case LISTITEM.CREATIVE:
          return CreativesService.active.set(this.item);
        default:
          return;
      }
    },
  },
};
</script>
