<template lang="pug">
Loading(v-if="subscription == null")
BillingLayoutContainer(v-else :navbar="false")
  template(v-slot:content)
    ImpressionBalance
    Subscription(
      v-if="subscription != null"
      title="Active subscription"
      subtitle="Currently active subscription"
      :subscription="subscription"
    )
    Subscription(
      v-if="upcoming_subscriptions.length > 0"
      v-for="subscription in upcoming_subscriptions"
      :key="subscription.id"
      title="Upcoming subscription"
      :subscription="subscription"
    )
    InvoicesCard
</template>

<script>
import BillingService from '@cm/Views/Billing/helpers/BillingService';

import Loading from '@master/UI/Loading';

import BillingLayoutContainer from '@cm/Views/Billing/Components/BillingLayoutContainer/BillingLayoutContainer';
import ImpressionBalance from '@cm/Views/Billing/Cards/ImpressionBalance';
import InvoicesCard from '@cm/Views/Billing/Cards/InvoicesCard';
import Subscription from '@cm/Views/Billing/Cards/Subscription';

export default {
  name: 'Overview',

  components: {
    BillingLayoutContainer,
    ImpressionBalance,
    InvoicesCard,
    Loading,
    Subscription
  },

  data() {
    return {
      organization: null,
      subscription: null,
      upcoming_subscriptions: []
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user == null) return;

      this.init();
    });
  },

  methods: {
    async init() {
      this.organization = await BillingService.getOrganization();
      this.subscription = await BillingService.getSubscription();
      this.upcoming_subscriptions = await BillingService.getUpcomingSubscriptions();
    }
  }
};
</script>
