<template lang="pug">
TestLayout(title="FreePlanFooter")
  span.color-gray-800.fs-14 Since footer is fixed element, it will be displayed at the bottom of the page.
  FreePlanFooter
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';

import FreePlanFooter from '@master/UI/FreePlanFooter/FreePlanFooter.vue';

export default {
  name: 'FreePlanFooterTest',

  components: {
    TestLayout,

    FreePlanFooter,
  },
};
</script>
