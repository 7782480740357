import ContentService from '@master/Services/ContentService';
import CreativesExportService from '@master/Services/CreativesExportService';

class CreativesService extends ContentService {
  export = new CreativesExportService();

  load(path = this.#getPath()) {
    if (path != null) {
      super.load(path);
    }
  }

  loadMore(path = this.#getPath()) {
    if (path != null) {
      super.loadMore(path);
    }
  }

  updateSorting(sort, reverse) {
    const path = this.#getPath();

    if (path != null) {
      super.updateSorting(sort, path, reverse);
    }
  }

  #getPath() {
    const folder_id = this.getId('folder_id');
    const campaign_id = this.getId('campaign_id');

    if (folder_id != null) {
      return `v2/folder/${folder_id}/creatives/view`;
    }

    if (campaign_id != null) {
      return `v2/campaigns/${campaign_id}/creatives/view`;
    }

    return null;
  }

  columns = [
    null,
    null,
    { name: 'Name', key: 'name' },
    { name: 'Type' },
    { name: 'Layout', key: 'layout_name' },
    { name: 'Size', key: 'width,height,type' },
    { name: 'Platform', key: 'supported_channels.dsp_title' },
    { name: 'Impressions', slug: 'Imp.', key: 'meta_analytics.impressions' },
    { name: 'Engagement', slug: 'Eng.', key: 'meta_analytics.engagement' },
    { name: 'CTR', key: 'meta_analytics.ctr' },
    null,
  ];
}

export default new CreativesService();
