<template lang="pug">
div(:class="styles.invoices")
  section.fw-500(:class="styles.invoice_content")
    span Billing date
    span Invoice number
    span Invoice total
    span Status
    span

  slot
</template>

<script>
import styles from './Invoice.module.scss';

export default {
  name: 'Invoices',

  data() {
    return {
      styles
    };
  }
};
</script>
