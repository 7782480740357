<template lang="pug">
SidebarContainer(
  title="Campaign Settings"
  :timestamp="campaign.updated_on"
  :path="campaign.path"
  :can_open="!is_in_view"
  :loading="creation_in_progress"
  loading_text="Creating campaign, please wait"
  @close="close"
)
  template(v-slot:actions)
    CampaignSiderbarIcons(:campaign="campaign")

  template(v-slot:content)
    div.label-group
      h4 Name:
    TextareaInput(
      ref="title"
      placeholder="Campaign title"
      v-model="campaign.name"
      :disabled="!can_update"
      @change="saveCampaign('name')"
    )

    hr

    Loading(v-if="loading")
    template(v-else)
      div.label-group
        h4 Owner:
        i.nexd-icon-16-help( aria-hidden="true" v-tooltip="{value: 'Only the owner of the campaign can change this'}")
      SearchSelect.mb-16(v-model="owner_id" :disabled="!can_change_owner" :options="accessible_users_list" @input="changeOwnerConfirmation")

      div.label-group
        h4 Collaborate with:
        i.nexd-icon-16-help( aria-hidden="true" v-tooltip="{value: 'Share this campaign with other users. Collaborators can access anything inside the campaign.'}")
      CollaboratorsInput.mb-16(
        v-model="campaign.collaborators"
        :path="`campaigns/${campaign.campaign_id}/collaborators`"
        :can_update="can_update"
        :owner_id="campaign?.owner?.user_id"
        @input="updateCollaborators"
      )

      div.label-group
        h4 Keywords:
        i.nexd-icon-16-help( aria-hidden="true" v-tooltip="{value: 'Keywords are great way to filter your campaigns.'}")
      KeywordsInput.mb-16(
        v-model="campaign.keywords"
        :disabled="!can_update"
        @input="saveCampaign"
      )

      div.label-group
        h4 Advertiser:
        i.nexd-icon-16-help( aria-hidden="true" v-tooltip="{value: 'By setting an Advertiser, invoices will be grouped by this value'}")
      CampaignAdvertisersInput.mb-16(v-model="campaign.advertiser" :disabled="!can_update" @input="saveCampaign")

      div.label-group
        h4 Analytics timezone:
        i.nexd-icon-16-help( aria-hidden="true" v-tooltip="{value: `Setting campaign-specific timezone will override the organisation default timezone for this campaign's analytics.`}")
      SearchSelect(v-model="campaign.analytics_timezone" :options="timezones" :disabled="!can_update" @input="saveCampaign")

  template(v-slot:campaign_details)
    CreativeTags(v-if="campaign.meta?.creatives?.total > 0" :totals="campaign.meta?.creatives")
</template>

<script>
import CampaignSiderbarIcons from './CampaignSiderbarIcons.vue';
import CampaignAdvertisersInput from './CampaignAdvertisersInput.vue';
import CreativeTags from './../container/CreativeTags.vue';

import CampaignMixins from '@cm/Views/Campaigns/Campaign/CampaignMixins';
import TextareaInput from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TextareaInput';

import SidebarContainer from '@root/src/global/sidebar/edit/container/SidebarContainer';

import CampaignService from '@master/Services/CampaignService';
import CreativesService from '@master/Services/CreativesService';
import SearchService from '@master/Services/SearchService';
import UsersService from '@master/Services/Cache/UsersService';

import CollaboratorsInput from '@master/UI/CollaboratorsInput.vue';
import KeywordsInput from '@master/UI/KeywordsInput.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';

import Loading from '@master/UI/Loading.vue';

import { VIEW } from '@master/constants';
import { getTimezones } from '@helpers/Global';

export default {
  name: 'CampaignSidebar',
  mixins: [CampaignMixins],

  components: {
    SearchSelect,
    CampaignSiderbarIcons,
    KeywordsInput,
    CollaboratorsInput,
    CampaignAdvertisersInput,
    CreativeTags,
    TextareaInput,
    SidebarContainer,
    Loading,
  },

  props: {
    campaign: Object,
  },

  computed: {
    is_in_view() {
      return this.$route.params.campaign_id === this.campaign.campaign_id;
    },
  },

  data() {
    return {
      owner_id: this.campaign?.owner?.user_id,
      accessible_users_list: [],
      creation_in_progress: false,
      loading: false,
      timezones: null,
    };
  },

  created() {
    if (this.is_new) {
      this.createCampaign();
    } else if (this.campaign.collaborators == null) {
      // might be an object from campaign view, that does not have all of the available info
      // load in full campaign object on cases like that
      this.loading = true;
      this.$http
        .get(`v2/campaigns/${this.campaign.campaign_id}`)
        .then(campaign => {
          CampaignService.updateItems([campaign]);
          this.loading = false;
        });
    }

    UsersService.subscribe(users => {
      this.accessible_users_list = [];
      if (users == null) return;

      for (const obj of users) {
        this.accessible_users_list.push({
          label: obj.name,
          value: obj.user_id,
        });
      }
    }, this);

    getTimezones().then(timezones => {
      this.timezones = timezones;
    });
  },

  methods: {
    saveCampaign(key = null) {
      if (this.creation_in_progress) return;

      this.$http
        .put(`v2/campaigns/${this.campaign.campaign_id}`, this.campaign)
        .then(response => {
          CampaignService.updateItems([response]);

          if (key === 'name') {
            CreativesService.updateBreadcrumb(
              this.campaign.campaign_id,
              this.campaign.name,
            );
          }

          if (this.$route.name === VIEW.SEARCH) {
            SearchService.active.set(this.campaign);
          }

          if (this.$route.name === VIEW.CREATIVES) {
            CreativesService.updateCustomData('campaign', response);
          }
        });
    },

    createCampaign() {
      if (this.creation_in_progress) return;
      this.creation_in_progress = true;

      this.$http
        .post('v2/campaigns', this.campaign)
        .then(campaign => {
          CampaignService.updateCreatedItem(this.campaign, campaign);

          // if request finishes, let check what we will do with this object
          CampaignService.active.set(campaign);
          this.creation_in_progress = false;

          if (this.$refs.title) {
            this.$refs.title.focus();
          }
        })
        .catch(_ => {
          this.creation_in_progress = false;
          this.close();
        });
    },

    close() {
      if (!this.creation_in_progress) {
        CampaignService.active.set(null);
      }
    },

    async changeOwnerConfirmation(user_id) {
      if (this.creation_in_progress) return;

      const user = this.accessible_users_list.find(
        usr => usr.value === user_id,
      );
      if (
        await this.$confirm(
          `Are you sure you want to change campaign owner to ${user.label}?`,
        )
      ) {
        const path = `campaigns/${this.campaign.campaign_id}/owner`;
        this.$http.put(path, { user_id }).then(response => {
          CampaignService.updateItems([response]);
        });
      } else {
        this.owner_id = this.campaign?.owner?.user_id;
      }
    },

    updateCollaborators() {
      CampaignService.updateItems([this.campaign]);
    },
  },
};
</script>
