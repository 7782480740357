<template lang="pug">
div.card-body.transparent
  div.row
    div.col.flex-grow
      div.inline-block
        h3 {{analytics.vtr.total.video_start | PercentageFilter}}
        small Started watching
    div.col-10
      div.inline-block
        h3 {{analytics.vtr.total.vtr_50 | PercentageFilter}}
        small Watched 50%
  div.row
    div.col.flex-grow
      div.inline-block
        h3 {{analytics.vtr.total.vtr_25 | PercentageFilter}}
        small Watched 25%
    div.col-10
      div.inline-block
        h3 {{(analytics.vtr.total.vtr_75) | PercentageFilter}}
        small Watched 75%
</template>

<script>
import { Controller } from '@master/Services/AnalyticsAPPService';

export default {
  name: 'EngagementsAddon',
  props: {
    analytics: Object,
  },

  data() {
    return {
      Controller,
    };
  },
};
</script>
