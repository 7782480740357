<template lang="pug">
div(:class="{[styles.group]: true, [styles.bg]: bg}")
  div(:class="styles.subtitle")
    span {{ label }}
    i.nexd-icon-16-help(v-if="tooltip != null" aria-hidden="true" v-tooltip="tooltip")
  slot
</template>

<script>
import styles from '../Sidebar.module.scss';

export default {
  name: 'Group',

  props: {
    label: {
      type: String,
      default: null
    },

    tooltip: {
      type: Object,
      default: () => (null)
    },

    bg: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      styles
    };
  }
};
</script>
