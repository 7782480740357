<template lang="pug">
div#analytics-wrapper(v-content)
  div#container
    div.flex-grow.capped-size(:key="$route.path")
      div.py-24.px-16(v-if="error")
        p Could not find analytics
      template(v-else)
        CreativeView(v-if="$route?.params?.live_id != null || $route?.params?.public_live_id != null")
        CampaignView(v-else-if="data != null")
    Stickybar
</template>

<script>
import AnalyticsService from '@master/Services/AnalyticsService';
import { filters } from '@master/Services/AnalyticsAPPService';

import CampaignView from '@analytics/Views/Campaign/CampaignView';
import CreativeView from '@analytics/Views/Creative/CreativeView';

import Stickybar from '@analytics/UI/Stickybar';

export default {
  name: 'AnalyticsView',

  components: {
    CampaignView,
    CreativeView,
    Stickybar,
  },

  data() {
    return {
      filters,

      data: null,
      error: false,
    };
  },

  created() {
    if (this.$route?.params?.public_id != null || this.$route?.params?.public_live_id != null) {
      this.$user.isAuthenticated(); /** init user state for navbar */
    }

    AnalyticsService.load();
    AnalyticsService.subscribe(data => {
      this.data = data;
      this.error = AnalyticsService.getErrorState();
    }, this);
  },

  methods: {
    updateFilters() {
      const date_keys = ['start', 'end'];

      for (const date_key of date_keys) {
        if (isNaN(this.$route.query?.[date_key]) || parseInt(this.$route.query?.[date_key], 10) < 0) {
          this.$route.query[date_key] = null;
        }
      }

      if (this.$route.query.base !== 'viewable' && this.$route.query.base !== 'impressions') {
        this.$route.query.base = 'impressions';
      }

      if (this.$route.query.start_week !== 'Monday' && this.$route.query.start_week !== 'Sunday') {
        this.$route.query.start_week = 'Monday';
      }

      if (this.$route.query.incvtr != null) {
        this.$set(filters, 'incvtr', this.$route.query.incvtr === 'true' || this.$route.query.incvtr === true);
      }

      for (const filter_key in filters) {
        if (this.$route.query[filter_key] != null) {
          this.$set(filters, filter_key, this.$route.query[filter_key]);
        }
      }
    },
  },

  watch: {
    '$route.query': {
      handler() {
        this.updateFilters();
      },
      immediate: true,
      deep: true,
    },

    filters: {
      handler() {
        this.$router
          .replace({
            query: { ...this.$route.query, ...filters },
          })
          .catch(() => {
            /**
            if filters are being corrected route change
            throws error about going to duplicate route, supressing it
          */
          });
      },
      immediate: true,
      deep: true,
    },

    '$route.params.live_id': {
      handler() {
        this.$set(filters, 'flight_condition_key', '');
      },
    },
  },
};
</script>
