<template lang="pug">
span
  div(v-sticky-content :class="styles.container" data-intercom-target="asset-library")
    section(:class="styles.tabs")
      span(
        v-for="tab of Object.values(tabs)"
        :key="tab"
        :class="[active_tab === tab ? 'bg-white' : 'color-gray-800', {'text-left pl-12': is_sdk_user }]"
        @click="changeTab(tab)"
      ) {{ tab }} library

    section(:class="styles.library")
      Loading.small.py-12(v-if="active_tab === TABS.TEAM && items == null")
      div(v-else-if="show_navigation_header" :class="[styles.folder_header, {'border': !has_assets}]" @click="goBack")
        i.nexd-icon-32-arrow-right.mr-8(aria-hidden="true" :class="{'disabled': loading_more}")
        i.nexd-icon-16-folder.mr-4(aria-hidden="true")
        span.fw-500.fs-12.overflow-ellipsis {{ navigator.current.name }}

      template(v-if="has_assets")
        div(:class="styles.assets" :style="{'max-height': scroll_height + 'px'}")
          div(
            v-for="(asset, index) in visible_assets"
            :key="index"
            :class="[styles.asset, {'bg-gray-100': asset.in_use, [styles.folder]: asset.item_type === LISTITEM.FOLDER}]"
            :draggable="asset.item_type !== LISTITEM.FOLDER && !disabled"
            @dragstart="dragStartHandler($event, asset)"
            @click="goToDir(asset)"
          )
            FolderThumbnail.mr-8(v-if="asset.item_type === LISTITEM.FOLDER")
            Thumbnail.mr-8(v-else :asset="asset" :small="true" bg="light")
            div.flex-grow.flex.flex-direction-column.flex-align-start
              span(:class="styles.name" v-tooltip="asset.name.length > 15 ? {value: asset.name, class: 'advanced'} : null") {{asset.name}}
              span.fs-10.color-gray-800(v-if="asset.width && asset.height") {{asset.width}} x {{asset.height}}
              Button.fs-10.color-cyan-blue(
                v-if="active_tab === TABS.ASSET && !disabled"
                type="link"
                :text="true"
                :disabled="library?.loading_percentage != null"
                label="Add to team library"
                @click="addToTeamLibrary(asset)"
              )
            i.nexd-icon-32-delete(v-if="!disabled && active_tab === TABS.ASSET" aria-hidden="true" @click="remove(asset, index)")
            i.nexd-icon-32-arrow-right(v-if="asset.item_type === LISTITEM.FOLDER" aria-hidden="true")

          Loading.pt-16.small(v-if="loading_more")
          Button.ml-12(v-else-if="show_load_more_button" type="link" label="Load more assets" :disabled="loading_more" @click="loadMore")

    section(:class="[styles.dropzone, {[styles.tall]: !has_assets}]")
      Dropzone(
        ref="dropzone"
        file="assets"
        :mime="['image/*', 'video/*', 'text/csv', 'application/zip']"
        :loading="initializing"
        :asset_library="true"
        :active="false"
        :disable="true"
        @self="selfDropHandler"
        @change="handleFileChange"
      )

    div.dropbox(v-if="show_droparea" :class="styles.dropbox_overlay")
      Loading(v-if="library?.loading_percentage != null" :fill="true" :text="library?.loading_label" :percentage="library?.loading_percentage")

    div(v-if="disabled" :class="[styles.overlay, styles.droparea]")
</template>

<script>
import styles from './AssetLibrary.module.scss';

import GroupAssetsLibraryCacheService from '@master/Services/GroupAssetsLibraryCacheService';
import AssetsLibraryService from '@master/Services/AssetsLibraryService';

import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import { LISTITEM } from '@master/constants';
import { sortBy } from '@helpers/Global';

import Library from '@root/src/libs/Library';
import Navigator from '@libs/Navigator';

import Dropzone from '@cm/Views/Creatives/Cards/Components/Dropzone.vue';
import FolderThumbnail from '@cm/UI/Global/Thumbnail/FolderThumbnail.vue';
import Thumbnail from '@cm/UI/Global/Thumbnail/Thumbnail.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Loading from '@master/UI/Loading.vue';

const TABS = {
  ASSET: 'asset',
  TEAM: 'team',
};

export default {
  name: 'AssetLibrary',

  mixins: [SDKMixin],

  components: {
    Button,
    Dropzone,
    FolderThumbnail,
    Loading,
    Thumbnail,
  },

  props: {
    creative: Object,
    disabled: Boolean,
  },

  computed: {
    swapping_asset_in_progress() {
      return this.$store.get('swapping_asset_in_progress') ?? false;
    },

    visible_assets() {
      return this.active_tab === TABS.ASSET ? this.assets : this.items;
    },

    has_assets() {
      return this.visible_assets != null && this.visible_assets.length > 0;
    },

    has_used_assets() {
      return this.has_assets && this.assets.some(asset => asset.in_use);
    },

    show_load_more_button() {
      if (this.active_tab === TABS.ASSET) {
        return this.can_load_more_assets;
      }

      return this.can_load_more;
    },

    can_drop() {
      return !this.disabled && !this.swapping_asset_in_progress;
    },

    show_droparea() {
      return this.can_drop && (this.dropbox_visible || this.library?.loading_percentage != null);
    },

    show_navigation_header() {
      return this.navigator.current != null && this.active_tab === TABS.TEAM;
    },

    tabs() {
      if (this.is_sdk_user) {
        return {
          ASSET: 'asset',
        };
      }

      return TABS;
    },
  },

  data() {
    return {
      styles,

      LISTITEM,
      TABS,

      navigator: new Navigator(),
      library: new Library(),

      loading_more: false,
      items: null,
      can_load_more: false,
      assets: null,
      can_load_more_assets: false,

      active_tab: TABS.ASSET,
      initializing: true,
      dropbox_visible: false,
      scroll_height: 440,
      resizeHandler: null,

      start_from_team_library: false,
    };
  },

  created() {
    this.$store.set('active_assets_library', this);

    if (this.creative.assets_library_id == null) {
      this.createAssetsLibrary();
    } else {
      this.initAssetsLibrary();
    }

    GroupAssetsLibraryCacheService.load();
    GroupAssetsLibraryCacheService.subscribe(state => {
      this.items = state?.items;
      this.can_load_more = state?.can_load_more ?? false;
      this.hideLoadMore();
      this.setScrollHeight();
    }, this);

    AssetsLibraryService.subscribe(state => {
      if (state) {
        this.assets = sortBy(state.items, asset => asset.name);
        this.can_load_more_assets = state.can_load_more ?? false;
        this.hideLoadMore();
        this.setScrollHeight();

        // reset initializing
        this.initializing = false;
      }
    }, this);
  },

  mounted() {
    document.addEventListener('dragover', e => {
      if (this.can_drop) {
        e.preventDefault();
        this.dropbox_visible = true;
      }
    });

    document.addEventListener('dragleave', e => {
      if (this.can_drop) {
        e.preventDefault();
        this.dropbox_visible = false;
      }
    });

    document.addEventListener('drop', async e => {
      if (this.start_from_team_library) {
        this.dropbox_visible = false;
        return;
      }

      if (this.can_drop && this.$refs.dropzone) {
        this.library.fileChangeHandler(await this.$refs.dropzone.dropHandler(e));
        this.dropbox_visible = false;
      }
    });

    this.resizeHandler = () => this.setScrollHeight();
    window.addEventListener('resize', this.resizeHandler, false);
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeHandler, false);
  },

  methods: {
    dragStartHandler(e, asset) {
      this.start_from_team_library = this.active_tab === TABS.TEAM;

      e.dataTransfer.dropEffect = 'copy';
      e.dataTransfer.setData('application/original-asset', JSON.stringify(asset));
    },

    createAssetsLibrary() {
      this.$http.get(`creatives/${this.creative.creative_id}/assets/library`).then(({ assets_library_id }) => {
        this.$set(this.creative, 'assets_library_id', assets_library_id);
        this.initAssetsLibrary();
      });
    },

    async initAssetsLibrary() {
      AssetsLibraryService.setID(this.creative.assets_library_id);
      await AssetsLibraryService.load();
      this.updateLibrary();
    },

    async loadMore() {
      if (!this.can_load_more_assets && !this.can_load_more) {
        return;
      }

      this.loading_more = true;
      await this.library.loadMore();
      this.updateLibrary();
    },

    hideLoadMore() {
      if (this.loading_more) {
        this.loading_more = false;
      }
    },

    selectFile() {
      if (this.$refs.dropzone?.$refs?.input) {
        this.$refs.dropzone.$refs.input.click();
      }
    },

    async assetBGCropperHandler(filename, cb) {
      this.changeTab(TABS.ASSET);

      let overwrite = false;

      if (this.library.findAssetByName(filename)) {
        overwrite = await this.library.confirmAssetUpload([filename]);
      }

      if (overwrite != null) {
        cb(overwrite);
      }
    },

    async assetSlotConvertHandler(asset, cb) {
      this.changeTab(TABS.ASSET);

      let overwrite = false;

      if (this.library.findAssetByName(asset.basename)) {
        overwrite = await this.library.confirmAssetUpload([asset.basename]);
      }

      if (overwrite != null) {
        this.library.create({ assets: [asset], overwrite }, cb);
      }
    },

    async assetSlotUploadHandler(file) {
      this.changeTab(TABS.ASSET);
      this.library.fileChangeHandler({
        files: [file],
        errors: [],
      });
    },

    selfDropHandler(data) {
      // droppign from one browser to another
      if (data.library_id !== this.creative.assets_library_id) {
        this.library.uploadAsset({ uri: data.uri }, AssetsLibraryService);
      }
    },

    addToTeamLibrary(asset) {
      this.library.uploadAsset(asset, GroupAssetsLibraryCacheService);
    },

    handleFileChange(event) {
      this.library.fileChangeHandler(event);
    },

    async remove(asset, index) {
      let delete_asset = false;

      if (asset.in_use) {
        delete_asset = await this.$confirm(
          'Are you sure you want to delete this asset from Asset library?',
          'Asset is used in the creative. When you delete the asset from asset library it will also be removed from the creative.',
        );
      } else {
        delete_asset = await this.$confirm('Are you sure you want to delete this asset from Asset library?');
      }

      if (delete_asset) {
        // remove the asset before request
        this.handleAssetRemove(asset, index);

        this.$http.delete(`assets/library/${this.creative.assets_library_id}/${asset.original_asset_id}`, {}, { notification: 'success' }).then(() => {
          this.$emit('overwrite');
        });
      }
    },

    handleAssetRemove(asset) {
      this.library.removeItems([asset.item_id]);
      this.updateLibrary();

      // clear slots
      if (asset.in_use) {
        let creatives = [this.creative];
        if (this.creative.expanded != null) {
          creatives.push(this.creative.expanded);
        }

        for (const creative of creatives) {
          this.clearAssetsObj(asset, creative);
          this.clearAssetsObj(asset, creative, 'additional_assets');
        }
      }
    },

    clearAssetsObj(asset, creative, key = 'assets') {
      for (const asset_id in creative[key]) {
        const delete_asset = creative[key][asset_id];
        if (delete_asset.original_asset_id === asset.original_asset_id) {
          // remove the asset obj from the creative obj
          if (creative[key]?.[asset_id] != null) {
            this.$delete(creative.assets, asset_id);
          }

          // asset might have video settings or something, those are removed as well
          if (creative?.settings?.[key]?.[asset_id] != null) {
            this.$delete(creative.settings[key], asset_id);
          }
        }
      }
    },

    async setScrollHeight() {
      await this.$nextTick();

      let height = Math.min(window.innerHeight - 180, this.$parent.$el.clientHeight) - 215;

      if (this.show_navigation_header) {
        height -= 32;
      }

      this.scroll_height = height;
    },

    // called on layout change
    markAllAssetsUnused() {
      for (const asset of this.assets) {
        this.$set(asset, 'in_use', false);
      }
    },

    async loadItems() {
      if (this.$refs?.scrollable != null) {
        this.$refs.scrollable.scrollTop = 0;
      }

      this.items = null;
      await GroupAssetsLibraryCacheService.load(this.navigator.current?.item_id);
      this.updateLibrary();
    },

    goToDir(dir) {
      if (dir.item_type === LISTITEM.FOLDER) {
        this.navigator.goToDir(dir);
        this.loadItems();
      }
    },

    goBack() {
      if (!this.loading_more) {
        this.navigator.goBack();
        this.loadItems();
      }
    },

    changeTab(tab) {
      if (!this.disabled) {
        this.active_tab = tab;
        this.updateLibrary(tab);
      }
    },

    updateLibrary(tab = this.active_tab) {
      if (tab === TABS.ASSET) {
        this.library.assets = this.assets;
        this.library.service = AssetsLibraryService;
        this.library.folder_id = undefined;
      } else if (tab === TABS.TEAM) {
        this.library.assets = this.items;
        this.library.service = GroupAssetsLibraryCacheService;
        this.library.folder_id = this.navigator.current?.folder_id;
      }
    },
  },
};
</script>
