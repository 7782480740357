import Subscriber from '@libs/Subscriber';

class TemplatesSettingsService extends Subscriber {
  subscribe(cb, vnode = null) {
    super.subscribe(cb, vnode);

    if (this.data == null) {
      this.#load();
    }
  }

  #load() {
    super.load('/templates/settings', {}, true);
  }
}

export default new TemplatesSettingsService();
