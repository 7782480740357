<template lang="pug">
div.creative-preview
  div.relative
    div(v-if="creative.type !== -1 && $parent.hasLORT()")
      LivePreview(
        ref="preview"
        :key="preview_key"
        :creative="creative"
        :options="preview_options"
        :height="component_height"
        :width="component_width"
        :poll="true"
        @versionchange="handleVersionChange")
    GyroController(v-if="has_gyrotool" ref="gyro" @ready="initGyro")
  PreviewDevice.mt-16(v-if="!is_sdk_user" v-bind="$props")
  PreviewDeviceOptions.mt-16(v-bind="$props" v-model="toggle_high_aspect_ratio" @input="reloadDevice")
  CreativeSizes(:creative="creative" :optimizable="has_uploaded_assets" @optimize="openOptimizerModal" @reload="reloadDevice")
</template>

<script>
import CreativeSizes from '@master/UI/CreativeSizes.vue';
import GyroController from '@master/UI/GyroController.vue';
import LivePreview from '@master/UI/LivePreview/LivePreview.vue';
import PreviewDevice from '@master/UI/LivePreview/PreviewDevice.vue';
import PreviewDeviceOptions from '@master/UI/LivePreview/PreviewDeviceOptions.vue';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import { DEVICE } from '@master/constants';

export default {
  name: 'PreviewTemplate',

  mixins: [CreativeTraits, SDKMixin],

  components: {
    CreativeSizes,
    GyroController,
    LivePreview,
    PreviewDevice,
    PreviewDeviceOptions,
  },

  props: {
    creative: Object,

    device: {
      type: Number,
      default: DEVICE.MOBILE,
    },

    isExpanded: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    preview_key() {
      let key = this.device;
      if (this.isFullscreen()) {
        // high aspect ratio toggle
        key += `_${this.preview_options.screen_height}`;
      }
      return key;
    },

    has_uploaded_assets() {
      if (this.creative.assets == null) return false;

      const asset_keys = Object.keys(this.creative.assets);
      if (asset_keys.includes('splash')) {
        return asset_keys.length > 1;
      }
      return asset_keys.length > 0;
    },

    preview_options() {
      let preview_options = {
        screen_height: null,
        expandable: false,
        browser_bars: true,
        force_placement_device: null,
      };

      if (this.isFullscreen()) {
        if (this.toggle_high_aspect_ratio) {
          preview_options.screen_height = null;
        } else {
          preview_options.screen_height = 854;
        }
        preview_options.browser_bars = this.$user.isBrowserFrameEnabled();
      }

      if (this.isInfeed() || this.isLightbox()) {
        preview_options.force_placement_device = this.device;
      }

      return preview_options;
    },

    has_gyrotool() {
      return this.isMobile(this.preview_options.force_placement_device) && this.creative.has_gyro;
    },

    is_mobile() {
      return this.isMobile(this.device);
    },

    component_height() {
      if (this.is_mobile && this.toggle_high_aspect_ratio) return '475px';
      if (this.is_mobile) return '425px';
      return 'auto';
    },

    component_width() {
      if (this.is_mobile) return '240px';
      return 'auto';
    },
  },

  data() {
    return {
      toggle_high_aspect_ratio: true,
    };
  },

  methods: {
    initGyro() {
      if (this.$refs.preview != null) {
        this.$refs.preview.initGyro();
      }
    },

    reloadDevice() {
      if (this.$refs.preview != null) {
        this.$refs.preview.reloadDevice();
      }
    },

    openOptimizerModal() {
      this.$emit('openOptimizerModal');
    },

    handleVersionChange(version) {
      this.$emit('versionchange', version);
    },
  },
};
</script>
