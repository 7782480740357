<template lang="pug">
div(ref="modal")
  div.modal
    div.backdrop
    div.modal-content.py-32
      h2.px-32.fs-22
        span.color-primary.text-capitalize {{ copy ? 'copy' : 'move' }}&nbsp;
        | selection to:
      p.px-32.my-16.fs-14.color-gray-800 Select the destination that you wish the selected items to be {{ copy ? 'copied' : 'moved' }} to:

      section.px-32.py-8.bg-header.flex.flex-align-center.flex-justify-between.column-gap-16
        span.overflow-ellipsis.fw-500(:class="{'cursor-pointer': !cannot_go_back && navigator?.history?.length > 1}" @click="goBack")
          i(:class="back_button_icon" aria-hidden="true" v-tooltip="cannot_go_back ? {value: 'Live creatives cannot be moved out of the campaign.'} : null")
          | {{ navigator?.current?.type === BREADCRUMB.ROOT ? 'My Campaigns' : navigator?.current?.label }}
        SearchInput(v-model="query" @input="searchItems")

      section.py-8.px-32.bg-gray-100.flex.flex-direction-column.row-gap-8(style="height:50vh;overflow-y:auto;")
        Loading(v-if="loading_items")
        center.mt-32.fs-14.color-gray-800(v-else-if="!items?.length") No items to display
        template(v-else v-for="(item, index) of items")
          div.copymove-list-item(:key="index" :class="{inactive: !canAccessItem(item)}" @click="goToDir(item)")
            div.list-item-column
              FolderThumbnail(v-if="item.item_type === LISTITEM.FOLDER && !canMoveTo(item)")
              label(v-else @click.stop)
                input(type="radio" v-model="selected_item_id" :value="item.item_id")
            div.list-item-column.list-item-name
              FolderThumbnail(v-if="item.item_type === LISTITEM.FOLDER && canMoveTo(item)")
              div.pr-8.list-item-name-container
                span(:class="{'clamp': !item.location}") {{ item.name }}
                span.list-item-location(v-if="item.location") &nbsp; in {{ item.location }}
            div.list-item-column
              span.color-gray-800
                | {{ item?.meta?.campaigns?.total ?? item?.meta?.creatives?.total }} {{ $route.meta.view }}
            div.list-item-column
              span.color-gray-800 Last edit: {{ item.updated_on | DateFilter }}
            div.list-item-column
              i.nexd-icon-32-arrow-right(v-if="canAccessItem(item)")

        template(v-if="can_load_more_items")
          Loading.mt-16(v-if="loading_more_items" style="height:max-content;")
          Buttons(v-else align="left")
            Button(type="link" label="Load more items" @click="loadMoreItems")

      section.px-32.mt-32.flex.flex-align-center
        label.flex.flex-align-center(v-if="moving_creatives" v-tooltip="{value: checkbox_tooltip}")
          input(type="checkbox" v-model="copy" :disabled="selection.has_folders_selection || (has_live_items && !in_current_campaign)")
          span.color-gray-800 Copy selected items and keep them in original location

        Buttons.flex-grow
          Button(type="link-primary" label="Close" @click="close")
          Button(
            type="primary"
            :label="move_button_label"
            :animate="true"
            :disabled="!canMoveTo(selected_item, true)"
            v-tooltip="!canMoveTo(selected_item, true) ? {value: save_button_tooltip} : null"
            @click="move"
          )

      div(v-if="processing_request")
        Loading(
          :fill="true"
          :text="copy ? 'Copying Item(s)' : 'Moving Item(s)'"
          :subtext="`Please wait while we ${ copy ? 'copy' : 'move' } the selected item(s) and files to the destination. This may take some time`"
        )
</template>

<script>
import { BREADCRUMB, LISTITEM } from '@master/constants';

import CampaignService from '@master/Services/CampaignService';
import CopyMoveService from '@master/Services/CopyMoveService';
import CreativesService from '@master/Services/CreativesService';

import Navigator from '@libs/Navigator';
import Selection from '@libs/Selection';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Loading from '@master/UI/Loading.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';
import Modal from '@master/UI/Modal.vue';
import SearchInput from '@master/UI/Input/SearchInput.vue';

import FolderThumbnail from '@cm/UI/Global/Thumbnail/FolderThumbnail.vue';

export default {
  name: 'CopyMoveModal',
  mixins: [Modal],

  components: {
    Button,
    Buttons,
    FolderThumbnail,
    Loading,
    LoadingModal,
    SearchInput,
  },

  props: {
    selection: {
      type: Selection,
      required: true,
    },

    breadcrumbs: {
      type: Array,
      required: true,
      default: () => null,
    },

    has_live_items: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    back_button_icon() {
      const type = this.navigator?.current?.type;

      return {
        'nexd-icon-32-campaign': type === BREADCRUMB.ROOT,
        'nexd-icon-32-arrow-right rotate hover-primary fs-32': type !== BREADCRUMB.ROOT,
        disabled: this.cannot_go_back,
      };
    },

    cannot_go_back() {
      return this.has_live_items && !this.copy && this.navigator?.current?.item_id === this.$route.params.campaign_id;
    },

    checkbox_tooltip() {
      if (this.selection.has_folders_selection) {
        return 'You can copy if only creatives are selected from list.';
      }

      if (this.has_live_items && !this.in_current_campaign) {
        return 'Live creatives cannot be moved out of the the campaign.';
      }

      return null;
    },

    move_button_label() {
      return `${this.copy ? 'Copy' : 'Move'} ${this.selected_item_id != null ? 'to Selected Item' : 'Here'}`;
    },

    moving_creatives() {
      return this.$route.params.campaign_id != null;
    },

    campaign_id() {
      for (const nav of this.navigator?.history ?? []) {
        if (nav?.type === BREADCRUMB.CAMPAIGN) {
          return nav.item_id;
        }
      }

      return null;
    },

    in_campaign() {
      return this.campaign_id != null;
    },

    in_current_campaign() {
      return this.campaign_id === this.$route.params.campaign_id;
    },

    path() {
      if (this.navigator?.current?.type === BREADCRUMB.FOLDER) {
        if (this.in_campaign) {
          return `v2/folder/${this.navigator?.current?.item_id}/creatives/view`;
        }

        return `v2/folder/${this.navigator?.current?.item_id}/campaigns/view`;
      }

      if (this.navigator?.current?.type === BREADCRUMB.CAMPAIGN) {
        return `v2/campaigns/${this.navigator?.current?.item_id}/creatives/view`;
      }

      return 'v2/campaigns/view';
    },

    selected_item() {
      return this.items.find(i => i.item_id === this.selected_item_id);
    },
  },

  data() {
    return {
      BREADCRUMB,
      LISTITEM,

      navigator: null,
      service: null,

      items: [],
      loading_items: false,
      loading_more_items: false,
      can_load_more_items: false,

      query: '',
      copy: false,
      selected_item_id: null,
      start_location: null,
      processing_request: false,
      save_button_tooltip: null,
    };
  },

  created() {
    this.service = new CopyMoveService();
    this.service.init(this, this.$router);
    this.service.subscribe(data => {
      this.items = this.filterItems(data?.items);
      this.can_load_more_items = data?.can_load_more ?? false;
    }, this);

    this.createNavigator();
    this.start_location = this.navigator?.current;
    this.loadItems();
  },

  methods: {
    close() {
      this.$emit('close');
    },

    createNavigator() {
      const bcs = [];

      for (const breadcrumb of this.breadcrumbs) {
        if (breadcrumb.type === BREADCRUMB.BETWEEN) {
          bcs.push(...breadcrumb.breadcrumbs);
        } else {
          bcs.push(breadcrumb);
        }
      }

      this.navigator = new Navigator([...bcs]);
    },

    goBack() {
      if (this.cannot_go_back) {
        return;
      }
      this.navigator.goBack();
      this.loadItems();
    },

    goToDir(item) {
      if (!this.canAccessItem(item)) {
        return;
      }

      this.navigator.goToDir({
        label: item.name,
        item_id: item.item_id,
        type: item.campaign_id === item.item_id ? BREADCRUMB.CAMPAIGN : BREADCRUMB.FOLDER,
      });

      this.loadItems();
    },

    async loadItems() {
      if (this.loading_items) {
        return;
      }

      this.selected_item_id = null;

      this.loading_items = true;
      await this.service.load(this.path);
      this.loading_items = false;
    },

    async loadMoreItems() {
      if (this.loading_more_items) {
        return;
      }

      this.loading_more_items = true;
      await this.service.loadMore(this.path);
      this.loading_more_items = false;
    },

    async searchItems() {
      if (this.loading_items) {
        return;
      }

      if (!this.query.length) {
        return this.loadItems();
      }

      this.selected_item_id = null;
      this.navigator.goToRoot();

      this.loading_items = true;
      await this.service.updateSearch(this.query, 'search');
      this.loading_items = false;
    },

    filterItems(items) {
      return (items ?? []).filter(item => {
        if (this.selection.selected.includes(item.item_id)) {
          return false;
        }

        // creatives can be moved to campaigns and folders
        if (this.moving_creatives) {
          // do not show campaign folders that have no campaigns
          if (item.item_type === LISTITEM.FOLDER && item.type === LISTITEM.CAMPAIGN) {
            return item.meta?.campaigns?.total > 0;
          }

          return item.item_type !== LISTITEM.CREATIVE;
        }

        // folders and campaigns can be moved to other folders
        return item.item_type === LISTITEM.FOLDER;
      });
    },

    canAccessItem(item) {
      // cannot open empty campaign
      if (item.item_type === LISTITEM.CAMPAIGN && item.meta?.folders?.total > 0) {
        return true;
      }

      // cannot open empty creative-folder
      if ((this.in_campaign || this.moving_creatives) && item.item_type === LISTITEM.FOLDER && (item.meta?.campaigns?.total > 0 || item.meta?.folders?.total > 0)) {
        return true;
      }

      // cannot open empty camapign-folder
      if (item.item_type === LISTITEM.FOLDER && item.meta?.folders?.total > 0) {
        return true;
      }

      return this.moving_creatives && item.item_type === LISTITEM.CAMPAIGN;
    },

    canMoveTo(item, tooltip = false) {
      item = item ?? this.navigator?.current;

      // cannot move items to same location as they already are
      if (this.start_location?.item_id === item.item_id) {
        if (tooltip) {
          this.save_button_tooltip = 'Cannot move items to the same location.';
        }
        return false;
      }

      // cannot move creatives/creative-folders to campaign-folder or in current place
      if (this.moving_creatives) {
        if (item?.campaign_id != null && item?.campaign_id !== this.$route.params.campaign_id) {
          return true;
        }

        if (tooltip && !this.in_campaign) {
          this.save_button_tooltip = 'Creatives cannot be moved out of the campaign.';
        }

        return this.in_campaign;
      }

      // cannot move live items outside of campaign
      if (this.has_live_items && !this.copy && !this.in_campaign) {
        return false;
      }

      return true;
    },

    move() {
      if (this.processing_request) {
        return;
      }

      this.processing_request = true;

      // by default we are moving items to current view
      let path = this.pathFromNavigator();
      let key = this.navigator?.current?.type === BREADCRUMB.FOLDER ? 'folder_id' : 'campaign_id';
      let id = this.navigator?.current?.item_id;

      // if user selects item from list, then move to selected item
      if (this.selected_item != null) {
        path = this.selected_item?.path;
        key = this.selected_item?.item_type === LISTITEM.FOLDER ? 'folder_id' : 'campaign_id';
        id = this.selected_item?.item_id;
      }

      const payload = {
        folders: this.selection.selected_folders,
      };

      if (this.moving_creatives) {
        payload.creatives = this.selection.selected_items;
      } else {
        payload.campaigns = this.selection.selected_items;
      }

      let request;

      // if we are moving items to root, we can just remove items from folder
      if (id == null) {
        path = '/';
        request = this.$http.delete(`v2/folder/${this.$route.params.folder_id}/items`, payload);
      } else {
        payload[key] = id;
        request = this.$http.post(this.copy ? 'creatives/copy' : 'v2/move', payload);
      }

      request
        .then(success => {
          if (success) {
            this.askPermissionToNavigateToDestination(path);
          }
        })
        .finally(() => {
          this.processing_request = false;
        });
    },

    async askPermissionToNavigateToDestination(path) {
      this.close();
      this.updateServices();

      if (!path) {
        return;
      }

      const action = this.copy ? 'copied' : 'moved';

      const result = await this.$confirm(`Items ${action}`, `Selected item(s) have been successfully ${action} to the chosen destination.`, {
        buttons: [
          { type: 'link-primary', label: 'Close', action: false },
          { type: 'primary', label: 'To Destination', action: true },
        ],
      });

      if (result) {
        this.$router.push({ path }).catch(() => {
          /** catch error when navigating to the same page */
        });
      }
    },

    updateServices() {
      if (!this.copy) {
        if (this.moving_creatives) {
          CreativesService.removeItems(this.selection.selected);
        } else {
          CampaignService.removeItems(this.selection.selected);
        }
      }

      this.selection.unselectAll();
    },

    pathFromNavigator() {
      try {
        return new URL(this.navigator.current?.preview_url)?.pathname;
      } catch {
        const id = this.navigator.current?.item_id;

        switch (this.navigator?.current?.type) {
          case BREADCRUMB.FOLDER:
            const campaign = this.navigator.history?.find(item => item.type === BREADCRUMB.CAMPAIGN);

            if (campaign != null) {
              return `/c/${campaign.item_id}/f/${id}`;
            }

            return `/f/${id}`;
          case BREADCRUMB.CAMPAIGN:
            return `/c/${id}`;
          default:
            return '/';
        }
      }
    },
  },
};
</script>
