<template lang="pug">
TestLayout(title="Dropdowns")

  TestSection(subtitle="Default")
    Dropdown(:options="optionsa")
      template(v-slot:button="scope")
        Button(type="primary" :label="`opened: ${scope.opened}`")
    pre {{modela}}
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Button from '@master/UI/Buttons/Button';
import Dropdown from '@master/UI/Dropdown';

export default {
  name: 'DropdownTest',

  components: {
    TestLayout,
    TestSection,

    Button,
    Dropdown,
  },

  data() {
    return {
      optionsa: [
        {
          value: 'foo',
          label: 'Foo',
          onclick: option => {
            this.modela = option.value;
          },
        },
        {
          value: 'baz',
          label: 'Baz',
          onclick: option => {
            this.modela = option.value;
          },
        },
      ],
      modela: 'Foo',
    };
  },
};
</script>
