<template lang="pug">
div.template-settings
  div.template-settings-warning(v-if="size_warning") Please check placement size values
  div.settings-tab-wrapper(:style="wrapper_style")
    div.settings-tab-content(:style="content_style")
      template(v-if="$parent.isQuantum()")
        TrackingTab(
          v-if="$store.get('active_tab') === 'tracking'"
          class="visible"
          :key="$store.get('quantum_state')"
          :creative="creative"
          :parent="parent"
          :isExpanded="show_expanded_layout"
        )
        CTATab(
          v-else-if="$store.get('active_tab') === 'cta'"
          class="visible"
          :key="$store.get('quantum_state')"
          :creative="creative"
          :parent="parent"
          :isExpanded="show_expanded_layout"
        )

      Loading(v-if="arno == null")
      template(v-else)
        div.assets-list(:class="{'visible': $store.get('active_tab') === 'assets'}")
          template(v-if="assets_are_already_groupped")
            AssetsTab(
              ref="assets-primary"
              key="0"
              :fixed="assets_are_already_groupped"
              :creative="creative"
              :sizeset="sizeset"
              :arno="arno"
              @change="assetChangeHandler"
              @reload="assetReloadChangeHandler"
            )
          template(v-else)
            AssetsTab(
              ref="assets-primary"
              key="0"
              :main="true"
              :creative="creative"
              :sizeset="sizeset"
              :background="true"
              :arno="arno"
              @change="assetChangeHandler"
              @reload="assetReloadChangeHandler"
            )
            //- optional assets, exclude bg and ctas for quantum
            AssetsTab(
              ref="assets-optional"
              key="1"
              :sizeset="sizeset"
              :creative="creative"
              :main="true"
              :optional="true"
              :cta="true"
              :background="true"
              :arno="arno"
              @change="assetChangeHandler"
              @reload="assetReloadChangeHandler"
            )
        div.assets-list(v-if="!$parent.isQuantum()" :class="{'visible': $store.get('active_tab') === 'extras'}")
          AssetsTab(
            ref="assets-extras" key="extras"
            :creative="creative"
            :extras="true"
            :optional="true"
            :cta="true"
            :arno="arno"
            @change="change"
            @reload="assetReloadChangeHandler"
          )
          EffectsBlock(v-if="!$parent.isVast()" :creative="creative" :settings="creative.settings" @change="settingsChange")
        TabOptions.assets-list(:class="{'visible': $store.get('active_tab') === 'options'}" :creative="creative" :arno="arno" @change="settingsChange")
</template>

<script>
import AssetsTab from './TemplateSettings/AssetsTab.vue';
import TabOptions from './Tabs/Options.vue';
import EffectsBlock from './TemplateSettings/EffectsBlock.vue';
import TrackingTab from '@cm/Views/Creatives/Cards/Components/Tracking/TrackingTab.vue';
import CTATab from '@cm/Views/Creatives/Cards/Components/Tracking/CTATab.vue';
import Loading from '@master/UI/Loading.vue';

import { sleep } from '@helpers/Global';
import { alert } from '@libs/alerts';
import CreativeSizeService from '@master/Services/CreativeSizeService';

export default {
  name: 'Settings',

  components: {
    AssetsTab,
    TabOptions,
    EffectsBlock,
    TrackingTab,
    CTATab,
    Loading,
  },

  props: {
    creative: Object,
    // on two state layouts, parent is first state, otherwise parent === creative
    parent: Object,
    isVast: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    dcoFeed: {
      type: Array,
      default: null,
    },
    sizeset: {
      type: String,
      default: null,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    show_expanded_layout: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    content_style() {
      let style = {};

      // will be rerendered if active tab changes (as well initially after mounted)
      if (this.active_tab != null) {
        let max = 0;

        // since vue disallows same ref keys (unless in loop), select all the assets-list classes from this element
        const tabs = this.$el.querySelectorAll('.assets-list');
        for (const el of tabs) {
          let height = el.clientHeight;
          if (height != null) {
            max = Math.max(height, max);
          }
        }

        // set the longes tab size as the wrapper min height
        style.minHeight = max + 'px';
      }
      return style;
    },

    wrapper_style() {
      let style = {};

      // will be rerendered if active tab changes (as well initially after mounted)
      if (this.active_tab != null) {
        if (this.floating) {
          // if the panel is floating (responsive overview tab), get the height of the parent element and add overflow scroll
          style.overflowY = 'auto';
          if (this.$parent?.$refs?.preview?.container_style != null) {
            style.maxHeight = parseInt(this.$parent.$refs.preview.container_style.minHeight, 10) - 40 + 'px';
          }
        }
      }
      return style;
    },

    creative_size() {
      return this.creative.width + this.creative.height;
    },

    is_splitscreen_carousel() {
      if (!this.creative?.template?.template_base) return false;
      return this.creative.template.template_base.startsWith('splitCarouselVideo');
    },

    assets_are_already_groupped() {
      if (!this.creative?.template?.assets) return true;

      for (const asset of Object.values(this.creative.template.assets)) {
        // if at least one asset has auto group (or null value), return false
        if (asset.ui_group == null || asset.ui_group === 'auto') return false;
      }

      return true;
    },
  },

  data() {
    return {
      active_feed_item_index: 0,
      arno: null,
      destroyed: false,
      size_warning: false,
    };
  },

  created() {
    CreativeSizeService.subscribe(state => {
      this.size_warning = state?.has_warning ?? false;
    }, this);

    if (this.creative?.template?.settings != null && this.creative.settings != null) {
      // fill initial missing settings from template
      for (const key in this.creative.template.settings) {
        if (this.creative.settings[key] == null) {
          this.$set(this.creative.settings, 'key', this.creative.template.settings[key]);
        }
      }

      if (this.creative.settings.cta == null) {
        this.$set(this.creative.settings, 'cta', {
          enabled: false,
          assets: {},
        });
      } else {
        if (this.creative.settings.cta.enabled == null) {
          this.$set(this.creative.settings.cta, 'enabled', false);
        }
        if (this.creative.settings.cta.assets == null) {
          this.$set(this.creative.settings.cta, 'assets', {});
        }
      }
    }
  },

  mounted() {
    this.initArno();
  },

  beforeDestroy() {
    this.destroyed = true;
    if (this.arno?.close != null) {
      this.arno.close();
    }
  },

  methods: {
    async waitArno(time) {
      if (this.destroyed) return;

      await sleep(time);
      if (!window.Arno) {
        if (time * 2 >= 8000) {
          await alert(undefined, 'Failed to initialize layout, reloading page...<br>If this keeps happening, please contact support');
          return window.location.reload();
        }
        await this.waitArno(time * 2);
      }
    },

    async initArno() {
      if (this.arno?.close != null) {
        this.arno.close();
      }
      this.arno = null;

      if (this.is_splitscreen_carousel) {
        // bugsnag has reported that sometimes arno has not been loaded
        if (!window.Arno) {
          await this.waitArno(1000);
        }

        if (window.Arno) {
          this.arno = await new Arno({
            templateBase: 'splitCarouselVideo',
            width: this.creative.width,
            height: this.creative.height,
            // nb! creative.settings is always expected to exist
            settings: { splitscreenReverse: this.creative.settings.splitscreen_reverse ?? false },
          });
        }
        return;
      }

      // since arno is called async, some things wait for it
      // set arno to empty object to create slots that have arno != null checks
      this.arno = {};
    },

    isLoading() {
      const refs = ['assets-primary', 'assets-optional', 'assets-extras', 'assets-optional-background'];

      for (const ref of refs) {
        if (this.$refs?.[ref]?.$refs != null) {
          const assets = this.$refs[ref].$refs.asset || [];
          for (const asset of assets) {
            if (asset.loading !== null) {
              return true;
            }
          }
        }
      }
      return false;
    },

    change() {
      this.$emit('change');
    },

    settingsChange(reload_assets_library = false) {
      if (reload_assets_library) {
        this.$emit('assetsChange');
      }
    },

    assetChangeHandler(type = 'asset') {
      if (type === 'asset') {
        this.$emit('assetsChange');
      } else if (type === 'dco') {
        this.$emit('DCOChange');
      } else {
        this.$emit('change');
      }
    },

    assetReloadChangeHandler() {
      // alias for reload emits
      this.assetChangeHandler('asset');
    },
  },
  watch: {
    creative_size: {
      handler() {
        this.initArno();
      },
    },
    'creative.settings.splitscreen_reverse': {
      handler() {
        this.initArno();
      },
    },
  },
};
</script>
