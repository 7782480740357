<template lang="pug">
aside#analytics-options-sidebar.analytics-card(:class="styles.wrapper")
  div(:class="styles.title") Show data for:

  SidebarFilters

  Group(v-if="Controller.show_sidebar_impresion_type_switch" label="Calculate reports based on:" :tooltip="{template: 'analytics.switch', position: 'left'}")
    CustomCheckbox(
      :radio="true"
      :value="filters.base === 'impressions'"
      @change="filters.base = 'impressions'"
    )
      span.ml-4 Impressions
    CustomCheckbox.mt-4(
      :radio="true"
      :value="filters.base === 'viewable'"
      @change="filters.base = 'viewable'"
    )
      span.ml-4 Viewable Impressions

  Group(v-if="show_vtr_toggle" label="Engagement calculation includes:")
    CustomCheckbox(
      :value="filters.incvtr === 'true' || filters.incvtr === true"
      :disabled="video_eng_inc_disabled"
      @change="filters.incvtr = $event"
    )
      span.ml-4(v-tooltip="{value: 'Select whether to include impressions where users watched at least 25% of video'}") Video first quartile viewing (VTR25%)

  Group(v-if="is_creative_view && !is_sdk_user" label="Devices:")
    SearchSelect(v-model="filters.device" :options="devices" :search="false" @change="filters.device = $event?.value")

  Group(v-if="$route?.params?.flight_id != null && is_creative_view" label="Flight rule groups:" :bg="true")
    SearchSelect.bg-white(v-model="filters.flight_condition_key" :options="ruleset_options" :search="false" :disabled="ruleset_options == null" @change="filters.flight_condition_key = $event?.value")

  Group(v-if="admin_toggle" label="Traffic (NEXD only):")
    SearchSelect(v-model="filters.traffic" :options="{all: 'All', inapp: 'Inapp', web: 'Web'}" :input="false")

  template(v-if="this.$route.meta.requires_auth && data != null && !isDraft(data?.status)")
    hr(:class="styles.separator")
    ExportCampaign
</template>

<script>
import styles from './Sidebar.module.scss';

import AnalyticsService from '@master/Services/AnalyticsService';
import CreativeService from '@master/Services/CreativeService';
import FlightAnalyticsService from '@master/Services/FlightAnalyticsService';
import { filters, Controller } from '@master/Services/AnalyticsAPPService';

import CampaignTraits from '@cm/Views/Campaigns/Traits/CampaignTraits.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import ExportCampaign from '@analytics/UI/Sidebar/Components/ExportCampaign.vue';
import Group from '@analytics/UI/Sidebar/Components/Group.vue';
import SidebarFilters from '@analytics/UI/Sidebar/Components/SidebarFilters.vue';

import CustomCheckbox from '@master/UI/CustomCheckbox.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';

export default {
  name: 'Sidebar',
  mixins: [CampaignTraits, SDKMixin],

  components: {
    CustomCheckbox,
    ExportCampaign,
    Group,
    SearchSelect,
    SidebarFilters,
  },

  computed: {
    show_vtr_toggle() {
      if (this.creative) {
        return this.creative?.has_video;
      }
      return this.data?.has_video;
    },

    video_eng_inc_disabled() {
      // vid eng switch is only enabled for creatives analytics after 1st of june
      // used reverse date check just because if theres error with the data type it would be true
      // also if date range selected is smaller than 1st of july, disable
      const status = filters.start < 1593561600;

      // maybe this check must be added as well, not sure... disabled for easier testing
      // this.campaign == null || this.campaign.started_on == null || !(this.campaign.started_on > 1590969600) &&

      // validate checkbox if the status is true (disabled) the filter must be true (default)
      if (status && !filters.incvtr) {
        filters.incvtr = true;
      }

      return status;
    },

    devices() {
      const enabled_devices = Controller.enabledDevices();

      let categories = [
        { value: 'all', label: 'All devices' },
        {
          value: 'mobile',
          label: 'Mobile',
          disabled: enabled_devices?.['mobile'] !== true,
        },
        {
          value: 'desktop',
          label: 'Desktop',
          disabled: enabled_devices?.['desktop'] !== true,
        },
        {
          value: 'tv',
          label: 'TV',
          disabled: enabled_devices?.['tv'] !== true,
        },
      ];

      if (this.admin_toggle) {
        categories.push({
          value: 'validators',
          label: 'Robots & Validators',
          disabled: enabled_devices?.['validators'] !== true,
        });
      }

      return categories;
    },

    is_creative_view() {
      return this.$route?.params?.live_id != null || this.$route?.params?.public_live_id != null;
    },
  },

  data() {
    return {
      styles,
      filters,
      Controller,

      admin_toggle: false,
      data: null,
      creative: null,
      ruleset_options: null,
    };
  },

  async created() {
    this.$user.subscribe(() => {
      this.admin_toggle = this.$user.adminMode();
    }, this);

    AnalyticsService.subscribe(data => {
      this.data = data;
    }, this);

    CreativeService.subscribe(creative => {
      this.creative = creative;
    }, this);

    if (this.$route?.params?.flight_id != null && this.is_creative_view) {
      this.ruleset_options = await FlightAnalyticsService.getOptions();
    }
  },
};
</script>
