<script>
export default {
  computed: {
    instream_expert() {
      return this.$user.isInstreamExpert();
    },

    show_responsive_type_option() {
      return (
        this.admin_toggle || this.enterprise_user || this.has_responsive_feature
      );
    },

    show_skin_type_options() {
      return this.admin_toggle || this.enterprise_user;
    },
  },

  data() {
    return {
      admin_toggle: false,
      enterprise_user: false,
      has_responsive_feature: false,
    };
  },

  created() {
    this.$user.subscribe(_ => {
      this.admin_toggle = this.$user.adminMode();
      this.enterprise_user = this.$user.isEnterpriseUser();
      this.has_responsive_feature = this.$user.isResponsiveApproved();
    }, this);
  },
};
</script>
