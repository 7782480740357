import CropperHelper from '@helpers/Cropper';

export default {
  install: Vue => {
    Vue.mixin({
      beforeCreate() {
        this.$cropper = () => {
          return new CropperHelper(this.$route, Vue);
        };
      },
    });
  },
};
