<template lang="pug">
TestLayout(title="Toggle")

  TestSection(subtitle="Default")
    Toggle(v-model="modela")
    pre {{modela}}

  TestSection(subtitle="Certain on/ off")
    Toggle(v-model="modelb" on="foo" off="baz")
    pre {{modelb}}

  TestSection(subtitle="Disabled")
    Toggle(v-model="modelc" :disabled="true")
    pre {{modelc}}
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Toggle from '@master/UI/Toggle.vue';

export default {
  name: 'ToggleTest',

  components: {
    TestLayout,
    TestSection,

    Toggle,
  },

  data() {
    return {
      modela: false,
      modelb: 'foo',
      modelc: false,
    };
  },
};
</script>
