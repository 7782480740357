<template lang="pug">
div(:style="style")
</template>

<script>
import Animation from './Animation';

export default {
  name: 'AnimationTimelineLine',

  props: {
    animation: {
      type: Animation,
      required: true,
    },
    height: {
      type: Number,
      default: 2,
    },
  },

  computed: {
    style() {
      return {
        background: `linear-gradient(
          90deg,
          var(--primary-color-lighter) ${this.animation_start_percentage}%,
          var(--primary-color) ${this.animation_start_percentage}%,
          var(--primary-color) ${this.animation_end_percentage}%,
          var(--primary-color-lighter) ${this.animation_end_percentage}%
        )`,
        height: `${this.height}px`,
      };
    },

    animation_start_percentage() {
      if (!this.animation) return 0;
      return (this.animation.delay * 100) / this.animation.timeline.duration;
    },

    animation_end_percentage() {
      if (!this.animation) return 0;
      return ((this.animation.delay + this.animation.duration) * 100) / this.animation.timeline.duration;
    },
  },
};
</script>
