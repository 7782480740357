<script>
import RequestLimiter from '@libs/RequestLimiter';
import { clone } from '@helpers/Global';

export default {
  computed: {
    needs_expanding_textarea() {
      const included = ['assets.survey.introscreen', 'assets.survey.endscreen'];
      if (this.parent != null) return included.includes(this.parent.ui_group_key);
      if (this.parent_slot != null) return included.includes(this.parent_slot.ui_group_key);
      return false;
    },
  },

  methods: {
    modularitySetup() {
      this.applyCreativeObjectsToLocalObject();

      this.setModuleDefaults();

      for (const k in this.module.slots) {
        if (this.module.slots[k].modules != null) {
          for (const i in this.module.slots[k].modules) {
            if (this.module.slots[k].modules[i].module_id === 'text') {
              // Currently allowing to display only text module inside module.
              this.module_slots[k] = this.module.slots[k];
            } else if (this.module.slots[k].modules[i].module_id === 'image' && this.module.module_id !== 'button') {
              // Disabling background slots for button - but allowing for background module
              this.module_slots[k] = this.module.slots[k];
            }
          }
        }
      }

      this.$on('parentremovecall', () => {
        // console.log('CHILD got RESET Request -> THIS -> ', this.$vnode.key);
        this.resetToDefaults();
      });
    },

    applyCreativeObjectsToLocalObject(name, id) {
      if (id == null) {
        id = this.$vnode.key;
      }
      if (name == null) {
        name = 'object';
      }

      this.$set(this, name, {});
      if (this.creative != null && this.creative.objects != null && this.creative.objects[id] != null) {
        this.$set(this, name, this.creative.objects[id]);
      }
    },

    setModuleDefaults() {
      if (this.object.data == null) {
        this.$set(this.object, 'data', {});
      }
      if (this.object.settings == null) {
        // If parent has some default value  for it's child module, we will apply those here
        if (this.parent != null && this.module != null && this.module.module_id != null && this.parent.settings[this.module.module_id] != null) {
          this.$set(this.object, 'settings', clone(this.parent.settings[this.module.module_id]));
        } else {
          this.$set(this.object, 'settings', {});
        }
      }

      if (this.module.settings != null) {
        // Fill module default settings - if creative doesn't have anything - currently only shallow
        for (const key in this.module.settings) {
          if (this.object.settings[key] == null) {
            this.$set(this.object.settings, key, clone(this.module.settings[key]));
          }
        }
      }
    },

    resetToDefaults() {
      this.$delete(this.object, 'data');
      this.$delete(this.object, 'settings');
      if (this.creative != null) {
        this.$delete(this.creative.objects, this.$vnode.key);
      }
      this.setModuleDefaults();
    },

    emitRemoveToChildren(that) {
      if (that.$children != null && that.$children.length > 0) {
        for (const k in that.$children) {
          that.$children[k].$emit('parentremovecall');
          this.emitRemoveToChildren(that.$children[k]);
        }
      }
    },

    removeMyselfAndChildren() {
      // Since all children might not be modules, but might contain modules - we will try to pass message recursively as far as possible
      this.resetToDefaults();
      this.emitRemoveToChildren(this);
    },

    hasUserData() {
      for (const k in this.creative.objects) {
        if (k.indexOf(this.$vnode.key) === 0) {
          // console.log('HAS USER DATA', k);
          return true;
        }
      }
      return false;
    },

    __update() {
      this.$emit('update', 'object', this.module._fullpath, this.object);
    },

    __remove() {
      this.removeMyselfAndChildren();
      this.__delete('object', this.module._fullpath);
    },

    __save(type, id, data) {
      if (data == null) {
        this.__delete(type, id);
        return;
      }

      RequestLimiter.hook(`${type}.${id}`, () => {
        let datatype = type;
        if (type === 'object') {
          datatype = 'objects';
        } else if (type === 'asset') {
          datatype = 'assets';
        }

        let method = 'post';
        if (this.creative[datatype][id] != null) {
          method = 'put';
        }

        this.$http[method](`v2/creative/${this.creative.creative_id}/${datatype}/${id}`, data).then(response => {
          if (response.creative_id != null) {
            delete response.creative_id;
          }
          if (response._inserted != null) {
            // This is a bit $#17, that _inserted is included with object self data/settings, but currently no point of rewriting all those things in result obj.
            const _inserted = response._inserted;
            delete response._inserted;
            for (const indx in _inserted) {
              delete _inserted[indx].creative_id;
              this.$set(this.creative[datatype], _inserted[indx].object_id, _inserted[indx]);
            }
          }
          this.$set(this.creative[datatype], id, response);
        });
      });
    },

    __delete(type, id) {
      RequestLimiter.hook(
        `${type}.${id}`,
        () => {
          let datatype = type;
          if (type === 'object') {
            datatype = 'objects';
          } else if (type === 'asset') {
            datatype = 'assets';
          }
          let method = 'delete';
          this.$http[method](`v2/creative/${this.creative.creative_id}/${datatype}/${id}`).then(response => {
            if (datatype === 'objects' && response.deleted != null) {
              // SHould have it here - cause whenever compoent is closed children are not reachable and we dan't remove this data from creative, but if API tells what to remove - we good
              for (const object_id of response.deleted) {
                this.$delete(this.creative[datatype], object_id);
              }
            } else {
              this.$delete(this.creative[datatype], id);
            }
          });
        },
        1,
      );
    },
  },
};
</script>
