<template lang="pug">
router-link(:to="to")
  div.custom-tr(
    :class="classes"
    :draggable="draggable"
    @dragstart.stop="$emit('dragstart', $event)"
    @drag="$emit('drag', $event)"
    @dragend="$emit('dragend', $event)"
    @drop="$emit('drop', $event)"
    @dragover="$emit('dragover', $event)"
    @dragenter="$emit('dragenter', $event)"
    @dragleave="$emit('dragleave', $event)"
  )
    slot
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    to: {
      type: [String, Object],
      required: true
    },

    draggable: {
      type: Boolean,
      default: false
    },

    classes: {
      type: [String, Object, Array],
      default: () => (null)
    }
  }
};
</script>
