import Vue from 'vue';

function addFacebookScript(callback = null) {
  const id = 'facebook-jssdk';
  if (document.getElementById(id)) {
    if (callback) return callback();
  }

  let js = document.createElement('script');
  js.id = id;
  js.src = 'https://connect.facebook.net/en_US/sdk.js?_=' + Vue.prototype.$version;
  document.body.append(js);
}

function getUserFacebookPages(user_id, token = null) {
  return new Promise((resolve, reject) => {
    const query = 'fields=name,username,id,picture{url,width,height},access_token';
    window.FB.api(`/${user_id}/accounts?access_token=${token}&${query}`, pages => {
      if (pages.error) {
        reject(pages.error.message);
      } else {
        resolve(pages.data);
      }
    });
  });
}

function getFacebookPagePosts(page_id, page_token, paging_token = null) {
  return new Promise((resolve, reject) => {
    const query = 'fields=full_picture,permalink_url,message,created_time,attachments,likes.summary(true),comments.summary(true),reactions.summary(true),shares,actions,insights.metric(post_impressions_unique)';
    let path = `/${page_id}/published_posts?access_token=${page_token}&${query}`;
    if (paging_token != null) {
      path += `&after=${paging_token}`;
    }
    window.FB.api(path, posts => {
      if (posts.error) {
        reject(posts.error.message);
      } else {
        resolve({
          posts: posts.data,
          paging: posts.paging != null ? posts.paging.cursors : null,
        });
      }
    });
  });
}

function getFacebookPagePost(page_id, page_token, post_id) {
  return new Promise((resolve, reject) => {
    const query = 'fields=full_picture,permalink_url,message,created_time,attachments,likes.summary(true),comments.summary(true),reactions.summary(true),shares,actions,insights.metric(post_impressions_unique)';
    let path = `/${page_id}_${post_id}?access_token=${page_token}&${query}`;
    window.FB.api(path, post => {
      if (post.error) {
        reject(post.error.message);
      } else {
        resolve(post);
      }
    });
  });
}

const required_user_scopes_readable = [
  { value: 'read_insights', label: 'Access your Page and App insights' },
  { value: 'pages_read_engagement', label: 'Read content posted on the Page' },
  { value: 'pages_read_user_content', label: 'Read user content on your Page' },
  { value: 'pages_show_list', label: 'Show a list of the Pages you manage' },
  { value: 'business_management', label: 'Show a list of the business Pages you manage' },
];

const required_user_scopes = required_user_scopes_readable.map(scope => scope.value);

export { addFacebookScript, getUserFacebookPages, getFacebookPagePosts, getFacebookPagePost, required_user_scopes, required_user_scopes_readable };
