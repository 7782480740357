<template lang="pug">
  div(ref="modal" v-if="publishing")
    div.modal.publish-notif-modal
      div.backdrop
      div.modal-content
        div.modal-body
          div.publish-notif-content
            div.publish-notif-img
              img(:src="$cdn + 'dist/assets/cm/publishing-progress.svg'" alt="publishing")
            div.publish-notif-label Exporting...
            div It takes just a few moments
</template>

<script>
import Modal from '@master/UI/Modal';

import CreativesService from '@master/Services/CreativesService';
import DSPService from '@master/Services/Cache/DSPService';

export default {
  name: 'PublishingNotification',

  extends: Modal,
  props: {
    campaign: {
      type: Object,
      default: () => (null)
    },

    creatives: {
      type: Array,
      default: () => ([])
    },

    updateTag: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      publishing: false,
      loading: false
    };
  },

  created() {
    this.update();
  },

  methods: {
    async confirmUserTagUpdates() {
      const uniq_elements = this.creatives
        .reduce((updated_elements, creative) => {
          if (DSPService.hasZip(creative.dsp)) {
            updated_elements.add('zip');
          } else {
            updated_elements.add('tag');
          }

          return updated_elements;
        }, new Set);

      const header = 'These changes require a new ' + [...uniq_elements].join(' and ');
      const message = 'To get these changes to appear in live, you need to update your (' +
        [...uniq_elements].join(', ') +
        ') file. After it is updated, we will automatically generate a new (' +
        [...uniq_elements].join(', ') +
        ') file for you to download.';

      return await this.$confirm(header, message);
    },

    async update() {
      if (this.updateTag && !await this.confirmUserTagUpdates()) {
        return this.close(true);
      }

      this.publishing = true;

      const post_data = {
        creatives: this.creatives.map(creative => creative.creative_id),
        save: true,
        publishData: {
          published_by: null
        }
      };

      this.$http.post(`campaigns/${this.campaign.campaign_id}/publish`, post_data, { notification: false })
        .then(response => {
          if (response.data) {
            for (const creative of this.creatives) {
              if (response?.data?.info?.creatives[creative.creative_id] != null) {
                for (const key in creative) {
                  const values = response.data.info.creatives[creative.creative_id];
                  if (values[key] != null) {
                    this.$set(creative, key, values[key]);
                  }
                }
              }
            }
          }
          this.$emit('onExport', { tag_update: this.updateTag });
          this.close();
          CreativesService.export.resetNeedTagUpdate();
        })
        .catch(error => {
          this.error(error.response.msg);
        })
        .finally(_ => {
          this.publishing = false;
        });
    },

    close(error = false) {
      this.$emit('close', error);
    },

    error(msg = 'Something went wrong when publishing, please try again later.') {
      this.$alert(msg);
      this.close(true);
    }
  }
};
</script>
