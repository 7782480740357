<template lang="pug">
  span.download-tags
    i.nexd-icon-32-tag(aria-hidden="true" v-tooltip="{value: 'Download Tag', appendTo: 'self'}" @click.stop="downloadTag(false)")
    i.nexd-icon-32-multiple-tags(
      v-if="isQuantum()"
      aria-hidden="true"
      v-tooltip="{value: 'Download as separate tags', appendTo: 'self'}"
      @click.stop="downloadTag(true)"
    )
</template>

<script>
import CreativeTraits from '@master/Traits/CreativeTraits';

export default {
  name: 'DownloadTagsButton',
  mixins: [CreativeTraits],
  props: {
    creative: Object
  },
  methods: {
    downloadTag(multiple = false) {
      const path = `campaigns/${this.creative.campaign_id}/creatives/tag/${this.creative.creative_id}${multiple ? '?multiple=true' : ''}`;
      this.$http.get(path).then(() => { /** download handled by dataservice */ });
    }
  }
};
</script>
