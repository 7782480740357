<template>
  <div v-on-clickaway="away" class="select-control" @click="toggleStatus();" :class="{open: this.status === true}">
    <div class="option">
      <span class="outline-circle" :class="active.class"/>
      <span class="value">{{active.value}}</span>
      <i class="nexd-icon-32-arrow-down-small" aria-hidden="true"/>
    </div>
    <div class="options" :class="{'visible': status === true}">
      <template v-for="(item, key) in colorOptions" >
        <div v-if="active.value !== item.value" :key="key" class="option" @click="selectItem(key, item);">
          <span class="outline-circle" :class="item.class"/><span class="value">{{item.value}}</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>

import { filters, colorOptions, Controller } from '@master/Services/AnalyticsAPPService';
import { clone } from '@helpers/Global';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'FilterSelect',
  mixins: [clickaway],
  model: {
    event: 'change'
  },
  props: {
    vModel: String,
    default: {
      type: Number,
      default: 0
    },
    hasVideo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filters,
      status: false,
      active: null,
      defaultKey: null,
      colorOptions: clone(colorOptions)
    };
  },
  created () {
    this.optionsHandler();
    this.active = this.colorOptions[this.defaultKey];
    for (let key in this.colorOptions) {
      let item = this.colorOptions[key];
      if (key === this.vModel) {
        this.active = item;
        break;
      }
    }
    if (this.vModel == null) {
      this.$emit('change', this.defaultKey);
    }
  },
  methods: {
    selectItem (key, item) {
      this.active = item;
      this.$emit('change', key);
    },
    toggleStatus () {
      this.status = !this.status;
    },
    away () {
      this.status = false;
    },
    optionsHandler () {
      let fixed_default = this.default;
      this.colorOptions = clone(colorOptions);
      // outcommented, because we have not vtr option currenlty
      // if (this.hasVideo === false) {
      //   delete this.colorOptions.vtr;
      // }

      // remove viewability options when company has viwability disabled
      if (!Controller.isViewabilityEnabled()) {
        delete this.colorOptions.viewability;
        delete this.colorOptions.viewable;

        // since viewable is removed, inc default by 1 if needed
        if (this.default > 1) {
          fixed_default--;
        }
      }

      const options = Object.keys(this.colorOptions);
      if (fixed_default > options.length - 1) {
        fixed_default = options.length - 1;
      }
      this.defaultKey = Object.keys(this.colorOptions)[fixed_default];

      if ((filters.base === 'viewable') && this.defaultKey === 'impressions' && this.colorOptions.viewable != null) {
        this.defaultKey = 'viewable';
      }
    }
  },
  watch: {
    hasVideo: {
      handler () {
        this.optionsHandler();
      }
    },
    'filters.base': {
      handler () {
        this.optionsHandler();
        // reset to default if impressions or viewable is selected and option removed
        if (this.colorOptions[this.active.value] == null) {
          this.active = this.colorOptions[this.defaultKey];
        }
      }
    }
  }
};
</script>
