<template lang="pug">
ListItem(
  :to="folder.path"
  :classes="folder_dynamic_class"
  :draggable="can_update"
  @dragstart.stop="dragStart"
  @drag="dragScroll"
  @dragend="dragEnd"
  @drop="drop"
  @dragover.prevent=""
  @dragenter.prevent="dragEnter"
  @dragleave.prevent="dragLeave"
)
  div.custom-td
    div.status(:class="folder_status" v-tooltip="{value: status, position: 'right'}")
    CustomCheckbox(
      v-if="can_update"
      :value="selection.selected_folders.includes(folder.folder_id)"
      :padded="true"
      @change="selection.toggle(folder)"
    )
  div.custom-td.fw-500
    FolderThumbnail.mr-16(v-if="folder?.folder_id != null" :count="folder?.meta?.campaigns?.total ?? 0")
    div.title.overflow-ellipsis(v-tooltip="{value: folder.name}") {{ folder.name }}
  div.custom-td.color-primary
    i.nexd-icon-16-shared-by(v-if="!is_owner" aria-hidden="true" v-tooltip="{value: folder?.owner?.name != null ? folder.owner.name : null}")
  div.custom-td.color-gray-800
    div {{ folder.updated_on | DateFilter }}
  div.custom-td
    div {{ folder?.meta?.creatives?.total ?? 0 }}
  template(v-for="(percentage, key) in analytics_keys")
    div.custom-td
      template(v-if="folder?.meta?.analytics != null")
        div(v-if="percentage") {{ folder.meta.analytics[key] | PercentageFilter }}
        div(v-else) {{ folder.meta.analytics[key] | ShortNumberFilter }}
      div.color-gray-800(v-else) -
  div.custom-td
    i.nexd-icon-32-edit(
      v-if="can_update"
      aria-hidden="true"
      v-tooltip="{value: 'Edit'}"
      @click.stop.prevent="setActiveFolder"
    )
  FolderQuickNavigation(:folder="folder")
</template>

<script>
import FolderMixin from '@root/src/global/mixins/FolderMixin';
import CampaignService from '@master/Services/CampaignService';
import FolderTraits from '@master/Traits/FolderTraits';
import { BREADCRUMB } from '@master/constants';

import CustomCheckbox from '@master/UI/CustomCheckbox';
import FolderThumbnail from '@cm/UI/Global/Thumbnail/FolderThumbnail';
import FolderQuickNavigation from '@cm/Views/Campaigns/Folder/FolderQuickNavigation';
import ListItem from '@cm/UI/Global/ListItem';

import DraggableListItemMixin from '@root/src/global/mixins/DraggableListItemMixin';

export default {
  mixins: [FolderTraits, FolderMixin, DraggableListItemMixin],

  components: {
    CustomCheckbox,
    FolderThumbnail,
    FolderQuickNavigation,
    ListItem,
  },

  computed: {
    folder_dynamic_class() {
      let classes = [];
      if (this.is_active_folder) {
        classes.push('active');
      }
      if (this.add_to_folder_in_progress) {
        classes.push('drop-area');
      }
      if (this.show_drag_message) {
        classes.push('show-message');
      }
      return classes;
    },

    selected() {
      return this.selection.has_folders_selection && this.selection.selected_folders.includes(this.folder.folder_id);
    },
  },

  data() {
    return {
      BREADCRUMB,
    };
  },

  created() {
    this.prepareDraggableImage();
  },

  methods: {
    dragStart(e) {
      if (!this.can_update) {
        e.preventDefault();
        return false;
      }

      e.dataTransfer.setData('action', 'add-to-folder');

      let payload = {
        campaigns: [],
        folders: [this.folder.folder_id],
      };

      if (this.selected && this.selection.is_some_selected) {
        payload.campaigns = this.selection.selected_items;
        payload.folders = this.selection.selected_folders;
      }

      const dragging_items = Object.values(payload).flat().length;

      e.dataTransfer.setData('application/payload', JSON.stringify(payload));
      e.dataTransfer.setDragImage(this.createDraggable(this.folder.name, dragging_items), 0, 0);

      this.$store.set('add_to_folder_folderids', payload.folders);
      this.$store.set('add_to_folder_in_progress', true);
    },

    drop(e) {
      this.addToFolderStopped();

      if (e.dataTransfer.getData('action') !== 'add-to-folder') return;

      const payload = JSON.parse(e.dataTransfer.getData('application/payload'));

      if (payload.folders.includes(this.folder.folder_id)) return;

      this.$http
        .post('v2/move', { folder_id: this.folder.folder_id, ...payload })
        .catch(_ => {
          /** supress errors */
        })
        .finally(() => {
          CampaignService.load();
          this.selection.unselectAll();
        });
    },
  },
};
</script>
