<template lang="pug">
div(ref="modal")
  div.modal(v-show="creative != null")
    div.backdrop
    div.modal-content.small
      div.modal-head
        div.modal-title Optimize Creative
          i.nexd-icon-32-help-big(v-tooltip="{template: 'cm.creativeOptimizer', appendTo: 'self'}")
      div.modal-body
        CreativeOptimizer(
          v-if="session_id != null"
          :key="options.optimized.quality"
          :original="options.original"
          :total="options.total"
          :optimized="options.optimized"
          @calculateNewSize="calculateNewOptimizedSize"
          @showLoading="showLoading"
        )
        Loading(v-if="loading" :fill="true" :text="loading_text")

      div.modal-footer.flex.flex-align-center
        div.text-left.color-gray-800.fs-14(v-if="options.original.size != null") Original uploaded assets size:&nbsp;
          span.fs-22 {{ options.original.size | FileSize(2) }}

        Buttons.flex-grow
          Button(type="link-primary" label="Cancel" @click="cancel")
          Button(type="primary" label="Save" :animate="true" @click="save")
</template>
<script>
import CreativeOptimizer from '@master/UI/CreativeOptimizer';
import Loading from '@master/UI/Loading';
import Modal from '@master/UI/Modal';

import Buttons from '@master/UI/Buttons/Buttons';
import Button from '@master/UI/Buttons/Button';

export default {
  name: 'CreativeOptimizerModal',
  extends: Modal,
  components: {
    Buttons,
    Button,
    Loading,
    CreativeOptimizer,
  },

  props: {
    creative: Object,
  },

  data() {
    return {
      session_id: null,
      loading: true,
      loading_text: null,
      options: {
        original: {
          size: this.creative.packsize.draft.total_size,
          quality: this.creative.quality_lvl,
        },
        optimized: {
          size: this.creative.packsize.draft.total_size,
          quality: this.creative.quality_lvl,
        },
        total: {
          size: this.creative.packsize.draft.total_size,
        },
      },
    };
  },

  created() {
    // Creating optimizer session
    this.$http
      .post(`creatives/${this.creative.creative_id}/optimize/initialize`)
      .then(result => {
        this.session_id = result.id;
      })
      .catch(() => {
        this.$emit('close');
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    cancel() {
      this.cleanup();
      this.$emit('close');
    },

    save() {
      this.loading = true;

      const quality_level = this.options.optimized.quality;
      if (this.creative.quality_lvl === quality_level) {
        // If not changed actually no need to trigger Backend saving
        this.cleanup();
        this.$emit('close');
        return;
      }

      // save will trigger cleanup on the back
      const path = `creatives/${this.creative.creative_id}/optimize/${this.session_id}`;
      this.$http
        .put(path, { quality: quality_level })
        .then(result => {
          if (result !== false) {
            this.$emit('save', quality_level, result);
          } else {
            this.$emit('close');
            this.$notifications.add('danger', 'Cannot update creative optimization level. Please contact support@nexd.com');
          }
        })
        .finally(_ => {
          this.loading = false;
        });
    },

    cleanup() {
      // destroy optimizer session
      if (this.session_id != null) {
        const path = `tools/optimizer/${this.session_id}`;
        this.$http.delete(path, {}, { notification: false });
      }
    },

    calculateNewOptimizedSize(quality) {
      this.showLoading('Calculating new load size');
      const path = `creatives/${this.creative.creative_id}/optimize/${this.session_id}/${quality}`;
      this.$http
        .post(path)
        .then(response => {
          this.options.optimized.size = response.draft.total_size;
          this.options.optimized.quality = quality;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showLoading(loading_text = null) {
      this.loading = true;
      this.loading_text = loading_text;
    },
  },
};
</script>
